import React, {
  FunctionComponent,
  RefAttributes,
  PropsWithoutRef,
  ForwardRefExoticComponent,
} from 'react';
import {
  Link as ReactRouterLink,
  NavLink as ReactRouterNavLink,
  LinkProps as RouterLinkProps,
  NavLinkProps as RouterNavLinkProps,
} from 'react-router-dom';
import { Navlink as SliceUILink, INavlinkProps as SliceUILinkProps } from '@lce/slice_v2';

// eslint-disable-next-line max-len
// from @types/theme-ui__components: https://github.com/DefinitelyTyped/DefinitelyTyped/blob/86bd530585927c9a4e16bc8f5db17439b66c30b3/types/theme-ui__components/index.d.ts#L21
type ForwardRef<T, P> = ForwardRefExoticComponent<
PropsWithoutRef<P> & RefAttributes<T>
>;

type IRouterLinkProps = {
  asNav?: boolean;
}

type CombinedProps = SliceUILinkProps & RouterLinkProps<{}> & RouterNavLinkProps<{}> & IRouterLinkProps;

// Must cast as any to prevent $$typeof issue with ExoticComponent<P>
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const FinalLink: ForwardRef<HTMLAnchorElement, CombinedProps> = SliceUILink as any;

/**
 * Component combines the NavLink component from SliceUI with Link Component from React Router
 */
const RouterLink: FunctionComponent<CombinedProps> = ({ children, ...props }) => (
  <FinalLink as={ props.asNav ? ReactRouterNavLink : ReactRouterLink } { ...props }>
    {children}
  </FinalLink>
);

export default RouterLink;
