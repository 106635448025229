import { useState, useCallback, useEffect } from 'react';
import * as query from 'query-string';
import { useLocation, useHistory } from 'react-router-dom';

const useQueryParam = (key: string) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const location = useLocation();
  const history = useHistory();
  const urlSearchParams = new URLSearchParams(location.search);
  const [ param, setParam ] = useState<any>(urlSearchParams.get(key));

  useEffect(() => {
    if (!param) {
      setParam(query.parse(location.search)[key]);
    }
  }, [ key, location.search, param ]);

  const setQueryString = useCallback((newValue) => {
    setParam(newValue);
    const urlSearchParams = new URLSearchParams(window.location.search);
    urlSearchParams.set(key, newValue.toString());
    history.push(`${ window.location.pathname }?${ urlSearchParams.toString() }`);
  }, [ history, key ]);

  return [
    param,
    setQueryString,
  ];
};

export default useQueryParam;
