import React from 'react';
import { Flex } from '@lce/slice_v2';

import { Graphs } from 'ui/Graphs';
import { useMediaQuery } from 'features/common';
import { useFetchSellerDashboardStatistics } from 'features/fundraiser';

export interface IMiniStatsProps {
  sellerId: number;
}

const MiniStats: React.FC<IMiniStatsProps> = ({ sellerId }) => {
  const { data: sellerStats } = useFetchSellerDashboardStatistics(sellerId);
  const isSmall = useMediaQuery('(max-width: 345px)');
  return (
    <Flex
      data-testid="mini-stats"
      sx={ {
        minWidth: [ '', '400px' ],
        justifyContent: 'center',
        flexWrap: isSmall ? 'wrap' : 'nowrap',
        gap: [ '15px', '24px' ],
      } }
    >
      <Graphs graphStats={ sellerStats || [] } isDashboard={ false } />
    </Flex>
  );
};

export default MiniStats;
