/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect } from 'react';

import TagManager from '../TagManager';
import { TagManagerSettings } from '../TagManager/interface';

export interface IGoogleProviderProps {
  children: React.ReactNode;
  settings: any;
  gtm: TagManagerSettings;
}

const GoogleProvider: React.FC<IGoogleProviderProps> = ({ children, settings, gtm }) => {
  useEffect(() => {
    if (settings.CONFIG.GOOGLE_TAG_MANAGER.ID) {
      TagManager.initialize(gtm);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      { children }
    </>
  );
};

export default GoogleProvider;
