import React from 'react';
import { Flex, Text } from '@lce/slice_v2';
import { useTranslation } from '@lce/i18n';
import _map from 'lodash/map';

import FeatureImage from 'assets/images/homepage/feature_image.webp';
import { Button } from 'ui/components';

type HeroImageButtonType = {
  'data-testid': string;
  text: string;
  href: string;
  variant: string;
};

export const HeroImage: React.FC = () => {
  const [ t ] = useTranslation();
  const buttons: HeroImageButtonType[] = t('home.arch.buttons', { returnObjects: true });

  return (
    <Flex
      data-testid="hero-image"
      sx={ {
        alignItems: 'center',
        background: `linear-gradient(rgba(0, 0, 0, 0.4),rgba(0, 0, 0, 0.4)), 
            url(${ FeatureImage }) no-repeat center center`,
        backgroundSize: 'cover',
        color: 'white',
        flexDirection: 'column',
        gap: [ '16px', '32px' ],
        p: [ '100px 16px 50px', '150px 32px', '200px 64px' ],
        textAlign: 'center',
        width: '100%',
      } }
    >
      <Text variant="home.heading">
        {t('home.arch.body')}
      </Text>
      <Flex sx={ { flexDirection: [ 'column', 'row' ], gap: '16px' } }>
        {_map(buttons, button => (
          <Button { ...button } sx={ { width: '226px' } }>
            {button.text}
          </Button>
        ))}
      </Flex>
    </Flex>
  );
};

export default HeroImage;
