import React from 'react';
import {
  Switch, useHistory, useRouteMatch,
} from 'react-router-dom';
import { Box } from '@lce/slice_v2';

import * as FundraiserDefaults from '../../constants/FundraiserDefaults';

import { CreateSalesAgent } from '.';

import { PrivateRouteWrapper } from 'ui/routing';
import { NoLayout } from 'ui/common';

export const Admin: React.FC = () => {
  const history = useHistory();
  const { url } = useRouteMatch();

  //url = /admin
  return (
    <Box
      data-testid="admin-routes" sx={ {
        pt: [ 0, 0, 0, '35px' ], pl: [ '15px', '15px', '92px' ], px: [ '15px', '15px' ], m: [ '0 auto', '0 auto', 0 ],
      } }
    >
      <Switch>
        <PrivateRouteWrapper
          component={ CreateSalesAgent }
          exact={ true }
          history={ history }
          layout={ NoLayout }
          path={ `${ url }/create-sales-agent` }
          roles={ [ FundraiserDefaults.SuperAdminRoleName ] }
        />
      </Switch>
    </Box>
  );
};
