/* eslint-disable max-len */
import React from 'react';
import { useTranslation } from '@lce/i18n';
import { Box, Text } from '@lce/slice_v2';

/**
 * This is a lot of static content
 * TODO: Obtain this content from CMS post 1.1 launch
 */
// eslint-disable-next-line max-lines-per-function
const AboutUsContent: React.FC = () => {
  const [ t ] = useTranslation();

  const detailSection = (title, content) => (
    <Box sx={ { mt: '24px', mb: '24px' } }>
      { title && (
        <Text variant="text.header.subUpper">
          {title}
        </Text>
      )}
      { content && (
        <Text>
          {content}
        </Text>
      )}
    </Box>
  );

  const subTitle = title => (
    <Text
      sx={ {
        fontSize: [ '24px', '24px', '34px' ], color: 'primaryOrange', mt: '16px', mb: '16px',
      } }
      variant="text.header.subUpper"
    >
      {title}
    </Text>
  );

  return (
    <Box sx={ { mb: '30px', mt: '30px', maxWidth: '994px' } }>
      <Text sx={ { mb: '30px' } } variant="text.header.title.large">
        {t('contactUs.Title')}
      </Text>
      <Text sx={ { color: 'primaryOrange', mb: '30px' } } variant="text.h2">
        Life has its challenges right now. Fundraising shouldn't be one of them. We're here to help when you need it.
      </Text>
      {subTitle(t('contactUs.SubTitle'))}
      {detailSection(t('contactUs.TrustedBrandNameTitle'), t('contactUs.TrustedBrandNameContent'))}
      {detailSection(t('contactUs.BigBigProfitTitle'), t('contactUs.BigBigProfitContent'))}
      {detailSection(t('contactUs.AllVirtualTitle'), t('contactUs.AllVirtualContent'))}
      {detailSection(t('contactUs.DirectDeliveryTitle'), t('contactUs.DirectDeliveryContent'))}
      {detailSection(t('contactUs.EngageSellersTitle'), t('contactUs.EngageSellersContent'))}
      {detailSection(t('contactUs.TopNotchTitle'), t('contactUs.TopNotchContent'))}

      {subTitle(t('contactUs.HistoryOfCommitmentTitle'))}
      {detailSection(t('contactUs.AboutLceTitle'), t('contactUs.AboutLceContent1'))}
      {detailSection('', t('contactUs.AboutLceContent2'))}
      {detailSection('', t('contactUs.AboutLceContent3'))}
      {detailSection('', t('contactUs.AboutLceContent4'))}
      {detailSection(t('contactUs.IllitchCompaniesTitle'), t('contactUs.IllitchCompaniesContent'))}
    </Box>
  );
};

export default AboutUsContent;
