import * as React from 'react';
import { Flex, Skeleton } from '@lce/slice_v2';

export const SellerInformationSkeleton = () => (
  <Flex
    data-testid="seller-information-skeleton"
    sx={ { alignItems: 'center', gap: '16px' } }
  >
    <Skeleton count={ 1 } sx={ { height: '130px', width: '130px' } } />
    <Flex sx={ { flexDirection: 'column', width: '400px' } }>
      <Skeleton count={ 1 } sx={ { height: '18px', width: '60%' } } />
      <Skeleton count={ 1 } sx={ { height: '40px', width: '100%' } } />
      <Skeleton count={ 1 } sx={ { height: '7px', width: '80%' } } />
    </Flex>
  </Flex>
);
