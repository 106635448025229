import {
  Container, Flex, Form, Spinner,
} from '@lce/slice_v2';
import React from 'react';
import { toast } from 'react-toastify';

import { EmailNotificationForm } from '../EmailNotificationForm';

import { useUpdateEmailPreferences } from 'features/fundraiser';
import { EmailNotifications } from 'features/fundraiser/types/emailNotifications';
import { ApiError } from 'features/common';
import { FundraiserNotification } from 'features/fundraiser/types/fundraiser';

export interface IEmailNotificationEdit {
  onCloseEmail: () => void;
  error: ApiError | null;
  isFetching: boolean;
  emailNotifications?: EmailNotifications;
  emailEditMode: boolean;
}

type EmailFormValues = {
  NotificationsDisabled: string;
  Notifications: Array<FundraiserNotification>;
}

const EmailNotificationEdit: React.FC<IEmailNotificationEdit> = ({
  onCloseEmail, error, isFetching, emailNotifications, emailEditMode,
}) => {
  const { mutateAsync: updateEmailData, isLoading: isUpdating } = useUpdateEmailPreferences();

  const onFormSubmit = async(formValues: EmailFormValues) => {
    const fundraiserPreferences = {
      NotificationsDisabled: formValues.NotificationsDisabled === 'on' ? true : false,
      Notifications: formValues.Notifications,
    };
    const res = await updateEmailData({ fundraiserPreferences });
    res ? onCloseEmail() : toast.error('There was an error. Please try again.');
  };

  if (isFetching || isUpdating) {
    return (
      <Container sx={ { textAlign: 'center' } }>
        <Spinner data-testid="email-spinner" variant="lce" />
      </Container>
    );
  }

  return (
    <Flex
      data-testid="email-notifications-settings" sx={ {
        flexDirection: 'column', flex: '1', padding: '30px', gap: '10px',
      } }
    >
      <Form
        context={ emailNotifications }
        initialValues={ emailNotifications }
        onSubmit={ onFormSubmit }
      >
        <EmailNotificationForm
          emailEditMode={ emailEditMode }
          emailNotifications={ emailNotifications }
          error={ error }
          onClose={ onCloseEmail }
        />
      </Form>

    </Flex>
  );
};

export default EmailNotificationEdit;
