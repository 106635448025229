import * as React from 'react';
import {
  Box,
  Text,
  Container,
} from '@lce/slice_v2';
import { useTranslation } from '@lce/i18n';

const FaqFooter: React.FC = () => {
  const [ t ] = useTranslation();

  return (
    <Container sx={ { mb: [ '30px', '0' ], mt: [ '20px', '58px' ], maxWidth: '1212px' } }>
      <Box
        sx={ {
          width: '100%', textAlign: 'center', backgroundColor: 'rgb(236,236,236)',
          height: [ '210px', '160px', '145px' ], paddingLeft: '40px', paddingRight: '40px',
          paddingTop: [ '24px', '30px', '42px' ],
        } }
      >
        <Text as="h3" sx={ { fontSize: '20px', fontWeight: '500' } }>
          {t('couponCodeFaqPage.footerLine1')}
        </Text>
        <Text as="h3" sx={ { fontSize: '18px', fontWeight: '500' } }>
          {t('couponCodeFaqPage.footerLine2')}
        </Text>
      </Box>
    </Container>
  );
};

export default FaqFooter;
