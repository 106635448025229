import React, { useCallback, useRef } from 'react';
import { Button } from '@lce/slice_v2';
import { useFormContext } from 'react-hook-form';
import { noop } from 'lodash';
import { ThemeUIStyleObject } from 'theme-ui';

interface IClearButtonProps {
  text: string;
  setReset?: React.Dispatch<React.SetStateAction<Function>>;
  sx?: ThemeUIStyleObject;
}

export const ClearButton: React.FC<IClearButtonProps> = ({ setReset = noop, sx, text }) => {
  const { reset } = useFormContext();
  setReset(reset);

  return (
    <Button
      onClick={ () => reset() }
      sx={ {
        height: '46px',
        width: [ '100%', '100%', '48.5%' ],
        paddingLeft: [ 0, 0, '12px' ],
        marginRight: [ 0, 0, '1.5%' ],
        ...sx,
      } }
      type="button"
      variant="secondary"
    >
      {text}
    </Button>
  );
};

export const useFormReset = () => {
  const resetRef = useRef<Function>();

  const setResetForm = useCallback((resetFn) => {
    if (!resetRef.current) {
      resetRef.current = resetFn;
    }
  }, []);

  return { resetForm: () => resetRef.current && resetRef.current(), setResetForm };
};
