/* eslint-disable max-lines-per-function */
import React from 'react';
import { Box, Flex, Text } from '@lce/slice_v2';
import { useTranslation } from '@lce/i18n';

import { CopyShare } from '../CopyShare';
import { FundraiserQRCode } from '../FundraiserQRCode';
import { EmailLinks } from '../EmailLinks';
import { SocialButtons } from '../SocialButtons';

import {
  useFetchFundraiserQRCode,
  useFetchSellerQRCode,
  FundraiserListItem,
  FundraiserType,
} from 'features/fundraiser';
import { useFetchProductsPageRoute } from 'features/products';
import { useIsUpcomingFundraiser } from 'features/fundraiser/hooks/useIsUpcomingFundraiser';

interface IInviteProps {
  fundraiser: FundraiserListItem;
  idSuffix?: string;
  sellerId?: number;
  sellerSupportGuid?: string;
  showSocials?: boolean;
  customUpcomingText?: string;
}

// eslint-disable-next-line complexity
const Invite: React.FC<IInviteProps> = ({
  fundraiser, idSuffix, sellerId, sellerSupportGuid, showSocials = true, customUpcomingText,
}) => {
  const [ t ] = useTranslation();
  const { data: fundraiserQRCode, isSuccess } = useFetchFundraiserQRCode(fundraiser.Id);
  const { data: sellerQRCode, isSuccess: isSellerSuccess } = useFetchSellerQRCode(sellerId);
  const productPageRoute = useFetchProductsPageRoute(sellerSupportGuid, fundraiser.FundraiserType);
  const copyLink = sellerSupportGuid ? productPageRoute : `/login/join-fundraiser/${ fundraiser.FundraiserGuid }`;
  const copyUrl = `${ window.location.origin }${ copyLink }`;
  const socialImage = fundraiser.FundraiserImageSource || process.env.REACT_APP_OG_IMAGE || '';
  const displayFundraiserQRCode = fundraiser.Active && isSuccess && !sellerSupportGuid;
  const displaySellerQRCode = fundraiser.Active && isSellerSuccess && sellerSupportGuid;
  const showSocialButtons = showSocials && fundraiser.FundraiserType === FundraiserType.MealDeal;
  const isFundraiserUpcoming = useIsUpcomingFundraiser(fundraiser);
  const emailSubject = sellerSupportGuid
    ? encodeURIComponent(`${ t('emailTemplate.seller.emailMessage.subject', { context: fundraiser.FundraiserType }) }`)
    : encodeURIComponent(`${ t('emailTemplate.chairperson.emailMessage.subject',
      { context: fundraiser.FundraiserType }) }`);
  const emailBody = sellerSupportGuid
    ? encodeURIComponent(`${ t('emailTemplate.seller.emailMessage.body',
      { context: fundraiser.FundraiserType, supportGuid: sellerSupportGuid, groupName: fundraiser.Name }) }`)
    : encodeURIComponent(`${ t('emailTemplate.chairperson.emailMessage.body',
      { context: fundraiser.FundraiserType, fundraiserId: fundraiser.FundraiserGuid, groupName: fundraiser.Name }) }`);

  return (
    <Box>
      { isFundraiserUpcoming ? (
        <Box
          sx={ {
            width: '100%',
            border: '1px solid orange',
            borderRadius: '3px',
            padding: '20px',
          } }
        >
          <strong>
            {customUpcomingText ?? t('dashboard.steps.invite.checkBack')}
          </strong>
        </Box>
      )
        : (
          <Box sx={ { width: '100%' } }>
            <Flex
              sx={ {
                flexDirection: !displayFundraiserQRCode && !displaySellerQRCode ? 'column' : [ 'column', 'row' ],
                justifyContent: 'space-between',
              } }
            >
              <Flex
                sx={ {
                  flexDirection: 'column',
                  m: [ '0 12px', '12px 0 0 12px' ],
                  pb: [ 0, '12px' ],
                  justifyContent: 'space-between',
                } }
              >
                <CopyShare copyText={ copyUrl } idSuffix={ idSuffix } sx={ { mb: [ '15px', '30px' ] } } />
                <EmailLinks
                  body={ emailBody }
                  idSuffix={ idSuffix }
                  subject={ emailSubject }
                  sx={ { mb: showSocials ? [ '12px', '16px' ] : 0 } }
                  title={ t('dashboard.steps.invite.sendLink') }
                />
                {showSocialButtons && (
                  <Flex
                    sx={ {
                      alignItems: 'center',
                      columnGap: '8px',
                      flexWrap: 'wrap',
                      rowGap: '8px',
                      mb: !displayFundraiserQRCode && !displaySellerQRCode ? '8px' : [ '8px', 0 ],
                    } }
                  >
                    <Text sx={ { fontSize: '12px', whiteSpace: 'nowrap' } }>
                      {t('dashboard.steps.invite.postSocial')}
                    </Text>
                    <SocialButtons
                      fundraiserImage={ socialImage }
                      fundraiserName={ fundraiser.Name }
                      link={ copyUrl }
                      sx={ { alignItems: 'center' } }
                    />
                  </Flex>
                )}
              </Flex>
              {(displayFundraiserQRCode || displaySellerQRCode) && (
                <FundraiserQRCode
                  imageAltText={ sellerSupportGuid
                    ? t('emailTemplate.seller.profileSection.SellerQRCodeAltText')
                    : t('emailTemplate.chairperson.profileSection.FundraiserQRCodeAltText') }
                  imageDataURL={ `data:image/png;base64,${ displaySellerQRCode ? sellerQRCode : fundraiserQRCode }` }
                  sellerQRCode={ sellerSupportGuid ? true : false }
                />
              )}
            </Flex>
          </Box>
        )}
    </Box>
  );
};

export default Invite;
