import React from 'react';
import { Box, Flex, Image } from '@lce/slice_v2';

interface IFundraiserCreate {
  image: string;
  testId: string;
}

export const FundraiserCreate: React.FC<IFundraiserCreate> = ({ children, image, testId }) => (
  <Flex data-testid={ testId } sx={ { flexDirection: 'row' } }>
    <Box
      sx={ {
        alignSelf: 'center', mr: '10px', display: [ 'none', 'none', 'block' ], flexBasis: '40%',
      } }
    >
      <Image alt="LC Fundraising Logo" src={ image } title="LC Fundraising" />
    </Box>
    {children}
  </Flex>
);
