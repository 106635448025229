/* eslint-disable max-lines-per-function */
import React from 'react';
import { Text, Flex, Box } from '@lce/slice_v2';
import { useTranslation } from '@lce/i18n';
import { useFeature } from '@growthbook/growthbook-react';
import _map from 'lodash/map';

import FundraiserProgramCard, { IFundraiserProgramCard } from './FundraiserProgramCard';

import { FundraiserType } from 'features/fundraiser';
import { useMediaQuery } from 'features/common';

const FundraiserPrograms: React.FC = () => {
  const [ t ] = useTranslation();
  const isLarge = useMediaQuery('(min-width: 411px)');
  const isBrochureProgramEnabled = useFeature('brochureProgramEnabled').on;

  const fundraiserTypes: FundraiserType[] = [
    FundraiserType.Brochure,
    FundraiserType.Digital,
    FundraiserType.MealDeal,
  ];

  return (
    <Flex
      data-testid="fundraiser-programs"
      sx={ {
        alignItems: 'center',
        flexDirection: 'column',
        gap: '16px',
        p: [ '32px 16px', '32px' ],
        width: '100%',
      } }
    >
      <Flex
        sx={ {
          flexDirection: 'column',
          gap: '8px',
          maxWidth: '700px',
          textAlign: 'center',
        } }
      >
        <Text
          sx={ { maxWidth: isLarge ? 'none' : '300px', mx: 'auto' } }
          variant="home.subheading"
        >
          {t('home.fundraiserPrograms.title')}
        </Text>
        <Text variant="home.subdescription">
          {t('home.fundraiserPrograms.description')}
        </Text>
      </Flex>
      {/*<Link
        //TODO: Uncomment when link is provided
        href={ t('home.fundraiserPrograms.link.url') }
        variant="links.primaryTextUppercase"
      >
        { t('home.fundraiserPrograms.link.text') }
      </Link>*/}
      <Box
        sx={ {
          display: 'grid',
          gap: [ '16px', '16px', '32px' ],
          gridTemplateAreas: [
            '"BR" "DG" "MD"',
            '"BR BR DG DG" ". MD MD ."',
            '"BR DG MD"',
          ],
          gridTemplateColumns: [ '1fr', '1fr 1fr 1fr 1fr', '1fr 1fr 1fr' ],
          gridTemplateRows: [ 'auto', '1fr 1fr', '1fr' ],
          maxWidth: '1216px',
        } }
      >
        {_map(fundraiserTypes, (type) => {
          const programData: IFundraiserProgramCard =
            t('home.fundraiserPrograms.program', { context: type, returnObjects: true });
          const isBrochureAndBrochureProgramEnabled = type === FundraiserType.Brochure && isBrochureProgramEnabled;
          const urlContext = `${ type }${ isBrochureAndBrochureProgramEnabled ? '-brochureProgramEnabled' : '' }`;
          const fundraiserCreationUrl = t('home.fundraiserPrograms.fundraiserCreationUrl', { context: urlContext });
          return (
            <FundraiserProgramCard { ...programData } fundraiserCreationUrl={ fundraiserCreationUrl } key={ type } />
          );
        })}
      </Box>
      <Text sx={ { maxWidth: '1216px', width: '100%' } } variant="text.disclaimer">
        {t('common.BundleDisclaimer')}
      </Text>
    </Flex>
  );
};

export default FundraiserPrograms;
