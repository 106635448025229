import React from 'react';

import { MealDealLandingPage } from '../../MealDealLandingPage';
import { RouteTwo } from '../RouteTwo';

export const Calculate: React.FC = () => (
  <MealDealLandingPage>
    <RouteTwo />
  </MealDealLandingPage>
);
