import React from 'react';
import { useTranslation } from '@lce/i18n';
import { Text, Button } from '@lce/slice_v2';

export interface INewGuestProps {
  onGuestCallback?: () => void;
}

const NewGuest: React.FC<INewGuestProps> = ({ onGuestCallback }) => {
  const [ t ] = useTranslation();

  return (
    <>
      <Text variant="text.header.sub">
        {t('checkout.NewUserTitle')}
      </Text>
      <Text variant="text.login.message">
        {t('checkout.GuestCheckoutMessage')}
      </Text>
      <Button data-testid="guest_checkout" onClick={ onGuestCallback } sx={ { width: [ '100%' ] } } variant="primary">
        {t('checkout.GuestCheckoutLabel')}
      </Button>
    </>
  );
};

export default NewGuest;
