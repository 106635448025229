/* eslint-disable max-len */
import {
  Image, Flex, Text, Progress, IconButton,
} from '@lce/slice_v2';
import React, { useState } from 'react';
import Toggle from '@lce/slice_v2/Elements/Form/Input/Toggle';
import { Trash2 } from 'react-feather';

import Star from './star.png';
import { RemoveSellerModal } from './RemoveSellerModal';

import DefaultSellerAvatar from 'assets/images/default-seller-avatar.png';
import { RouterLink } from 'ui/common';
import { updateSellerActivity } from 'features/fundraiser/api';
import { useDeleteFundraiserSeller } from 'features/fundraiser/hooks/useDeleteFundraiserSeller';
import { useActivateFundraiserSeller } from 'features/fundraiser/hooks/useActivateFundraiserSeller';
import { Seller } from 'features/fundraiser/types/seller';

export interface ISellerStatusCard {
  fundraiserSeller: Seller;
  activateSeller: () => void;
  deactivateSeller: () => void;
  isChairperson: boolean;
}

// eslint-disable-next-line max-lines-per-function
const SellerStatusCard: React.FC<ISellerStatusCard> = ({
  fundraiserSeller, activateSeller, deactivateSeller, isChairperson,
}) => {
  const [ isRemoveSeller, setIsRemoveSeller ] = useState<boolean>(false);

  const [ isActive, setIsActive ] = useState(fundraiserSeller.Active);
  const { mutateAsync: deactivateFundraiserSeller } = useDeleteFundraiserSeller();
  const { mutateAsync: activateFundraiserSeller } = useActivateFundraiserSeller();

  const onRemoveSeller = () => {
    setIsRemoveSeller(false);
  };

  const isActiveHandler = async() => {
    fundraiserSeller.Active = !fundraiserSeller.Active;
    const isSellerUpdated = await updateSellerActivity(fundraiserSeller.Id, fundraiserSeller.Active);
    if (isSellerUpdated) {
      setIsActive(fundraiserSeller.Active);
      if (fundraiserSeller.Active) {
        await activateFundraiserSeller(fundraiserSeller.Id);
        activateSeller();
      } else {
        await deactivateFundraiserSeller(fundraiserSeller.Id);
        deactivateSeller();
      }
    }
  };

  return (
    <Flex
      sx={ isActive
        ? {
          mb: '20px', padding: [ '20px', '20px', '0px 20px' ], alignItems: 'center', flexWrap: 'wrap',
        }
        : {
          mb: '20px', padding: [ '20px', '20px', '0px 20px' ], alignItems: 'center', flexWrap: 'wrap',
        } }
      variant="cards.fundraiserSeller"
    >
      <RouterLink
        sx={ isActive ? { width: [ '50%', '25%' ] } : { width: [ '50%', '25%' ], pointerEvents: 'none' } }
        to={ `/dashboard/fundraisers/${ fundraiserSeller.FundraiserId }/seller/${ fundraiserSeller.CustomerId }` }
      >
        <Flex sx={ { alignItems: 'center' } }>
          <Image
            src={ fundraiserSeller.AvatarSource || DefaultSellerAvatar }
            sx={ {
              width: '50px', height: '50px', backgroundColor: 'primaryGrayLight', mr: '10px',
            } }
          />

          <Text sx={ { fontWeight: 'bold' } }>
            {fundraiserSeller.FirstName}

            {' '}

            {fundraiserSeller.LastInitial}
          </Text>
        </Flex>
      </RouterLink>

      <Flex sx={ { width: [ '50%', '15%' ], alignItems: 'center' } }>
        <Text sx={ { fontWeight: 'bold', ml: 'auto', mr: [ '0px', '25px' ] } }>
          Kit Goal:
          {' '}

          {fundraiserSeller.KitActual}
          /

          {fundraiserSeller.Goal}
        </Text>
      </Flex>

      <Flex
        sx={ { flex: 1, alignItems: 'center', minWidth: '75px' } }
      >
        {fundraiserSeller.Goal > 0 && fundraiserSeller.KitActual >= fundraiserSeller.Goal
          ? (<Image height="24px" src={ Star } sx={ { mr: '10px' } } width="24px" />)
          : (<div />)}

        <Progress
          max={ 1 }
          value={ fundraiserSeller.Goal === 0 ? 0 : fundraiserSeller.KitActual / fundraiserSeller.Goal }
          variant="lce"
        />
      </Flex>
      { !isChairperson ? (
        <Flex data-testid={ `ManageSeller-isChairperson-${ isChairperson }` } sx={ { ml: '8px', alignItems: 'center' } }>
          <Toggle
            checked={ isActive } data-testid="toggle-btn" id="toggle-acivation" name="Toggle Activation"
            onChange={ () => isActiveHandler() }
          />

          { !isActive ? (
            <Flex sx={ { ml: 'auto' } }>
              <IconButton onClick={ () => setIsRemoveSeller(true) }>
                <Trash2 />
              </IconButton>

              {isRemoveSeller ? (
                <RemoveSellerModal
                  fundraiserId={ fundraiserSeller.FundraiserId }
                  fundraiserSellerId={ fundraiserSeller.Id }
                  onClose={ onRemoveSeller }
                />
              ) : null}
            </Flex>
          ) : <div /> }
        </Flex>
      ) : (<div />)}
    </Flex>
  );
};

export default SellerStatusCard;
