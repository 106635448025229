/* eslint-disable max-lines-per-function */
import React, { useState, useEffect } from 'react';
import {
  Box, Heading, Text, Flex, Button,
} from '@lce/slice_v2';
import { Users } from 'react-feather';
import { useHistory } from 'react-router-dom';
import { useTranslation } from '@lce/i18n';

import { FundraiserListOptions, FundraiserListTabs } from 'ui/fundraiser';
import { FundraiserSearchOptions, useConditionalRedirect, useFetchPaginatedFundraisers } from 'features/fundraiser';
import { UserInfo } from 'features/common/types/userInfo';
import * as FundraiserDefaults from 'constants/FundraiserDefaults';
import { useDebounce } from 'features/common';
import { FundraiserState } from 'features/fundraiser/types/fundraiser';
import { setFundraiserState } from 'features/fundraiser/utils/SetFundraiserState';
import { FundraiserPopover } from 'features/popovers/types';
import { getFundraiserLinkPath } from 'features/fundraiser/utils';

export type IList = {
  user: UserInfo | undefined;
};

const List: React.FC<IList> = ({ user }) => {
  const [ debouncedKeyword, setKeyword, keyword ] = useDebounce<string>('', 500);
  const [ t ] = useTranslation();

  const [ totalCount, setTotalCount ] = useState<number>(0);

  const chairPersonRole = user?.Roles.includes(FundraiserDefaults.ChairpersonRoleName);
  const superAdminRole = user?.Roles.includes(FundraiserDefaults.SuperAdminRoleName);
  const salesAgentRole = user?.Roles.includes(FundraiserDefaults.SalesAgentRoleName);

  const state = setFundraiserState(user?.Roles);

  const [ options, setOptions ] = useState<FundraiserSearchOptions>({
    canSort: false, sort: 0, state: state, page: 1, pageSize: 5,
  });
  const { data: resolvedData, isLoading } = useFetchPaginatedFundraisers(options, debouncedKeyword);
  const [ popovers, setPopovers ] = useState<FundraiserPopover[]>(user?.Popovers || []);
  const [ fundraisersFetched, setFundraisersFetched ] = useState<boolean>(false);
  const fundraiserLinkPath = getFundraiserLinkPath(resolvedData?.Items?.[0], user);
  const history = useHistory();

  // Sellers should not see the Create New Fundraiser button
  // Sellers do not have the SuperAdmin, Internal Sales Agent, or Chairperson role
  const canCreateFundraiser = superAdminRole || salesAgentRole || chairPersonRole;

  useEffect(() => {
    if (!fundraisersFetched && !isLoading && resolvedData?.TotalCount) {
      setTotalCount(resolvedData?.TotalCount ?? 0);
      setFundraisersFetched(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ isLoading, resolvedData?.TotalCount ]);

  useConditionalRedirect(totalCount === 1, fundraiserLinkPath);

  // SuperAdmins and Internal Sales Agents can access Sort by, Start Date, or End Date
  useEffect(() => {
    if (user?.Roles.includes(FundraiserDefaults.SuperAdminRoleName) ||
      user?.Roles.includes(FundraiserDefaults.SalesAgentRoleName) ||
      user?.Roles.includes(FundraiserDefaults.ISCRoleName)) {
      setOptions(options => ({ ...options, canSort: true }));
    } else {
      setOptions(options => ({ ...options, canSort: false }));
    }
  }, [ user ]);

  const onPageSelectHandler = (page: number) => {
    setOptions({ ...options, page: page });
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const onPageSizeSelectHandler = (size: number) => {
    setOptions({ ...options, page: 1, pageSize: Number(size) });
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const onSearchChangeHandler = (value: string) => {
    setKeyword(value);
  };

  const onSortChangeHandler = (value: number) => {
    setOptions({ ...options, sort: value });
  };

  const onDateChangeHandler = (selectedStartDate?: string, selectedEndDate?: string) => {
    selectedStartDate && setOptions({ ...options, selectedStartDate });
    selectedEndDate && setOptions({ ...options, selectedEndDate });
  };

  const onTabSelectHandler = (tab: FundraiserState) => {
    setOptions({ ...options, state: tab, page: 1 });
  };

  const onStartAFundraiser = () => {
    history.push('/start-a-fundraiser/create');
  };

  return (
    <Box>
      <Flex sx={ { justifyContent: 'space-between', flexDirection: [ 'column', 'column', 'row' ] } }>
        <Flex sx={ { flexDirection: 'column' } }>
          <Heading as="h1">
            { t('dashboard.MyFundraisers') }
          </Heading>
          <Text sx={ { fontSize: '20px' } } variant="text.header.sub">
            { t('dashboard.Welcome') }
            {user?.FirstName ? ' ' + user.FirstName + '!' : '!'}
          </Text>
        </Flex>

        {
          canCreateFundraiser ? (
            <Flex
              as={ Button }
              data-testid="dashboardFundraisers__createAFundraiser"
              onClick={ onStartAFundraiser }
              sx={ {
                alignItems: 'center', flexDirection: 'row', mt: [ 16, 0 ], paddingLeft: [ 0, '12px' ],
              } }
              variant="buttons.text"
            >
              <Users size={ 24 } />

              <Text sx={ { ml: '16px' } }>
                { t('dashboard.CreateNewFundraiser') }
              </Text>
            </Flex>
          ) : null
        }
      </Flex>

      <FundraiserListOptions
        keyword={ keyword }
        onDateChange={ onDateChangeHandler }
        onSearchChange={ onSearchChangeHandler }
        onSortChange={ onSortChangeHandler }
        selectedOptions={ options }
      />

      <FundraiserListTabs
        currentPage={ options.page }
        fundraisers={ resolvedData }
        loading={ isLoading }
        onPageSelectHandler={ onPageSelectHandler }
        onPageSizeSelectHandler={ onPageSizeSelectHandler }
        onTabSelect={ onTabSelectHandler }
        pageSize={ options.pageSize }
        popovers={ popovers }
        setPopovers={ setPopovers }
        user={ user }
      />
    </Box>
  );
};

export default List;
