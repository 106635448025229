/* eslint-disable max-lines-per-function */
import React, { useState } from 'react';
import { Flex, Image, Text } from '@lce/slice_v2';
import { toast } from 'react-toastify';
import { useTranslation } from '@lce/i18n';

import { FundraiserListItem } from 'features/fundraiser/types/fundraiser';
import DefaultFundraiserImage from 'assets/images/default-fundraiser-image.png';
import DefaultSellerAvatar from 'assets/images/default-seller-avatar.png';
import { FileUpload, GenericToast } from 'ui/common';
import { useUploadFundraiserPhoto, useUploadSellerPhoto } from 'features/fileUpload';
import { Seller } from 'features/fundraiser';

export interface IHeaderImage {
  fundraiser: FundraiserListItem;
  seller?: Seller;
}

const HeaderImage: React.FC<IHeaderImage> = ({ fundraiser, seller }) => {
  const [ t ] = useTranslation();
  const [ newImage, setNewImage ] = useState<File>();
  // eslint-disable-next-line max-len
  const [ imageSrc, setImageSrc ] = useState<string | ArrayBuffer>(seller ? seller.AvatarSource : fundraiser.FundraiserImageSource);

  const { mutateAsync: uploadSellerImage } = useUploadSellerPhoto();
  const { mutateAsync: uploadFundraiserImage } = useUploadFundraiserPhoto();

  const maxFileSizeLimit = 5000000;

  const onImageSelectHandler = async(file: File) => {
    if (file) {
      if (file.size > maxFileSizeLimit) {
        toast(<GenericToast text={ t('dashboard.header.toast.imageTooLarge') } />);
        return;
      }

      const reader = new FileReader();
      reader.onload = function(load) {
        if (load && load.target && load.target.result) {
          setImageSrc(load.target.result);
        }
      };

      reader.readAsDataURL(file);

      if (file.name !== newImage?.name) {
        setNewImage(file);
        const formData = new FormData();
        formData.append('formFile', file);
        formData.append('fileName', file.name);
        try {
          if (seller) {
            await uploadSellerImage({ id: seller.Id, data: formData });
            toast(<GenericToast text={ t('dashboard.header.toast.sellerUpdated') } />);
          } else {
            await uploadFundraiserImage({ id: fundraiser.Id, data: formData });
            toast(<GenericToast text={ t('dashboard.header.toast.fundraiserUpdated') } />);
          }
        } catch {
          toast(<GenericToast text={ t('dashboard.header.toast.imageError') } />);
        }
      }
    }
  };
  return (
    <Flex
      data-testid="header-image"
      sx={ {
        width: '100%',
        maxWidth: '325px',
        height: '100%',
        flexDirection: 'column',
        p: '12px 24px',
        mb: '8px',
        mr: [ 'auto', '44px' ],
        ml: [ 'auto', 0 ],
        border: [ 'none', '1px solid #ddd' ],
        borderRadius: '4px',
        justifyContent: 'center',
      } }
    >
      <Image
        alt={ `${ seller ? seller.FirstName : fundraiser.Name } ${ t('dashboard.header.image') }` }
        src={ seller ? seller.AvatarSource || DefaultSellerAvatar : imageSrc || DefaultFundraiserImage }
        sx={ {
          height: [ '175px', '150px' ],
          width: 'auto',
          m: '0 auto 8px',
          objectFit: 'contain',
        } }
        title={ `${ seller ? seller.FirstName : fundraiser.Name } ${ t('dashboard.header.image') }` }
      />
      <FileUpload onFileSelect={ onImageSelectHandler } />
      {seller && seller.FirstName && (
        <Text sx={ { fontFamily: 'roboto', fontSize: '18px', fontWeight: 'bold' } }>
          {`${ seller.FirstName } ${ seller.LastInitial || '' }`}
        </Text>
      )}
    </Flex>
  );
};

export default HeaderImage;

