import { format } from 'date-fns';

export const formatFundraiserDateRange = (startDate: Date | undefined, endDate: Date | undefined) => {
  if (!startDate || !endDate) {
    return '';
  }

  const formattedStart = format(new Date(startDate), 'MMMM do');
  const formattedEnd = format(new Date(endDate), 'MMMM do');

  return `${ formattedStart } - ${ formattedEnd }`;
};
