import { useQuery } from 'react-query';
import map from 'lodash/map';

import { fetchShippingCost } from '../api';

import * as QueryKeys from 'constants/QueryKeys';
import { ApiError } from 'features/common';
import { CartItem } from 'features/cart';

export const useShipping = (cartItems: CartItem[]) => {
  const key = map(cartItems, (cart: CartItem) => `${ cart.Sku }:${ cart.Quantity }`);
  return useQuery<number, ApiError>(`${ QueryKeys.shippingCost }-${ key }`,
    () => fetchShippingCost(cartItems), {
      retry: false,
    });
};
