/* eslint-disable max-len */
/* eslint-disable max-lines-per-function */
/* eslint-disable no-extra-parens */
/* eslint-disable implicit-arrow-linebreak */
import {
  Box, Flex, Headline, Text, Image,
} from '@lce/slice_v2';
import * as React from 'react';
import { ThemeUIStyleObject } from 'theme-ui';
import { useTranslation } from '@lce/i18n';

import UserOption from '../UserOption/UserOption';

import LCESpear from './assets/lcespear.svg';
import LCESpearMobile from './assets/lcespear_mobile.svg';
import fundraiserseller from './assets/fundraiserseller.png';
import fundraiserbuyer from './assets/fundraiserbuyer.png';
import fundraiserchairperson from './assets/fundraiserchairperson.png';

import * as FundraiserDefaults from 'constants/FundraiserDefaults';
import { useMediaQuery } from 'features/common';

export interface IUserLandingPageProps{
  sx?: ThemeUIStyleObject;
  role: string;
}

const UserLandingPage: React.FC<IUserLandingPageProps> = ({ role }) => {
  const [ t ] = useTranslation();
  const isLarge = useMediaQuery('(min-width: 961px)');

  const isChairperson = role === FundraiserDefaults.ChairpersonRoleName.toLowerCase();
  const isBuyer = role === FundraiserDefaults.BuyerRoleName.toLowerCase();
  const userImage = isChairperson ? fundraiserchairperson : isBuyer ? fundraiserbuyer : fundraiserseller;

  return (
    <Flex
      as="section"
      data-testid={ `fundraiser-landing-${ role }-page` }
      sx={ { flexDirection: 'column' } }
    >
      <Flex
        sx={ {
          alignItems: 'center', justifyContent: [ 'center', 'center', 'space-evenly' ],
          width: '100%', maxWidth: isChairperson ? [ '100%', 'initial', '524px' ] : [ '100%', 'initial', '452px' ],
          margin: [ 0, 0, '48px auto' ],
          pb: [ '20px', '32px', 0 ], pt: [ '164px', '196px', '84px' ],
        } }
      >
        <Box sx={ { mr: [ 0, '20px', 0 ] } }>
          <Image
            data-testid={ `${ role }-landing-image` }
            src={ userImage }
            sx={ {
              width: [ '106px', '106px', '144px' ], height: [ '106px', '106px', '144px' ],
              alignSelf: [ 'initial', 'initial', 'center' ],
              mr: [ '14px', '16px', 0 ],
            } }
          />
        </Box>
        <Box sx={ { alignSelf: 'center', mb: '12px' } }>
          <Headline
            as="h1"
            sx={ {
              fontSize: [ '26px', '26px', '40px' ],
              fontFamily: 'mrEaves',
              mb: [ 0, 0, '12px' ],
              textTransform: 'uppercase',
            } }
          >
            {t(`userLandingPages.${ role }.LandingPageTitle`)}
          </Headline>
          <Text
            sx={ {
              fontWeight: 'normal', m: 0,
              width: isChairperson ? [ '200px', '300px', 'initial' ] : 'initial',
              lineHeight: [ 1.2, 'initial', 'initial' ],
            } } variant="text.header.sub"
          >
            {t(`userLandingPages.${ role }.LandingPageDescription`)}
          </Text>
        </Box>
      </Flex>

      <Flex
        as="section"
        sx={ {
          backgroundColor: '#f7f2ec',
          paddingTop: [ '48px', '48px', '76px' ],
          justifyContent: 'center', flexDirection: [ 'column', 'column', 'row' ],
          w: [ '100%', 0, 0 ],
        } }
      >
        <UserOption
          role={ role }
          side="left"
        />

        <Flex
          data-testid="spearseparator"
          sx={ {
            flexDirection: [ 'row', 'row', 'column' ],
            justifyContent: 'space-evenly',
            m: [ 'auto', 'auto', '0 12px' ],
            alignItems: 'center',
            transform: [ 'none', 'none', 'translateY(-20px)' ],
            width: [ '100%', '75%', 'initial' ],
          } }
        >
          <Box sx={ { transform: [ 'rotate(270deg)', 'rotate(270deg)', 'none' ] } }>
            {isLarge ? <Image src={ LCESpear } /> : <Image src={ LCESpearMobile } />}
          </Box>
          <Text sx={ { fontFamily: 'lcScript', fontSize: [ '24px' ], textTransform: 'uppercase' } }>
            {t('userLandingPages.spearDividerText')}
          </Text>
          <Box sx={ { transform: [ 'rotate(90deg)', 'rotate(90deg)', 'rotate(180deg)' ] } }>
            {isLarge ? <Image src={ LCESpear } /> : <Image src={ LCESpearMobile } />}
          </Box>
        </Flex>

        <UserOption
          role={ role }
          side="right"
        />

      </Flex>
    </Flex>
  );
};
export default UserLandingPage;
