import { useMutation } from 'react-query';

import { fetchFilteredReport } from '../api';
import { IReportFilterOptions } from '../types';

import { ApiError } from 'features/common';

const useFetchFilteredReport =
  () => useMutation<Blob, ApiError, IReportFilterOptions >(fetchFilteredReport);

export { useFetchFilteredReport };

