import { useTranslation } from '@lce/i18n';
import { Box, Heading } from '@lce/slice_v2';
import React from 'react';

import { OrderHistoryList } from 'ui/order';

const Orders = () => {
  const [ t ] = useTranslation();

  return (
    <Box sx={ { marginTop: [ '0px', '30px', '30px', '0px' ] } }>
      <Heading as="h1">
        {t('orderHistory.Title')}
      </Heading>
      <Box>
        <OrderHistoryList />
      </Box>
    </Box>
  );
};

export default Orders;
