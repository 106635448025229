import { useQuery } from 'react-query';

import { FundraiserSummary } from '../types/fundraiser';
import { fetchFundraiserSellerSummary } from '../api';

import * as QueryKeys from 'constants/QueryKeys';
import { ApiError } from 'features/common';

const useFetchFundraiserSellerSummary =
  (id: number) => useQuery<FundraiserSummary, ApiError>(`${ QueryKeys.SellerReport }${ id.toString() }`,
    () => fetchFundraiserSellerSummary(id), {
      retry: false, refetchOnWindowFocus: false, cacheTime: 0, enabled: !!id,
    });

export { useFetchFundraiserSellerSummary };
