import React from 'react';
import { RouteComponentProps, Redirect } from 'react-router-dom';
import {
  Container, Heading, Spinner, Text,
} from '@lce/slice_v2';

import { ProductDetail } from 'ui/products';
import { FundraiserTracker } from 'ui/fundraiser';
import { useCartState } from 'features/cart';
import { useFetchProduct } from 'features/products';

interface MatchParams {
  sku: string;
}

type ProductDetailPageProps = RouteComponentProps<MatchParams>

const ProductDetailPage: React.FC<ProductDetailPageProps> = ({ match }) => {
  const cartState = useCartState();
  const { data: product, isLoading, error } = useFetchProduct(match.params.sku);

  // error status - 404
  if (error && error.Status === 404) {
    return <Redirect to="/404" />;
  }

  // loading state
  if (isLoading && !error) {
    return (
      <Container sx={ { textAlign: 'center' } }>
        <Spinner variant="lce" />
      </Container>
    );
  }

  if (error || !product) {
    return (
      <Container>
        <Heading>Uh oh</Heading>
        <Text variant="text.header.sub">There was an issue getting product information</Text>
      </Container>
    );
  }

  return (
    <div>
      <FundraiserTracker />
      <ProductDetail canStartShopping={ cartState.canStartShopping } product={ product } />
    </div>
  );
};

export default ProductDetailPage;
