import { useMutation, useQueryClient } from 'react-query';

import { CancelFundraiserRequest } from '../types';

import { ApiError } from 'features/common';
import api from 'api';

/** Api Call */
function cancelFundraiser(request: CancelFundraiserRequest) {
  return api.put<boolean>('api/fundraiser/cancel', request)
    .then(res => res);
}

/** Hook */
export const useCancelFundraiser = () => {
  const queryClient = useQueryClient();

  return useMutation<boolean, ApiError, CancelFundraiserRequest>(cancelFundraiser,
    {
      onSuccess: () => queryClient.invalidateQueries('fundraisers'),
    });
};
