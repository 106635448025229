import React from 'react';
import Pagination from 'rc-pagination';
import './styles.css';
import { ChevronLeft, ChevronRight, MoreHorizontal } from 'react-feather';
import * as locale from 'rc-pagination/lib/locale/en_US';
import {
  Form, Flex, Text,
} from '@lce/slice_v2';

export type IPaginate = {
  onPageChange: (page: number, pageSize: number) => void;
  onPageSizeChange: (newSize: number) => void;
  totalCount: number;
  pageSize: number;
  currentPage: number;
  showPageSelect?: boolean;
}

const Paginate: React.FC<IPaginate> = ({
  onPageChange,
  onPageSizeChange,
  totalCount,
  pageSize = 5,
  currentPage,
  showPageSelect = true,
}) => (
  <Flex sx={ { justifyContent: 'center', flexDirection: [ 'column', 'row' ] } }>
    { showPageSelect && (
      <Flex sx={ { alignItems: 'center', mr: '30px', justifyContent: 'center' } }>
        <Text
          sx={ {
            mr: '16px', fontFamily: 'Roboto', fontSize: '12px', fontWeight: 600,
          } }
        >
          Rows Per Page
        </Text>
        <Form.Select
          defaultValue={ pageSize }
          id="paginate"
          name="paginate"
          onChange={ e => onPageSizeChange(Number(e.target.value)) }
          sx={ { minWidth: '104px' } }
        >
          <option>5</option>
          <option>10</option>
          <option>25</option>
          <option>50</option>
          <option>75</option>
          <option>100</option>
        </Form.Select>
      </Flex>
    )}
    <Pagination
      // eslint-disable-next-line react/forbid-component-props
      className="pagination-container"
      current={ currentPage }
      jumpNextIcon={ <MoreHorizontal /> }
      jumpPrevIcon={ <MoreHorizontal /> }
      locale={ locale }
      nextIcon={ <ChevronRight /> }
      onChange={ onPageChange }
      onShowSizeChange={ (current, size) => onPageSizeChange(size) }
      pageSize={ pageSize }
      prevIcon={ <ChevronLeft /> }
      showPrevNextJumpers={ true }
      total={ totalCount }
    />
  </Flex>

);

export default Paginate;
