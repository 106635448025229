import { Text } from '@lce/slice_v2';
import React from 'react';
import { useTranslation } from '@lce/i18n';

import { IShippingMethodLabelProps } from './types';

const ShippingMethodLabel: React.FC<IShippingMethodLabelProps> = ({ hasOnlyCouponItems, isCouponItemExist }) => {
  const [ t ] = useTranslation();
  return (
    <>
      <Text sx={ { mt: '30px' } } variant="text.header.form">
        {t('checkout.ShippingMethodLabel')}
      </Text>
      <Text>
        { !hasOnlyCouponItems && t('checkout.ShippingMethodText') }
      </Text>
      <Text>
        { isCouponItemExist && t('checkout.ShippingMethodLabelForCoupon') }
      </Text>
    </>
  );
};

export default ShippingMethodLabel;
