import { doesScriptExist, doesNoScriptExist } from '../../utilities';

import { TagManagerSettings } from './interface';
import Snippets from './Snippets';

declare global {
  // tslint:disable-next-line
  interface Window {
    // eslint-disable-next-line
    dataLayer: any;
  }
}

const TagManager = {
  gtm(settings: TagManagerSettings) {
    const snippets = Snippets.tags({ gtmSettings: settings });

    const script = () => {
      const element = document.createElement('script');
      element.innerHTML = snippets.script;
      return element;
    };

    const noScript = () => {
      const element = document.createElement('noscript');
      element.innerHTML = snippets.noScript;
      return element;
    };

    const dataLayer = () => {
      const element = document.createElement('script');
      element.innerHTML = snippets.dataLayer;
      return element;
    };

    return {
      noScript,
      script,
      dataLayer,
    };
  },
  pushToDataLayer(data) {
    typeof window.dataLayer !== 'undefined' && window.dataLayer && window.dataLayer.push(data);
  },
  initialize(settings: TagManagerSettings) {
    const gtm = this.gtm(settings);

    const script = gtm.script();
    const noScript = gtm.noScript();
    const dataLayer = gtm.dataLayer();

    !doesScriptExist(dataLayer) && document.head.appendChild(dataLayer);
    !doesScriptExist(script) && document.head.insertBefore(script, document.head.childNodes[0]);
    !doesNoScriptExist(noScript) && document.body.insertBefore(noScript, document.body.childNodes[0]);
  },
};

export default TagManager;
