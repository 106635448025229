/* eslint-disable max-len */
import * as React from 'react';
import {
  Box,
  Text,
} from '@lce/slice_v2';
import { map as _map } from 'lodash';

import { QAndA } from '../QAndA';
import { IQAndA } from '../QAndA/QAndA';

export interface ICategory {
  faqCategoryTitle: string;
  qAndACollection: IQAndA[];
}

const Category: React.FC<ICategory> = ({ faqCategoryTitle, qAndACollection }) => (
  <Box>
    <Text
      as="h2" sx={ {
        fontSize: '20px',
        fontFamily: 'mrEaves',
        textTransform: 'uppercase',
        fontWeight: 'bold',
        borderBottom: '1px solid #747373',
        marginTop: '28px',
      } }
    >
      { faqCategoryTitle }
    </Text>
    {_map(qAndACollection, qAndA => (
      <QAndA answer={ qAndA.answer } key={ `${ qAndA.question }` } question={ qAndA.question } />
    ))}
  </Box>
);

export default Category;
