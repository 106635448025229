/* eslint-disable max-lines-per-function */
import React from 'react';
import _map from 'lodash/map';
import _isEmpty from 'lodash/isEmpty';
import {
  Box, Flex, Text, Image,
} from '@lce/slice_v2';
import { useTranslation } from '@lce/i18n';

import { CopyTextButton } from 'ui/fundraiser/ResourceHub/components';
import { getTemplateImage } from 'ui/fundraiser/ResourceHub/utils/getTemplateImage';
import { EmailLinks } from 'ui/fundraiser/EmailLinks';
import { FundraiserListItem } from 'features/fundraiser';
import { formatEmailBodyForCopy } from 'ui/fundraiser/ResourceHub/utils/formatEmailBodyForCopy';

export interface IResourceHubEmailTemplateProps {
  emailText: IEmail;
  fundraiserRole: string;
  fundraiser: FundraiserListItem;
}

export interface IEmailDescription {
  text: string;
}

export interface IEmail {
  caesarMan?: string;
  title?: string;
  heading: string;
  bodyImage?: string;
  emailSubject: string;
  emailBody: string[];
  description?: IEmailDescription[];
}

export const ResourceHubEmailTemplate: React.FC<IResourceHubEmailTemplateProps> = ({
  emailText, children, fundraiserRole, fundraiser,
}) => {
  const [ t ] = useTranslation();
  const caesarMan = getTemplateImage(emailText.caesarMan || '');
  const bodyImage = getTemplateImage(emailText.bodyImage || '');
  const emailBodyText = formatEmailBodyForCopy(emailText.emailBody);
  const copyText = `${ emailText.emailSubject }\n\n${ emailBodyText }`;
  const hasDescription = !_isEmpty(emailText.description);

  const emailTemplates: IEmail[] =
    t(`ResourceHub.${ fundraiser.FundraiserType }.${ fundraiserRole }.emailSection.emailTemplates`,
      { returnObjects: true });

  return (
    <Box
      data-testid="resourceHubEmailTemplate"
      sx={ {
        maxWidth: [ '100%', '100%', '1070px' ],
        m: [ 'auto', 'auto 5% auto 5%', 'auto auto 50px auto' ],
      } }
    >
      <Flex
        sx={ {
          flexDirection: 'row', maxWidth: [ '100%', '100%', '1280px' ], alignItems: 'end',
        } }
      >
        <Flex
          sx={ {
            maxWidth: emailText.title === '' || emailText.title === emailTemplates[6]?.title
              ? [ emailText.title === 'Follow Up' ? '72px' : '100px', '110px' ]
              : [ '68px', '93px' ],
          } }
        >
          <Image
            src={ caesarMan.src } sx={ {
              width: '100%',
              height: [ emailText.title === '' ? '80px' : 'auto', emailText.title === '' ? '108px' : 'auto' ],
              mb: emailText.title === emailTemplates[0].title ? '5px' : '0',
            } }
          />
        </Flex>
        <Flex
          sx={ {
            lineHeight: 1, pt: '15px', m: '10px', flexDirection: 'column', justifyContent: 'end',
          } }
        >
          {emailText.title && (
            <Text
              sx={ {
                fontFamily: 'lcScript', fontSize: [ '24px', '26px' ],
                transform: 'rotate(-1deg)translateY(-2px)',
              } }
            >
              {emailText.title}
            </Text>
          )}
          <Text sx={ { fontSize: [ '22px', '26px' ], fontWeight: '900', mb: emailText.title ? 0 : '15px' } }>
            {emailText.heading}
          </Text>
        </Flex>
      </Flex>
      {hasDescription && (
        <Box sx={ { m: '12px 0 24px 0', ml: [ '0', '106px' ] } }>
          {
            _map(emailText.description, description => (
              <Text sx={ { mb: '12px' } }>
                {description.text}
              </Text>
            ))
          }
        </Box>
      )}
      <Box
        sx={ {
          backgroundColor: 'white', p: [ '12px', '12px 16px', '30px 60px 30px 73px;' ],
          borderRadius: '8px', boxShadow: '0px 0px 4px 1px rgba(0,0,0,0.15)', mb: [ '10px', '20px', '10px' ],
        } }
      >
        <Flex sx={ { flexDirection: 'row' } }>
          <Box
            sx={ {
              maxWidth: [ '0', '300px' ],
              mt: [ '0', emailText.title === emailTemplates[0].title ? '0' : '20px' ],
            } }
          >
            <Image
              alt={ t(`ResourceHub.${ fundraiser.FundraiserType }.imageAltTexts.${ bodyImage.alt }`) }
              src={ bodyImage.src }
              sx={ {
                width: [ '0px', '100%' ], m: [ 'auto', 'auto' ], maxHeight: [ '0', '300px' ],
              } }
            />
          </Box>
          <Flex sx={ { flexDirection: 'column', ml: [ '10px', '20px', '50px' ] } }>
            <Text
              sx={ {
                maxWidth: '621px',
                m: [ '0 0 34px 0', '20px 0 40px 0' ],
                fontStyle: 'italic',
                wordWrap: 'break-word',
              } }
            >
              {children}
            </Text>
            <Flex
              sx={ {
                fontSize: '12px',
                flexDirection: [ 'column', 'column', 'column', 'row' ],
                justifyContent: 'space-between',
                alignItems: 'center',
                display: [ 'flex', 'flex', 'box', 'flex' ],
                whiteSpace: 'nowrap',
                gap: [ 0, '24px' ],
              } }
            >
              <EmailLinks
                body={ encodeURIComponent(emailBodyText) }
                subject={ emailText.emailSubject }
                sx={ { alignItems: 'baseline' } }
                title={ t(`ResourceHub.${ fundraiser.FundraiserType }.sendEmailText`) }
              />
              <CopyTextButton
                buttonText={ t(`ResourceHub.${ fundraiser.FundraiserType }.buttonText`) }
                copyText={ copyText }
                sx={ {
                  mt: [ '12px', 'unset' ], mb: [ '5px', 'unset' ],
                  fontFamily: 'Lato', fonstSize: '14px',
                } }
              />
            </Flex>
          </Flex>
        </Flex>
      </Box>
    </Box>
  );
};
