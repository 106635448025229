/* eslint-disable max-lines-per-function */
import {
  Box,
  Button, Flex, Form, Text,
} from '@lce/slice_v2';
import React from 'react';
import * as Yup from 'yup';
import { useTranslation } from '@lce/i18n';

import { ChairpersonInfo } from 'features/fundraiser';

interface IRemoveChairpersonForm {
  chairperson?: ChairpersonInfo;
  onClose: () => void;
  onSubmit: (updateChairperson: ChairpersonInfo) => void;
}

const RemoveChairpersonForm: React.FC<IRemoveChairpersonForm> = ({ chairperson, onClose, onSubmit }) => {
  const [ t ] = useTranslation();

  const validationSchema = Yup.object().shape({
    CoFirstName: Yup.string().required(t('fundraiser.FirstNameRequired')),
    CoLastName: Yup.string().required(t('fundraiser.LastNameRequired')),
    CoPhoneNumber: Yup.string()
      .matches(/^[0-9]*$/, t('fundraiser.PhoneNumberValid'))
      .min(10, t('fundraiser.PhoneNumberLength'))
      .max(10)
      .required(t('fundraiser.PhoneRequired')),
    CoEmailAddress: Yup.string()
      .email(t('fundraiser.EmailValid'))
      .required(t('fundraiser.EmailRequired')),
    CoConfirmEmailAddress: Yup.string()
      .required(t('fundraiser.EmailRequired'))
      .oneOf([ Yup.ref('CoEmailAddress') ], t('fundraiser.EmailMatch'))
      .email(t('fundraiser.EmailMatch')),
  });

  return (

    <Form
      initialValues={ chairperson } onSubmit={ e => onSubmit(e) } summary={ false }
      validationSchema={ validationSchema }
    >
      <Flex
        sx={ {
          flexDirection: 'column',
          padding: [ '0 20px', '0 50px' ],
          textAlign: 'center',
          alignItems: 'center',
        } }
      >
        <Text sx={ { mb: '16px' } } variant="text.header.subUpper">
          {t('fundraiser.RemovePrimaryChairpersonHeader')}
        </Text>

        <Text sx={ { mb: '16px', maxWidth: '460px' } }>
          {t('fundraiser.RemovePrimaryChairpersonMessage')}
        </Text>
      </Flex>

      <Box
        sx={ {
          margin: [ '30px 0px', '30px 45px' ],
          padding: '30px',
          border: '1px solid',
          borderColor: 'primaryGrayLight',
          borderRadius: '4px',
        } }
      >
        <Text>New Primary Chairperson / Group Leader</Text>
        <Flex sx={ { flexDirection: [ 'column', 'row' ] } }>
          <Form.Field
            data-testid="new-first-name"
            id="FirstName"
            label="First Name *"
            name="CoFirstName"
            sx={ { marginRight: [ 0, '12px' ] } }
            variant="forms.fundraiser.field"
          />
          <Form.Field
            data-testid="new-last-name"
            id="LastName"
            label="Last Name *"
            name="CoLastName"
            sx={ { marginLeft: [ 0, '12px' ] } }
            variant="forms.fundraiser.field"
          />
        </Flex>

        <Flex sx={ { flexDirection: [ 'column', 'row' ] } }>
          <Form.Field
            data-testid="new-phone-number"
            id="Phone"
            label="Primary Phone Number*"
            name="CoPhoneNumber"
            sx={ { marginRight: [ 0, '12px' ] } }
            variant="forms.fundraiser.field"
          />
          <Form.Field
            id="SecondaryPhone"
            label="Secondary Phone Number"
            name="CoSecondaryPhoneNumber"
            sx={ { marginLeft: [ 0, '12px' ] } }
            variant="forms.fundraiser.field"
          />
        </Flex>
        <Flex sx={ { flexDirection: [ 'column', 'row' ] } }>
          <Form.Field
            data-testid="new-email"
            id="EmailAddress"
            label="Email Address*"
            name="CoEmailAddress"
            sx={ { marginRight: [ 0, '12px' ] } }
            variant="forms.fundraiser.field"
          />
          <Form.Field
            data-testid="new-confirm-email"
            id="ConfirmEmailAddress"
            label="Confirm Email Address*"
            name="CoConfirmEmailAddress"
            sx={ { marginLeft: [ 0, '12px' ] } }
            variant="forms.fundraiser.field"
          />
        </Flex>
      </Box>
      <Flex sx={ { justifyContent: 'space-between', margin: '10px auto 80px auto', maxWidth: '660px' } }>
        <Button
          onClick={ onClose }
          sx={ { flex: '1', mr: '10px', height: [ '45px', '36px' ] } }
          variant="buttons.secondary"
        >
          Cancel
        </Button>
        <Button
          data-testid="update-primary-chairperson" sx={ { flex: '1', ml: '10px', height: [ '45px', '36px' ] } }
        >
          Update Primary Chairperson

        </Button>
      </Flex>
    </Form>
  );
};

export { RemoveChairpersonForm };
