/* eslint-disable max-len */
import React, { useMemo, useState } from 'react';
import { Box, Text } from '@lce/slice_v2';

import { FundraiserListItem, FundraiserListItemSkeleton } from '../FundraiserListItem';

import { TabbedCard, TabbedCardConfig, Paginate } from 'ui/common';
import { UserInfo } from 'features/common/types/userInfo';
import * as FundraiserDefaults from 'constants/FundraiserDefaults';
import { PagedList } from 'features/common';
import { FundraiserListItem as ListItem } from 'features/fundraiser';
import { FundraiserState } from 'features/fundraiser/types/fundraiser';
import { FundraiserPopover } from 'features/popovers/types';

export interface IFundraiserListTabsProps {
  user: UserInfo | undefined;
  pageSize: number;
  currentPage: number;
  onPageSelectHandler: (page: number) => void;
  onPageSizeSelectHandler: (size: number) => void;
  onTabSelect: (tab: FundraiserState) => void;
  fundraisers: PagedList<ListItem> | undefined;
  loading: boolean;
  setPopovers: React.Dispatch<React.SetStateAction<FundraiserPopover[]>>;
  popovers: FundraiserPopover[];
}

export interface ITabList extends IFundraiserListTabsProps {
  selectedTab: FundraiserState;
  currentTab: FundraiserState;
}

const TabList: React.FC<ITabList> = ({
  user, fundraisers, loading, ...props
}) => {
  if (props.selectedTab !== props.currentTab) {
    return (
      <Box />
    );
  }

  return (
    <Box sx={ { mt: '16px' } }>
      { fundraisers && user && !loading ? (
        <>
          {fundraisers?.Items.map(x => (
            <FundraiserListItem
              fundraiser={ x }
              fundraiserState={ props.selectedTab }
              key={ x.Id }
              popovers={ props.popovers }
              setPopovers={ props.setPopovers }
              user={ user }
            />
          ))}
          { fundraisers?.TotalCount > 5 && (
            <Paginate
              currentPage={ props.currentPage }
              onPageChange={ props.onPageSelectHandler }
              onPageSizeChange={ props.onPageSizeSelectHandler }
              pageSize={ props.pageSize }
              totalCount={ fundraisers?.TotalCount || 0 }
            />
          ) }
        </>
      )
        : (
          <>
            <FundraiserListItemSkeleton />
            <FundraiserListItemSkeleton />
            <FundraiserListItemSkeleton />
          </>
        )}

      { fundraisers?.TotalCount === 0 && !loading && (
        <Text>
          <Text sx={ { textAlign: 'center' } } variant="text.header.sub">No Fundraisers found</Text>
        </Text>
      )}

    </Box>

  );
};

// eslint-disable-next-line max-lines-per-function
const FundraiserListTabs: React.FC<IFundraiserListTabsProps> = (props) => {
  let defaultTab = FundraiserState.ActiveUpcoming;
  if (props.user?.Roles.includes(FundraiserDefaults.SuperAdminRoleName) || props.user?.Roles.includes(FundraiserDefaults.ISCRoleName) || props.user?.Roles.includes(FundraiserDefaults.SalesAgentRoleName)) {
    defaultTab = FundraiserState.Active;
  }
  const [ selectedTab, setSelectedTab ] = useState<FundraiserState>(defaultTab);

  const onTabSelect = (tab: FundraiserState) => {
    setSelectedTab(tab);
    props.onTabSelect(tab);
  };

  // eslint-disable-next-line max-lines-per-function
  const config = useMemo<TabbedCardConfig[]>(() => {
    const tabs = [
      {
        tab: {
          id: 'active-fundraisers',
          label: 'Active & Upcoming',
          value: FundraiserState.ActiveUpcoming,
        },
        sections: [
          {
            label: 'list',
            content: <TabList currentTab={ FundraiserState.ActiveUpcoming } selectedTab={ selectedTab } { ...props } />,
          },
        ],
      },
    ];

    if (props.user?.Roles.includes(FundraiserDefaults.SuperAdminRoleName) || props.user?.Roles.includes(FundraiserDefaults.ISCRoleName) || props.user?.Roles.includes(FundraiserDefaults.SalesAgentRoleName)) {
      tabs.shift();

      tabs.unshift({
        tab: {
          id: 'active-fundraisers',
          label: FundraiserState.Active,
          value: FundraiserState.Active,
        },
        sections: [
          {
            label: 'list',
            content: <TabList currentTab={ FundraiserState.Active } selectedTab={ selectedTab } { ...props } />,
          },
        ],
      },
      {
        tab: {
          id: 'upcoming-fundraisers',
          label: FundraiserState.Upcoming,
          value: FundraiserState.Upcoming,
        },
        sections: [
          {
            label: 'list',
            content: <TabList currentTab={ FundraiserState.Upcoming } selectedTab={ selectedTab } { ...props } />,
          },
        ],
      });
    }

    tabs.push({
      tab: {
        id: 'completed-fundraisers',
        label: FundraiserState.Completed,
        value: FundraiserState.Completed,
      },
      sections: [
        {
          label: 'list',
          content: <TabList currentTab={ FundraiserState.Completed } selectedTab={ selectedTab } { ...props } />,
        },
      ],
    });

    if (props.user?.Roles.includes(FundraiserDefaults.SuperAdminRoleName) || props.user?.Roles.includes(FundraiserDefaults.SalesAgentRoleName)) {
      tabs.push({
        tab: {
          id: 'cancelled-fundraisers',
          label: FundraiserState.Canceled,
          value: FundraiserState.Canceled,
        },
        sections: [
          {
            label: 'list',
            content: <TabList currentTab={ FundraiserState.Canceled } selectedTab={ selectedTab } { ...props } />,
          },
        ],
      });
    }
    return tabs;
  }, [ props, selectedTab ]);

  return (
    <Box>
      <TabbedCard config={ config } onTabSelect={ onTabSelect } />
    </Box>
  );
};

export default FundraiserListTabs;
