import { useQuery } from 'react-query';

import * as QueryKeys from '../../../constants/QueryKeys';
import { State } from '../types';
import { fetchStates } from '../api';

import { ApiError } from 'features/common';

// eslint-disable-next-line max-len
export const useFetchStates = (country: number) => useQuery<State[], ApiError>(`${ QueryKeys.StatesByCountry }${ country }`,
  () => fetchStates(country), {
    refetchOnMount: false,
    retry: false,
    refetchOnWindowFocus: false,
  });
