import { useQuery } from 'react-query';

import { ApiError } from '../types';
import { UserInfo } from '../types/userInfo';

import * as QueryKeys from 'constants/QueryKeys';
import api from 'api';
import { appInsights } from 'features/analytics/ApplicationInsights';

const useUserInfoRequest = () => api.get('api/user/info');

const useUserInfo = (authenticated: boolean) => useQuery<UserInfo, ApiError>(QueryKeys.UserInfo, useUserInfoRequest,
  {
    enabled: !!authenticated,
    retry: true,
    refetchOnMount: true,
    refetchOnReconnect: true,
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      if (data.Guid) {
        appInsights.clearAuthenticatedUserContext();
        appInsights.setAuthenticatedUserContext(data.Guid);
      }
    },
  });

export { useUserInfo };
