import React from 'react';
import { Flex, IconButton, Text } from '@lce/slice_v2';
import { ChevronDown, ChevronUp } from 'react-feather';

export interface IAccordianBoxTitle {
  title: string | React.ReactNode;
  openTitle?: string | React.ReactNode;
  isOpen: boolean;
  onOpenCallback: (isOpen: boolean) => void;
}

const Title: React.FC<IAccordianBoxTitle> = (props: IAccordianBoxTitle) => {
  const title = props.title;
  const openTitle = props.openTitle;

  return (
    <Flex
      sx={ {
        justifyContent: 'space-between',
        alignItems: 'center',
        bg: 'primaryGrayMid',
        padding: '8px 16px',
        height: '36px',
        fontFamily: 'mrEaves',
        fontSize: '18px',
        textTransform: 'uppercase',
        borderBottomLeftRadius: '4px',
        borderBottomRightRadius: '4px',
      } }
    >
      <Text sx={ { fontWeight: '800' } }>
        { props.openTitle && props.isOpen ? openTitle : title}
      </Text>
      <IconButton onClick={ () => props.onOpenCallback(!props.isOpen) } sx={ { cursor: 'pointer' } }>
        { !props.isOpen ? <ChevronDown /> : <ChevronUp /> }
      </IconButton>
    </Flex>
  );
};

export default Title;
