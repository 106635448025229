/* eslint-disable max-lines-per-function */
/* eslint-disable no-extra-parens */
import React, { useEffect } from 'react';
import { Box, Flex, Form } from '@lce/slice_v2';
import { useFormContext } from 'react-hook-form';
import { map as _map } from 'lodash';
import { useTranslation } from '@lce/i18n';

const boxStyling = {
  m: [ '0px' ],
  display: 'flex',
  flex: [ '1 1 50%', '1 1 30%', '1 1 30%', 'auto' ],
  maxWidth: '250px',
  minWidth: 'auto',
};

const fieldStyling = {
  whiteSpace: 'nowrap',
  display: 'flex',
  m: [ '0px 25px 25px 0px', '0px 0px 25px 0px', '0px 0px 0px 0px' ],
};

const ReportStatus: React.FC = () => {
  const {
    formState: { isSubmitting }, setValue, watch,
  } = useFormContext();
  const [ t ] = useTranslation();

  const statuses = [
    {
      name: 'statusActive',
      label: t('fundraiserReportFilters.ReportStatus.Active'),
    },
    {
      name: 'statusCancelled',
      label: t('fundraiserReportFilters.ReportStatus.Cancelled'),
    },
    {
      name: 'statusUpcoming',
      label: t('fundraiserReportFilters.ReportStatus.Upcoming'),
    },
    {
      name: 'statusCompleted',
      label: t('fundraiserReportFilters.ReportStatus.Completed'),
    },
  ];

  const {
    statusAll, statusActive, statusCancelled, statusUpcoming, statusCompleted,
  } = watch([ ..._map(statuses, ({ name }) => name), 'statusAll' ]);

  useEffect(() => {
    if (statusAll && (statusActive || statusCancelled || statusUpcoming || statusCompleted)) {
      setValue('statusAll', false);
    }

    if ((statusActive && statusCancelled && statusUpcoming && statusCompleted) ||
    (!statusAll && !statusActive && !statusCancelled && !statusUpcoming && !statusCompleted)) {
      setValue('statusAll', true);
      setValue('statusActive', false);
      setValue('statusCancelled', false);
      setValue('statusUpcoming', false);
      setValue('statusCompleted', false);
    }
  }, [ statusAll, statusActive, statusCancelled, statusUpcoming, statusCompleted, setValue ]);

  return (
    <Box
      sx={ { mt: [ '1%', '1%', '1%', '20px' ] } }
    >
      <Form.Label
        sx={ {
          m: [ 'auto auto 10px auto' ],
          p: 0,
        } }
      >
        {t('fundraiserReportFilters.ReportStatus.Label')}
      </Form.Label>
      <Flex
        sx={ {
          flexDirection: [ 'row', 'row', 'row', 'row' ],
          height: [ 'auto', 'auto', '80px', 'auto' ],
          width: [ 'auto', 'auto', 'auto', 'auto' ],
          flexWrap: [ 'wrap' ],
          'div:nth-of-type(5) ': {
            div: { m: [ '0px 0px 0px 0px' ] },
          },
        } }
      >
        <Box mb={ 4 } sx={ boxStyling }>
          <Form.Field
            component={ Form.Input.Checkbox }
            disabled={ isSubmitting }
            id="statusAll"
            inline={ true }
            label={ t('fundraiserReportFilters.ReportStatus.All') }
            name="statusAll"
            onChange={ (e) => {
              setValue(e.target.name, e.target.checked);
              if (e.target.checked) {
                _map(statuses, ({ name }) => setValue(name, false));
              } else {
                setValue(statuses[0].name, !e.target.checked);
              }
            } }
            sx={ fieldStyling }
          />
        </Box>
        {_map(statuses, ({ name, label }) => (
          <Box key={ name } mb={ 4 } sx={ boxStyling }>
            <Form.Field
              component={ Form.Input.Checkbox }
              disabled={ isSubmitting }
              id={ name }
              inline={ true }
              label={ label }
              name={ name }
              sx={ fieldStyling }
            />
          </Box>
        ))}
        <Box
          sx={ {
            ...boxStyling, overflow: 'hidden', height: '24px', width: '100px',
          } }
        />
      </Flex>
    </Box>
  );
};

export default ReportStatus;

