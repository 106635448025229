import { useMemo } from 'react';

/* eslint-disable no-confusing-arrow */
export function useMergeWithDefaults<TP, TD>(props: object & TP,
  defaults: object & TD,
  keysToOmit: string[] = [ 'required' ]) {
  return useMemo(() => {
    const pairs = Object
      .keys(defaults)
      .filter(key => !keysToOmit.includes(key))
      .map(key => props[key] === undefined ? [ key, defaults[key] ] : [ key, props[key] ]);

    return Object.fromEntries(pairs);
  }, [ defaults, keysToOmit, props ]);
}
