/* eslint-disable max-len */
import { Seller } from 'features/fundraiser/types/seller';

export const sellersFilter = (searchValue: string,
  setFilteredSellers: React.Dispatch<React.SetStateAction<Seller[]>>,
  sellers: Seller[]) => {
  const fullNameSpace = ' ';

  if (searchValue?.length > 0) {
    const result = sellers?.filter(
      seller => seller?.FirstName?.trim().toLowerCase().includes(searchValue.toLowerCase()) ||
      seller?.LastName?.trim().toLowerCase().includes(searchValue.toLowerCase()) ||
      seller?.LastInitial?.trim().toLowerCase().includes(searchValue.toLowerCase()) ||
      seller?.FirstName?.concat(fullNameSpace, seller?.LastInitial).trim().toLowerCase().includes(searchValue.toLowerCase()) ||
      seller?.FirstName?.concat(fullNameSpace, seller?.LastName).trim().toLowerCase().includes(searchValue.toLowerCase()) ||
        seller?.FirstName?.concat(fullNameSpace, seller?.LastName, fullNameSpace, seller?.LastInitial).trim().toLowerCase().includes(searchValue.toLowerCase()));
    setFilteredSellers(result || []);
  } else {
    setFilteredSellers(sellers || []);
  }
};
