import { useState } from 'react';
import { useLocation } from 'react-router-dom';

import { useEventListener } from 'features/common/hooks/useEventListener';

export const useIsArchExpanding = () => {
  const [ isArchExpanding, setIsArchExpanding ] = useState(true);
  const location = useLocation();
  const onScroll = () => {
    setIsArchExpanding(window.scrollY < 100);
  };
  useEventListener('scroll', onScroll);

  if (location.pathname !== '/') {
    return false;
  }

  return isArchExpanding;
};
