import React from 'react';
import { Box, Text } from '@lce/slice_v2';
import { useTranslation } from '@lce/i18n';
import { RouteComponentProps } from 'react-router-dom';

import { LandingImage } from '../components';

import { theme } from 'theme';

export type LandingConfirmationProps = RouteComponentProps & {
  testId?: string;
}

export interface MatchParams extends LandingConfirmationProps{
  page: string;
}
export const dataTestIdLandingConfirmation = 'landing-confirmation-component';

export const LandingConfirmation: React.FC<LandingConfirmationProps> = ({
  testId = dataTestIdLandingConfirmation,
  match: { params },
}) => {
  const { page } = params as MatchParams;
  const [ t ] = useTranslation();

  return (
    <Box
      data-testid={ testId }
      sx={ {
        m: 'auto', maxWidth: '992px', fontSize: '20px', itemAlign: 'center', width: '100%',
      } }
    >
      <LandingImage page={ page } />
      <Text
        sx={ {
          fontSize: '28px', fontWeight: '900',
          mb: [ '24px', '300px' ], mt: [ '0', '70px' ], textAlign: 'center',
          lineHeight: 1,
          fontFamily: theme.fonts.mrEaves,
        } }
      >
        {t('landing.shared.confirmation.message')}
      </Text>
    </Box>
  );
};
