import _map from 'lodash/map';

import { htmlDecode } from './htmlDecode';

export const formatEmailBodyForCopy = (emailBody: string[]) => {
  if (!emailBody || emailBody.length === 0) {
    return '';
  }

  const decodedBody = htmlDecode(emailBody.join('\n'));
  const bracketlessBody = decodedBody.replace(/\[|\]/g, '').split('\n');

  const copyText = _map(bracketlessBody, (text, index) => {
    if (index === emailBody.length - 1) {
      return text;
    } else {
      return `${ text }\n\n`;
    }
  }).join('');

  return copyText;
};
