import React, { useState } from 'react';
import { Card, Box } from '@lce/slice_v2';

import CardTabs, { Tab } from './CardTabs/CardTabs';

export interface TabbedCardConfig {
  tab: Tab;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  sections: any[];
}

export interface ITabbedCardProps {
  config: TabbedCardConfig[];
  onTabSelect?: (tab) => void;
}

const TabbedCard: React.FC<ITabbedCardProps> = (props) => {
  const [ selectedTab, setSelectedTab ] = useState(props.config[0].tab.value);

  const tabClickHandler = (value) => {
    setSelectedTab(value);

    if (props.onTabSelect) {
      props.onTabSelect(value);
    }
  };

  return (
    <Card variant="cards.tabbedCard">
      <CardTabs
        onClick={ tabClickHandler }
        selected={ selectedTab }
        tabs={ props.config.map(x => x.tab) }
      />
      {props.config.map(cardTab => (
        <Box
          key={ cardTab.tab.id }
          sx={ {
            display: selectedTab === cardTab.tab.value ? 'block' : 'none',
          } }
        >
          { cardTab.sections.map(section => (
            <div key={ section.label }>
              {section.content}
            </div>
          ))}
        </Box>
      ))}
    </Card>
  );
};

export default TabbedCard;
