/* eslint-disable max-lines-per-function */
/* eslint-disable max-len */
import {
  Box, Button, Flex, Heading, Text,
} from '@lce/slice_v2';
import React from 'react';
import { map as _map } from 'lodash';

import { ResourceHubSocialTemplate, ISocial } from './ResourceHubSocialTemplate';

import { FundraiserListItem } from 'features/fundraiser';

export interface IResourceHubSocialSectionProps {
  socialSectionContent: {
    socialCards: ISocial[];
    downloadSocialPackage: string;
    heading: string;
    subHeading: string;
  };
  fundraiserRole: string;
  fundraiser: FundraiserListItem;
  sellerSupportGuid: string | undefined;
}

const ResourceHubSocialSection: React.FC<IResourceHubSocialSectionProps> = ({
  socialSectionContent, fundraiserRole, fundraiser, sellerSupportGuid,
}) => {
  const {
    socialCards, downloadSocialPackage, heading, subHeading,
  } = socialSectionContent;

  const zipLink = fundraiserRole === 'chairperson'
    ? `${ process.env.REACT_APP_BASE_BLOB_URL }/misc/Little%20Caesars%20Fundraising%20Social%20Media%20Package%20for%20Chairperson.zip`
    : `${ process.env.REACT_APP_BASE_BLOB_URL }/misc/Little%20Caesars%20Fundraising%20Social%20Media%20Package%20for%20Sellers.zip`;

  return (
    <Box
      data-testid="resourceHubSocialSection"
      sx={ { pt: [ '44px', '28px' ], px: '16px', pb: [ '16px', '42px' ] } }
    >
      <Flex
        sx={ {
          flexDirection: 'column', alignItems: 'center', mb: [ '20px', '50px' ], textAlign: 'center',
        } }
      >
        <Heading sx={ { fontSize: [ '30px', '40px' ] } }>
          {heading}
        </Heading>
        <Text sx={ { fontSize: [ '16px', '18px' ], maxWidth: '400px', textAlign: 'center' } }>
          {subHeading}
        </Text>
      </Flex>
      <Flex
        sx={ {
          maxWidth: [ 'unset', 'unset', 'unset', '1070px' ], m: '0 auto', width: '100%',
          justifyContent: [ 'space-evenly', 'space-evenly', 'space-evenly', 'center' ], gap: 5,
          flexDirection: [ 'column', 'column', 'unset' ], flexWrap: 'wrap', alignItems: 'center',
        } }
      >
        {_map(socialCards, (socialInfo: ISocial, index) => (
          <ResourceHubSocialTemplate
            fundraiser={ fundraiser }
            key={ index }
            sellerSupportGuid={ sellerSupportGuid }
            socialInfo={ socialInfo }
          />
        ))}
      </Flex>
      <Flex>
        <Button
          as="a"
          download={ true }
          href={ zipLink }
          sx={ {
            m: [ '10px auto 30px', '30px auto 0px auto' ],
            color: 'primaryOrange',
            width: '320px',
            py: '10px',
            height: 'auto',
          } }
          variant="secondary"
        >
          {downloadSocialPackage}
        </Button>
      </Flex>
    </Box>
  );
};

export default ResourceHubSocialSection;
