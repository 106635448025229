import React from 'react';
import Select from 'react-select';
import { useFormContext, Controller } from 'react-hook-form';
import { ThemeUIStyleObject } from 'theme-ui';
import { IFieldProps } from '@lce/slice_v2/Elements/Form/Field';
import { Box } from '@lce/slice_v2';

import { IOption } from '../../IOption';

interface ITypeaheadSelector extends IFieldProps {
  isMulti: boolean;
  options: IOption[];
  sx?: ThemeUIStyleObject;
}

const TypeaheadSelect: React.FC<ITypeaheadSelector> = ({
  name, id, options, label = null, isMulti = true, sx,
}) => {
  const { control, formState: { errors } } = useFormContext();

  const style = {
    dropdownIndicator: provided => ({
      ...provided,
      svg: {
        fill: '#000',
      },
    }),
    placeholder: defaultStyles => ({
      ...defaultStyles,
      color: '#000',
    }),
  };

  return (
    <>
      {errors[name] && (
        <Box
          variant="text.errorMessage"
        >
          {errors[name]}
        </Box>
      )}
      <Controller
        as={ Select }
        components={ {
          IndicatorSeparator: () => null,
        } }
        control={ control }
        id={ id }
        isMulti={ isMulti }
        label={ label }
        name={ name }
        options={ options }
        styles={ style }
        sx={ sx }
      />
    </>
  );
};

export default TypeaheadSelect;
