import React, { useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  Text, Container, Spinner, Heading,
} from '@lce/slice_v2';
import { useTranslation } from '@lce/i18n';

import { GenericToast } from 'ui/common';
import { Address } from 'features/common';
import { useFetchOrderSeller } from 'features/checkout';
import { OrderConfirmationCard } from 'ui/order';
import { CreateMealDealAccountForm } from 'ui/account/MealDeal/CreateAccount';

export interface IOrderConfirmationLocationProps {
  isGuest: boolean;
  guestId: string;
  guestAddress: Address;
}

export interface IMealDealOrderConfirmationPage {
  isCreateAccountOpenDefault?: boolean;
  isAccountCreatedDefault?: boolean;
}

export interface IMealDealOrderConfirmationPageParams {
  orderGuid: string;
}

// eslint-disable-next-line max-lines-per-function
export const MealDealOrderConfirmationPage: React.FC<IMealDealOrderConfirmationPage> = ({
  isCreateAccountOpenDefault = false, isAccountCreatedDefault = false,
}) => {
  const [ t ] = useTranslation();
  const location = useLocation<IOrderConfirmationLocationProps>();
  const { orderGuid }: IMealDealOrderConfirmationPageParams = useParams();
  const { data: orderSeller, isLoading, error } = useFetchOrderSeller(orderGuid);
  const [ isCreateAccountOpen, setIsCreateAccountOpen ] = useState<boolean>(isCreateAccountOpenDefault);
  const [ accountCreated, setAccountCreated ] = useState<boolean>(isAccountCreatedDefault);

  const onClose = () => {
    setIsCreateAccountOpen(!isCreateAccountOpen);
  };

  const onCreateAccountSuccess = () => {
    setAccountCreated(true);
    onClose();
    toast(<GenericToast text="Your account has been created" />);
  };

  // loading state
  if (isLoading && !error) {
    return (
      <Container sx={ { textAlign: 'center' } }>
        <Spinner variant="lce" />
      </Container>
    );
  }

  if (error) {
    return (
      <Container>
        <Heading>Uh oh</Heading>
        <Text variant="text.header.sub">
          {t('orderConfirmation.error')}
        </Text>
      </Container>
    );
  }

  if (!orderSeller) {
    return null;
  }

  return (
    <OrderConfirmationCard
      isGuest={ location.state?.isGuest && !accountCreated }
      isOpen={ isCreateAccountOpen }
      onClose={ onClose }
      orderSeller={ orderSeller }
      testId="meal-deal-order-confirmation-page"
    >
      <CreateMealDealAccountForm
        address={ location.state?.guestAddress }
        guestId={ location.state?.guestId }
        isConfirm={ false }
        onSuccess={ onCreateAccountSuccess }
      />
    </OrderConfirmationCard>
  );
};
