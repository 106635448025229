import * as React from 'react';
import { Box, Flex, Text } from '@lce/slice_v2';
import { format } from 'date-fns';

import { AccordianBox } from 'ui/common';
import { BuyerOrder, OrderItem } from 'features/fundraiser/types/buyer';

export interface IBuyerListItem {
  order: BuyerOrder;
}

const BuyerListItem: React.FC<IBuyerListItem> = (props: IBuyerListItem) => {
  const totalKits: number = props.order.OrderInfo.OrderItems.reduce((sum, { Quantity }) => sum + Quantity, 0);

  return (
    <Box sx={ { margin: '10px 0px' } }>
      <Flex
        sx={ {
          justifyContent: 'space-between',
          padding: '10px 30px',
          border: '1px solid',
          borderColor: 'primaryGrayMid',
          borderRadius: '4px',
          height: [ 'auto', '40px' ],
          alignItems: [ 'left', 'center' ],
          flexDirection: [ 'column', 'row' ],
        } }
      >
        <Text>
          {`${ props.order.BuyerInfo.FirstName } ${ props.order.BuyerInfo.LastName }`}
        </Text>
        <Text>
          {props.order.BuyerInfo.Email}
        </Text>
        <Text>
          { format(new Date(props.order.OrderInfo.DateOfPurchase), 'MM/dd/yyyy') }
        </Text>
      </Flex>
      <AccordianBox openFromTop={ true } title={ `Kits: ${ totalKits }` }>
        <OrderItems orderItems={ props.order.OrderInfo.OrderItems } />
      </AccordianBox>
    </Box>
  );
};

interface IOrderItems {
  orderItems: OrderItem[];
}

const OrderItems: React.FC<IOrderItems> = (props: IOrderItems) => (
  <Box sx={ { padding: '10px 30px' } }>
    {props.orderItems.map(item => (
      <Text key={ item.Id }>
        {`(${ item.Quantity }) ${ item.Name }`}
      </Text>
    ))}
  </Box>
);

export default BuyerListItem;
