import { useQuery } from 'react-query';

import api from 'api';
import * as QueryKeys from 'constants/QueryKeys';

/** API Call */
const fetchTerms = () => api.get('api/content/terms-of-sale').then(res => res);

const useFetchTermsOfSale = () => useQuery(QueryKeys.TermsOfSale, fetchTerms,
  {
    retry: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });

export { useFetchTermsOfSale };
