import React from 'react';

import { ResourceHubHeader } from './ResourceHubHeader';
import { ResourceHubEmailSection } from './ResourceHubEmailSection';
import { ResourceHubSocialSection } from './ResourceHubSocialSection';

import { FundraiserListItem } from 'features/fundraiser';

export interface IResourceHubProps{
  fundraiserRole: string;
  data: string;
  fundraiser: FundraiserListItem | undefined;
  sellerSupportGuid: string | undefined;
}

const ResourceHub: React.FC<IResourceHubProps> = ({
  fundraiserRole, data, fundraiser, sellerSupportGuid,
}) => {
  if (!fundraiser || !data) {
    return null;
  }

  return (
    <>
      <ResourceHubHeader heading={ data[fundraiserRole].heading } />
      <ResourceHubEmailSection
        emailSectionContent={ data[fundraiserRole].emailSection }
        fundraiser={ fundraiser }
        fundraiserRole={ fundraiserRole }
      />
      <ResourceHubSocialSection
        fundraiser={ fundraiser }
        fundraiserRole={ fundraiserRole }
        sellerSupportGuid={ sellerSupportGuid }
        socialSectionContent={ data[fundraiserRole].socialSection }
      />
    </>
  );
};

export default ResourceHub;
