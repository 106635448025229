import { createContext, useContext } from 'react';

import { CardInfo, PaymentState } from 'ui/checkout';

type Action =
  { type: 'SET_TOKEN'; code: string; token: string } |
  { type: 'SET_ERROR_CODE'; code: string; token: string; popup: boolean } |
  { type: 'UNSET_ERROR_CODE'; code: string; token: string; popup: boolean } |
  { type: 'SET_PAYMENT_STEP' } |
  { type: 'UNSET_PAYMENT_STEP'} |
  { type: 'SET_REVIEW_STEP' } |
  { type: 'UNSET_REVIEW_STEP'} |
  { type: 'SET_POPUP' } |
  { type: 'UNSET_POPUP'} |
  { type: 'SET_INLINE_ERROR' } |
  { type: 'UNSET_INLINE_ERROR'} |
  { type: 'SET_CARD'; card: CardInfo } |
  { type: 'UNSET_CARD'; card: CardInfo }

type Dispatch = (action: Action) => void;

export const PaymentStateContext = createContext<PaymentState | undefined>(undefined);
export const PaymentDispatchContext = createContext<Dispatch | undefined>(undefined);

export const usePaymentState = () => {
  const context = useContext(PaymentStateContext);
  if (context === undefined) {
    throw new Error('usePaymentState must be used within PaymentStateContext');
  }
  return context;
};

export const usePaymentDispatch = () => {
  const context = useContext(PaymentDispatchContext);
  if (context === undefined) {
    throw new Error('usePaymentDispatch must be used within PaymentDispatchContext');
  }
  return context;
};
