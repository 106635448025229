import React from 'react';
import useClipboard from 'react-use-clipboard';
import {
  Button, Flex, Image, Text,
} from '@lce/slice_v2';
import { useTranslation } from '@lce/i18n';
import { toast } from 'react-toastify';

import copyPaste from '../../../assets/icons/copypaste.svg';

import { GenericToast } from 'ui/common/Toast/CustomToasts';

export interface ICopyShareDescriptionProps {
  copyText: string;
  onCopy?: () => void;
}

const CopyShareDescription: React.FC<ICopyShareDescriptionProps> = ({
  copyText, onCopy,
}) => {
  const [ t ] = useTranslation();
  const [ , setCopied ] = useClipboard(copyText);

  const onCopyClick = () => {
    setCopied();
    toast(<GenericToast text={ t('common.TextCopied') } />, { position: 'top-center' });
    onCopy && onCopy();
  };

  return (
    <Flex
      data-testid="copyShareDescription"
      sx={ {
        flexDirection: 'column',
        alignItems: 'center',
      } }
    >
      <Text
        sx={ {
          mb: 2,
          borderRadius: '4px',
          border: '1px solid #C4C4C4',
          p: '8px',
          width: '100%',
          wordBreak: 'break-word',
          maxHeight: [ '120px', 'none' ],
          overflow: 'auto',
          fontSize: [ '14px', '16px' ],
        } }
      >
        { copyText }
      </Text>
      <Button
        onClick={ onCopyClick }
        sx={ {
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          gap: 2,
          fontSize: [ '14px', '16px' ],
        } }
        variant="buttons.text"
      >
        <Text>Copy to Clipboard</Text>
        <Image src={ copyPaste } sx={ { width: 'auto', height: [ '18px', '20px' ] } } />
      </Button>
    </Flex>
  );
};

export default CopyShareDescription;

