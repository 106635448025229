import * as React from 'react';
import { Flex } from '@lce/slice_v2';

import { SellerAvatar } from './SellerAvatar';
import { SellerText } from './SellerText';

import { FundraiserType, Seller } from 'features/fundraiser';

export interface ISellerInformationProps {
  seller: Seller;
  fundraiserType: FundraiserType;
}

export const SellerInformation: React.FC<ISellerInformationProps> = ({ seller, fundraiserType }) => (
  <Flex sx={ { justifyContent: 'center', gap: '16px', maxWidth: '810px' } }>
    <SellerAvatar imageSrc={ seller.AvatarSource } />
    <SellerText fundraiserType={ fundraiserType } seller={ seller } />
  </Flex>
);
