import { createContext, useContext } from 'react';

import { Cart } from 'features/cart/types';

export type Action =
  | { type: 'ADD_ITEM' }
  | { type: 'UPDATE_CART'; items: Cart }
  | { type: 'REMOVE_PRODUCT' }
  | { type: 'CLEAR' }
  | { type: 'SET_GUEST'; guestId?: string }
  | { type: 'SET_SELLER'; sellerId: string }
  | { type: 'SET_FUNDRAISER'; fundraiserId: number}
  | { type: 'SET_START_SHOPPING' }

type Dispatch = (action: Action) => void;

export type CartState = {
  cart: Cart;
  subtotal: number;
  totalQuantity: number;
  guestId?: string;
  sellerId: string;
  fundraiserId: number;
  canStartShopping: boolean;
}

export type CustomerData = {
  sellerId: string;
  fundraiserId: number;
  guestId: string;
  canStartShopping: boolean;
}

export const CartStateContext = createContext<CartState | undefined>(undefined);
export const CartDispatchContext = createContext<Dispatch | undefined>(undefined);

export const useCartState = (): CartState => {
  const context = useContext(CartStateContext);
  if (context === undefined) {
    throw new Error('useCartState must be used within a CartStateProvider');
  }

  return context;
};

export const useCartDispatch = () => {
  const context = useContext(CartDispatchContext);
  if (context === undefined) {
    throw new Error('useCartDispatch must be used within a CartStateProvider');
  }

  return context;
};
