import { useMutation, useQueryClient } from 'react-query';

import { removeFundraiserSeller } from '../api';

import * as QueryKeys from 'constants/QueryKeys';
import { ApiError } from 'features/common';

// eslint-disable-next-line max-len
export const useRemoveFundraiserSeller =
 (fundraiserId) => {
   const queryClient = useQueryClient();

   return useMutation<boolean, ApiError, number>(removeFundraiserSeller,
     {
       onSuccess: () => queryClient.invalidateQueries(`${ QueryKeys.SellerPrefix }-${ fundraiserId }`),
     });
 };
