/* eslint-disable react/forbid-component-props */
import React, { useState } from 'react';
import {
  Flex, Image, IconButton,
} from '@lce/slice_v2';
import { ThemeUIStyleObject } from 'theme-ui';
import { useTranslation } from '@lce/i18n';
import { FacebookShareButton, PinterestShareButton, TwitterShareButton } from 'react-share';

import { InstagramModal } from './InstagramModal';

import Instagram from 'assets/icons/instagram.svg';
import Facebook from 'assets/icons/facebook.svg';
import Pinterest from 'assets/icons/pinterest.svg';
import Twitter from 'assets/icons/twitterX.svg';

export interface ISocialButtons {
  fundraiserName: string;
  fundraiserImage: string;
  link: string;
  sx?: ThemeUIStyleObject;
}

const buttonStyle = {
  height: '40px',
  width: '40px',
  padding: '0px',
  cursor: 'pointer',
};

// eslint-disable-next-line max-lines-per-function
export const SocialButtons: React.FC<ISocialButtons> = ({
  fundraiserName, fundraiserImage, link, sx,
}) => {
  const [ t ] = useTranslation();
  const [ isInstagramModalOpen, setIsInstagramModalOpen ] = useState(false);

  const handleModalOpen = () => {
    setIsInstagramModalOpen(!isInstagramModalOpen);
  };

  return (
    <Flex sx={ { ...sx, gap: 4 } }>
      <FacebookShareButton
        data-testid="social-media-facebook-button"
        style={ buttonStyle }
        url={ link }
      >
        <Image alt="Facebook" id="click-social-facebook" src={ Facebook } title="Facebook" />
      </FacebookShareButton>

      <PinterestShareButton
        data-testid="social-media-pinterest-button"
        description={ t('mealDeal.success.shareText', { fundraiserName }) }
        media={ fundraiserImage }
        style={ buttonStyle }
        url={ link }
      >
        <Image
          alt="Pinterest" id="click-social-pinterest" src={ Pinterest } title="Pinterest"
        />
      </PinterestShareButton>

      <TwitterShareButton
        data-testid="social-media-x-button"
        style={ buttonStyle }
        title={ t('mealDeal.success.shareText', { fundraiserName }) }
        url={ link }
      >
        <Image alt="X" id="click-social-x" src={ Twitter } title="X" />
      </TwitterShareButton>

      <IconButton
        data-testid="social-media-instagram-button"
        onClick={ handleModalOpen }
        sx={ buttonStyle }
      >
        <Image alt="Instagram" id="click-social-instagram" src={ Instagram } title="Instagram" />
      </IconButton>
      <InstagramModal
        fundraiserName={ fundraiserName }
        isOpen={ isInstagramModalOpen }
        link={ link }
        onClose={ handleModalOpen }
      />
    </Flex>
  );
};
