import React from 'react';

import { MealDealLandingPage } from '../../MealDealLandingPage';
import { RouteOne } from '../RouteOne';

export const Start: React.FC = () => (
  <MealDealLandingPage>
    <RouteOne />
  </MealDealLandingPage>
);
