import * as React from 'react';
import { Box, Image } from '@lce/slice_v2';

import DefaultSellerAvatar from 'assets/images/default-seller-avatar.png';

export interface ISellerAvatarProps {
  imageSrc?: string;
}

export const SellerAvatar: React.FC<ISellerAvatarProps> = ({ imageSrc }) => (
  <Box
    sx={ {
      height: 'auto',
      width: '130px',
      flexShrink: 0,
      alignSelf: 'center',
    } }
  >
    <Image alt="Avatar" src={ imageSrc || DefaultSellerAvatar } title="Avatar" />
  </Box>
);
