import React from 'react';
import {
  Image, Text, Card,
} from '@lce/slice_v2';
import { ThemeUIStyleObject } from 'theme-ui';

export interface IInfoCardProps {
  image: string;
  imageAlt?: string;
  title: string;
  description?: string;
  sx?: ThemeUIStyleObject;
}

const InfoCard: React.FC<IInfoCardProps> = ({
  image, imageAlt, title, description, children, sx,
}) => (
  <Card sx={ { ...sx } }>
    <Image
      alt={ imageAlt }
      src={ image }
      sx={ { mb: '20px', width: '100%' } }
    />
    <Text sx={ { color: 'black' } } variant="text.h2">
      {title}
    </Text>
    { description && (
      <Text sx={ { mb: '20px' } }>
        {description}
      </Text>
    )}
    {children}
  </Card>
);

export default InfoCard;
