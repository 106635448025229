import React from 'react';
import { useTranslation } from '@lce/i18n';
import { Box, Container, Text } from '@lce/slice_v2';

import { Banner } from 'ui/common';
import BannerImage from 'assets/images/banners/tetimonials-banner-kids-with-hearts.jpg';

interface IQuoteProps {
  author: string;
  quote: string;
  title: string;
}

/**
 * TODO: These bad boys should come from a CMS
 */
const Testimonials: React.FC = () => {
  const [ t ] = useTranslation();

  return (
    <Container>
      <Banner
        gradient="to-bottom"
        image={ BannerImage }
        showOnMobile={ true }
        text={ t('testimonials.banner.Title') }
      />
      <Box sx={ { maxWidth: '855px' } }>
        <Quote
          author={ t('testimonials.quote1.author') }
          quote={ t('testimonials.quote1.quote') }
          title={ t('testimonials.quote1.title') }
        />
        <Quote
          author={ t('testimonials.quote2.author') }
          quote={ t('testimonials.quote2.quote') }
          title={ t('testimonials.quote2.title') }
        />
        <Quote
          author={ t('testimonials.quote3.author') }
          quote={ t('testimonials.quote3.quote') }
          title={ t('testimonials.quote3.title') }
        />
        <Quote
          author={ t('testimonials.quote4.author') }
          quote={ t('testimonials.quote4.quote') }
          title={ t('testimonials.quote4.title') }
        />
        <Quote
          author={ t('testimonials.quote5.author') }
          quote={ t('testimonials.quote5.quote') }
          title={ t('testimonials.quote5.title') }
        />
        <Quote
          author={ t('testimonials.quote6.author') }
          quote={ t('testimonials.quote6.quote') }
          title={ t('testimonials.quote6.title') }
        />
        <Quote
          author={ t('testimonials.quote7.author') }
          quote={ t('testimonials.quote7.quote') }
          title={ t('testimonials.quote7.title') }
        />
      </Box>
    </Container>
  );
};

const Quote: React.FC<IQuoteProps> = props => (
  <Box sx={ { mt: '35px', mb: '35px' } }>
    <Text sx={ { color: 'primaryOrange' } } variant="text.quote">
      "
      {props.title}
      "
    </Text>
    <Text sx={ { lineHeight: '18px', mt: '16px', mb: '16px' } }>
      {props.quote}
    </Text>
    <Text variant="text.quote.author">
      {props.author}
    </Text>
  </Box>
);

export default Testimonials;
