import { useMutation, useQueryClient } from 'react-query';

import { FundraiserProfitCheckAddress } from '../types/fundraiser';
import { updateFundraiserProfitCheckAddress } from '../api';

import { ApiError } from 'features/common';
import * as QueryKeys from 'constants/QueryKeys';

// eslint-disable-next-line max-len
export const useUpdateFundraiserProfitCheckAddress = () => {
  const queryClient = useQueryClient();
  return useMutation<boolean, ApiError, FundraiserProfitCheckAddress>(updateFundraiserProfitCheckAddress, {
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries(`${ QueryKeys.Group }`);
      queryClient.invalidateQueries(`${ QueryKeys.FundraiserById }-${ variables.fundraiserId }`);
    },
  });
};
