import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

/**
 * This page exists for the purpose of tracking conversions.
 * It will immediately redirect to the fundraiser dashboard
 */
const NewFundraiserFormConfirm = () => {
  const history = useHistory();

  useEffect(() => {
    history.push('/dashboard/fundraisers');
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ ]);

  return (<div />);
};

export default NewFundraiserFormConfirm;
