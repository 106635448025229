import { ELEVATED_RELATIONSHIPS, FundraiserListItem, FundraiserRelationship } from '../types';

import { UserInfo } from 'features/common';

export const getFundraiserLinkPath = (fundraiser: FundraiserListItem | undefined, user: UserInfo | undefined) => {
  if (!fundraiser || !user) {
    return '';
  }

  const sellerRole = fundraiser.Relationships.includes(FundraiserRelationship.SELLER);

  if (fundraiser.Relationships.some(role => ELEVATED_RELATIONSHIPS.includes(role))) {
    return `/dashboard/fundraisers/manage/${ fundraiser.Id }`;
  }

  if (sellerRole) {
    return `/dashboard/fundraisers/${ fundraiser.Id }/seller/${ user.Id }`;
  }

  return '';
};
