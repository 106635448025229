/* eslint-disable max-len */
/* eslint-disable no-extra-parens */
/* eslint-disable complexity */
/* eslint-disable react/forbid-component-props */
import React, { useEffect } from 'react';
import { Box, Flex } from '@lce/slice_v2';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import { format } from 'date-fns';
import { useTranslation } from '@lce/i18n';
import { ChevronLeft } from 'react-feather';

import {
  FundraiserRelationship, useFetchChairpersonDashboardStatistics,
} from 'features/fundraiser';
import { FundraiserStartCaesar } from 'ui/fundraiser';
import { FundraiserNavigation } from 'ui/fundraiser/FundraiserNavigation';
import { DashboardHeader } from 'ui/fundraiser/DashboardHeader';
import ChairpersonSteps from 'ui/fundraiser/ChairpersonSteps/ChairpersonSteps';
import { Graphs } from 'ui/Graphs';
import { useMediaQuery } from 'features/common';
import { useFetchGroup } from 'features/fundraiser/hooks/useFetchGroup';
import { ProfitCheckAddressModal } from 'ui/fundraiser/ProfitCheckAddressModal';
import { ProvideAddressBanner } from 'ui/fundraiser/ProvideAddressBanner';
import { FundraiserProfitCheckAddress, FundraiserStatus } from 'features/fundraiser/types/fundraiser';
import { hasIncompleteProfitCheckAddress } from 'features/fundraiser/utils/HasIncompleteProfitCheckAddress';
import { RedirectAwareLink } from 'ui/common/RedirectAwareLink';
import { useIsUpcomingFundraiser } from 'features/fundraiser/hooks/useIsUpcomingFundraiser';
import { useStatefulFetchFundraiserById } from 'ui/hooks';
interface MatchParams {
  fundraiserId: string;
}

type FundraiserDetailPageProps = RouteComponentProps<MatchParams>

// eslint-disable-next-line max-lines-per-function
const FundraiserDetail: React.FC<FundraiserDetailPageProps> = ({ match }) => {
  const [ t ] = useTranslation();
  const isLarge = useMediaQuery('(min-width: 1160px)');
  const {
    data: fundraiser, isLoading, isError, error,
  } = useStatefulFetchFundraiserById(match.params.fundraiserId);
  const { data: chairpersonStats } = useFetchChairpersonDashboardStatistics(fundraiser?.Id || 0);
  const groupId = fundraiser?.GroupId.toString() || '';
  const { data: groupDetail } = useFetchGroup(groupId);
  const [ isAddressModalOpen, setIsAddressModalOpen ] = React.useState<boolean>(false);
  const [ fundraiserProfitCheckData, setFundraiserProfitCheckData ] = React.useState<FundraiserProfitCheckAddress | null>(null);
  const isFundraiserUpcoming = useIsUpcomingFundraiser(fundraiser);
  const userCanViewPage = fundraiser?.Relationships.includes(FundraiserRelationship.CHAIRPERSON) || fundraiser?.Relationships.includes(FundraiserRelationship.SUPERADMIN) || fundraiser?.Relationships.includes(FundraiserRelationship.SALESAGENT) || fundraiser?.Relationships.includes(FundraiserRelationship.ISC);
  const isFundraiserCancelled = !!fundraiser?.CancellationDate;
  const showChart = !(isFundraiserCancelled || isFundraiserUpcoming);

  useEffect(() => {
    if (fundraiser && groupDetail) {
      setFundraiserProfitCheckData({
        fundraiserId: fundraiser?.Id || 0,
        profitCheckPayableTo: fundraiser?.ProfitCheckPayableTo || '',
        groupAddress: groupDetail?.GroupAddress?.Address1 || '',
        groupCity: groupDetail?.GroupAddress?.City || '',
        groupState: groupDetail?.GroupAddress?.StateProvinceIso || '',
        groupZipCode: groupDetail?.GroupAddress?.ZipPostalCode || '',
      });
    }
  }, [ fundraiser, groupDetail ]);

  const isFundraiserExpiredOrCancelled = isFundraiserCancelled || fundraiser?.IsExpired;
  const isMissingProfitCheckAddress = hasIncompleteProfitCheckAddress(fundraiser, groupDetail);
  const showProvideAddressBanner = isFundraiserExpiredOrCancelled && isMissingProfitCheckAddress;
  const formattedStartDate = fundraiser && format(new Date(fundraiser.StartDate), 'MM/dd/yyyy');
  const startingSoonMessage = `${ t('fundraiser.StartingSoon') } ${ formattedStartDate } ${ t('fundraiser.SeeYouLater') }`;
  const beforeFundraiserStartMessage = fundraiser && fundraiser?.Status !== FundraiserStatus.Pending
    ? startingSoonMessage
    : t('fundraiser.Pending');

  const handleAddressModal = () => {
    setIsAddressModalOpen(!isAddressModalOpen);
  };

  if (!isLoading && !userCanViewPage) {
    return <Redirect to="/dashboard/fundraisers" />;
  }
  return (
    <Box>
      { isError && error?.Status === 404 ? <Redirect to="/404" /> : <div /> }
      { isError && error?.Status === 403 ? <Redirect to="/403" /> : <div /> }
      <FundraiserNavigation fundId={ match.params.fundraiserId } url={ match.url } />

      <RedirectAwareLink pathname="/dashboard/fundraisers">
        <Flex
          sx={ {
            alignItems: 'center', color: 'primaryOrange', fontWeight: 'bold', ml: [ 0, 0, '-30px' ], mb: '12px',
          } }
        >
          <ChevronLeft color="#ff671b" height="32px" width="32px" />
          Back to All Fundraisers
        </Flex>
      </RedirectAwareLink>

      {showProvideAddressBanner && <ProvideAddressBanner onClick={ handleAddressModal } />}

      { fundraiser && <DashboardHeader description={ fundraiser.CustomMessage } fundraiser={ fundraiser } />}

      { (isFundraiserUpcoming && !isFundraiserCancelled) && (
        <FundraiserStartCaesar
          message={ beforeFundraiserStartMessage }
        />
      ) }

      {showChart && (
        <Flex
          sx={ {
            mx: 'auto',
            mb: '48px',
            justifyContent: 'center',
            minWidth: isLarge ? '' : '155px',
            maxWidth: isLarge ? '980px' : '500px',
            flexWrap: isLarge ? 'nowrap' : 'wrap',
            gap: isLarge ? '45px' : '22px',
          } }
        >
          <Graphs graphStats={ chairpersonStats || [] } isDashboard={ true } />
        </Flex>
      )}

      {fundraiser && (
        <ChairpersonSteps
          fundraiser={ fundraiser }
          handleAddressModal={ handleAddressModal }
          isMissingProfitCheckAddress={ isMissingProfitCheckAddress }
        />
      )}

      <ProfitCheckAddressModal
        fundraiserHasExpired={ !!isFundraiserExpiredOrCancelled }
        fundraiserId={ fundraiser?.Id || 0 }
        isMissingProfitCheckAddress={ isMissingProfitCheckAddress }
        isOpen={ isAddressModalOpen }
        onClose={ handleAddressModal }
        profitCheckData={ fundraiserProfitCheckData }
      />

    </Box>
  );
};

export default FundraiserDetail;
