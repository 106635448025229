import { useQuery } from 'react-query';

import { fetchOrderHistory } from '../api';
import { OrderHistoryItem } from '../types/OrderHistoryItem';

import { ApiError } from 'features/common';

export const useOrderHistory = () => useQuery<OrderHistoryItem[], ApiError>('orderhistory', fetchOrderHistory,
  {
    retry: false,
    refetchOnWindowFocus: false,
  });
