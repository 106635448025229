import React from 'react';
import { Box } from '@lce/slice_v2';
import { useTranslation } from '@lce/i18n';

import { Templates } from '../templates';

import { FundraiserType } from 'features/fundraiser';

export type LandingContentProps = {
  fundraiserType: FundraiserType;
  page: string;
  testId?: string;
}

export const dataTestIdLandingContent = 'landing-content-component';

export const LandingContent: React.FC<LandingContentProps> = ({
  fundraiserType,
  page,
  testId = dataTestIdLandingContent,
}) => {
  const [ t ] = useTranslation();
  const translationPath = `landing.${ fundraiserType }.${ page }`;
  const type = t(`${ translationPath }.type`);
  const LandingPage = Templates[type];
  return (
    <Box data-testid={ testId } sx={ { maxWidth: '992px', m: '0 auto 22px' } }>
      <LandingPage fundraiserType={ fundraiserType } page={ page } />
    </Box>
  );
};
