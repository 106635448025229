/* eslint-disable max-len */
/* eslint-disable complexity */
import React, { useState, useEffect } from 'react';
import { useTranslation } from '@lce/i18n';
import {
  Button, Box, Form, Flex, Text, Option,
} from '@lce/slice_v2';
import * as Yup from 'yup';
import { subDays, format as dateformat } from 'date-fns';

import { SimpleSignupSummary } from './SimpleSignupSummary';

import { Step, Mode } from 'ui/common';
import {
  FundraiserInfo, FundraiserType, Group, useFetchParentGroupData,
} from 'features/fundraiser';
import { useFetchStates, useLocalStorage } from 'features/common/hooks';
import { useFetchGroup } from 'features/fundraiser/hooks/useFetchGroup';

export interface ISimpleSignupFormProps {
  info: FundraiserInfo | undefined;
  mode: Mode;
  onEdit: () => void;
  onNext: (info: FundraiserInfo) => void;
}

// eslint-disable-next-line max-lines-per-function
const SimpleSignupForm: React.FC<ISimpleSignupFormProps> = ({
  info, mode, onEdit, onNext,
}) => {
  const [ t ] = useTranslation();
  const [ startDate, setStartDate ] = useState<string>(info?.StartDate ? dateformat(new Date(info.StartDate), 'yyyy-MM-dd') : dateformat(new Date(), 'yyyy-MM-dd'));

  const [ endDate, setEndDate ] = useState<string>(info?.EndDate ? dateformat(new Date(info.EndDate), 'yyyy-MM-dd') : '');
  const selectedGroupId = info?.GroupId.toString() || '0';
  const [ fundraiserInfo, setFundraiserInfo ] = useState<FundraiserInfo | undefined>(info);
  const [ calculatedKitGoal, setCalculatedKitGoal ] = useState<number>(info?.SellerKitGoal || 0);
  const [ existingGroup, setExistingGroup ] = useState<Group>();
  const [ stateIso, setStateIso ] = useState<string|undefined>(info?.GroupState);

  const { data: states } = useFetchStates(1);

  const { data: groupDetail } = useFetchGroup(selectedGroupId);

  const [ parentId ] = useLocalStorage<string>('parentId');
  const {
    groupTypeId, groupTypeName, numberOfSellers, parentGroupId, salesAgentCode,
  } = useFetchParentGroupData(parentId);

  useEffect(() => {
    if (selectedGroupId === '0') {
      setExistingGroup(undefined);
    } else if (groupDetail) {
      setExistingGroup(groupDetail);
    }

    setStateIso(groupDetail?.GroupAddress?.StateProvinceIso);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ groupDetail, selectedGroupId ]);

  useEffect(() => {
    const date = new Date(startDate);

    if (date.getFullYear() >= 2020 && date.getFullYear() < 2100) {
      setStartDate(dateformat(new Date(`${ startDate }T00:00:00`), 'yyyy-MM-dd'));
      date.setDate(date.getDate() + 30);
      setEndDate(date.toISOString().slice(0, 10));
    }
  }, [ startDate ]);

  let validationSchema = Yup.object();
  const today = new Date();
  const threeYearsFromToday = new Date();
  threeYearsFromToday.setFullYear(today.getFullYear() + 3);
  validationSchema = validationSchema.shape({
    GroupId: Yup.number(),
    OrgName: Yup.string()
      .required('Name is required')
      .matches(/^[a-zA-Z0-9\s']+$/, 'Your name can only contain letters, numbers, and apostrophe’s')
      .max(255, 'Name is too long'),
    GroupAddress1: Yup.string().required('Address is required'),
    GroupCity: Yup.string().required('City is required'),
    GroupState: Yup.string().required('State is required'),
    GroupZip: Yup.string().required('Zip is required'),
    StartDate: Yup.date()
      .required('Start Date is required')
      .min(subDays(new Date(), 1), 'Select today or later')
      .max(threeYearsFromToday, 'Start date cannot be more than 3 years from today')
      .typeError('Start Date is required'),
    DollarGoal: Yup.number()
      .required('Dollar Goal is required')
      .nullable()
      .min(0.01, 'Dollar Goal must be greater than 0')
      .max(99999999999999.9999, 'Number too large')
      .typeError('Dollar Goal must be a number'),
    VerifyAccurateInfo: Yup.boolean()
      .required('Acknowledgement is required')
      .oneOf([ true ], 'Acknowledgement is required'),
  });

  const handleStartDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const startDate = new Date(e.target.value);

    if (startDate.getFullYear() >= 2020 && startDate.getFullYear() < 2100) {
      setStartDate(dateformat(new Date(`${ e.target.value }T00:00:00`), 'yyyy-MM-dd'));
      startDate.setDate(startDate.getDate() + 30);
      setEndDate(startDate.toISOString().slice(0, 10));
    }
  };

  const calculateKitGoal = (sellers, dollarGoal) => {
    if (!sellers || !dollarGoal) {
      setCalculatedKitGoal(0);
      return;
    }

    const kits = dollarGoal / 6 / sellers;
    setCalculatedKitGoal(Math.ceil(kits));
  };

  const onDollarGoalChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.value) {
      return;
    }
    const goal = parseInt(e.target.value);

    if (goal === 0 || numberOfSellers === 0) {
      setCalculatedKitGoal(0);
    }

    calculateKitGoal(numberOfSellers, goal);
  };

  const handleOnSubmit = (e) => {
    const info: FundraiserInfo = {
      ...e,
      DollarGoal: e.DollarGoal,
      EstimatedSellers: numberOfSellers,
      GroupAddressId: existingGroup?.GroupAddress?.Id || 0,
      GroupId: existingGroup?.Id || 0,
      GroupTypeId: groupTypeId,
      GroupTypeName: groupTypeName,
      ParentId: parentGroupId,
      SellerKitGoal: calculatedKitGoal,
      StartDate: startDate,
      EndDate: endDate,
      SalesAgentCode: salesAgentCode,
      FundraiserType: FundraiserType.SimpleSignUp,
    };
    setFundraiserInfo(info);
    onNext(info);
  };

  return (
    <>
      { mode === 'active' && (
        <Step onEdit={ onEdit } title={ t('fundraiser.YourFundraiser') }>
          <Box data-testid="simple-signup-form" sx={ { marginTop: -20 } } variant="cards.step.body">
            <Form
              initialValues={ info }
              onSubmit={ handleOnSubmit }
              summary={ false }
              validationSchema={ validationSchema }
            >
              <Flex sx={ { flexDirection: 'column' } }>
                <Form.Field
                  autoComplete="organization"
                  defaultValue={ existingGroup?.Name }
                  hint="Name displayed to buyers and to which profit checks will be paid"
                  id="OrgName"
                  label="Your Name *"
                  name="OrgName"
                  variant="forms.fundraiser.field"
                />
                <Flex sx={ { flexDirection: [ 'column', 'row' ] } }>
                  <Form.Field
                    id="StartDate"
                    label="Start Date *"
                    name="StartDate"
                    onChange={ handleStartDateChange }
                    sx={ { marginRight: [ 0, '12px' ] } }
                    type="date"
                    value={ startDate }
                    variant="forms.fundraiser.field"
                  />
                  <Form.Field
                    disabled={ true }
                    id="EndDate"
                    label="End Date"
                    name="EndDate"
                    sx={ { marginLeft: [ 0, '12px' ], color: 'primaryGrayDark' } }
                    type="date"
                    value={ endDate }
                    variant="forms.fundraiser.field"
                  />
                </Flex>

                <Flex sx={ { flexDirection: [ 'column', 'row' ] } }>
                  <Form.Field
                    id="DollarGoal"
                    label="Fundraiser Profit Goal *"
                    max="1000000"
                    min="0"
                    name="DollarGoal"
                    onChange={ e => onDollarGoalChange(e) }
                    sx={ { marginRight: [ 0, '12px' ], input: { width: [ '100%', '200px' ] } } }
                    variant="forms.fundraiser.field"
                  />
                </Flex>

                <Text variant="text.header.form">
                  {t('fundraiser.SimpleGroupMailingAddress')}
                </Text>
                <Text sx={ { fontSize: 'smaller' } }>
                  {t('fundraiser.SimpleMailingAddressHint')}
                </Text>
                <Flex sx={ { flexDirection: [ 'column', 'row' ] } }>
                  <Form.Field
                    autoComplete="address-line1"
                    defaultValue={ existingGroup?.GroupAddress?.Address1 }
                    id="GroupAddress1"
                    label="Street Address *"
                    name="GroupAddress1"
                    sx={ { marginRight: [ 0, '12px' ] } }
                    variant="forms.fundraiser.field"
                  />
                  <Form.Field
                    autoComplete="address-line2"
                    defaultValue={ existingGroup?.GroupAddress?.Address2 }
                    id="GroupAddress2"
                    label="Apt/Suite/Other"
                    name="GroupAddress2"
                    sx={ { marginLeft: [ 0, '12px' ] } }
                    variant="forms.fundraiser.field"
                  />
                </Flex>
                <Flex sx={ { flexDirection: [ 'column', 'row' ] } }>
                  <Form.Field
                    autoComplete="address-level2"
                    defaultValue={ existingGroup?.GroupAddress?.City }
                    id="GroupCity"
                    label="City *"
                    name="GroupCity"
                    sx={ { marginRight: [ 0, '12px' ] } }
                    variant="forms.fundraiser.field"
                  />
                  <Form.Field
                    autoComplete="address-level1"
                    component={ Form.Select }
                    defaultValue={ stateIso || '' }
                    id="GroupState"
                    label="State *"
                    name="GroupState"
                    sx={ { marginLeft: [ 0, '12px' ] } }
                    variant="forms.fundraiser.field"
                  >
                    <Option value="">---</Option>
                    {states && states.map(state => (
                      <Option
                        key={ state.Id }
                        label={ state.Abbreviation }
                        value={ state.Abbreviation }
                      />
                    ))}
                  </Form.Field>
                  <Form.Field
                    autoComplete="postal-code"
                    defaultValue={ existingGroup?.GroupAddress?.ZipPostalCode }
                    id="GroupZip"
                    label="Zip/Postal Code *"
                    name="GroupZip"
                    sx={ { marginLeft: [ 0, '12px' ] } }
                    variant="forms.fundraiser.field"
                  />
                </Flex>
                <Flex sx={ { flexDirection: [ 'column', 'row' ] } }>
                  <Form.Field
                    component={ Form.Input.Checkbox }
                    defaultChecked={ false }
                    id="VerifyAccurateInfo"
                    inline={ true }
                    label={ t('fundraiser.VerifyAccurateInfo') }
                    name="VerifyAccurateInfo"
                    sx={ { mt: '16px', svg: { color: 'black' } } }
                  />
                </Flex>

                <Button id="fundraisingDetailsNext" sx={ { mt: '33px' } } type="submit">
                  {t('fundraiser.SimpleFundraisingDetailsNext')}
                </Button>

              </Flex>
            </Form>
          </Box>
        </Step>
      )}

      { mode === 'preview' && (
        <Step onEdit={ onEdit } showEdit={ true } title={ t('fundraiser.YourFundraiser') }>
          <Box variant="cards.step.body">
            <SimpleSignupSummary details={ fundraiserInfo } />
          </Box>
        </Step>
      )}
    </>
  );
};

export default SimpleSignupForm;
