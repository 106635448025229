import { createContext, useContext } from 'react';

import { CheckoutResponse } from '../../types/response';

import { Address } from 'features/common';
import { CheckoutState, PaymentStatus, OrderStatus } from 'ui/checkout';

type Action =
  { type: 'SET_CHECKOUT'; checkout: CheckoutResponse } |
  { type: 'UNSET_CHECKOUT' } |
  { type: 'SET_SHIPPING_ADDRESS'; address: Address } |
  { type: 'SET_BILLING_ADDRESS'; address: Address } |
  { type: 'SET_LOADING' } |
  { type: 'UNSET_LOADING' } |
  { type: 'SET_ERROR_CODE'; errorCode: string } |
  { type: 'UNSET_ERROR_CODE'; errorCode: string } |
  { type: 'SET_ORDER_STATUS'; status: OrderStatus; orderId: number; orderGuid: string } |
  { type: 'SET_PAYMENT_STATUS'; status: PaymentStatus; paymentId?: string } |
  { type: 'SET_GIFT_MESSAGING'; giftMessageRecipient: string; giftMessage: string };

type Dispatch = (action: Action) => void;

export const CheckoutStateContext = createContext<CheckoutState | undefined>(undefined);
export const CheckoutDispatchContext = createContext<Dispatch | undefined>(undefined);

export const useCheckoutState = () => {
  const context = useContext(CheckoutStateContext);
  if (context === undefined) {
    throw new Error('useCheckoutState must be used within CheckoutStateContext');
  }
  return context;
};

export const useCheckoutDispatch = () => {
  const context = useContext(CheckoutDispatchContext);
  if (context === undefined) {
    throw new Error('useCheckoutDispatch must be used within CheckoutDispatchContext');
  }
  return context;
};
