import React from 'react';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Box } from '@lce/slice_v2';
import { useTranslation } from '@lce/i18n';
import _toLower from 'lodash/toLower';

import enUs from '../../i18n/en-us.json';

import { useFundraiserState } from 'features/fundraiser/context/fundraiserContext';
import { FundraiserType } from 'features/fundraiser';
import { LandingContent, LandingHeader } from 'ui/components';

export interface MatchParams {
  page: string;
}

const Landing: React.FC<RouteComponentProps<MatchParams>> = ({ match: { params: { page } } }) => {
  const [ t ] = useTranslation();
  const { fundraiserType } = useFundraiserState();
  const currentPage = _toLower(page);
  const isValid = fundraiserType === FundraiserType.MealDeal
    ? enUs.translation.landing.MD[currentPage]
    : enUs.translation.landing.DG[currentPage];

  return isValid ? (
    <Box>
      <Helmet>
        <title>
          {t(`landing.${ fundraiserType }.${ currentPage }.title`) + ' | Little Caesars Fundraising'}
        </title>
      </Helmet>
      <LandingHeader fundraiserType={ fundraiserType } page={ currentPage } />
      <LandingContent fundraiserType={ fundraiserType } page={ currentPage } />
    </Box>
  ) : <Redirect to="/404" />;
};

export default Landing;
