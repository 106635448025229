import React from 'react';
import { Redirect } from 'react-router-dom';

import { Login } from '../login';

import { useJoinFundraiser } from 'features/fundraiser';

/* This component is responsbile for determining which login page to show
   This simply returns a specific component based on the scenario
   - Default Login Page
   - Fundraiser Seller Login/Register Page
   In retrospect, I don't love this solution so we should rework this if possible
*/
const FundraiserLoginManager = () => {
  const { joinFundraiserGuid } = useJoinFundraiser();

  if (joinFundraiserGuid) {
    return <Redirect to={ `/login/join-fundraiser/${ joinFundraiserGuid }` } />;
  }

  return (<Login />);
};

export default FundraiserLoginManager;
