import React from 'react';
import {
  Modal as SliceModal, IModalProps as ISliceModalProps, Text, Button, Flex, Image, IconButton,
} from '@lce/slice_v2';
import { X } from 'react-feather';

import CaesarKite from 'assets/images/caesar-kite.svg';

export interface IModalProps {
  heading: string;
  message: string | React.ReactNode;
  ctaText: string;
  onClose?: () => void;
}

const Modal: React.FC<IModalProps & ISliceModalProps> = props => (
  <SliceModal
    { ...props }
    closeIcon={ false }
    onClose={ props.onClose }
    sx={ {
      maxWidth: '700px',
      borderRadius: '4px',
      marginTop: [ '120px', '120px', '120px' ],
      width: [ '90%', '100%', '100%' ],
    } }
  >
    <Flex
      sx={ {
        justifyContent: 'flex-end',
        paddingLeft: '20px',
        paddingRight: '20px',
        width: '100%',
      } }
    >
      <IconButton onClick={ props.onClose } sx={ { cursor: 'pointer' } }>
        <X size={ 80 } />
      </IconButton>
    </Flex>
    <Flex
      sx={ {
        flexDirection: 'column',
        padding: [ '15px', '50px 120px' ],
        textAlign: 'center',
      } }
    >
      <Text sx={ { mb: '16px' } } variant="text.header.subUpper">
        {props.heading}
      </Text>
      <Text sx={ { mb: '16px' } }>
        {props.message}
      </Text>
      <Button onClick={ props.onClose } sx={ { width: '100%' } }>
        {props.ctaText}
      </Button>
      <Image alt="Caesar Kite" src={ CaesarKite } title="Caesar Kite" />
    </Flex>
  </SliceModal>
);

export default Modal;
