/* eslint-disable max-len */
import { useQuery } from 'react-query';

import { fetchSellerDashboardStatistics } from '../api';
import { Stat } from '../types/stats';

import * as QueryKeys from 'constants/QueryKeys';
import { ApiError } from 'features/common';

const useFetchSellerDashboardStatistics =
  (sellerId: number) => useQuery<Stat[], ApiError>([ `${ QueryKeys.SellerDashboardStatistics } }` ],
    () => fetchSellerDashboardStatistics(sellerId),
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: !!sellerId,
    });

export { useFetchSellerDashboardStatistics };
