import React, { useEffect, useState } from 'react';
import {
  Error, Box, Form, Option, Text, Flex, IconButton,
} from '@lce/slice_v2';
import { Search, XCircle } from 'react-feather';

import DateInput from 'ui/patterns/DateInput';
import { FundraiserSearchOptions } from 'features/fundraiser/types';

export interface IListOptions {
  onSortChange: (e: number) => void;
  onSearchChange: (e: string) => void;
  onDateChange: (selectedStartDate?: string, selectedEndDate?: string) => void;
  keyword: string;
  selectedOptions: FundraiserSearchOptions;
}

// eslint-disable-next-line max-lines-per-function
const FundraiserListOptions: React.FC<IListOptions> = ({
  onSortChange,
  onSearchChange,
  onDateChange,
  keyword,
  selectedOptions,
}) => {
  const [ dateError, setDateError ] = useState('');
  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>, isStartDate = false) => {
    const date = e.target.value;

    isStartDate ? onDateChange(date, undefined) : onDateChange(undefined, date);
  };

  useEffect(() => {
    const start = selectedOptions.selectedStartDate ? new Date(selectedOptions.selectedStartDate) : undefined;
    const end = selectedOptions.selectedEndDate ? new Date(selectedOptions.selectedEndDate) : undefined;
    if (start && end && start > end) {
      setDateError('Start date cannot be after end date');
    }
  }, [ selectedOptions.selectedStartDate, selectedOptions.selectedEndDate ]);

  return (
    <Flex
      sx={ {
        flexDirection: [ 'column', 'row' ],
        mb: '24px',
        mt: '40px',
        alignItems: 'flex-end',
      } }
    >
      <Box sx={ { position: 'relative', flex: 1, width: '100%' } }>
        <Form.Input.Text
          id="fundraiser-search"
          name="fundraiser-search"
          onChange={ e => onSearchChange(e.target.value) }
          placeholder="Search"
          value={ keyword }
          variant="search"
        />
        <Box sx={ { position: 'absolute', left: '14px', top: '8px' } }>
          <Search color="#868686" size="20px" />
        </Box>
        { keyword.length > 0 && (
          <Box sx={ { position: 'absolute', right: '14px', top: '3px' } }>
            <IconButton onClick={ () => onSearchChange('') }>
              <XCircle color="#868686" size={ 20 } />
            </IconButton>
          </Box>
        )}
      </Box>
      { selectedOptions.canSort
        ? (
          <>
            <Box
              sx={ {
                position: 'relative', width: '100%', maxWidth: [ '100%', '132px' ],
                ml: [ 0, '20px' ], mt: [ '15px', 0 ],
              } }
            >
              <Text>Sort by</Text>
              <Form.Select
                id="fundraiser-filter"
                name="fundraiser-filter"
                onChange={ e => onSortChange(Number(e.target.value)) }
                sx={ {
                  fontFamily: 'Lato',
                  fontWeight: '700',
                  fontSize: '16px',
                } }
              >
                <Option value="0">Name: A-Z</Option>
                <Option value="1">End Date</Option>
                <Option value="2">Start Date</Option>
              </Form.Select>
            </Box>
            <Flex
              sx={ {
                width: [ '100%', 'inherit' ], minWidth: '300px', flexDirection: 'column', mt: [ '8px', 0 ],
                ml: [ 0, '20px' ],
              } }
            >
              <Flex
                sx={ {
                  width: [ '100%', 'inherit' ], flexDirection: 'row',
                } }
              >
                <DateInput
                  inputStyle={ {
                    fontFamily: 'Lato',
                    fontWeight: 700,
                    fontSize: '16px',
                    height: '36px',
                    margin: '0',
                    maxWidth: '164px',
                  } }
                  name="Start Date"
                  onChange={ (e: React.ChangeEvent<HTMLInputElement>) => handleDateChange(e, true) }
                />
                <DateInput
                  inputStyle={ {
                    fontFamily: 'Lato',
                    fontWeight: 700,
                    fontSize: '16px',
                    height: '36px',
                    margin: '0',
                    maxWidth: '164px',
                  } }
                  name="End Date"
                  onChange={ (e: React.ChangeEvent<HTMLInputElement>) => handleDateChange(e) }
                  sx={ { ml: '20px' } }
                />
              </Flex>
              {dateError && (
                <Error sx={ { mb: '-24px' } }>
                  { dateError }
                </Error>
              )}
            </Flex>
          </>
        )
        : null }
    </Flex>
  );
};

export default FundraiserListOptions;
