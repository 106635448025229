import React from 'react';
import {
  Box, Flex, Grid, Heading, Image, Spinner, Text,
} from '@lce/slice_v2';
import { match } from 'react-router-dom';
import { useTranslation } from '@lce/i18n';
import { format } from 'date-fns';

import anouncerCaesarMan from './assets/Announcer-Caesar-Man.png';

import { useFetchFundraiserFormData } from 'features/fundraiser/hooks/useFetchFundraiser';
import { useStatefulFetchFundraiserById } from 'ui/hooks';
import { FinalOrderStatus, FundraiserListItem, Fundraiser } from 'features/fundraiser/types/fundraiser';
import { KeyValuePairs } from 'ui/components';
import { FundraiserNavigation } from 'ui/fundraiser/FundraiserNavigation';

interface MatchParams {
  fundraiserId: string;
}

export type FinalOrderPageProps = {
  match: match<MatchParams>;
  testId?: string;
}

export const dataTestIdFinalOrderPage = 'final-order-page-component';

// eslint-disable-next-line max-lines-per-function
export const FinalOrderPage: React.FC<FinalOrderPageProps> = ({
  match,
  testId = dataTestIdFinalOrderPage,
}) => {
  const {
    data: fundraiser,
    isLoading: isFundraiserDataLoading,
    // isError: fundraiserFetchError,
  } = useStatefulFetchFundraiserById(match?.params?.fundraiserId);

  const {
    data: fundraiserFormData,
    isLoading: isFormDataLoading,
    // isError: formDataError,
  } = useFetchFundraiserFormData(parseInt(match?.params?.fundraiserId));

  if (isFundraiserDataLoading || isFormDataLoading) {
    return (
      <Flex
        data-testid={ testId }
        sx={ {
          alignItems: 'center', height: '100vh', justifyContent: 'center', width: '100%',
        } }
      >
        <Spinner
          variant="lce"
        />
      </Flex>
    );
  }

  return (
    <>
      <FundraiserNavigation fundId={ match.params.fundraiserId } url={ match.url } />
      <FinalOrderDetails
        fundraiser={ fundraiser }
        fundraiserFormData={ fundraiserFormData }
        testId={ testId }
      />
    </>
  );
};

type FinalOrderDetailsProps ={
  fundraiser: FundraiserListItem | undefined;
  fundraiserFormData: Fundraiser | undefined;
  testId?: string;
}

// eslint-disable-next-line max-lines-per-function
const FinalOrderDetails: React.FC<FinalOrderDetailsProps> = ({ fundraiser, fundraiserFormData, testId }) => {
  const [ t ] = useTranslation();

  if (fundraiser?.FinalOrderDetails?.Status === FinalOrderStatus.FinalOrderUpcoming) {
    return (
      <Flex data-testid={ testId } sx={ { flexDirection: 'column', alignItems: 'center', gap: '12px' } }>
        <Image alt="Caesar Man Announcer" src={ anouncerCaesarMan } sx={ { width: [ '157px', '217px' ] } } />
        <Heading as="h1">
          {t('dashboard.finalOrderPage.pending.header')}
        </Heading>
        <Text sx={ { color: 'black' } }>
          {t('dashboard.finalOrderPage.pending.instructions')}
        </Text>
      </Flex>
    );
  }

  if (fundraiser?.FinalOrderDetails?.Status === FinalOrderStatus.FinalOrderDue) {
    return (
      <Flex data-testid={ testId } sx={ { gap: '12px', flexDirection: 'column' } }>
        <Heading as="h1">
          {t('dashboard.finalOrderPage.active.header')}
        </Heading>
        <Grid
          sx={ {
            gridTemplateColumns: [ '1FR', 'auto auto' ],
            gridGap: [ 0, '12px' ],
          } }
        >
          <KeyValuePairs
            list={ [
              {
                term: t('dashboard.finalOrderPage.active.details.nameOfGroup'),
                description: fundraiser.Name,
              },
              {
                term: t('dashboard.finalOrderPage.active.details.finalOrderDate'),
                description: fundraiser?.FinalOrderDetails?.FinalOrderDate
                  ? format(new Date(fundraiser?.FinalOrderDetails?.FinalOrderDate), 'yyyy-MM-dd')
                  : 'unknown',
              },
              {
                term: t('dashboard.finalOrderPage.active.details.chairperson'),
                description:
                  `${ fundraiserFormData?.Chairperson?.FirstName } ${ fundraiserFormData?.Chairperson?.LastName }`,
              },
            ] }
          />
          <KeyValuePairs
            list={ [
              {
                term: t('dashboard.finalOrderPage.active.details.fundraiserId'),
                description: fundraiser.Id.toString(),
              },
              {
                term: t('dashboard.finalOrderPage.active.details.groupId'),
                description: fundraiser.GroupId.toString(),
              },
              {
                term: t('dashboard.finalOrderPage.active.details.salesAgent'),
                description: fundraiser.SalesAgentName,
              },
            ] }
          />
        </Grid>
        <Text sx={ { color: 'primaryGrayDark' } }>
          {t('dashboard.finalOrderPage.active.instructions')}
        </Text>
      </Flex>
    );
  }

  return <Box data-testid={ testId } />;
};
