import React from 'react';
import {
  Text, Box, IBoxProps, ITextProps,
} from '@lce/slice_v2';
import { ThemeUIStyleObject } from 'theme-ui';

export interface IDateInput extends IBoxProps {
  canEditDates?: boolean;
  date?: string;
  name?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  inputStyle?: React.CSSProperties;
  textProps?: ITextProps;
  sx?: ThemeUIStyleObject;
}

const DateInput: React.FC<IDateInput> = ({
  date,
  onChange,
  name,
  inputStyle,
  textProps,
  ...props
}) => (
  <Box { ...props }>
    {name && (
      <Text { ...textProps }>
        { name }
      </Text>
    )}
    <input
      className="custom-date-field"
      id={ `${ name?.replace(' ', '-') }-date` }
      name={ `${ name?.replace(' ', '-') }-date` }
      onChange={ onChange }
      style={ inputStyle }
      type="date"
      value={ date }
    />
  </Box>
);
export default DateInput;
