import * as Yup from 'yup';
import { useTranslation } from '@lce/i18n';

import { firstNameInputSchema, phoneInputSchema } from 'ui/components/forms';

export const useShippingSchema = (hasOnlyCouponItems: boolean | undefined = false) => {
  const { t } = useTranslation();

  const baseSchema = {
    ...firstNameInputSchema(t, 'FirstName', true),
    LastName: Yup.string().required(t('fundraiser.LastNameRequired')),
    IsCouponItem: Yup.boolean(),
    ...phoneInputSchema(t),
    Email: Yup.string()
      .lowercase()
      .email(t('fundraiser.EmailValid'))
      .required(t('fundraiser.EmailRequired')),
    ConfirmEmailAddress: Yup.string().lowercase().oneOf([ Yup.ref('Email') ],
      t('fundraiser.EmailMatch'))
      .required(t('fundraiser.EmailRequired')),
  };

  const addressSchema = {
    Address1: Yup.string()
      .required(t('checkout.AddressRequired'))
      .max(25, t('checkout.AddressMaxLength')),
    City: Yup.string()
      .required(t('checkout.CityRequired')),
    StateProvinceIso: Yup.string()
      .required(t('checkout.StateRequired')),
    poBox: Yup.boolean()
      .oneOf([ true ], t('checkout.PoBoxRequired')),
    ZipPostalCode: Yup.string()
      .required(t('fundraiser.ZipPostalCodeRequired'))
      .matches(/^[0-9]{5}(?:-[0-9]{4})?$/, t('fundraiser.ZipPostalCodeInvalid')),
  };

  return Yup.object().shape({
    ...baseSchema,
    ...hasOnlyCouponItems ? {} : addressSchema,
  });
};
