import { scale } from '../utils';

const { medium, large } = scale;

export const base = {
  fontWeight: 800,
  fontFamily: 'Lato',
  lineHeight: '1',
  borderRadius: '4px',
  border: '1px solid',
  cursor: 'pointer',
  minWidth: 120,
  fontSize: '14px',
  height: '36px',
};

export const primary = {
  bg: 'primaryOrange',
  borderColor: 'primaryOrange',
};

export const secondary = {
  color: 'primaryOrange',
  borderColor: 'primaryOrange',
  bg: 'white',
};

export default {
  base,
  primary: {
    ...base,
    ...primary,
    medium: {
      ...base,
      ...primary,
      ...medium,
    },
    large: {
      ...base,
      ...primary,
      ...large,
      height: '40px',
    },
  },
  secondary: {
    ...base,
    ...secondary,
    medium: {
      ...base,
      ...secondary,
      ...medium,
    },
    large: {
      ...base,
      ...secondary,
      ...large,
      height: '40px',
    },
  },
  skeleton: {
    padding: '24px',
    width: '100%',
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    ':hover': {
      backgroundColor: '#eee',
    },
  },
  outline: {
    ...base,
    bg: 'transparent',
    border: 'solid 2px white',
    fontWeight: 900,
  },
  disabled: {
    ...base,
    bg: 'primaryGrayLight',
    color: 'white',
    borderColor: 'primaryGrayLight',
    cursor: 'not-allowed',
    medium: {
      ...base,
      bg: 'primaryGrayLight',
      color: 'white',
      borderColor: 'primaryGrayLight',
    },
  },
  text: {
    ...base,
    border: 'none',
    background: 'none',
    color: 'primaryOrange',
    fontWeight: 600,
    fontSize: 'large',
    paddingTop: 0,
    paddingBottom: 0,
  },
  popover: {
    variant: 'buttons.text',
    fontSize: '10px',
    fontWeight: 400,
    ':hover': {
      background: 'rgba(192,192,192,0.3)',
    },
  },
  navMenu: {
    background: '#fff',
    border: 'none',
    padding: '0',
    margin: '0',
    cursor: 'pointer',
    appearance: 'none',
    color: 'black',
    marginLeft: '16px',
  },
  tabbedCard: {
    width: '300px',
    bg: 'white',
    color: 'primaryGrayDark',
    padding: '15px 20px',
    cursor: 'pointer',
    active: {
      cursor: 'pointer',
      padding: '15px 20px',
      width: '300px',
      borderBottom: '5px solid',
      borderColor: 'primaryOrange',
      bg: 'white',
      color: 'black',
      fontWeight: 600,
    },
  },
  clearTopRight: {
    position: 'absolute',
    cursor: 'pointer',
    top: '15px',
    right: '15px',
    background: 'none',
    border: 'none',
    padding: 0,
    color: 'black',
    lineHeight: 0,
  },
  confirmationButtonDG: {
    fontWeight: 900,
    fontFamily: 'Lato',
    fontSize: '14px',
    lineHeight: '1',
    borderRadius: '4px',
    cursor: 'pointer',
    bg: 'primaryOrange',
    display: 'inline-block',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 0.6,
    ml: '15px',
    mt: '4px',
    p: '0px',
    minWidth: '120px',
    width: 'auto',
    height: '36px',
  },
  confirmationButtonMD: {
    fontWeight: 900,
    fontFamily: 'Lato',
    fontSize: '14px',
    lineHeight: '1',
    borderRadius: '4px',
    cursor: 'pointer',
    bg: 'primaryOrange',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    p: '5px',
    width: '40px',
    height: '40px',
  },
};
