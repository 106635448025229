import * as React from 'react';
import type { TFunction } from 'i18next';
import { Box, Text } from '@lce/slice_v2';
import { addDays, format, parse } from 'date-fns';

import { DeliveryTime, FundraiserBrochure } from 'features/fundraiser/types/fundraiser';

export interface IDeliveryDetailsSummary {
  details: FundraiserBrochure | undefined;
  deliveryTimes: DeliveryTime[];
  t: TFunction;
}

const DeliveryDetailsSummary: React.FC<IDeliveryDetailsSummary> = ({ details, deliveryTimes, t }) => {
  const deliveryTime = deliveryTimes.find(time => time.value === details?.preferredDeliveryTime);
  const weekDate = details?.preferredDeliveryWeek || '';
  const parsedDate = parse(weekDate, 'yyyy-MM-dd', new Date());
  const startWeek = format(parsedDate, 'MMMM do, yyyy');
  const endWeek = format(addDays(parsedDate, 4), 'MMMM do, yyyy');
  const deliveryState = details?.sameAsMailing ? details?.organizationState : details?.deliveryState;
  return (
    <Box data-testid="delivery-details-summary" sx={ { mb: '16px' } }>
      <Text variant="text.header.form">
        {t('fundraiser.labels.DeliveryAddress')}
      </Text>
      <Text>
        {details?.deliveryName}
      </Text>
      <Text>
        {details?.deliveryAddress}
      </Text>
      <Text>
        {`${ details?.deliveryCity }, ${ deliveryState } ${ details?.deliveryZipCode }`}
      </Text>
      <Text>
        {t('fundraiser.summary.CrossStreets', { crossStreets: details?.deliveryMainCrossStreets })}
      </Text>
      <Text>
        {t('fundraiser.summary.PhoneNumber', { phoneNumber: details?.deliveryPhoneNumber })}
      </Text>
      <Text>
        {
          t('fundraiser.summary.DeliveryRestrictions', {
            deliveryRestrictions: details?.deliveryRestrictions ? 'Yes' : 'No',
          })
        }
      </Text>
      <Text sx={ { mt: '16px' } } variant="text.header.form">
        {t('fundraiser.labels.DeliveryScheduling')}
      </Text>
      <Text>
        {t('fundraiser.summary.DeliveryWeek', { startWeek, endWeek })}
      </Text>
      <Text>
        {t('fundraiser.summary.DeliveryTime', { deliveryTime: deliveryTime?.label })}
      </Text>
    </Box>
  );
};

export default DeliveryDetailsSummary;
