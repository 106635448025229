import * as React from 'react';
import { Flex, Text } from '@lce/slice_v2';

import { UserInfo } from 'features/common/types/userInfo';

type IReadOnlyForm = {
  user: UserInfo;
}

const ReadOnlyForm: React.FC<IReadOnlyForm> = ({ user }) => (
  <Flex sx={ { flexDirection: 'column', flex: '1', padding: '30px' } }>
    <Text>
      {user.FirstName}
      {' '}
      {user.LastName}
    </Text>
    <Text>
      Phone:
      {' '}
      {user.Phone}
    </Text>
    <Text>
      {user.Email}
    </Text>
  </Flex>
);

export default ReadOnlyForm;
