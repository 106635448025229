/* eslint-disable max-len */
import { useQuery } from 'react-query';

import { fetchChairpersonDashboardStatistics } from '../api';
import { Stat } from '../types/stats';

import * as QueryKeys from 'constants/QueryKeys';
import { ApiError } from 'features/common';

const useFetchChairpersonDashboardStatistics =
  (fundraiserId: number) => useQuery<Stat[], ApiError>([ `${ QueryKeys.ChairpersonDashboardStatistics } }` ],
    () => fetchChairpersonDashboardStatistics(fundraiserId),
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: !!fundraiserId,
    });

export { useFetchChairpersonDashboardStatistics };
