import React from 'react';
import {
  Error, Flex, Button, Card, Text,
} from '@lce/slice_v2';
import numeral from 'numeral';
import { useTranslation } from '@lce/i18n';
import { useHistory } from 'react-router-dom';
import _map from 'lodash/map';

import { OrderUpsellText } from './OrderUpsellText';
import { OrderSummarySkeleton } from './OrderSummarySkeleton';

import { CartItem } from 'features/cart';
import { FundraiserType } from 'features/fundraiser';
import { useFetchProductsPageRoute } from 'features/products';
import { useShipping, useCheckoutState } from 'features/checkout';
import { GoogleEvents } from 'features/analytics';
import { areAllFreeShippingItemsDonationItems } from 'features/fundraiser/utils';

export type SummaryType = | 'cart'| 'checkout';

export interface IOrderSummaryProps {
  cartItems: CartItem[];
  subtotal: number;
  shouldDisplaySubtotal?: boolean;
  shouldDisplayShipping?: boolean;
  type: SummaryType;
  fundraiserType: FundraiserType;
  ineligibleItems?: boolean;
  supportGuid?: string;
}

// eslint-disable-next-line max-lines-per-function
const OrderSummary: React.FC<IOrderSummaryProps> = ({
  cartItems, subtotal, type, fundraiserType, ineligibleItems,
  supportGuid, shouldDisplaySubtotal = true, shouldDisplayShipping = true,
}) => {
  const history = useHistory();

  const [ t ] = useTranslation();
  const { isFetching, data: shippingCost, error } = useShipping(cartItems);
  const productPageRoute = useFetchProductsPageRoute();
  const isMealDeal = fundraiserType === FundraiserType.MealDeal;
  const checkoutRoute = t(`routes.checkoutPage.${ fundraiserType }`, { supportGuid: supportGuid });
  const hasOnlyDonationItems = areAllFreeShippingItemsDonationItems(cartItems);

  const categoryRedirect = () => history.push(productPageRoute);
  const checkoutRedirect = () => {
    GoogleEvents.beginCheckout({
      cartTotal: subtotal,
      products: _map(cartItems, item => ({
        name: item.ProductName,
        price: item.UnitPrice,
        id: item.ProductId.toString(),
        quantity: item.Quantity,
      })),
    });
    history.push(checkoutRoute);
  };

  const shippableUnits = (): number => {
    let kits = 0;
    cartItems.forEach(item => !item.IsFreeShipping && (kits += item.Quantity));
    return kits;
  };

  const ShippingCostText: React.FC = () => {
    const showShippingCost = shippableUnits() > 0;
    const shippingCostResponse = !isFetching && shippingCost !== undefined;
    const formattedShippingCost = numeral(shippingCost).format(t('currency.format'));
    const shippingWaitingMessage = error?.Status === 500 ? '' : 'Calculating';

    return (
      <Text sx={ { fontSize: '20px', fontWeight: 800 } }>
        {showShippingCost
          ? shippingCostResponse
            ? formattedShippingCost
            : shippingWaitingMessage
          : t('cart.NoShippingCost')}
      </Text>
    );
  };

  return (
    <Flex
      data-testid="order-summary"
      sx={ {
        flexDirection: 'column', position: 'sticky', top: '101px', minWidth: 'auto',
      } }
    >
      <Flex as={ Card } variant="cards.orderSummary">
        <Text variant="text.header.subUpper">
          {t('cart.OrderSummaryTitle')}
        </Text>

        { cartItems.map(item => (
          <Flex
            key={ item.ProductId }
            sx={ { justifyContent: 'space-between', margin: '8px 0' } }
          >
            <Text>
              (
              {item.Quantity}
              )
              {' '}
              {item.ProductName}
            </Text>
            <Text>
              {numeral(item.UnitPrice * item.Quantity).format(t('currency.format'))}
            </Text>
          </Flex>
        ))}

        {shouldDisplaySubtotal && (
          <Flex data-testid="order-summary-subtotal" sx={ { justifyContent: 'space-between', mb: '30px' } }>
            <Text sx={ { fontSize: '20px', fontWeight: 800 } }>
              {t('cart.SubtotalLabel')}
            </Text>
            <Text sx={ { fontSize: '20px', fontWeight: 800 } }>
              {numeral(subtotal).format(t('currency.format'))}
            </Text>
          </Flex>
        )}

        {shouldDisplayShipping && (
          <Flex data-testid="order-summary-shipping" sx={ { justifyContent: 'space-between', mb: '30px' } }>
            <Flex sx={ { flexDirection: 'column', flexBasis: '75%' } }>
              <Text sx={ { fontSize: '20px', fontWeight: 800 } }>
                {t('cart.ShippingLabel')}
              </Text>
              <OrderUpsellText cart={ cartItems } />
            </Flex>
            <ShippingCostText />
          </Flex>
        )}

        {!hasOnlyDonationItems && isMealDeal && (
          <Text sx={ { my: 3 } } variant="text.helper">
            {t('cart.MealDealShippingInfoText')}
          </Text>
        )}

        {!hasOnlyDonationItems && !shouldDisplayShipping && !isMealDeal && (
          <Flex sx={ { justifyContent: 'space-between', my: '30px' } }>
            <Flex sx={ { flexDirection: 'column', flexBasis: '75%' } }>
              <Text>
                {t('cart.CouponCodeShippingInfoText')}
              </Text>
            </Flex>
          </Flex>
        )}

        <Flex sx={ { justifyContent: 'space-between', mb: '30px' } }>
          <Text sx={ { fontSize: '20px', fontWeight: 800 } }>
            {t('cart.OrderTotalLabel')}
          </Text>
          <Text sx={ { fontSize: '22px', fontWeight: 800 } }>
            <span style={ { fontWeight: 400 } }>USD</span>
            {' '}
            {!isFetching && shippingCost !== undefined && numeral(subtotal + shippingCost).format(t('currency.format'))}
          </Text>
        </Flex>
        {error?.Status === 500 && (
          <Error sx={ { textAlign: 'center' } }>
            {t('cart.500Error')}
          </Error>
        )}
        <Button
          data-testid="continue_shopping"
          onClick={ categoryRedirect }
          sx={ { width: '100%', mb: '15px' } }
          variant="secondary"
        >
          {t('cart.ContinueShoppingLabel')}
        </Button>

        { type === 'cart'
          ? (
            <Button
              data-testid="cart_checkout"
              disabled={ error?.Status === 500 || ineligibleItems }
              onClick={ checkoutRedirect }
              sx={ { width: '100%' } }
              variant={ error?.Status === 500 || ineligibleItems ? 'disabled' : 'primary' }
            >
              {t('cart.ToCheckoutLabel')}
            </Button>
          )
          : (
            <div />
          )}
      </Flex>
    </Flex>
  );
};

export interface ICheckoutOrderSummaryProps {
  fundraiserType: FundraiserType;
  shouldDisplayShipping?: boolean;
  shouldDisplaySubtotal?: boolean;
}

const CheckoutOrderSummary: React.FC<ICheckoutOrderSummaryProps> = ({
  fundraiserType,
  shouldDisplayShipping = true,
  shouldDisplaySubtotal = true,
}) => {
  const state = useCheckoutState();

  if (!state || !state.checkout) {
    return <OrderSummarySkeleton />;
  }

  return (
    <OrderSummary
      cartItems={ state.checkout.Cart }
      fundraiserType={ fundraiserType }
      ineligibleItems={ state.checkout.IneligibleItems }
      shouldDisplayShipping={ shouldDisplayShipping }
      shouldDisplaySubtotal={ shouldDisplaySubtotal }
      subtotal={ state.checkout.Subtotal }
      type="checkout"
    />
  );
};

export { OrderSummary, CheckoutOrderSummary };
