import { Text } from '@lce/slice_v2';
import _map from 'lodash/map';
import React from 'react';

import { BracketTextWrapper } from '../BracketTextWrapper';

import { htmlDecode } from 'ui/fundraiser/ResourceHub/utils';

interface IEmailContent {
  body: string[];
  subject: string;
}
const EmailTemplateContent: React.FC<IEmailContent> = ({ body, subject }) => (
  <Text
    sx={ {
      maxWidth: '621px', m: [ 'auto', '0 auto' ],
      fontStyle: 'italic',
    } }
  >
    <Text sx={ { mb: '20px' } }>
      {subject}
    </Text>
    {
      _map(body, (text) => {
        const formattedText = htmlDecode(text);
        return (
          <Text key={ text } sx={ { mb: '20px' } }>
            <BracketTextWrapper sx={ { color: 'primaryOrange' } } text={ formattedText } />
          </Text>
        );
      })
    }
  </Text>
);

export default EmailTemplateContent;
