import React from 'react';
import * as Yup from 'yup';
import {
  Form,
  Flex,
  Box,
  Button,
  Text,
  Card,
  Spinner,
} from '@lce/slice_v2';
import { useTranslation } from '@lce/i18n';
import { useHistory } from 'react-router-dom';

import { useContactUs } from 'features/contact';
import { phonePattern, PhoneInput } from 'ui/components/forms/phone-input/phone-input';
export interface IContactUsForm {
  isConfirm: boolean;
}

// eslint-disable-next-line max-lines-per-function
const ContactUsForm: React.FC<IContactUsForm> = ({ isConfirm = false }) => {
  const [ t ] = useTranslation();
  const { mutate: contact, isLoading } = useContactUs();
  const history = useHistory();
  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required(t('contactUs.FirstNameRequired')),
    lastName: Yup.string().required(t('contactUs.LastNameRequired')),
    Organization: Yup.string(),
    phoneNumber: Yup.string()
      .min(10, t('fundraiser.PhoneNumberMinLength'))
      .max(17, t('fundraiser.PhoneNumberLength'))
      // eslint-disable-next-line max-len
      .matches(phonePattern, { excludeEmptyString: false, message: t('fundraiser.PhoneNumberValid') })
      .notRequired()
      .nullable(),
    email: Yup.string()
      .email(t('contactUs.EmailValid'))
      .required(t('contactUs.EmailRequired')),
    comments: Yup.string(),
  });

  const handleOnSubmit = async(e) => {
    await contact(e);
    history.push('/contact-us/confirm');
  };

  return (
    <Box data-testid="contact-us-form" id="form" p={ [ 0, 5, 0 ] } sx={ { maxWidth: '700px' } }>
      <Text
        sx={ {
          fontSize: [ '24px', '24px', '34px' ], color: 'primaryOrange', mt: '16px', mb: '16px',
        } }
        variant="text.header.subUpper"
      >
        {t('contactUs.SendUsAMessage')}
      </Text>

      <Card p={ [ 5, 30 ] }>
        { isConfirm
          ? (
            <Text>
              {t('contactUs.SubmitMessage')}

              {' '}
            </Text>
          )
          : (
            <Form
              initialValues={ {
                firstName: '',
                lastName: '',
                organization: '',
                email: '',
                phoneNumber: '',
                comments: '',
              } }
              onSubmit={ (data) => {
                handleOnSubmit(data);
              } }
              summary={ false }
              validationSchema={ validationSchema }
            >
              <Text>
                {t('contactUs.HaveAQuestion')}
              </Text>

              <Text mt="14px">
                *
                {' '}

                {t('contactUs.RequiredFields')}
              </Text>

              <Flex sx={ { flexDirection: [ 'column', 'row' ] } }>
                <Box
                  sx={ {
                    mr: [ 0, 8 ],
                    width: '100%',
                  } }
                >
                  <Form.Field
                    autoComplete="given-name"
                    data-testid="first-name"
                    id="firstName"
                    label="First Name*"
                    name="firstName"
                  />
                </Box>

                <Box
                  sx={ {
                    width: '100%',
                  } }
                >
                  <Form.Field
                    autoComplete="family-name"
                    data-testid="last-name"
                    id="lastName"
                    label="Last Name*"
                    name="lastName"
                  />
                </Box>
              </Flex>

              <Box
                sx={ {
                  width: '100%',
                } }
              >
                <Form.Field
                  autoComplete="organization"
                  data-testid="organization"
                  id="organization"
                  label="Organization"
                  name="organization"
                />
              </Box>

              <Flex sx={ { flexDirection: [ 'column', 'row' ] } }>
                <Box
                  sx={ {
                    mr: [ 0, 8 ],
                    width: '100%',
                  } }
                >
                  <PhoneInput label="Phone Number" name="phoneNumber" />
                </Box>

                <Box
                  sx={ {
                    width: '100%',
                  } }
                >
                  <Form.Field
                    autoComplete="email"
                    data-testid="email"
                    id="email"
                    label="Email Address*"
                    name="email"
                    type="email"
                  />
                </Box>
              </Flex>

              <Box
                sx={ {
                  width: '100%',
                } }
              >
                <Form.Field
                  component={ Form.Input.Textarea }
                  data-testid="comments"
                  id="comments"
                  label="Comments"
                  name="comments"
                />
              </Box>

              <Flex
                sx={ {
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                  mt: '30px',
                  width: [ '100%', '100%', '100%' ],
                  ml: 'auto',
                } }
              >
                <Button
                  data-testid="submit-button"
                  id="chairpersonNext"
                  sx={ { width: '100%' } }
                  type="submit"
                  variant={ isLoading ? 'disabled' : 'primary' }
                >
                  { isLoading ? (
                    <Spinner sx={ { height: '100%' } } variant="lce" />
                  ) : t('contactUs.SendMessage') }
                </Button>
              </Flex>
            </Form>

          )}
      </Card>
    </Box>
  );
};

export default ContactUsForm;
