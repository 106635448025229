import React from 'react';
import { useTranslation } from '@lce/i18n';
import {
  Box, Container, Flex, Link, Text,
} from '@lce/slice_v2';

import { Banner, RouterLink } from 'ui/common';
import BannerImage from 'assets/images/banners/faq-banner.png';

interface IFaqProps {
  question: string;
  answer: string;
}

/**
 * TODO: These bad boys should come from a CMS
 */
// eslint-disable-next-line max-lines-per-function
const FrequentlyAskedQuestions: React.FC = () => {
  const [ t ] = useTranslation();

  return (
    <>
      <Container>
        <Banner
          gradient="to-bottom"
          image={ BannerImage }
          showOnMobile={ true }
          text={ t('faqs.banner.Title') }
        />
        <Box sx={ { maxWidth: '855px' } }>
          <Faq
            answer={ t('faqs.faq1.answer') }
            question={ t('faqs.faq1.question') }
          />
          <Faq
            answer={ t('faqs.faq2.answer') }
            question={ t('faqs.faq2.question') }
          />
          <Faq
            answer={ t('faqs.faq3.answer') }
            question={ t('faqs.faq3.question') }
          />
          <Box sx={ { mt: '35px', mb: '35px' } }>
            <Text sx={ { color: 'primaryOrange' } } variant="text.quote">
              { t('faqs.faq4.question') }
            </Text>
            <Text sx={ { lineHeight: '18px', mt: '16px', mb: '16px' } }>
              { t('faqs.faq4.answer.part1') }
              {' '}
              <RouterLink sx={ { color: 'primaryOrange' } } to="/start-a-fundraiser">Start Your Fundraiser</RouterLink>
              {' '}
              { t('faqs.faq4.answer.part2') }
            </Text>
          </Box>
          <Faq
            answer={ t('faqs.faq5.answer') }
            question={ t('faqs.faq5.question') }
          />
          <Faq
            answer={ t('faqs.faq6.answer') }
            question={ t('faqs.faq6.question') }
          />
          <Faq
            answer={ t('faqs.faq7.answer') }
            question={ t('faqs.faq7.question') }
          />
          <Faq
            answer={ t('faqs.faq8.answer') }
            question={ t('faqs.faq8.question') }
          />
          <Faq
            answer={ t('faqs.faq9.answer') }
            question={ t('faqs.faq9.question') }
          />
          <Faq
            answer={ t('faqs.faq10.answer') }
            question={ t('faqs.faq10.question') }
          />
          <Faq
            answer={ t('faqs.faq11.answer') }
            question={ t('faqs.faq11.question') }
          />
          <Faq
            answer={ t('faqs.faq12.answer') }
            question={ t('faqs.faq12.question') }
          />
          <Box sx={ { mt: '35px', mb: '35px' } }>
            <Text sx={ { color: 'primaryOrange' } } variant="text.quote">
              { t('faqs.faq13.question') }
            </Text>
            <Text sx={ { lineHeight: '18px', mt: '16px', mb: '16px' } }>
              { t('faqs.faq13.answer.part1') }
              {' '}
              <RouterLink sx={ { color: 'primaryOrange' } } to="/products">Products</RouterLink>
              {' '}
              { t('faqs.faq13.answer.part2') }
            </Text>
          </Box>
          <Faq
            answer={ t('faqs.faq14.answer') }
            question={ t('faqs.faq14.question') }
          />
          <Faq
            answer={ t('faqs.faq15.answer') }
            question={ t('faqs.faq15.question') }
          />
          <Text>
            {t('faqs.footer')}
            {' '}
            <Link
              href="mailto:service@pizzakit.com"
              sx={ { color: 'primaryOrange' } }
            >
              Fundraising@LittleCaesars.com
            </Link>
          </Text>
        </Box>
      </Container>
      <Flex
        sx={ {
          alignItems: 'center', maxWidth: '1122px', m: '0 auto',
          p: [ '0 44px', '0 44px', '0 44px', '0' ], mt: '16px',
          mb: [ '40px', 0 ],
        } }
      >
        <Text
          sx={ {
            alignSelf: 'flex-start', fontSize: '14px', fontFamily: 'roboto',
          } }
        >
          {t('common.BundleDisclaimer')}
        </Text>
      </Flex>
    </>
  );
};

const Faq: React.FC<IFaqProps> = props => (
  <Box sx={ { mt: '35px', mb: '35px' } }>
    <Text sx={ { color: 'primaryOrange' } } variant="text.quote">
      {props.question}
    </Text>
    <Text sx={ { lineHeight: '18px', mt: '16px', mb: '16px' } }>
      {props.answer}
    </Text>
  </Box>
);

export default FrequentlyAskedQuestions;
