import React from 'react';
import {
  Route, RouteProps, Switch, useRouteMatch,
} from 'react-router-dom';

import ArticleDetail from './articleDetail';

export type IArticlesProps = RouteProps;

const Articles: React.FC<IArticlesProps> = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route component={ ArticleDetail } exact={ true } path={ `${ path }/:articleName` } />
    </Switch>
  );
};

export default Articles;
