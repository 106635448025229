import React from 'react';
import {
  Flex, Box, Skeleton,
} from '@lce/slice_v2';

import { useFundraiserState } from 'features/fundraiser/context/fundraiserContext';

const ProductListItemSkeleton: React.FC = () => {
  const { fundraiserType } = useFundraiserState();
  return (
    <Box variant={ `cards.productCard${ fundraiserType }` }>
      <Box variant={ `links.productCardImageWrapperLink${ fundraiserType }` }>
        <Skeleton
          count={ 1 }
          sx={ { pb: '65%', mb: 0, minHeight: '100px' } }
          variant={ `images.productCardImage${ fundraiserType }` }
        />
      </Box>
      <Flex variant={ `cards.productCardDetailsWrapper${ fundraiserType }` }>
        <Flex sx={ { flexDirection: 'column', alignItems: 'flex-start' } }>
          <Skeleton count={ 1 } sx={ { height: '22px', width: '70%', mb: '8px' } } />
          <Skeleton count={ 1 } sx={ { height: '19px', width: '50%' } } />
          <Skeleton count={ 1 } sx={ { height: '22px', width: '25%' } } />
        </Flex>
        <Flex
          sx={ {
            alignItems: 'center',
            justifyContent: 'space-between',
            flexDirection: 'row',
            width: '100%',
          } }
        >
          <Skeleton
            count={ 1 }
            sx={ {
              height: '36px', width: '100px', mr: '15px', mb: 0,
            } }
          />
          <Skeleton count={ 1 } sx={ { height: '36px', width: '50%', mb: 0 } } />
        </Flex>
      </Flex>
    </Box>
  );
};

export default ProductListItemSkeleton;

