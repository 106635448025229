import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { useLocalStorage } from 'features/common/hooks';

export const useStoreQueryParamToLocalStorage = (queryParamKey: string, localStorageKey?: string) => {
  const [ , setLocalStorageValue ] = useLocalStorage<string>(localStorageKey ?? queryParamKey);
  const { search } = useLocation();

  const queryParamValue = new URLSearchParams(search).get(queryParamKey);

  useEffect(() => {
    if (queryParamValue) {
      setLocalStorageValue(queryParamValue);
    }
  }, [ queryParamValue, setLocalStorageValue ]);
};
