import React from 'react';
import {
  Box, Flex, Text, Image,
} from '@lce/slice_v2';

export interface IHiwCardProps {
  title: string | React.ReactNode;
  children: React.ReactNode;
  icon: string | React.ReactNode;
}

// eslint-disable-next-line max-lines-per-function
const HIWCard: React.FC<IHiwCardProps> = ({ children, icon, title }) => (
  <Flex
    sx={ {
      width: '342px',
      flexDirection: 'column',
      ml: '16px',
      mr: '16px',
      minHeight: [ 0, '485px' ],
    } }
  >
    <Image
      alt={ title }
      src={ icon }
      sx={ {
        padding: '31px',
        height: '211px',
      } }
      title={ title }
    />
    <Flex
      sx={ {
        justifyContent: 'space-between',
        alignItems: 'center',
        bg: 'primaryGrayMid',
        padding: '8px 16px',
        height: '36px',
        fontFamily: 'mrEaves',
        fontSize: '18px',
        textTransform: 'uppercase',
        borderBottomLeftRadius: '4px',
        borderBottomRightRadius: '4px',
      } }
    >
      <Text sx={ { fontWeight: '800' } }>
        {title}
      </Text>
    </Flex>
    <Box
      sx={ {
        border: '1px solid',
        borderTop: 'none',
        borderRadius: '4px',
        borderColor: 'primaryGrayMid',
      } }
    >
      <Text
        sx={ {
          padding: '25px',
          fontFamily: 'Lato',
          fontSize: '16px',
          lineHeight: '19px',
        } }
      >
        {children}
      </Text>
    </Box>
  </Flex>
);
export default HIWCard;
