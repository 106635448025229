import * as React from 'react';
import { Flex, Skeleton } from '@lce/slice_v2';

export const SellerGraphsSkeleton = () => (
  <Flex
    data-testid="seller-graphs-skeleton"
    sx={ {
      minWidth: [ 'none', '404px' ],
      justifyContent: 'center',
      flexWrap: [ 'wrap', 'nowrap' ],
      gap: [ '16px', '32px' ],
    } }
  >
    <Skeleton count={ 1 } sx={ { height: [ '112px', '124px' ], width: [ '112px', '124px' ], borderRadius: '50%' } } />
    <Skeleton count={ 1 } sx={ { height: [ '112px', '124px' ], width: [ '112px', '124px' ], borderRadius: '50%' } } />
    <Skeleton count={ 1 } sx={ { height: [ '112px', '124px' ], width: [ '112px', '124px' ], borderRadius: '50%' } } />
  </Flex>
);
