import React from 'react';
import { Container } from '@lce/slice_v2';

import {
  FeaturedFundraiser,
  FeaturedOrganizations,
  FeaturedProducts,
  FundraiserPrograms,
  FundraisingSupport,
  HeroImage,
  MoreInfo,
  UserCategories,
  UserTestimonial,
} from 'ui/home';
import { DESKTOP_HEADER_HEIGHT, MOBILE_HEADER_HEIGHT } from 'ui/common';

const Home: React.FC = () => (
  <Container sx={ { pt: [ MOBILE_HEADER_HEIGHT, MOBILE_HEADER_HEIGHT, MOBILE_HEADER_HEIGHT, DESKTOP_HEADER_HEIGHT ] } }>
    <HeroImage />
    <UserCategories />
    <FundraiserPrograms />
    <FundraisingSupport />
    <UserTestimonial />
    <FeaturedOrganizations />
    <FeaturedFundraiser />
    <FeaturedProducts />
    <MoreInfo />
  </Container>
);

export default Home;
