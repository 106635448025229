/* eslint-disable max-len */
/* eslint-disable max-lines-per-function */
import React from 'react';
import { Box, Flex, Text } from '@lce/slice_v2';
import { format } from 'date-fns';
import { useTranslation } from '@lce/i18n';

import { HeaderImage } from './HeaderImage';
import { HeaderDescription } from './HeaderDescription';

import { FundraiserListItem } from 'features/fundraiser/types/fundraiser';
import { Seller } from 'features/fundraiser';

export interface IDashboardHeader {
  fundraiser: FundraiserListItem;
  seller?: Seller;
  description: string;
}

const DashboardHeader: React.FC<IDashboardHeader> = ({ fundraiser, seller, description }) => {
  const [ t ] = useTranslation();
  return (
    <Flex data-testid="dashboard-header" sx={ { flexDirection: [ 'column', 'row' ], mb: '20px' } }>
      <HeaderImage fundraiser={ fundraiser } seller={ seller } />
      <Box sx={ { mb: '24px', width: '100%', minWidth: [ '', '250px' ] } }>
        <Flex sx={ { justifyContent: 'space-between', flexDirection: [ 'column', 'column', 'row' ] } }>
          <Text
            sx={ {
              fontFamily: 'roboto', fontSize: '32px',
              mt: [ '10px', '10px', 0 ], mb: '10px',
            } } variant="text.header.title"
          >
            {`${ fundraiser.Name } ${ fundraiser.CancellationDate ? ` ${ t('dashboard.header.cancelled') }` : '' }`}
          </Text>
        </Flex>
        <Box sx={ { mb: '12px' } }>
          <Text variant="text.header.form">
            {`${ format(new Date(fundraiser.StartDate), 'MMMM dd, yyyy') } - ${ format(new Date(fundraiser.EndDate), 'MMMM dd, yyyy') }`}
          </Text>
          { fundraiser.SalesCode && fundraiser.SalesAgentName && (
            <Text variant="text.header.form">
              {`${ t('dashboard.header.salesAgent') } ${ fundraiser.SalesAgentName } - ${ fundraiser.SalesCode }`}
            </Text>
          )}
          { fundraiser.CancellationDate && (
            <Text variant="text.header.form">
              {`${ t('dashboard.header.cancellationDate') } ${ format(new Date(fundraiser.CancellationDate), 'MMMM dd, yyyy') }`}
            </Text>
          )}
          <Text variant="text.header.form">
            {`${ t('dashboard.header.fundraiserId') } ${ fundraiser.Id }`}
          </Text>
        </Box>
        <HeaderDescription description={ description } fundraiser={ fundraiser } seller={ seller } />
      </Box>
    </Flex>
  );
};

export default DashboardHeader;
