import * as React from 'react';

import { CtaLeadCapture } from './cta-lead-capture';
import { CallToAction } from './call-to-action';
import { LeadCapture } from './lead-capture';
import { Information } from './information';

export type Template = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: React.FC<any>;
}

export const Templates: Template = {
  'cta-lead-capture': CtaLeadCapture,
  'call-to-action': CallToAction,
  'lead-capture': LeadCapture,
  information: Information,
};
