import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import {
  Form,
  Flex,
  Box,
  Button,
  Heading,
  Spinner,
  Option,
  Text,
} from '@lce/slice_v2';
import { useTranslation } from '@lce/i18n';
import { useHistory, useLocation, Link } from 'react-router-dom';

import SecurityQuestions from '../SecurityQuestions';
import { ILocationStateProps } from '../types';

import { RouterLink } from 'ui/common';
import { CreateAccountRequest, useCreateAccount, useFetchFundraiserInvite } from 'features/account';
import { Address, useQueryParam } from 'features/common';
import {
  phonePattern,
  PhoneInput,
  FirstNameInput,
  firstNameInputSchema,
} from 'ui/components';

export interface ICreateAccountFormProps {
  guestId?: string;
  address?: Address;
  isConfirm: boolean;
  onSuccess?: () => void;
  onBackCallback?: () => void;
}

// eslint-disable-next-line max-lines-per-function
const CreateAccountForm: React.FC<ICreateAccountFormProps> = ({
  guestId, address, isConfirm, onSuccess, onBackCallback,
}) => {
  const [ t ] = useTranslation();
  const history = useHistory();
  const [ fundraiserGuid ] = useQueryParam('inviteId');
  const [ , setInviteUserRegistered ] = useState<boolean>(false);

  const {
    mutateAsync: submitAccount, error, isLoading, isError,
  } = useCreateAccount();
  const { data: fetchFundraiserInvite } = useFetchFundraiserInvite(fundraiserGuid);
  const { state } = useLocation<ILocationStateProps>();

  useEffect(() => {
    if (fetchFundraiserInvite?.IsAlreadyRegistered) {
      setInviteUserRegistered(true);

      // todo: Should we throw up some message stating they already have an account?
      history.push('/login');
    }
  }, [ fetchFundraiserInvite, history ]);

  const validationSchema = Yup.object().shape({
    ...firstNameInputSchema(t, 'firstName'),
    lastName: Yup.string().required(t('createAccount.LastNameRequired')),
    email: Yup.string()
      .email(t('createAccount.EmailValid'))
      .required(t('createAccount.EmailRequired')),
    password: Yup.string()
      .required(t('createAccount.PasswordRequired'))
      .matches(/^(?=\D*\d)[^ ]{6,}$/, t('createAccount.PasswordRequired'))
      .min(6, t('createAccount.PasswordMin')),
    phone: Yup.string()
      .required(`${ t('createAccount.PhoneRequired') }`)
      .matches(phonePattern,
        t('createAccount.PhoneNumberValid'))
      .min(10, t('fundraiser.PhoneNumberMinLength'))
      .max(17, t('fundraiser.PhoneNumberLength')),
    confirmPassword: Yup.string()
      .required(t('createAccount.ConfirmPasswordRequired'))
      .oneOf([ Yup.ref('password') ], t('createAccount.PasswordMatch')),
    securityQuestion: Yup.string().required(t('createAccount.SecurityQuestionRequired')),
    securityQuestionAnswer: Yup.string()
      .required(t('createAccount.SecurityQuestionAnswerRequired'))
      .min(4, t('createAccount.SecurityQuestionAnswerLength'))
      .notOneOf([ Yup.ref('password') ], t('createAccount.SecurityQuestionAnswerUniqueness')),
  });

  const handleOnSubmit = async(e) => {
    const formData: CreateAccountRequest = {
      firstName: e.firstName,
      lastName: e.lastName,
      phone: e.phone,
      email: e.email,
      password: e.password,
      confirmTerms: e.confirmTerms,
      securityQuestion: e.securityQuestion,
      securityQuestionAnswer: e.securityQuestionAnswer,
      optInMarketing: e.optInMarketing,
      guestId: guestId,
    };

    const data = await submitAccount(formData);

    if (data) {
      // if a success handler is specified, let the parent component handle what to do
      // otherwise redirect to the confirm page
      if (onSuccess) {
        onSuccess();
      } else {
        history.push('/create-account/confirm', state);
      }
    }
  };

  return (
    <Box data-testid="create-account-form" margin="0 auto" p={ [ 5, 40 ] } sx={ { maxWidth: '980px' } }>
      <Heading as="h2">Create Account</Heading>

      { !isConfirm
        ? (
          <>
            <Text>
              {t('createAccount.ParentGuardian')}
            </Text>

            <Form
              initialValues={ {
                firstName: address?.FirstName || '',
                lastName: address?.LastName || '',
                email: address?.Email || '',
                phone: '',
                password: '',
                confirmPassword: '',
                confirmTerms: true, // todo: set appropriately if confirm terms is needed
              } }
              onSubmit={ (data) => {
                handleOnSubmit(data);
              } }
              reValidateMode="onSubmit"
              summary={ false }
              validationSchema={ validationSchema }
            >
              <Flex sx={ { flexDirection: [ 'column', 'column', 'row' ] } }>
                <Box
                  sx={ {
                    mr: [ 0, 8 ],
                    width: '100%',
                  } }
                >
                  <FirstNameInput
                    label="First Name *"
                    name="firstName"
                    sx={ {} }
                  />
                </Box>

                <Box
                  sx={ {
                    width: '100%',
                  } }
                >
                  <Form.Field
                    autoComplete="family-name"
                    id="lastName"
                    label="Last Name *"
                    name="lastName"
                  />
                </Box>
              </Flex>

              <Flex sx={ { flexDirection: [ 'column', 'column', 'row' ] } }>
                <Box
                  sx={ {
                    mr: [ 0, 8 ],
                    width: '100%',
                  } }
                >
                  <Form.Field
                    autoComplete="email"
                    id="email"
                    label="Email *"
                    name="email"
                    type="email"
                  />
                </Box>

                <Box
                  sx={ {
                    width: '100%',
                  } }
                >
                  <PhoneInput label="Phone *" name="phone" />

                </Box>
              </Flex>

              <Flex sx={ { flexDirection: [ 'column', 'column', 'row' ] } }>
                <Box
                  sx={ {
                    mr: [ 0, 8 ],
                    width: '100%',
                  } }
                >
                  <Form.Field
                    autoComplete="new-password"
                    component={ Form.Input.Password }
                    id="password"
                    label="Password *"
                    name="password"
                  />
                </Box>

                <Box
                  sx={ {
                    width: '100%',
                  } }
                >
                  <Form.Field
                    component={ Form.Input.Password }
                    id="confirmPassword"
                    label="Confirm Password *"
                    name="confirmPassword"
                  />
                </Box>
              </Flex>

              <Flex sx={ { flexDirection: [ 'column', 'column', 'row' ] } }>
                <Box
                  sx={ {
                    mr: [ 0, 8 ],
                    width: '100%',
                  } }
                >
                  <Form.Field
                    component={ Form.Select }
                    id="securityQuestion"
                    label="Security Question *"
                    name="securityQuestion"
                    sx={ {
                      select: {
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        pr: '30px',
                      },
                    } }
                  >
                    {SecurityQuestions.map(x => (
                      <Option key={ x } value={ x }>
                        {x}
                      </Option>
                    ))}
                  </Form.Field>
                </Box>

                <Box
                  sx={ {
                    width: '100%',
                  } }
                >
                  <Form.Field
                    id="securityQuestionAnswer"
                    label="Security Question Answer *"
                    name="securityQuestionAnswer"
                  />
                </Box>
              </Flex>

              <Box mb={ 4 }>
                <Form.Field
                  component={ Form.Input.Checkbox }
                  id="parentOrGuardian"
                  inline={ true }
                  label={ t('createAccount.ParentOrGuardian') }
                  name="parentOrGuardian"
                />
              </Box>

              <Box mb={ 4 }>
                <Form.Field
                  component={ Form.Input.Checkbox }
                  id="optInMarketing"
                  inline={ true }
                  label={ t('createAccount.MarketingOptIn') }
                  name="optInMarketing"
                />
              </Box>

              { isError && error ? (
                <Box color="red">
                  { error.Message }
                </Box>
              ) : null }

              <Flex
                sx={ {
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                  mt: '30px',
                  width: [ '100%', '100%', '100%' ],
                  ml: 'auto',
                } }
              >
                { onBackCallback ? (
                  <Button
                    onClick={ onBackCallback }
                    sx={ { width: [ '100%', '50%' ] } }
                    type="button" variant="secondary"
                  >
                    Cancel
                  </Button>
                ) : null}

                <Flex
                  sx={ {
                    flexDirection: 'column', width: [ '100%', '50%' ], justifyContent: 'flex-end', ml: [ 0, 8 ],
                  } }
                >
                  <Button
                    disabled={ isLoading }
                    id="click-create-account"
                    sx={ { width: '100%' } }
                    type="submit"
                    variant={ isLoading ? 'disabled' : 'primary' }
                  >
                    { isLoading ? (
                      <Spinner sx={ { height: '100%' } } variant="lce" />
                    ) : t('common.CreateAccount') }
                  </Button>

                  <Text
                    sx={ {
                      textAlign: 'right', mt: '8px', fontSize: '12px', color: 'primaryGrayDark',
                    } }
                  >
                    By creating an account, you agree to Little Caesars Fundraiser's
                    {' '}

                    <RouterLink
                      sx={ { color: 'primaryOrange' } }
                      target="_blank"
                      to="/legal/terms-of-service"
                    >
                      Terms of use
                    </RouterLink>

                    {' '}
                    and consent to its

                    {' '}

                    <RouterLink
                      as="a"
                      href="https://littlecaesars.com/en-us/legal/privacy-policy"
                      rel="noopener noreferrer"
                      sx={ { color: 'primaryOrange' } }
                      target="_blank"
                    >
                      Privacy Policy
                    </RouterLink>
                  </Text>
                </Flex>
              </Flex>
            </Form>
          </>
        ) : <div /> }

      { isConfirm
        ? (
          <div>
            <h2>Your account has been created. Please login to continue.</h2>

            <Link to={ { pathname: '/login', state } }>Login</Link>
          </div>
        )
        : <div /> }
    </Box>
  );
};

export default CreateAccountForm;
