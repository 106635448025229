import { FundraiserListItem, FundraiserProfitCheckAddress } from './fundraiser';

export interface IStep {
  stepNumber: number;
  title: string;
  hasInfo: boolean;
  popupType?: PopoverType;
  content: JSX.Element;
  borderStyle?: string;
}

export enum PopoverType {
  Seller = 'SELLER',
  Buyer = 'BUYER',
  Manage = 'MANAGE',
  FriendFamily = 'FRIENDFAMILY',
}

export interface IDashboardProps {
  children: React.ReactNode;
  popupType?: string;
  stepNumber: number;
  title: string;
  hasInfo?: boolean;
  borderStyle?: string;
}

export interface IStepFundraiserProp {
  fundraiser: FundraiserListItem;
  handleAddressModal?: () => void;
  idSuffix?: string;
  isMissingProfitCheckAddress?: boolean;
}

export interface IFundraiserSupporterProps {
  fundraiser: FundraiserListItem;
  idSuffix?: string;
  sellerId: number | undefined;
  sellerSupportGuid: string | undefined;
}

export interface ISellerStepsProps {
  fundraiser: FundraiserListItem;
  sellerId: number;
  supportGuid: string;
  customerId: number;
}

export interface IFundraiserCheckAddress {
  checkPayableTo: string;
  street: string;
  city: string;
  state: string;
  zipCode: string;
}

export interface ICheckAddressConfirmModalProps {
  address: FundraiserProfitCheckAddress;
  onEdit: () => void;
  isExpired: boolean;
  isMissingProfitCheckAddress: boolean;
}
