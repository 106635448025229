import * as React from 'react';
import { useOktaAuth } from '@okta/okta-react';

import { useLocalStorage } from 'features/common';
import { CustomerData } from 'features/cart/context/cartContext/cartContext';
import { appInsights } from 'features/analytics/ApplicationInsights';

const Logout: React.FC<{}> = () => {
  const { authService } = useOktaAuth();
  const [ , setCustomerData ] = useLocalStorage<CustomerData>('fundraiser/buyer');

  React.useEffect(() => {
    setCustomerData({});
    appInsights.clearAuthenticatedUserContext();
    authService.logout('/');
    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);

  return (
    <div />
  );
};

export default Logout;
