import React from 'react';
import { map as _map } from 'lodash';
import { useTranslation } from '@lce/i18n';
import { Option } from '@lce/slice_v2';

const HowDidYouHearAboutUsOptions = () => {
  const [ t ] = useTranslation();
  const optionsList = t('HowDidYouHearAboutUsOptions.options', { returnObjects: true });

  return (
    <>
      <Option value="">
        {t('HowDidYouHearAboutUsOptions.default')}
      </Option>
      {_map(optionsList, option => (
        <Option key={ option } value={ option }>
          {option}
        </Option>
      ))}
    </>
  );
};

export default HowDidYouHearAboutUsOptions;
