import React from 'react';
import {
  Box, Text,
} from '@lce/slice_v2';
import { useTranslation } from '@lce/i18n';

export interface IRegisterUserBoxProps {
  children: React.ReactNode;
}

export const RegisterUserBox: React.FC<IRegisterUserBoxProps> = ({
  children,
}) => {
  const [ t ] = useTranslation();

  return (
    <Box sx={ { mb: [ '30px', '55px' ] } }>
      <Text variant="text.header.sub">
        {t('createAccount.NewUser')}
      </Text>
      <Text variant="text.login.message">
        {t('createAccount.NewUserMessage')}
      </Text>
      {children}
    </Box>
  );
};
