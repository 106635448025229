/* eslint-disable max-lines-per-function */
import React from 'react';
import { useTranslation } from '@lce/i18n';
import { Text, Box } from '@lce/slice_v2';
import numeral from 'numeral';
import { format } from 'date-fns';

import { FundraiserBrochure } from 'features/fundraiser/types/fundraiser';

export interface IFundraiserDetailsSummaryProps {
  details: Partial<FundraiserBrochure> | undefined;
}

const FundraiserDetailsSummary: React.FC<IFundraiserDetailsSummaryProps> = ({ details }) => {
  const [ t ] = useTranslation();
  const startDate = details?.startDate ? format(new Date(details.startDate), 'MM/dd/yyyy') : '';
  return (
    <Box data-testid="fundraiser-details-summary">
      <Text variant="text.header.form">
        {t('fundraiser.Organization')}
      </Text>
      {details && (
        <>
          <Text>
            {details.name}
          </Text>
          <Text>
            {t('fundraiser.summary.OrgType', { orgType: details.organizationGroupTypeName })}
          </Text>
          <Text>
            {t('fundraiser.summary.StartDate', { startDate })}
          </Text>
        </>
      )}
      <Text sx={ { mt: '16px' } } variant="text.header.form">
        {t('fundraiser.Goal')}
      </Text>
      {details && (
        <>
          <Text>
            {t('fundraiser.summary.DollarGoal',
              { dollarGoal: numeral(details.profitGoal).format(t('currency.format')) })}
          </Text>
          <Text>
            {t('fundraiser.summary.EstimatedSellers', { estimatedSellers: details.sellerCount })}
          </Text>
          <Text>
            {t('fundraiser.summary.SellerKitGoal', { sellerKitGoal: details.sellerKitGoals })}
          </Text>
          <Text>
            {t('fundraiser.summary.BrochureCount', { brochureCount: details.brochuresRequestCount })}
          </Text>
          <Text>
            {t('fundraiser.summary.PosterCount', { posterCount: details.postersRequestCount })}
          </Text>
          { details.salesAgentCode && (
            <>
              <Text sx={ { mt: '16px' } } variant="text.header.form">
                {t('fundraiser.SalesAgent')}
              </Text>
              <Text>
                {t('fundraiser.summary.SalesAgentCode', { salesAgentCode: details.salesAgentCode })}
              </Text>
            </>
          ) }
        </>
      ) }
    </Box>
  );
};

export default FundraiserDetailsSummary;
