import { useQuery } from 'react-query';

import { ProductCategory } from '../types/product';
import * as QueryKeys from '../../../constants/QueryKeys';
import { fetchProductCategories } from '../api';

export const useFetchCategories = () => useQuery<ProductCategory[], ''>(QueryKeys.ProductCategories,
  () => fetchProductCategories(), {
    refetchOnWindowFocus: false,
    retry: false,
  });
