import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { ChevronLeft, Menu } from 'react-feather';
import {
  Box, IconButton,
} from '@lce/slice_v2';

import { Links } from '../Links';

interface IOverlayProps {
  loggedIn: boolean;
  roles: string[];
}

const Overlay: React.FC<IOverlayProps> = ({ loggedIn, roles }) => {
  const [ isOpen, setIsOpen ] = useState<boolean>(false);
  const history = useHistory();

  useEffect(() => history.listen(() => setIsOpen(false)));

  const handleOnOpen = () => setIsOpen(true);
  const handleOnClose = () => setIsOpen(false);

  return (
    <>
      { isOpen
        ? (
          <IconButton onClick={ handleOnClose } variant="navMenu">
            <ChevronLeft size={ 35 } />
          </IconButton>
        )
        : (
          <IconButton
            data-testid="mobile-menu" onClick={ handleOnOpen } variant="navMenu"
          >
            <Menu size={ 35 } />
          </IconButton>
        )}

      <Box
        sx={ {
          transform: isOpen ? 'translateX(0%)' : '',
        } }
        variant="cards.mobileMenu"
      >
        <Links loggedIn={ loggedIn } roles={ roles } />
      </Box>
    </>
  );
};

export default Overlay;
