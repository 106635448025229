/* eslint-disable max-lines-per-function */
import * as React from 'react';
import { useTranslation } from '@lce/i18n';
import {
  Flex, Image, Text, Link,
} from '@lce/slice_v2';

import TestimonialUser from './assets/testimonialuser.png';
import BannerImage from './assets/familyeatingpizza.webp';

import { RightArchClipPathSvg } from 'ui/components/arch-clip-path-svgs';

const UserTestimonial: React.FC = () => {
  const [ t ] = useTranslation();

  return (
    <Flex
      data-testid="user-testimonial"
      sx={ { flexDirection: [ 'column', 'column', 'row' ], width: '100%' } }
    >
      <RightArchClipPathSvg />
      <Image
        alt={ t('home.userTestimonial.imageAltText') }
        src={ BannerImage }
        sx={ {
          width: [ '100%', '100%', '50%' ],
          clipPath: [ 'none', 'none', 'url(#right-arch-clip)' ],
          objectFit: 'cover',
          height: [ '185px', '300px', '400px' ],
        } }
      />
      <Flex
        sx={ {
          alignItems: 'center',
          flexDirection: 'column',
          gap: '16px',
          justifyContent: 'center',
          p: [ '32px 16px', '32px' ],
          textAlign: 'center',
          width: [ '100%', '100%', '50%' ],
        } }
      >
        <Text variant="home.subheading">
          {t('home.userTestimonial.title')}
        </Text>
        <Image
          alt={ t('home.userTestimonial.name') }
          height="100px"
          src={ TestimonialUser }
          sx={ { objectFit: 'contain', borderRadius: '50%' } }
          width="100px"
        />
        <Text sx={ { maxWidth: [ 'none', 'none', '480px' ] } } variant="home.quote">
          {t('home.userTestimonial.quote')}
        </Text>
        <Text variant="home.quote">
          {t('home.userTestimonial.name')}
        </Text>
        <Link
          data-testid="read-testimonials"
          href={ t('home.userTestimonial.link.href') }
          variant="links.primaryText"
        >
          {t('home.userTestimonial.link.text')}
        </Link>
      </Flex>
    </Flex>
  );
};

export default UserTestimonial;
