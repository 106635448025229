import { useQuery } from 'react-query';

import { fetchFundraiserFormData } from '../api';
import { Fundraiser } from '../types/fundraiser';
import * as QueryKeys from '../../../constants/QueryKeys';

import { ApiError } from 'features/common';

export const useFetchFundraiserFormData =
  (id: number) => useQuery<Fundraiser, ApiError>(`${ QueryKeys.FundraiserFormData }-${ id }`,
    () => fetchFundraiserFormData(id), {
      retry: false, refetchOnWindowFocus: false, cacheTime: 0, enabled: id > 0,
    });

