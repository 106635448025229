import React, { useEffect, useState } from 'react';
import { Box } from '@lce/slice_v2';
import { useTranslation } from '@lce/i18n';
import { map as _map } from 'lodash';

import OptionalTypeaheadSelect from '../OptionalTypeaheadSelect';
import { IOption } from '../IOption';

import { useFetchSalesCustomers } from 'features/fundraiser/hooks/useFetchSalesCustomers';
import { ISalesCustomer } from 'features/fundraiser/types/customer';
import { useFetchStates } from 'features/common';

// eslint-disable-next-line max-lines-per-function
const FilterOptions: React.FC = () => {
  const { data: salesCustomerQuery, isSuccess } = useFetchSalesCustomers();
  const { data: statesQuery, isSuccess: isStatesFetched } = useFetchStates(1);
  const [ salesCustomers, setSalesCustomers ] = useState<{
    salesAgents: IOption[]; iscs: IOption[];
  }>({ salesAgents: [], iscs: [] });
  const [ states, setStates ] = useState<IOption[]>([]);
  const [ t ] = useTranslation();

  const selects = [
    {
      label: t('fundraiserReportFilters.StateSelection.Label'),
      name: 'states',
      options: states,
      type: t('fundraiserReportFilters.StateSelection.Type'),
      value1: 'allStates',
      value2: 'specificStates',
    },
    {
      label: t('fundraiserReportFilters.SalesRepNameSelection.Label'),
      name: 'salesReps',
      options: salesCustomers.salesAgents,
      type: t('fundraiserReportFilters.SalesRepNameSelection.Type'),
      value1: 'allSalesReps',
      value2: 'specificSalesReps',
    },
    {
      label: t('fundraiserReportFilters.ISCNameSelection.Label'),
      name: 'iscs',
      options: salesCustomers.iscs,
      type: t('fundraiserReportFilters.ISCNameSelection.Type'),
      value1: 'allISCs',
      value2: 'specificISCs',
    },
  ];

  useEffect(() => {
    let name = '';
    let agent = {} as IOption;

    if (isSuccess) {
      const salesAgentsRes: IOption[] = [];
      const iscsRes: IOption[] = [];
      (salesCustomerQuery as ISalesCustomer[])?.forEach(({ FirstName, LastName, Role }) => {
        name = `${ FirstName } ${ LastName }`;
        agent = {
          label: name,
          value: name,
        } as IOption;

        if (Role === 'SalesAgent') {
          salesAgentsRes.push(agent);
        } else if (Role === 'Independent Sales Consultant') {
          iscsRes.push(agent);
        }
      });

      setSalesCustomers({ salesAgents: salesAgentsRes, iscs: iscsRes });
    }
  }, [ salesCustomerQuery, isSuccess ]);

  useEffect(() => {
    if (isStatesFetched) {
      const statesList = statesQuery?.map(state => (
        {
          label: state.Name,
          value: state.Abbreviation,
        } as IOption));

      setStates(statesList as IOption[]);
    }
  }, [ statesQuery, isStatesFetched ]);

  return (
    <>
      {_map(selects, ({
        name, label, options, type, value1, value2,
      }) => (
        <Box key={ name } sx={ { m: [ '6px 0px 5px 0px', '15px 0px 0px 0px' ] } }>
          <OptionalTypeaheadSelect
            id={ name }
            label={ label }
            nameSelection={ `${ name }Selection` }
            nameSelector={ `${ name }Selector` }
            options={ options ?? [] as IOption[] }
            type={ type }
            value1={ value1 }
            value2={ value2 }
          />
        </Box>
      ))}
    </>
  );
};

export default FilterOptions;
