import React, { useMemo } from 'react';
import {
  Box, Button, Heading, Text, Flex, Container,
} from '@lce/slice_v2';
import { useHistory } from 'react-router-dom';

type GradientType = 'none' | 'to-bottom' | 'to-top';

export interface IBannerProps {
  image: string;
  gradient: GradientType;
  showOnMobile: boolean;
  buttonVariant?: string;
  buttonText?: string;
  buttonLink?: string;
  text?: string | React.ReactNode;
  subText?: string;
  backgroundSize?: string;
  heightBreakpoints?: string[];
  buttonMarginProps?: string[];
  buttonBgColor?: string;
}

// eslint-disable-next-line max-lines-per-function
const Banner: React.FC<IBannerProps> = (props) => {
  const history = useHistory();

  const imageBackground = useMemo(() => {
    if (props.gradient === 'none') {
      return `url(${ props.image }) no-repeat`;
    } else if (props.gradient === 'to-bottom') {
      return `linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.65)), url(${ props.image }) no-repeat;`;
    } else if (props.gradient === 'to-top') {
      return `linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.65)), url(${ props.image }) no-repeat;`;
    }
  }, [ props.gradient, props.image ]);

  return (
    <Flex
      sx={ {
        backgroundColor: 'white', flexDirection: 'row', justifyContent: 'center',
      } }
    >
      <Container
        sx={ {
          m: [ '0 auto 40px', '0px auto 0 auto' ], maxWidth: [ '100%', '1280px' ], padding: '0 16px',
        } }
      >
        <Box
          sx={ {
            position: 'relative',
            background: imageBackground,
            backgroundPosition: 'center',
            backgroundSize: props.backgroundSize || 'cover',
            height: props.heightBreakpoints || [ '200px', '240px', '450px' ],
            display: [ props.showOnMobile ? 'block' : 'none', 'block', 'block' ],
          } }
        >
          <Box
            sx={ {
              position: 'absolute',
              top: '60%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: '100%',
              textAlign: 'center',
            } }
          >
            {props.text && (
              <Heading
                sx={ {
                  textTransform: 'uppercase', color: 'white', fontSize: [ '34px', '64px' ],
                } }
              >
                { props.text }
              </Heading>
            )}
            { props.subText && (
              <Text
                sx={ {
                  color: 'white',
                  fontWeight: 700,
                  fontSize: [ '14px, 14px, 20px' ],
                  maxWidth: [ '100%', '100%', '60%' ],
                  mr: 'auto',
                  ml: 'auto',
                } }
              >
                {props.subText}
              </Text>
            )}
            { props.buttonText && props.buttonLink && (
              <Button
                onClick={ () => history.push(props.buttonLink || '') }
                sx={ {
                  mt: props.buttonMarginProps || [ '19px', '19px', '36px' ],
                  minWidth: '240px',
                  borderColor: props.buttonBgColor || 'primaryOrange',
                  backgroundColor: props.buttonBgColor || 'primaryOrange',
                } }
                variant={ props.buttonVariant || 'primary' }
              >
                {props.buttonText}
              </Button>
            )}
          </Box>
        </Box>
      </Container>
    </Flex>
  );
};

export default Banner;
