/* eslint-disable @typescript-eslint/camelcase */
import TagManager from '../TagManager';

import { DataLayerProduct } from './types';

// IF WE WANT TO KNOW WHERE THE USER IS ADDING THE PRODUCT TO THE CART handleAddToCart()
const addToCart = (item: DataLayerProduct) => {
  TagManager.pushToDataLayer({
    event: 'add_to_cart',
    ecommerce: {
      currency: 'USD',
      value: item.price * item.quantity,
      items: [
        {
          item_name: item.name,
          item_id: item.id,
          price: item.price,
          quantity: item.quantity,
        },
      ],
    },
  });
};

export default addToCart;
