/* eslint-disable max-lines-per-function */
import React from 'react';
import { Container } from '@lce/slice_v2';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import * as FundraiserDefaults from 'constants/FundraiserDefaults';
import { UserLandingPage } from 'ui/userLanding';

const Chairperson: React.FC = () => {
  const { path } = useRouteMatch();

  return (
    <Container data-testid="chairperson">
      <Switch>
        <Route exact={ true } path={ path }>
          <UserLandingPage
            role={ FundraiserDefaults.ChairpersonRoleName.toLowerCase() }
          />
        </Route>
      </Switch>
    </Container>
  );
};

export default Chairperson;
