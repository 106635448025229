/* eslint-disable max-len */
/* eslint-disable react/forbid-component-props */
import React from 'react';
import { useTranslation } from '@lce/i18n';
import { Box, Flex, Text } from '@lce/slice_v2';
import {
  Copy, Icon, Star, Video, Youtube,
} from 'react-feather';

interface ITipSection {
  Icon: Icon;
  title: string;
  description: string;
}

/**
 * Since this popover never goes away and is always visable by the seller, it doesn't
 * use the Popover component and is just a UI component
 * */
const VideoTipPopover: React.FC = () => {
  const [ t ] = useTranslation();

  return (
    <Box
      sx={ {
        width: '100%',
        border: '1px solid',
        borderColor: 'primaryOrange',
        borderRadius: '4px',
        padding: '19px',
        mt: '30px',
        mb: '16px',
        position: 'relative',
        boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.25)',
      } }
    >
      <div className="video-tip-popover-arrow" />
      <Flex sx={ { alignItems: 'center' } }>
        <Box
          sx={ {
            marginRight: '24px',
            minWidth: '50px',
            height: '50px',
            borderRadius: '50%',
            textAlign: 'center',
            verticalAlign: 'middle',
            paddingTop: '14px',
            border: '2px solid',
            borderColor: 'primaryOrange',
          } }
        >
          <Star color="#FF671B" size={ 20 } />
        </Box>
        <Box sx={ { maxWidth: '216px' } }>
          <Text variant="text.header.subUpper">
            { t('editSeller.videoTip.Title') }
          </Text>
        </Box>
      </Flex>
      <Flex
        sx={ {
          mt: '20px', ml: '8px', mb: '10px', flexDirection: [ 'column', 'column', 'row' ],
        } }
      >
        <TipSection Icon={ Video } description={ t('editSeller.videoTip.tip1.Description') } title={ t('editSeller.videoTip.tip1.Title') } />
        <TipSection Icon={ Youtube } description={ t('editSeller.videoTip.tip2.Description') } title={ t('editSeller.videoTip.tip2.Title') } />
        <TipSection Icon={ Copy } description={ t('editSeller.videoTip.tip3.Description') } title={ t('editSeller.videoTip.tip3.Title') } />
      </Flex>
    </Box>
  );
};

const TipSection: React.FC<ITipSection> = ({ Icon, title, description }) => (
  <Flex sx={ { mt: [ '10px', '10px', 0 ] } }>
    <Icon color="#FF671B" size={ 30 } style={ { flexShrink: 0 } } />
    <Flex sx={ { flexDirection: 'column', ml: '15px', maxWidth: '216px' } }>
      <Text sx={ { fontSize: '16px' } } variant="text.header.subUpper">
        {title}
      </Text>
      <Text sx={ { fontSize: '14px', lineHeight: '14px', mt: '8px' } }>
        {description}
      </Text>
    </Flex>
  </Flex>
);

export default VideoTipPopover;
