import { useFeature } from '@growthbook/growthbook-react';
import { useQuery } from 'react-query';

import { HomepageDataType } from '../types';

import { ApiError } from 'features/common';

export const useFetchHomepageData = () => {
  const homepageDataUrl = useFeature('fr_homepage_data').value;
  return useQuery<HomepageDataType, ApiError>(homepageDataUrl, () => fetch(homepageDataUrl).then(res => res.json()), {
    retry: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });
};

