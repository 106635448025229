import React from 'react';
import { ThemeUIStyleObject } from 'theme-ui';
import { Flex } from '@lce/slice_v2';

import { Link } from 'ui/components/links/link';

export interface ComboLinkProps {
  primaryText: string;
  primaryHref: string;
  secondaryText: string;
  secondaryHref: string;
  primaryTestId?: string;
  secondaryTestId? : string;
  primarySx?: ThemeUIStyleObject;
  secondarySx?: ThemeUIStyleObject;
  primaryVariant?: string;
  secondaryVariant?: string;
  containerSx?: ThemeUIStyleObject;
}

export const primaryTestIdDefault = 'combo-link-primary';
export const secondaryTestIdDefault = 'combo-link-secondary';

export const ComboLink: React.FC<ComboLinkProps> = ({
  primaryText,
  primaryHref,
  secondaryText,
  secondaryHref,
  primaryTestId = primaryTestIdDefault,
  secondaryTestId = secondaryTestIdDefault,
  primarySx: primaryButtonSx = {},
  secondarySx: secondaryButtonSx = {},
  primaryVariant = 'button.primary.large',
  secondaryVariant = 'button.secondary.large',
  containerSx = {},
}) => (
  <Flex
    data-testid="combo-link-container"
    sx={ {
      flexDirection: 'column',
      alignItems: 'center',
      gap: '16px',
      ...containerSx,
    } }
  >
    <Link
      href={ primaryHref }
      sx={ primaryButtonSx }
      testId={ primaryTestId }
      variant={ primaryVariant }
    >
      {primaryText}
    </Link>
    <Link
      href={ secondaryHref }
      sx={ secondaryButtonSx }
      testId={ secondaryTestId }
      variant={ secondaryVariant }
    >
      {secondaryText}
    </Link>
  </Flex>
);
