import React from 'react';
import useClipboard from 'react-use-clipboard';
import { Flex, Image, Text } from '@lce/slice_v2';
import { useTranslation } from '@lce/i18n';
import { toast } from 'react-toastify';
import { ThemeUIStyleObject } from 'theme-ui';
import Input from '@lce/slice_v2/Elements/Form/Input';

import copyPaste from '../../../assets/icons/copypaste.svg';

import { GenericToast } from 'ui/common/Toast/CustomToasts';

export interface ICopyShareProps {
  title?: string;
  copyText: string;
  sx?: ThemeUIStyleObject;
  onCopy?: () => void;
  idSuffix?: string;
}

const CopyShare: React.FC<ICopyShareProps> = ({
  title, copyText, sx, onCopy, idSuffix,
}) => {
  const [ t ] = useTranslation();
  const [ , setCopied ] = useClipboard(copyText);

  const onCopyClick = () => {
    setCopied();
    toast(<GenericToast text={ t('common.Copied') } />, { position: 'top-center' });
    onCopy && onCopy();
  };

  return (
    <Flex
      data-testid="copyShare"
      sx={ { flexDirection: 'column', ...sx } }
    >
      {title && (
        <Text
          sx={ {
            textAlign: 'center', fontSize: '16px', mb: '24px', mr: 0,
          } }
          variant="header.sub"
        >
          {title}
        </Text>
      )}
      <Flex
        sx={ {
          flexDirection: 'row',
          alignItems: 'center',
        } }
      >
        <Input.Text
          disabled={ true }
          id="copyInput"
          name="copyInput"
          sx={ { mr: 2 } }
          value={ copyText }
        />
        <Image
          id={ `click-copy${ idSuffix }` }
          onClick={ onCopyClick } src={ copyPaste } sx={ { cursor: 'pointer' } }
        />
      </Flex>
    </Flex>
  );
};

export default CopyShare;
