export const formatCCNum = (ccNumber, onlyNumbers) => {
  const ccNum: number[] = [];
  const approvedChar = onlyNumbers ? /^[0-9]+$/ : /^[0-9- ]+$/;
  const valueSplit = ccNumber.split('');
  valueSplit.map((num) => {
    if (onlyNumbers && approvedChar.test(num)) {
      ccNum.push(num);
    } else if (!onlyNumbers && approvedChar.test(num)) {
      ccNum.push(num);
    }
    return true;
  });
  return ccNum.join('');
};
