import { useQuery } from 'react-query';

import { verifySalesAgent } from '../api';

import * as QueryKeys from 'constants/QueryKeys';

// eslint-disable-next-line max-len
const useVerifySalesAgent = (enabled: boolean, code: string) => useQuery(QueryKeys.VerifySalesAgent + code, () => verifySalesAgent(code),
  {
    enabled: enabled && !!code,
    refetchOnMount: false,
    refetchOnReconnect: false,
    retry: false,
    refetchOnWindowFocus: false,
  });

export { useVerifySalesAgent };
