/* eslint-disable max-lines-per-function */
import React from 'react';
import {
  Image,
  Flex,
  Box,
  Text,
} from '@lce/slice_v2';
import { useTranslation } from '@lce/i18n';

import AtSign from './at-sign 1.svg';
import PhoneIcon from './phone 1.svg';
import FaxIcon from './printer 1.svg';
import MailIcon from './mail 1.svg';

const ContactInfoList: React.FC = () => {
  const [ t ] = useTranslation();

  return (
    <Box mb="10px" p={ [ 0, 5, 0 ] }>
      <Text
        sx={ {
          fontSize: [ '24px', '24px', '34px' ], color: 'primaryOrange', mt: '16px', mb: '16px',
        } }
        variant="text.header.subUpper"
      >
        {t('contactUs.GetInTouch')}
      </Text>
      <Flex sx={ { mb: '44px' } }>
        <Image alt="LC @ Sign" src={ AtSign } title="LC @ Sign" />
        <Box sx={ { textAlign: 'left', ml: '32px' } }>
          <Text variant="text.header.sub">
            {t('contactUs.Email')}
          </Text>
          <Text color="primaryOrange" sx={ { fontSize: '14px' } }>
            fundraising@littlecaesars.com
          </Text>
        </Box>
      </Flex>
      <Flex sx={ { mb: '44px' } }>
        <Image alt="LC Phone Sign" src={ PhoneIcon } title="LC Phone Sign" />
        <Box sx={ { textAlign: 'left', ml: '32px' } }>
          <Text variant="text.header.sub">
            {t('contactUs.Phone')}
          </Text>
          <Text sx={ { fontSize: '14px' } }>
            888-4-LC-KITS (888-452-5487)
          </Text>
          <Text sx={ { fontSize: '14px' } }>
            Monday-Friday 8:30am to 6:00pm EST
          </Text>
        </Box>
      </Flex>
      <Flex sx={ { mb: '44px' } }>
        <Image alt="LC Fax Icon" src={ FaxIcon } title="LC Fax Icon" />
        <Box sx={ { textAlign: 'left', ml: '32px' } }>
          <Text variant="text.header.sub">
            {t('contactUs.Fax')}
          </Text>
          <Text sx={ { fontSize: '14px' } }>
            (844) 308-4008
          </Text>
        </Box>
      </Flex>
      <Flex>
        <Image alt="LC Mail Sign" src={ MailIcon } title="LC Mail Sign" />
        <Box sx={ { textAlign: 'left', ml: '32px' } }>
          <Text variant="text.header.sub">
            {t('contactUs.Mail')}
          </Text>
          <Text sx={ { fontSize: '14px' } }>
            Little Caesars Fundraising
          </Text>
          <Text sx={ { fontSize: '14px' } }>
            2211 Woodward Ave.
          </Text>
          <Text sx={ { fontSize: '14px' } }>
            Detroit, MI 48201-3400
          </Text>
        </Box>
      </Flex>
    </Box>
  );
};

export default ContactInfoList;
