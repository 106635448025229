import { SxStyleProp } from 'theme-ui';

import { FundraiserType } from 'features/fundraiser';

const productCardDigital = (isTextExpanded?: boolean): SxStyleProp => ({
  gridTemplateRows: isTextExpanded ? '1fr fit-content' : [ '1fr auto', '1fr 179px' ],
});

const productCardMealDeal = (isTextExpanded?: boolean): SxStyleProp => ({
  gridTemplateRows: isTextExpanded ? [ '1fr', '1fr fit-content' ] : [ '1fr', '1fr 179px' ],
});

export const productCard = (fundraiserType: FundraiserType, isTextExpanded?: boolean) => {
  switch (fundraiserType) {
    case FundraiserType.MealDeal:
      return productCardMealDeal(isTextExpanded);
    case FundraiserType.Digital:
    default:
      return productCardDigital(isTextExpanded);
  }
};
