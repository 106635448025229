import { useEffect, useState } from 'react';
import { useMutation } from 'react-query';

import { FundraiserPopover, HidePopoverRequest } from '../types';

import api from 'api';
import { ApiError, ApiResponse } from 'features/common';

const removePopoverHider =
  // eslint-disable-next-line max-len
  async(request: HidePopoverRequest) => api.post('api/popover/hide', request).then(res => res);

// eslint-disable-next-line arrow-body-style
const useHidePopover = () => {
  return useMutation<ApiResponse, ApiError, HidePopoverRequest>(removePopoverHider);
};

export type UseFundraiserPopoverProps = {
  fundraiserId: number;
  key: string;
  popovers: FundraiserPopover[];
  setPopovers: React.Dispatch<React.SetStateAction<FundraiserPopover[]>>;
};

const useFundraiserPopover = ({
  fundraiserId,
  key,
  popovers,
  setPopovers,
}: UseFundraiserPopoverProps): [ boolean, (request: HidePopoverRequest) => void, FundraiserPopover | undefined ] => {
  const { mutate: hide, isSuccess } = useHidePopover();
  const [ popover, setPopover ] = useState<FundraiserPopover | undefined>();
  const [ hidden, setHidden ] = useState<boolean>(true);

  useEffect(() => {
    if (isSuccess) {
      setHidden(true);
      setPopovers([
        ...popovers,
        {
          FundraiserId: fundraiserId,
          Key: key,
          Hidden: true,
        } as FundraiserPopover,
      ]);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ isSuccess ]);

  useEffect(() => {
    const match = popovers.find(x => x.FundraiserId === fundraiserId && x.Key === key);
    setPopover(match);
    // eslint-disable-next-line no-extra-parens
    if (!match || (match && !match.Hidden)) {
      setHidden(false);
    } else {
      setHidden(true);
    }
  }, [ popovers, fundraiserId, key ]);

  return [ hidden, hide, popover ];
};

export { useFundraiserPopover };
