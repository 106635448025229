/* eslint-disable max-lines-per-function */
import {
  Box, Flex, Heading, Text,
} from '@lce/slice_v2';
import React from 'react';
import { map as _map } from 'lodash';

import { EmailTemplateContent } from './components/EmailTemplateContent';
import { IEmail, ResourceHubEmailTemplate }
  from './components/ResourceHubEmailTemplate';

import { FundraiserListItem } from 'features/fundraiser';

export interface IResourceHubEmailSectionProps {
  emailSectionContent: {
    emailTemplates: IEmail[];
    heading: string;
    subHeading: string;
  };
  fundraiserRole: string;
  fundraiser: FundraiserListItem;
}

const ResourceHubEmailSection: React.FC<IResourceHubEmailSectionProps> =
  ({ emailSectionContent, fundraiserRole, fundraiser }) => {
    const { emailTemplates, heading, subHeading } = emailSectionContent;
    return (
      <Box
        data-testid="resourceHubEmailSection"
        sx={ { pb: '10px', px: '16px' } }
      >
        <Flex
          sx={ {
            flexDirection: 'column', alignItems: 'center', lineHeight: 3,
          } }
        >
          <Heading sx={ { fontSize: [ '30px', '40px' ] } }>
            {heading}
          </Heading>
          <Text sx={ { fontSize: [ '16px', '18px' ] } }>
            {subHeading}
          </Text>
        </Flex>
        {_map(emailTemplates, (emailText: IEmail, index) => (
          <ResourceHubEmailTemplate
            emailText={ emailText }
            fundraiser={ fundraiser }
            fundraiserRole={ fundraiserRole }
            key={ index }
          >
            <EmailTemplateContent body={ emailText.emailBody } subject={ emailText.emailSubject } />
          </ResourceHubEmailTemplate>
        ))}
      </Box>
    );
  };

export default ResourceHubEmailSection;
