/* eslint-disable max-len */
import buttons from './components/buttons';
import cards from './components/cards';
import colors from './components/colors';
import fonts from './components/fonts';
import links from './components/links';
import text from './components/text';
import forms from './components/forms';
import modals from './components/modals';
import images from './components/images';

export const theme = {
  cards,
  colors,
  buttons,
  fonts,
  links,
  text,
  forms,
  modals,
  images,
  breakpoints: [ '600px', '960px', '1280px' ],
  spinner: {
    lce: {
      color: 'primaryOrange',
    },
  },
  progress: {
    lce: {
      color: 'secondaryGreenLight',
      bg: 'primaryGrayLight',
      height: '24px',
    },
  },
  styles: {
    a: {
      color: 'black',
      ':hover': {
        color: 'primaryOrange',
      },
    },
  },
};

export const fontFamilies = `
  @import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
`;
