/* eslint-disable complexity */
/* eslint-disable max-lines-per-function */
import {
  Flex, Form, Button, Box, Text, Spinner,
} from '@lce/slice_v2';
import React from 'react';
import { useTranslation } from '@lce/i18n';
import _map from 'lodash/map';
import _every from 'lodash/every';
import _find from 'lodash/find';

import { IShippingDetails } from './types';
import { ProductShippingFields } from './ProductShippingFields';
import { ShippingDetailsLoader } from './ShippingDetailsLoader';
import { useShippingSchema } from './hooks';
import ShippingMethodLabel from './ShippingMethodLabel';

import { useFetchStates } from 'features/common';
import { useCheckoutState } from 'features/checkout';
import { FirstNameInput, PhoneInput } from 'ui/components/forms';
import { GoogleEvents } from 'features/analytics';
import { areAllFreeShippingItemsDonationItems } from 'features/fundraiser/utils';

const ShippingDetails: React.FC<IShippingDetails> = ({ setShippingDetails, shippingDetails, loading }) => {
  const [ t ] = useTranslation();
  const { checkout } = useCheckoutState();

  const { data: states, isLoading } = useFetchStates(1);

  const hasOnlyCouponItems = _every(checkout?.Cart, item => item.IsFreeShipping === true);
  const hasOnlyDonationItems = areAllFreeShippingItemsDonationItems(checkout?.Cart);
  const isCouponItemExist = _find(checkout?.Cart, item => item.IsFreeShipping === true)?.IsFreeShipping;

  const validationSchema = useShippingSchema(hasOnlyCouponItems);

  const handleOnSubmit = (e) => {
    setShippingDetails(e, hasOnlyCouponItems);
  };

  const gaAddShippingInfoOnSubmit = (subtotal, cart) => {
    GoogleEvents.addShippingInfo({
      cartTotal: subtotal,
      products: _map(cart, item => ({
        name: item.ProductName,
        price: item.UnitPrice,
        id: item.ProductId.toString(),
        quantity: item.Quantity,
      })),
    });
  };

  return (
    <Box data-testid="shipping-details-form">
      {!isLoading && checkout
        ? (
          <Form
            initialValues={ shippingDetails }
            onSubmit={ (data) => {
              gaAddShippingInfoOnSubmit(checkout.Subtotal, checkout.Cart);
              handleOnSubmit(data);
            } }
            reValidateMode="onSubmit"
            summary={ false }
            validationSchema={ validationSchema }
          >
            <Text variant="text.header.form">
              {t('checkout.ShippingAddress')}
            </Text>
            <Flex sx={ { flexDirection: 'column' } }>
              <Flex sx={ { flexDirection: [ 'column', 'row' ] } }>
                <FirstNameInput
                  label={ t('checkout.ShippingDetails.firstNameLabel') }
                  testId="shipping_firstName"
                  variant="forms.checkout.field"
                />

                <Form.Field
                  defaultValue={ hasOnlyCouponItems }
                  id="IsCouponItem"
                  label="IsCouponItem"
                  name="IsCouponItem"
                  sx={ { display: 'none' } }
                />

                <Form.Field
                  autoComplete="family-name"
                  data-testid="shipping_lastName"
                  id="LastName"
                  label={ t('checkout.ShippingDetails.lastNameLabel') }
                  name="LastName"
                  sx={ { marginLeft: [ 0, '12px' ] } }
                  variant="forms.checkout.field"
                />
              </Flex>

              { !hasOnlyCouponItems && <ProductShippingFields states={ states } />}

              <Text sx={ { mt: '30px' } } variant="text.header.form">
                {t('checkout.OrderDetailLabel')}
              </Text>
              <Flex sx={ { flexDirection: [ 'column', 'row' ] } }>
                <Form.Field
                  autoComplete="email"
                  data-testid="shipping_emailAddress"
                  id="EmailAddress"
                  label={ t('checkout.ShippingDetails.emailAddressLabel') }
                  name="Email"
                  onCopy={ (e) => {
                    e.preventDefault();
                    return false;
                  } } onPaste={ (e) => {
                    e.preventDefault();
                    return false;
                  } }
                  sx={ { mr: [ 0, '24px' ] } }
                  variant="forms.checkout.field"
                />
                <Form.Field
                  data-testid="shipping_confirmEmailAddress"
                  id="Confirm-Email"
                  label={ t('checkout.ShippingDetails.confirmEmailAddressLabel') }
                  name="ConfirmEmailAddress"
                  onCopy={ (e) => {
                    e.preventDefault();
                    return false;
                  } }
                  onPaste={ (e) => {
                    e.preventDefault();
                    return false;
                  } }
                  sx={ { mr: 0 } }
                  variant="forms.checkout.field"
                />
              </Flex>

              <PhoneInput
                defaultValue={ shippingDetails?.PhoneNumber }
                label={ t('checkout.ShippingDetails.phoneNumberLabel') }
                sx={ { width: [ '100%', '48%' ] } }
                testId="shipping_phoneNumber"
              />
              {!hasOnlyDonationItems && (
                <ShippingMethodLabel
                  hasOnlyCouponItems={ hasOnlyCouponItems }
                  isCouponItemExist={ isCouponItemExist }
                />
              )}
              <Button
                data-testid="shipping_button"
                disabled={ loading ? true : false }
                id="shippingNext"
                sx={ { mt: '33px' } }
                type="submit"
                variant={ loading ? 'disabled' : 'primary' }
              >
                { loading ? (
                  <Spinner sx={ { height: '100%' } } variant="lce" />
                ) : t('checkout.NextBillingLabel') }
              </Button>
            </Flex>
          </Form>
        ) : (
          <ShippingDetailsLoader />
        )}
    </Box>
  );
};

export default ShippingDetails;
