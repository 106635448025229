import { useTranslation } from '@lce/i18n';
import {
  Card, Flex, Image, Text, Button,
} from '@lce/slice_v2';
import React from 'react';
import { useHistory } from 'react-router-dom';

import CaesarBasket from 'assets/images/caesar-basket.svg';
import { useFetchProductsPageRoute } from 'features/products';

const EmptyCartCaesar = () => {
  const [ t ] = useTranslation();
  const history = useHistory();
  const productPageRoute = useFetchProductsPageRoute();

  return (
    <Flex
      as={ Card }
      sx={ {
        boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.25)',
        maxWidth: '700px',
        padding: [ '15px 15px', '41px 122px' ],
        flexDirection: 'column',
        alignItems: 'center',
      } }
    >
      <Text sx={ { mb: '11px' } } variant="text.header.subUpper">
        {t('cart.EmptyCartMessage')}
      </Text>
      <Text>
        {t('cart.SeeWhatsInStore')}
      </Text>
      <Button
        onClick={ () => history.push(productPageRoute) }
        sx={ { width: '100%', mt: '31px', mb: '31px' } }
        variant="primary"
      >
        {t('cart.StartShopping')}
      </Button>
      <Image alt="Little Caesar Shopping Cart" src={ CaesarBasket } title="Little Caesar Shopping Cart" />
    </Flex>
  );
};

export default EmptyCartCaesar;
