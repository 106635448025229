/* eslint-disable max-len */
import { useQuery } from 'react-query';

import { fetchSellerQRCode } from '../api';

import * as QueryKeys from 'constants/QueryKeys';
import { ApiError } from 'features/common';

const useFetchSellerQRCode =
  (sellerId: number | undefined) => useQuery<string, ApiError>([ `${ QueryKeys.FundraiserSellerQRCode } }` ],
    () => fetchSellerQRCode(sellerId),
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: !!sellerId,
    });

export { useFetchSellerQRCode };
