import { useMutation } from 'react-query';

import { uploadSellerPhoto } from '../api';

import { ApiError, ApiResponse } from 'features/common';

const useUploadSellerPhoto =
  () => useMutation<ApiResponse, ApiError, { id: number; data: FormData }>(uploadSellerPhoto);

export default useUploadSellerPhoto;
