/* eslint-disable max-len */
import React from 'react';
import { useTranslation } from '@lce/i18n';
import {
  Box, Link, Text,
} from '@lce/slice_v2';

import Invite from '../Invite';

import { IFundraiserSupporterProps } from 'features/fundraiser/types/dashboard';

export const CompleteYourProfile = () => {
  const [ t ] = useTranslation();
  return (
    <Text sx={ { mx: 3 } }>
      {t('dashboard.steps.seller.step1.content')}
    </Text>
  );
};

export const InviteFriendsAndFamily: React.FC<IFundraiserSupporterProps> = ({
  fundraiser, idSuffix, sellerId, sellerSupportGuid,
}) => {
  const [ t ] = useTranslation();
  return (
    <Invite
      customUpcomingText={ t('dashboard.steps.chairperson.step4.checkBack') } fundraiser={ fundraiser } idSuffix={ idSuffix } sellerId={ sellerId }
      sellerSupportGuid={ sellerSupportGuid }
    />
  );
};

export const PromoteYourFundraiser = ({ fundraiser, customerId }) => {
  const [ t ] = useTranslation();
  const resourceHubUrl = t('dashboard.steps.seller.promoteYourFundraiser.linkUrl',
    { fundraiserId: fundraiser.Id, customerId });

  return (
    <>
      <Text sx={ { mx: 3 } }>
        {t('dashboard.steps.seller.promoteYourFundraiser.content') }
      </Text>
      <Box
        sx={ {
          m: [ 2, 0 ], textAlign: 'right',
          position: [ 'relative', 'absolute' ], right: [ 'initial', '28px' ], bottom: [ 'initial', '24px' ],
        } }
      >
        <Link
          href={ resourceHubUrl }
          sx={ { color: 'primaryOrange', textDecoration: 'none', fontWeight: 'bold' } }
        >
          {t('dashboard.steps.seller.promoteYourFundraiser.linkText')}
        </Link>
      </Box>
    </>
  );
};
