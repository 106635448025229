import React, { useState } from 'react';
import { Flex, Box, Image } from '@lce/slice_v2';

import ReturningUser from './ReturningUser';
import NewGuest from './NewGuest';

import PizzaPizza from 'assets/images/pizza-pizza-kit.svg';
import { LoginForm } from 'ui/account';

export interface ILoginProps {
  onGuestCallback?: () => void;
}

const Login: React.FC<ILoginProps> = ({ onGuestCallback }) => {
  const [ showLogin, setShowLogin ] = useState<boolean>(false);

  const onLoginCallback = () => {
    setShowLogin(true);
  };

  return (
    <Flex
      data-testid="login-container"
      sx={ {
        mt: [ 0, '68px' ],
        flexDirection: [ 'column', 'row' ],
        justifyContent: 'center',
      } }
    >
      <Box sx={ { mr: '100px', display: [ 'none', 'none', 'block' ] } }>
        <Image alt="LC Fundraising Logo" src={ PizzaPizza } title="LC Fundraising" />
      </Box>
      { showLogin ? (
        <Flex sx={ { flexDirection: 'column', maxWidth: [ 'none', '300px' ] } }>
          <LoginForm onBackCallback={ () => setShowLogin(false) } redirect={ false } />
        </Flex>
      )
        : (
          <Flex sx={ { flexDirection: 'column', maxWidth: [ 'none', '300px' ] } }>
            <Box sx={ { mb: [ '30px', '55px' ] } }>
              <ReturningUser onLoginCallback={ onLoginCallback } />
            </Box>
            <Box>
              <NewGuest onGuestCallback={ onGuestCallback } />
            </Box>
          </Flex>
        )}
    </Flex>
  );
};

export default Login;
