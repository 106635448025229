import { useEffect, useMemo, useState } from 'react';
import { GrowthBook, GrowthBookProvider, InitOptions } from '@growthbook/growthbook-react';

import { isProhibitedEnvironment } from 'utils';

export type UseGrowthBook = {
  GrowthBookProvider: typeof GrowthBookProvider;
  growthbook: GrowthBook;
  growthbookReady: boolean;
};

export const useGrowthBook = (options: InitOptions = { streaming: false }): UseGrowthBook => {
  const [ growthbookReady, setGrowthbookReady ] = useState(false);
  const enableDevMode = !isProhibitedEnvironment();

  const growthbook = useMemo(() => new GrowthBook({
    apiHost: process.env.REACT_APP_GROWTHBOOK_API_HOST,
    clientKey: process.env.REACT_APP_GROWTHBOOK_SDK_KEY,
    enableDevMode,
    disableCache: true,
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }), [ ]);

  useEffect(() => {
    growthbook.init(options)
      .then(() => {
        setGrowthbookReady(true);
      })
      .catch(() => {
        setGrowthbookReady(false);
      });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { GrowthBookProvider, growthbook, growthbookReady };
};
