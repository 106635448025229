import React from 'react';
import { Box, Text } from '@lce/slice_v2';

import { IDetailsSummaryProps } from './types';

export const ShippingDetailsSummaryMD: React.FC<IDetailsSummaryProps> = ({
  details,
}) => (
  <Box>
    <Text>
      {details.FirstName}
      {' '}
      {details.LastName}
    </Text>
    <Text>
      {details.Email}
    </Text>
    <Text>
      {details.PhoneNumber}
    </Text>
  </Box>
);
