import React from 'react';
import { useTranslation } from '@lce/i18n';
import { Text, Box } from '@lce/slice_v2';
import numeral from 'numeral';
import { format } from 'date-fns';

import { FundraiserInfo } from 'features/fundraiser';

export interface ISimpleSignupSummaryProps {
  details: FundraiserInfo | undefined;
}

// eslint-disable-next-line max-lines-per-function
const SimpleSignupSummary: React.FC<ISimpleSignupSummaryProps> = ({ details }) => {
  const [ t ] = useTranslation();
  if (details?.StartDate.toString().length === 10) {
    details.StartDate = new Date(`${ details?.StartDate }T00:00:00`);
  }
  if (details?.EndDate.toString().length === 10) {
    details.EndDate = new Date(`${ details?.EndDate }T00:00:00`);
  }
  return (
    <Box>
      <Text variant="text.header.form">
        {t('fundraiser.Information')}
      </Text>
      <Text>
        {details?.OrgName}
      </Text>
      <Text>
        Start Date:
        {' '}
        { format(new Date(details?.StartDate || 0), 'MM/dd/yyyy')}
        {' '}
      </Text>
      <Text>
        End Date:
        {' '}
        { format(new Date(details?.EndDate || 0), 'MM/dd/yyyy')}
        {' '}
      </Text>
      <Text sx={ { mt: '30px' } } variant="text.header.form">
        {t('fundraiser.Goal')}
      </Text>
      <Text>
        Fundraiser Profit Goal:
        {' '}
        {' '}
        {numeral(details?.DollarGoal).format(t('currency.format'))}
      </Text>
    </Box>
  );
};

export default SimpleSignupSummary;
