/* eslint-disable react/no-danger */
/* eslint-disable max-len */
import React from 'react';
import { Container, Spinner } from '@lce/slice_v2';
import DOMPurify from 'dompurify';

import { useFetchTerms } from 'features/legal';

import '../legal.css';

/**
 * Currently relies on a "topic" in nopCommerce with system name "TermsOfService"
 */
const TermsOfService: React.FC = () => {
  const { data: terms, isSuccess, isLoading } = useFetchTerms();

  return (
    <>
      { isSuccess && (
        <Container>
          <div
            className="legal-section"
            dangerouslySetInnerHTML={ {
              __html: DOMPurify.sanitize(terms),
            } }
          />
        </Container>
      )}

      { isLoading && (
        <Container sx={ { textAlign: 'center' } }>
          <Spinner variant="lce" />
        </Container>
      )}
    </>
  );
};

export default TermsOfService;
