import React from 'react';
import {
  Flex, Card, Box, Image, Text,
} from '@lce/slice_v2';

import CheerleaderCaesar from 'assets/images/Cheerleader_Caesar.png';

export interface IAvatarProps {
  displayRole: string | undefined;
  name: string | undefined;
}

const Avatar: React.FC<IAvatarProps> = ({ name, displayRole }) => (
  <Flex as={ Card } sx={ { flexDirection: 'column' } } variant="cards.avatar">
    { name && (
      <>
        <Box sx={ { height: '100px', width: '100px' } }>
          <Image
            alt="Cheerleader Caesar" src={ CheerleaderCaesar }
            sx={ { borderRadius: '50%' } } title="Cheerleader Caesar"
          />
        </Box>
        <Text sx={ { fontWeight: 800, mt: '10px' } }>
          {name}
          {' '}
        </Text>
        <Text
          sx={ {
            mt: '5px', fontSize: '14px', fontWeight: 800, color: 'primaryGrayDark',
          } }
        >
          {displayRole}
        </Text>
      </>
    )}
  </Flex>
);

export default Avatar;
