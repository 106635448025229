/* eslint-disable max-lines-per-function */
import {
  Box, Flex, Text, Image,
} from '@lce/slice_v2';
import React from 'react';

import AccountIcon from 'assets/icons/LCF-Account-Icon.svg';

const AccountMenu: React.FC = ({ children }) => (
  <>
    <Flex
      data-testid="flex above image" sx={ {
        alignItems: 'center', width: '100%',
        ml: '46px',
        height: '50px',
        zIndex: 50,
      } }
    >
      <Image
        alt="Account Options Icon" src={ AccountIcon }
        sx={ {
          height: [ '25px', '25px', '30px' ], width: [ '25px', '25px', '30px' ],
        } }
      />
      <Text
        sx={ {
          fontWeight: [ 800, 800, 600 ],
          fontSize: [ '16px', '16px', null ],
          ml: '15px',
        } }
      >
        My Account
      </Text>
    </Flex>
    <Box sx={ { height: '400px', ml: '26px' } }>
      {children}
    </Box>
  </>
);

export default AccountMenu;
