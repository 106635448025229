import { useQuery } from 'react-query';

import { fetchEmailNotifications } from '../api';
import { EmailNotifications } from '../types/emailNotifications';

import * as QueryKeys from 'constants/QueryKeys';
import { ApiError } from 'features/common';

export const useFetchEmailNotifications =
  () => useQuery<EmailNotifications, ApiError>(QueryKeys.EmailNotifications,
    () => fetchEmailNotifications(),
    { retry: false, refetchOnWindowFocus: false });

