import { useMutation } from 'react-query';

import { NewSalesAgent } from '../types';
import { addSalesAgent } from '../api';

import { ApiError, ApiResponse } from 'features/common';

export const useAddSalesAgent =
  () => useMutation<ApiResponse, ApiError, NewSalesAgent>(addSalesAgent);

