import React from 'react';

export const TopArchClipPathSvg: React.FC = () => (
  <svg height="0" width="0">
    <defs>
      <clipPath clipPathUnits="objectBoundingBox" id="top-arch-clip">
        <path d="M 0,0.06 Q 0.5,-0.06 1,0.06 L 2,1 L 0,1 Z" />
      </clipPath>
    </defs>
  </svg>
);
