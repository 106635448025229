/* eslint-disable max-lines-per-function */
import * as React from 'react';
import {
  Box, Text, Image, Button,
} from '@lce/slice_v2';

import Plus from '../assets/plus.png';
import Minus from '../assets/minus.png';

export interface IQAndA {
  question: string;
  answer: string;
}

const QAndA: React.FC<IQAndA> = ({ question, answer }) => {
  const [ showAnswer, setShowAnswer ] = React.useState<boolean>(false);

  const handleClick = () => {
    setShowAnswer(!showAnswer);
  };

  return (
    <>
      <Box sx={ { mt: [ '12px', '16px' ], mb: [ '0', '10px' ], display: [ 'flex', 'block', 'block' ] } }>
        <Box
          aria-hidden="true" data-testid="hide-show" onClick={ handleClick } showAnswer={ showAnswer }
          sx={ { display: 'inline-block', flexShrink: '0', verticalAlign: showAnswer ? 'super' : 'initial' } }
        >
          {!showAnswer && (
            <Image
              alt="plus" src={ Plus } sx={ {
                width: '16px', height: '16px', mt: [ '14px', '10px' ], cursor: 'pointer',
                display: 'inline-block', transform: 'translate(0, 1px)',
              } }
            />
          )}
          {showAnswer && (
            <Image
              alt="minus" src={ Minus } sx={ {
                width: '16px', height: 'auto', mt: [ '20px', '10px' ], cursor: 'pointer', display: 'inline-block',
              } }
            />
          )}
        </Box>
        <Box sx={ { cursor: 'pointer', display: 'inline-block', maxWidth: [ '100%', '604px', '100%' ] } }>
          <Text
            as="h3"
            sx={ { maxWidth: '976px' } }
          >
            <Button
              aria-controls={ question }
              aria-expanded={ showAnswer }
              data-testid="showHide-btn"
              onClick={ handleClick }
              sx={ {
                fontSize: '20px', fontFamily: 'Roboto', cursor: 'pointer', lineHeight: '1.5',
                outline: 'none', paddingLeft: '12px', backgroundColor: 'white', textAlign: 'left',
                color: 'black', border: 'none', fontWeight: 'bold', height: 'auto',
                paddingBottom: '0', paddingTop: [ '8px', '0', '0' ],
              } }
              variant="none"
            >
              { question }
            </Button>
          </Text>
        </Box>
      </Box>
      {showAnswer && (
        <Box
          sx={ {
            display: 'block', paddingLeft: '30px', paddingRight: [ '10px', '0' ], mt: '2px', width: '100%',
          } }
        >
          <Text as="p" sx={ { fontSize: '18px', whiteSpace: 'break-spaces' } }>
            { answer }
          </Text>
        </Box>
      )}
    </>
  );
};

export default QAndA;
