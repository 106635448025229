/* eslint-disable max-len */
import { useQuery } from 'react-query';

import { fetchJoinFundraiserValidation } from '../api';
import { FundraiserSellerValidation } from '../types/fundraiser';

import * as QueryKeys from 'constants/QueryKeys';
import { ApiError } from 'features/common';

export const useFetchJoinFundraiserValidation =
  (enabled: boolean, fundraiserGuid: string) => useQuery<FundraiserSellerValidation, ApiError>(QueryKeys.ValidateJoinFundraiser + fundraiserGuid, () => fetchJoinFundraiserValidation(fundraiserGuid),
    {
      enabled: enabled && fundraiserGuid !== '',
      retry: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    });
