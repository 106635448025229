import React from 'react';
import {
  Box,
} from '@lce/slice_v2';

import HeaderImage from 'assets/images/banners/about-us-banner.png';
import { Banner } from 'ui/common';

const AboutUsHeader: React.FC = () => (
  <Box>
    <Banner
      backgroundSize="contain"
      gradient="none"
      image={ HeaderImage }
      showOnMobile={ false }
    />
  </Box>
);

export default AboutUsHeader;
