import React, { useMemo } from 'react';
import { Spinner, ISpinnerProps } from '@lce/slice_v2';

type Hook = {
  data: any;
  isLoading: boolean;
};

export function useConnectedFormField<T>(useHook: () => Hook,
  Component: React.FC<T>,
  props: T, dataPropKey = 'data'): React.ReactElement<T | ISpinnerProps> {
  const { data, isLoading } = useHook();

  const finalProps = useMemo(() => ({
    ...props,
    [dataPropKey]: data,
  }), [ dataPropKey, data, props ]);

  if (isLoading) {
    return (
      <Spinner
        data-testid="loading-spinner"
        sx={ { height: '100%' } }
        variant="lce"
      />
    );
  }

  return (
    <Component
      { ...finalProps }
    />
  );
}
