/* eslint-disable max-lines-per-function */
import React, {
  forwardRef, Ref, useEffect, useImperativeHandle, useState,
} from 'react';
import { Placement } from '@popperjs/core';
import { usePopper } from 'react-popper';
import { Box, Button } from '@lce/slice_v2';

import './popover.css';

export interface IPopoverProps {
  children: React.ReactNode;
  isOpen: boolean;
  onClose?: () => void;
  canHide?: boolean;
  placement?: Placement;
  referenceElement: HTMLElement | null;
  popperContainerClassName?: string;
}

export interface IPopoverRef {
  handleUpdate: () => void;
}

const Popover = forwardRef(({
  children,
  isOpen,
  onClose,
  canHide = true,
  placement = 'bottom',
  referenceElement,
  popperContainerClassName = 'popover-container',
}: IPopoverProps, ref: Ref<IPopoverRef>) => {
  const [ popperElement, setPopperElement ] = useState<HTMLElement | null>(null);
  const [ arrowElement, setArrowElement ] = useState<HTMLElement | null>(null);
  const [ hidden, setHidden ] = useState<boolean>(!isOpen);
  const {
    styles, attributes, update,
  } = usePopper(referenceElement, popperElement, {
    placement: placement || 'bottom',
    modifiers: [
      { name: 'arrow', options: { element: arrowElement } }, {
        name: 'offset',
        options: {
          offset: [ 0, 8 ],
        },
      },
    ],
  });

  const handleUpdate = () => {
    update && update();
  };

  useImperativeHandle(ref, () => ({ handleUpdate }));

  useEffect(() => {
    setHidden(!isOpen);
  }, [ isOpen ]);

  const dismiss = () => {
    onClose && onClose();
    setHidden(true);
  };

  return (
    <div
      className={ popperContainerClassName || 'popover-container' }
      ref={ setPopperElement }
      style={ styles.popper } { ...attributes.popper }
    >
      { !hidden && (
        <>
          <Box
            sx={ {
              padding: '16px',
              paddingRight: '80px',
              background: 'white',
              boxShadow: '0 0 5px 0 rgba(0, 0, 0, 0.25)',
              border: 'solid 1px',
              borderColor: 'primaryOrange',
              borderRadius: '4px',
              position: 'relative',
              m: [ '0 5px', 0 ],
            } }
          >
            {children}
            { canHide && (
              <Button
                onClick={ () => dismiss() }
                sx={ {
                  position: 'absolute',
                  bottom: '12px',
                  right: '12px',
                } }
                variant="popover"
              >
                Hide
              </Button>
            )}
          </Box>
          <div
            className="popper__arrow"
            data-popper-arrow={ true }
            ref={ setArrowElement }
            style={ {
              ...styles.arrow,
              background: 'white',
            } }
          />
        </>
      )}
    </div>
  );
});

export { Popover };
