import { Box, Heading, Text } from '@lce/slice_v2';
import React from 'react';

const RequestInfoThankYouData: React.FC<{}> = () => (
  <Box sx={ { margin: 'auto', maxWidth: '700px' } }>
    <Heading
      sx={ {
        fontWeight: 700,
        fontSize: '36px',
        margin: 'auto',
        color: 'primaryOrange',
        mb: '30px',
        textTransform: 'uppercase',
      } }
    >
      LOOKING FOR MORE INFO?
    </Heading>
    <Text>
      <strong>
        Thank you for your request. Your information has been sent.
        One of our friendly Account Representatives will contact you shortly.
      </strong>
    </Text>
  </Box>
);

export default RequestInfoThankYouData;
