/* eslint-disable max-lines-per-function */
import React from 'react';
import {
  Box, Link, Text, Image, Flex,
} from '@lce/slice_v2';
import { useTranslation } from '@lce/i18n';
import { RouteComponentProps } from 'react-router-dom';

import checkMarkIcon from 'assets/icons/CloseFundraiser.svg';
import { FundraiserQRCode } from 'ui/fundraiser/FundraiserQRCode';
import { useFetchFundraiserById } from 'features/fundraiser/hooks/useFetchFundraiserById';
import { useFetchSellerBySupportGuid } from 'features/fundraiser/hooks/useFetchSellerBySupportGuid';
import { useFetchSellerQRCode } from 'features/fundraiser/hooks/useFetchSellerQRCode';
import { CopyShare, EmailLinks, SocialButtons } from 'ui/fundraiser';
import { useFetchProductsPageRoute } from 'features/products';
import { useFundraiserState } from 'features/fundraiser/context/fundraiserContext';

export interface MatchParams {
  supportGuid: string;
}

export type IMealDealSuccessPageProps = RouteComponentProps<MatchParams>

export const MealDealSuccessPage: React.FC<IMealDealSuccessPageProps> = ({ match }) => {
  const [ t ] = useTranslation();

  const { data: seller } = useFetchSellerBySupportGuid();
  const { data: fundraiser } = useFetchFundraiserById(seller?.FundraiserId || 0);
  const { data: qrCode, isSuccess } = useFetchSellerQRCode(seller?.Id);

  const productPageRoute = useFetchProductsPageRoute();

  const { fundraiserType } = useFundraiserState();

  const emailSubject = encodeURIComponent(`${ t('emailTemplate.seller.emailMessage.subject',
    { context: fundraiserType }) }`);
  const emailBody = encodeURIComponent(`${ t('emailTemplate.seller.emailMessage.body',
    {
      context: fundraiserType,
      supportGuid: `${ match.params.supportGuid }`,
      groupName: fundraiser?.Name || '[INSERT YOUR GROUP NAME, SCHOOL, CHURCH, ETC]',
    }) }`);

  return (
    <Box data-testid="md-success-page" sx={ { pt: [ 0, '30px', '30px', 0 ] } }>
      <Flex
        sx={ {
          width: '100%',
          justifyContent: 'center',
          flexDirection: 'column',
          textAlign: 'center',
        } }
      >
        <Image
          src={ checkMarkIcon }
          sx={ {
            objectFit: 'contain',
            height: [ '100px', '150px' ],
            width: '100%',
            mx: 'auto',
            mb: 4,
          } }
        />
        <Text
          sx={ {
            color: 'primaryOrange',
            fontFamily: 'mrEaves',
            fontSize: [ '32px', '50px' ],
            fontWeight: 'bold',
            whiteSpace: 'pre-line',
            lineHeight: '0.9',
            mb: 4,
          } }
        >
          {t('mealDeal.success.title')}
        </Text>
        <Text
          sx={ {
            fontSize: [ '20px', '25px' ],
            maxWidth: [ '550px', '650px' ],
            whiteSpace: 'pre-line',
            mx: 'auto',
            fontWeight: '500',
            mb: 8,
          } }
        >
          {t('mealDeal.success.subtitle')}
        </Text>
      </Flex>
      <Box
        sx={ {
          display: 'grid',
          gridTemplateColumns: [ '1fr', '1fr 1fr' ],
          gap: 4,
          mb: 4,
        } }
      >
        <Box sx={ { height: 'fit-content' } }>
          <Flex
            sx={ {
              flexDirection: 'column',
              alignItems: 'center',
              textAlign: 'center',
              gap: 4,
              mb: 4,
              borderRadius: '4px',
              borderColor: 'primaryOrange',
              boxShadow: 'none',
            } }
            variant="cards.success"
          >
            <Text sx={ { lineHeight: 1, fontSize: '20px' } }>
              {t('mealDeal.success.socialMediaTitle')}
            </Text>
            <SocialButtons
              fundraiserImage={ process.env.REACT_APP_OG_IMAGE || '' }
              fundraiserName={ seller?.FundraiserName || 'my fundraiser' }
              link={ `${ window.location.origin }${ productPageRoute }` }
            />
          </Flex>
          <CopyShare copyText={ `${ window.location.origin }${ productPageRoute }` } />
          <EmailLinks
            body={ emailBody }
            largeFont={ true }
            subject={ emailSubject }
            sx={ { mt: 2, justifyContent: 'center' } }
            title={ t('mealDeal.success.emailTitle') }
          />
        </Box>
        <Flex
          sx={ {
            width: '100%',
            justifyContent: 'center',
            flexDirection: 'column',
            textAlign: 'center',
          } }
        >
          {isSuccess && (
            <>
              <Text sx={ { fontSize: '20px', fontWeight: 'bold' } }>
                {t('mealDeal.success.qrCodeTitle')}
              </Text>
              <Text sx={ { fontSize: '18px' } }>
                {t('mealDeal.success.qrCodeSubtitle')}
              </Text>
              <Flex
                sx={ {
                  width: '100%', justifyContent: 'center', ml: [ 0, 0, '10px' ], mb: '4px',
                } }
              >
                <FundraiserQRCode
                  imageAltText={ t('emailTemplate.seller.profileSection.SellerQRCodeAltText') }
                  imageDataURL={ `data:image/png;base64,${ qrCode }` }
                  sellerQRCode={ true }
                />
              </Flex>
            </>
          )}
          <Link
            href="/dashboard/fundraisers"
            sx={ { mx: 'auto' } }
            variant="links.primaryButtonLink"
          >
            {t('mealDeal.success.viewYourDashboard')}
          </Link>
        </Flex>
      </Box>
    </Box>
  );
};
