import React from 'react';
import { useTranslation } from '@lce/i18n';
import { Text, Box } from '@lce/slice_v2';

import { ChairpersonInfo } from 'features/fundraiser';

export interface IChairpersonDetailsSummaryProps {
  details: ChairpersonInfo | undefined;
  hasParentGroupId?: boolean;
}

// eslint-disable-next-line max-lines-per-function
const ChairpersonDetailsSummary: React.FC<IChairpersonDetailsSummaryProps> = ({ details, hasParentGroupId }) => {
  const [ t ] = useTranslation();

  return (
    <Box>
      <Text variant="text.header.form">
        {hasParentGroupId ? t('fundraiser.SimpleAboutChairperson') : t('fundraiser.PrimaryChairperson')}
      </Text>
      <Text data-testid="chairperson-name">
        {details?.FirstName}
        {' '}
        {details?.LastName}
      </Text>
      <Text>
        {details?.PhoneNumber}
      </Text>
      <Text>
        {details?.EmailAddress}
      </Text>

      { details?.CoEmailAddress && (
        <>
          <Text variant="text.header.form">
            {t('fundraiser.CoChairperson')}
          </Text>
          <Text>
            { details?.CoFirstName}
            {' '}
            {details.CoLastName}
          </Text>
          <Text>
            { details?.CoEmailAddress}
          </Text>
        </>
      )}

    </Box>
  );
};

export default ChairpersonDetailsSummary;
