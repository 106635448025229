import React from 'react';
import {
  Box, Flex, Text,
} from '@lce/slice_v2';
import { RouteProps } from 'react-router-dom';
import { useTranslation } from '@lce/i18n';

import {
  AboutUsContent, AboutUsHeader,
} from 'ui/aboutUs';

type ContactUsPageProps = RouteProps;

const AboutUs: React.FC<ContactUsPageProps> = () => {
  const [ t ] = useTranslation();
  return (
    <>
      <Box>
        <AboutUsHeader />
        <AboutUsContent />
      </Box>
      <Flex
        sx={ {
          alignItems: 'center', maxWidth: '1122px', m: '0 auto',
          p: [ '0 44px', '0 44px', '0 44px', '0' ], mt: '16px',
          mb: [ '40px', 0 ],
        } }
      >
        <Text
          sx={ {
            alignSelf: 'flex-start', fontSize: '14px', fontFamily: 'roboto',
          } }
        >
          {t('common.BundleDisclaimer')}
        </Text>
      </Flex>
    </>
  );
};

export default AboutUs;
