import { Link } from '@lce/slice_v2';
import React from 'react';

interface IPhoneNumberLinkWrapper {
  text: string;
}
export const PhoneNumberLinkWrapper: React.FC<IPhoneNumberLinkWrapper> = ({ text }) => {
  const dashedPhonePattern = new RegExp('(?:[-]*[0-9]){10,14}', 'g');
  const phoneNumber = text.match(dashedPhonePattern)?.[0];
  const formattedPhoneNumber = phoneNumber?.replace(/-/g, '‑');
  const parts = text.split(dashedPhonePattern);
  return (
    <>
      {parts[0]}
      {phoneNumber && (
        <Link data-testid="phone-number-link" href={ `tel:${ phoneNumber }` } sx={ { color: 'primaryOrange' } }>
          {formattedPhoneNumber}
        </Link>
      )}
      {parts[1]}
    </>
  );
};
export default PhoneNumberLinkWrapper;
