import {
  Box,
  Flex, Modal as SliceModal, Text,
} from '@lce/slice_v2';
import { useTranslation } from '@lce/i18n';
import React from 'react';
import { XSquare } from 'react-feather';

export interface IFundraiserOptionsModalProps {
  onClose: () => void;
  fundraiserId: number;
  fundraiserName: string;
  setIsCancelFundraiser: () => void;
  canCancel: boolean;
}

// eslint-disable-next-line max-lines-per-function
const FundraiserOptionsModal: React.FC<IFundraiserOptionsModalProps> = ({
  onClose,
  fundraiserId,
  setIsCancelFundraiser,
  canCancel,
  fundraiserName,
}) => {
  const [ t ] = useTranslation();

  return (
    <SliceModal
      closeIcon={ false }
      closeOnClickOutside={ true }
      closeOnEsc={ true }
      onClose={ onClose }
      sx={ {
        bottom: 0,
        position: 'fixed',
        width: '100%',
        maxWidth: '100%',
      } }
    >
      <Box sx={ { padding: '16px', textAlign: 'center' } }>
        <Text variant="fundraiserOptions.header">
          { fundraiserName }
        </Text>

        <Text variant="fundraiserOptions.subHeader">
          Fundraiser ID:
          {' '}
          {fundraiserId}
        </Text>
      </Box>
      { canCancel && (
        <Flex
          data-testid={ `cancel-${ fundraiserName }` }
          onClick={ setIsCancelFundraiser }
          sx={ {
            padding: '24px',
            cursor: 'pointer',
            '&:hover': {
              backgroundColor: '#eee',
            },
          } }
        >
          <XSquare color="#868686" />

          <Text variant="fundraiserOptions.label">
            { t('fundraiser.CancelFundraiser')}
          </Text>
        </Flex>
      )}
    </SliceModal>
  );
};

export default FundraiserOptionsModal;
