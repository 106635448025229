export const checkoutReducer = (state, action) => {
  switch (action.type) {
    case 'SET_CHECKOUT':
      return { ...state, checkout: action.checkout };
    case 'UNSET_CHECKOUT':
      return { checkout: undefined };
    case 'SET_SHIPPING_ADDRESS':
      return { ...state, shippingAddress: action.address };
    case 'SET_BILLING_ADDRESS':
      return { ...state, billingAddress: action.address };
    case 'SET_LOADING':
      return { ...state, loading: true };
    case 'UNSET_LOADING':
      return { ...state, loading: false };
    case 'SET_ERROR_CODE':
      return { ...state, errorCode: action.errorCode };
    case 'UNSET_ERROR_CODE':
      return { ...state, errorCode: '' };
    case 'SET_ORDER_STATUS':
      return {
        ...state, orderStatus: action.status, orderId: action.orderId, orderGuid: action.orderGuid,
      };
    case 'SET_PAYMENT_STATUS':
      return { ...state, paymentStatus: action.status };
    case 'SET_GIFT_MESSAGING':
      return { ...state, giftMessageRecipient: action.giftMessageRecipient, giftMessage: action.giftMessage };
    default:
      return state;
  }
};
