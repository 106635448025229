import React from 'react';
import { useTranslation } from '@lce/i18n';
import { map as _map } from 'lodash';

import { Graph } from './Graph';

import { Stat } from 'features/fundraiser/types/stats';
import { formatGraphStats } from 'features/fundraiser/utils/FormatGraphStats';

export interface IGraphs {
  graphStats: Stat[];
  isDashboard: boolean;
}

const Graphs: React.FC<IGraphs> = ({ graphStats, isDashboard }) => {
  const [ t ] = useTranslation();
  const graphs = formatGraphStats(graphStats, isDashboard);
  return (
    <>
      {_map(graphs, graph => (
        <Graph isDashboard={ isDashboard } key={ graph.title } title={ t(graph.title) } value={ graph.value } />
      ))}
    </>
  );
};
export default Graphs;
