/* eslint-disable max-lines-per-function */
import {
  Flex, Text, Image,
} from '@lce/slice_v2';
import React from 'react';
import { useTranslation } from '@lce/i18n';

import { BracketTextWrapper } from '../../ResourceHubEmailSection/components/BracketTextWrapper';
import { CopyTextButton } from '../../components';
import { getTemplateImage, htmlDecode } from '../../utils';

import { SocialButtons } from 'ui/fundraiser/SocialButtons';
import { FundraiserListItem } from 'features/fundraiser';
import { useFetchProductsPageRoute } from 'features/products';

export interface IResourceHubSocialTemplateProps {
  socialInfo: ISocial;
  fundraiser: FundraiserListItem;
  sellerSupportGuid?: string;
}

export interface ISocial {
  image?: string;
  body?: string;
}

export const ResourceHubSocialTemplate: React.FC<IResourceHubSocialTemplateProps> =
({ socialInfo, fundraiser, sellerSupportGuid }) => {
  const [ t ] = useTranslation();
  const imageUse = getTemplateImage(socialInfo.image || '');
  const socialImage = fundraiser.FundraiserImageSource || process.env.REACT_APP_OG_IMAGE || '';
  const productPageRoute = useFetchProductsPageRoute(sellerSupportGuid, fundraiser.FundraiserType);
  const sellerLink = `${ window.location.origin }${ productPageRoute }`;
  const decodedBodyText = htmlDecode(socialInfo.body);
  const formattedCopyText = decodedBodyText.replace(/\[|\]/g, '');
  return (
    <Flex
      data-testid="resourceHubSocialTemplate"
      sx={ {
        p: [ '11px 9px 24px 11px', '16px 14px 30px 16px' ],
        boxShadow: '0 0 4px 1px rgba(0, 0, 0, 0.15)',
        borderRadius: '4px',
        maxWidth: '400px',
        mb: [ '20px', '12px' ],
        height: [ 'auto', 'auto', '650px' ],
        flexDirection: 'column',
        justifyContent: 'space-between',
        width: '100%',
      } }
    >
      <Flex sx={ { flexDirection: 'column', alignItems: 'center' } }>
        <Image
          alt={ t(`ResourceHub.${ fundraiser.FundraiserType }.imageAltTexts.${ imageUse.alt }`) }
          src={ imageUse.src }
          sx={ {
            maxHeight: [ '185px', '275px' ],
            width: 'auto',
            mb: [ '13px', '28px' ],
          } }
        />
        <Text sx={ { fontStyle: 'italic', wordWrap: 'break-word', width: '100%' } }>
          <BracketTextWrapper sx={ { color: 'primaryOrange' } } text={ decodedBodyText } />
        </Text>
      </Flex>
      <Flex
        sx={ {
          flexDirection: [ 'column', 'row' ],
          justifyContent: 'space-between',
          gap: 4,
          mt: 4,
        } }
      >
        <Flex
          sx={ {
            alignItems: 'center',
            gap: 4,
            flexWrap: 'wrap',
          } }
        >
          <Text
            sx={ {
              fontFamily: 'lato',
              fontSize: '12px',
            } }
          >
            {t(`ResourceHub.${ fundraiser.FundraiserType }.socialMessage`)}
          </Text>
          <SocialButtons fundraiserImage={ socialImage } fundraiserName={ fundraiser.Name } link={ sellerLink } />
        </Flex>
        <CopyTextButton
          buttonText={ t(`ResourceHub.${ fundraiser.FundraiserType }.buttonText`) }
          copyText={ formattedCopyText }
          sx={ {
            width: 'fit-content',
            fontFamily: 'lato',
            fontSize: '14px',
            px: [ 0, 0, '20px' ],
            flexWrap: 'nowrap',
            whiteSpace: 'nowrap',
          } }
        />
      </Flex>
    </Flex>
  );
};
