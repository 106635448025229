import React from 'react';
import { RouteComponentProps, Redirect } from 'react-router-dom';
import {
  Container, Heading, Spinner, Text,
} from '@lce/slice_v2';

import { ProductDetail } from 'ui/products';
import { FundraiserTracker } from 'ui/fundraiser';
import { useCartState } from 'features/cart';
import { useFetchProduct } from 'features/products';

interface MatchParams {
  sku: string;
  supportGuid: string;
}

export type IMealDealProductDetailPageProps = RouteComponentProps<MatchParams>

const MealDealProductDetailPage: React.FC<IMealDealProductDetailPageProps> = ({ match }) => {
  const cartState = useCartState();
  const { data: product, isLoading, error } = useFetchProduct(match.params.sku);

  // error status - 404
  if (error && error.Status === 404) {
    return <Redirect to="/404" />;
  }

  // loading state
  if (isLoading && !error) {
    return (
      <Container sx={ { textAlign: 'center' } }>
        <Spinner variant="lce" />
      </Container>
    );
  }

  if (error || !product) {
    return (
      <Container>
        <Heading>Uh oh</Heading>
        <Text variant="text.header.sub">There was an issue getting product information</Text>
      </Container>
    );
  }

  return (
    <div data-testid="meal-deal-product-detail-page">
      <FundraiserTracker />
      <ProductDetail canStartShopping={ cartState.canStartShopping } product={ product } />
    </div>
  );
};

export default MealDealProductDetailPage;
