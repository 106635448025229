import React from 'react';
import { useOktaAuth } from '@okta/okta-react';

import { Desktop } from './Desktop';
import { Mobile } from './Mobile';

import { useUserInfo } from 'features/common/hooks/useUserInfo';
import { useMediaQuery } from 'features/common';

const joinFundPage = window.location.href.includes('join-fundraiser');
export const MOBILE_HEADER_HEIGHT = joinFundPage ? '92px' : '135px';
export const DESKTOP_HEADER_HEIGHT = '86px';

const Header: React.FC<{}> = () => {
  const { authState } = useOktaAuth();
  const { data: user } = useUserInfo(authState.isAuthenticated);
  const isLarge = useMediaQuery('(min-width: 1280px)');

  return (
    <header>
      { isLarge
        ? (
          <Desktop
            isAuthenticated={ authState.isAuthenticated }
            roles={ user?.Roles || [] }
          />
        )
        : (
          <Mobile
            isAuthenticated={ authState.isAuthenticated }
            joinFundPage={ joinFundPage }
            roles={ user?.Roles || [] }
          />
        )}
    </header>
  );
};

export default Header;
