import React, { useMemo } from 'react';
import { RouteComponentProps, Redirect, useLocation } from 'react-router-dom';

interface MatchParams {
  supportGuid: string;
}

type FundraiserSupportPageProps = RouteComponentProps<MatchParams>;

const FundraiserSupport: React.FC<FundraiserSupportPageProps> = ({ match }) => {
  const { search } = useLocation();

  const queryParams = useMemo(() => {
    const searchParams = new URLSearchParams(search);
    return searchParams.toString();
  }, [ search ]);

  return (
    <Redirect to={ `/products?support=${ match.params.supportGuid }&${ queryParams }` } />
  );
};

export default FundraiserSupport;
