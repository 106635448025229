import React, { useCallback } from 'react';
import {
  Box, Flex, Image,
} from '@lce/slice_v2';
import { useHistory, useLocation } from 'react-router-dom';

import NewGuest from 'ui/checkout/Login/NewGuest';
import ReturningUser from 'ui/checkout/Login/ReturningUser';
import PizzaPizza from 'assets/images/jumping-caesar.png';

export interface GuestLoginProps {
  onGuestCallback?: () => void;
  returnUrl?: string;
  testId?: string;
}

export const defaultTestId = 'meal-deal-guest-login';

export const GuestLogin: React.FC<GuestLoginProps> = ({ onGuestCallback, testId = defaultTestId }) => {
  const { pathname } = useLocation();
  const history = useHistory();

  const onLoginCallback = useCallback(() => {
    history.push(`/start-a-fundraiser/meal-deal/signin?redirectUrl=${ pathname }`);
  }, [ history, pathname ]);

  return (
    <Flex
      data-testid={ testId }
      sx={ {
        mt: [ 0, '68px' ],
        flexDirection: [ 'column', 'row' ],
        justifyContent: 'center',
      } }
    >
      <Box sx={ { mr: '100px', display: [ 'none', 'none', 'block' ] } }>
        <Image alt="LC Fundraising Logo" src={ PizzaPizza } title="LC Fundraising" />
      </Box>
      <Flex sx={ { flexDirection: 'column', maxWidth: [ 'none', '300px' ] } }>
        <Box sx={ { mb: [ '30px', '55px' ] } }>
          <ReturningUser onLoginCallback={ onLoginCallback } />
        </Box>
        <Box>
          <NewGuest onGuestCallback={ onGuestCallback } />
        </Box>
      </Flex>
    </Flex>
  );
};
