import { useQuery } from 'react-query';

import * as QueryKeys from '../../../../constants/QueryKeys';

import { FundraiserChaipersonRegistration } from 'features/account/types/response/FundraiserChaipersonRegistration';
import { fetchChairperonInvite } from 'features/account/api';
import { ApiError } from 'features/common';

// eslint-disable-next-line max-len
export const useFetchFundraiserInvite = (inviteId: string) => useQuery<FundraiserChaipersonRegistration, ApiError>(`${ QueryKeys.FundraiserInvite }${ inviteId }`,
  () => fetchChairperonInvite(inviteId),
  { retry: false, refetchOnWindowFocus: false, enabled: !!inviteId });
