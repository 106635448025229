import React, { useRef, useState } from 'react';
import { Box, Flex, Image } from '@lce/slice_v2';
import { useTranslation } from '@lce/i18n';

import infoIcon from '../../../assets/icons/info-icon.svg';
import { TooltipPopover } from '../Popovers';

import {
  BuyerInvitePopup, FriendFamilyPopUp, ManageSellerPopup, SellerInvitePopup,
} from './StepPopupContent';

import { IDashboardProps, PopoverType } from 'features/fundraiser/types/dashboard';

// eslint-disable-next-line max-lines-per-function
const DashboardStep: React.FC<IDashboardProps> = ({
  children, stepNumber, title, hasInfo, popupType, borderStyle = 'solid 1px #ddd',
}) => {
  const [ t ] = useTranslation();
  const [ visible, setVisible ] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const popoverRef = useRef<any>(null);

  return (
    <Box
      data-testid="dashboard-step"
      sx={ {
        position: 'relative', width: '100%', maxWidth: 480,
        minHeight: '190px',
        border: borderStyle, borderRadius: '4px', boxShadow: '0 0 4px 1px rgba(0, 0, 0, 0.15)', p: 2, mb: 4,
      } }
    >
      <Flex sx={ { flexDirection: [ 'column', 'row' ], mb: [ 4, 6 ] } }>
        <Flex
          sx={ {
            color: 'white', fontWeight: 'bold', textTransform: 'uppercase',
            backgroundColor: 'primaryOrange', borderRadius: '4px',
            height: '32px', px: 4, mb: [ 2, 0 ], width: 'fit-content',
            alignItems: 'center', justifyContent: 'center', whiteSpace: 'nowrap',
            minWidth: '85px',
          } }
        >
          {`${ t('dashboard.steps.step') } ${ stepNumber }`}
        </Flex>
        <Box
          sx={ {
            fontWeight: 'bold', fontSize: '18px', lineHeight: 1.2, mt: 1, ml: 3, maxWidth: '344px',
          } }
        >
          {title}
          {hasInfo && (
            <Image
              onClick={ () => setVisible(!visible) }
              ref={ popoverRef }
              src={ infoIcon }
              sx={ {
                cursor: 'pointer', ml: 2, mb: -1, height: '20px', width: '20px',
              } }
            />
          )}
          <TooltipPopover referenceElement={ popoverRef } setVisible={ setVisible } visible={ visible }>
            {popupType === PopoverType.Seller && <SellerInvitePopup />}
            {popupType === PopoverType.Buyer && <BuyerInvitePopup />}
            {popupType === PopoverType.Manage && <ManageSellerPopup />}
            {popupType === PopoverType.FriendFamily && <FriendFamilyPopUp />}
          </TooltipPopover>
        </Box>
      </Flex>
      <Box sx={ { lineHeight: 1.2 } }>
        {children}
      </Box>
    </Box>
  );
};

export default DashboardStep;
