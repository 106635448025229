import React, { useEffect } from 'react';
import Fingerprint2 from '@fingerprintjs/fingerprintjs';

export interface IFingerprintProviderProps {
  children: React.ReactNode;
}

const FingerprintProvider: React.FC<IFingerprintProviderProps> = ({ children }) => {
  useEffect(() => {
    if (!window.localStorage['@lce/local/uuid']) {
      Fingerprint2.get((components) => {
        const values = components.map(component => component.value);
        const hash = Fingerprint2.x64hash128(values.join(''), 31);
        // tslint:disable-next-line: strict-type-predicates
        if (typeof window !== 'undefined' && window.localStorage) {
          window.localStorage.setItem('@lce/local/uuid', hash);
        }
      });
    }
  }, []);
  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      { children }
    </>
  );
};

export default FingerprintProvider;
