import { useQuery } from 'react-query';

import { Group } from '../types';
import { fetchGroups } from '../api';

import * as QueryKeys from 'constants/QueryKeys';
import { ApiError } from 'features/common';

export const useFetchUserGroups = () => useQuery<Group[], ApiError>(`${ QueryKeys.UserFundraisingGroups }`,
  () => fetchGroups(),
  {
    retry: false,
    refetchOnWindowFocus: false,
  });
