/* eslint-disable max-len */
import React, { useMemo, useState } from 'react';
import {
  Box, Button, Container, Flex, Heading, Spinner,
} from '@lce/slice_v2';
import {
  Redirect,
  RouteComponentProps,
} from 'react-router-dom';
import { useTranslation } from '@lce/i18n';
import { useOktaAuth } from '@okta/okta-react';
import { toast } from 'react-toastify';

import {
  FundraiserInfo, ChairpersonInfo, Fundraiser, useFetchFundraiserById, FundraiserRelationship,
} from 'features/fundraiser';
import { useUserInfo } from 'features/common';
import ChairpersonDetails from 'ui/fundraiser/NewFundraiserForm/ChairpersonDetails/ChairpersonDetails';
import { FundraiserDetails } from 'ui/fundraiser/NewFundraiserForm/FundraiserDetails';
import { useFetchFundraiserFormData } from 'features/fundraiser/hooks/useFetchFundraiser';
import { useUpdateFundraiserFormData } from 'features/fundraiser/hooks/useUpdateFundraiserFormData';
import { GenericToast } from 'ui/common';
import { FundraiserNavigation } from 'ui/fundraiser/FundraiserNavigation';
import { FundraiserProfitCheckAddress } from 'features/fundraiser/types/fundraiser';

interface MatchParams {
  fundraiserId: string;
}

type FundraiserEditPageProps = RouteComponentProps<MatchParams>

// eslint-disable-next-line max-lines-per-function, complexity
const FundraiserEditPage: React.FC<FundraiserEditPageProps> = ({ match }) => {
  const [ t ] = useTranslation();
  const { authState } = useOktaAuth();
  const { data: userInfo } = useUserInfo(authState.isAuthenticated);
  const { data: fundraiser, isLoading: isFundraiserLoading } = useFetchFundraiserById(parseInt(match.params.fundraiserId));

  const [ fundraiserId ] = useState(parseInt(match.params.fundraiserId));
  const { data: formData, isLoading: isFormLoading } = useFetchFundraiserFormData(fundraiserId);
  const { mutateAsync: updateFormData, isLoading } = useUpdateFundraiserFormData();

  const [ fundraiserDetails, setFundraiserDetails ] = useState<boolean>(false);
  const [ chairpersonDetails, setChairpersonDetails ] = useState<boolean>(false);

  // eslint-disable-next-line max-len
  const [ fundraiserDetailFormData, setFundraiserDetailFormData ] = useState<FundraiserInfo | undefined>(formData?.Info);
  const [ chairpersonFormData, setChairpersonFormData ] = useState<ChairpersonInfo | undefined>();

  const userCanViewPage = fundraiser?.Relationships.includes(FundraiserRelationship.CHAIRPERSON) || fundraiser?.Relationships.includes(FundraiserRelationship.SUPERADMIN) || fundraiser?.Relationships.includes(FundraiserRelationship.SALESAGENT) || fundraiser?.Relationships.includes(FundraiserRelationship.ISC);

  const profitCheckInfo: FundraiserProfitCheckAddress = {
    fundraiserId: fundraiser?.Id || 0,
    profitCheckPayableTo: fundraiser?.ProfitCheckPayableTo || '',
    groupAddress: fundraiserDetailFormData?.GroupAddress1 || '',
    groupCity: fundraiserDetailFormData?.GroupCity || '',
    groupState: fundraiserDetailFormData?.GroupState || '',
    groupZipCode: fundraiserDetailFormData?.GroupZip || '',
  };

  useMemo(() => {
    if (formData) {
      setFundraiserDetailFormData({
        ...formData?.Info,
        SalesAgentCode: formData?.SalesAgentCode || '',
      });
      setChairpersonFormData(formData?.Chairperson);
    }
  }, [ formData ]);

  const onUpdateHandler = async() => {
    if (fundraiserDetailFormData && chairpersonFormData) {
      const fundraiser: Fundraiser = {
        Id: fundraiserId,
        Info: fundraiserDetailFormData,
        Chairperson: chairpersonFormData,
        SalesAgentCode: fundraiserDetailFormData.SalesAgentCode,
        FederalTaxId: fundraiserDetailFormData.FederalTaxId || '',
        ProfitCheckPayableTo: '',
      };

      const data = await updateFormData(fundraiser);
      if (data) {
        toast(<GenericToast text={ t('fundraiser.FundraiserUpdate') } />, { position: 'top-center' });
      } else {
        toast(<GenericToast text={ t('fundraiser.FundraiserFailUpdate') } />, { position: 'top-center' });
      }
    }
  };

  if (isFormLoading || !formData?.Info || !formData?.Chairperson) {
    return (
      <Container sx={ { textAlign: 'center' } }>
        <Spinner variant="lce" />
      </Container>
    );
  }

  if (!isFundraiserLoading && !userCanViewPage) {
    return <Redirect to="/dashboard/fundraisers" />;
  }

  return (
    <Box sx={ { flex: 1, maxWidth: '855px' } }>
      <FundraiserNavigation fundId={ match.params.fundraiserId } url={ match.url } />
      <Heading>
        {t('fundraiser.EditFundraiser')}
      </Heading>

      <FundraiserDetails
        fundraiserType={ fundraiser?.FundraiserType }
        info={ fundraiserDetailFormData }
        isEdit={ true }
        mode={ !fundraiserDetails ? 'preview' : 'active' }
        onEdit={ () => setFundraiserDetails(true) }
        onNext={ (info: FundraiserInfo) => {
          setFundraiserDetailFormData(info);
          setFundraiserDetails(false);
        } }
        profitCheckInfo={ profitCheckInfo }
      />

      <ChairpersonDetails
        defaultChairperson={ userInfo }
        fundraiserId={ fundraiserId }
        info={ chairpersonFormData }
        isEdit={ true }
        mode={ !chairpersonDetails ? 'preview' : 'active' }
        onChairpersonRemove={ () => setChairpersonDetails(false) }
        onEdit={ () => setChairpersonDetails(true) }
        onNext={ (info: ChairpersonInfo) => {
          setChairpersonFormData(info);
          setChairpersonDetails(false);
        } }
      />

      <Flex>
        <Button
          disabled={ (fundraiserDetailFormData || chairpersonFormData) && !isLoading ? false : true }
          onClick={ onUpdateHandler }
          sx={ { ml: 'auto', marginTop: '16px' } }
          // eslint-disable-next-line max-len
          variant={ (fundraiserDetailFormData || chairpersonFormData) && !isLoading ? 'buttons.primary' : 'buttons.disabled' }
        >
          { isLoading ? (
            <Spinner sx={ { height: '100%' } } variant="lce" />
          ) : 'Update' }
        </Button>
      </Flex>
    </Box>
  );
};

export default FundraiserEditPage;
