import { useQuery } from 'react-query';

import { fetchSellerByFundraiserId } from '../api';
import { Seller } from '../types';

import * as QueryKeys from 'constants/QueryKeys';
import { ApiError } from 'features/common';

const useFetchSellerByFundraiserId = (id: string) => useQuery<Seller[], ApiError>(`${ QueryKeys.SellerPrefix }-${ id }`,
  () => fetchSellerByFundraiserId(id),
  {
    retry: false,
    refetchOnWindowFocus: false,
  });

export { useFetchSellerByFundraiserId };
