import React from 'react';
import { Box, Text } from '@lce/slice_v2';
import { useTranslation } from '@lce/i18n';

import { IDetailsSummaryProps } from './types';
import ShippingMethodLabel from './ShippingMethodLabel';

export const ShippingDetailsSummary: React.FC<IDetailsSummaryProps> = ({
  details,
  hasOnlyCouponItems,
  hasOnlyDonationItems,
  isCouponItemExist,
}) => {
  const [ t ] = useTranslation();

  return (
    <Box data-testid="shipping-details-summary">
      <Text variant="text.header.form">
        {t('checkout.ShippingAddress')}
      </Text>
      <Text>
        {`${ details.FirstName } ${ details.LastName }`}
      </Text>
      {!hasOnlyCouponItems && (
        <>
          <Text>
            {`${ details.Address1 } ${ details.Address2 }`}
          </Text>
          <Text>
            {`${ details.City }, ${ details.StateProvinceIso } ${ details.ZipPostalCode }`}
          </Text>
        </>
      ) }
      <Text sx={ { mt: '30px' } } variant="text.header.form">
        {t('checkout.OrderDetailLabel')}
      </Text>
      <Text>
        {details.Email}
      </Text>
      <Text>
        {details.PhoneNumber}
      </Text>
      {!hasOnlyDonationItems && (
        <ShippingMethodLabel
          hasOnlyCouponItems={ hasOnlyCouponItems }
          isCouponItemExist={ isCouponItemExist }
        />
      )}
    </Box>
  );
};
