import { useMutation, useQueryClient } from 'react-query';

import { ApiError } from '../types';
import { UserInfo } from '../types/userInfo';

import * as QueryKeys from 'constants/QueryKeys';
import api from 'api';

const fetchUpdateUser = async(userInfo: UserInfo) => api.put<boolean>('api/user', userInfo).then(res => res);

export const useUpdateUserInfo = () => {
  const queryClient = useQueryClient();

  return (
    useMutation<boolean, ApiError, UserInfo>(fetchUpdateUser,
      {
        onSuccess: () => queryClient.invalidateQueries(`${ QueryKeys.UserInfo }`),
      }));
};
