/* eslint-disable max-lines-per-function */
import React from 'react';
import {
  Redirect, Switch, useHistory, useRouteMatch,
} from 'react-router-dom';
import { useFeature } from '@growthbook/growthbook-react';

import { AuthenticatedRoute } from 'ui/routing';
import { LayoutWithoutFooter } from 'ui/common/Layouts/Layouts';
import { BrochureCreatePage } from 'ui/brochure';

export const BrochureRoutes: React.FC = () => {
  const history = useHistory();
  const { url } = useRouteMatch();
  const isBrochureProgramDisabled = useFeature('brochureProgramEnabled').off;

  if (isBrochureProgramDisabled) {
    return <Redirect to="/404" />;
  }

  //url = brochure/
  return (
    <div data-testid="brochure-routes">
      <Switch>
        <AuthenticatedRoute
          component={ BrochureCreatePage }
          exact={ true }
          history={ history }
          layout={ LayoutWithoutFooter }
          path={ `${ url }/start-a-fundraiser/create` }
          seo={ { pageTitle: 'Start a Fundraiser | Little Caesars Fundraising' } }
        />
        <Redirect to="/404" />
      </Switch>
    </div>
  );
};
