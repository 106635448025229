/* eslint-disable max-lines-per-function */
import React from 'react';
import {
  Modal as SliceModal, Text, Flex, Button, IconButton, Link,
} from '@lce/slice_v2';
import { X } from 'react-feather';
import { useTranslation } from '@lce/i18n';

import { CopyShareDescription } from 'ui/fundraiser';

export interface IInstagramModalProps {
  fundraiserName: string;
  link: string;
  onClose: () => void;
  isOpen: boolean;
}

const InstagramModal: React.FC<IInstagramModalProps> = ({
  onClose, fundraiserName, link, isOpen,
}) => {
  const [ t ] = useTranslation();
  return (
    <SliceModal
      closeIcon={ false }
      isOpen={ isOpen }
      lockBodyScroll={ true }
      onClose={ onClose }
      sx={ {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        maxWidth: '700px',
        my: 0,
        width: '90%',
        overflow: 'auto',
        maxHeight: '96%',
        '> div': {
          p: [ '10px', '20px' ],
        },
      } }
    >
      <Flex sx={ { justifyContent: 'flex-end' } }>
        <IconButton onClick={ onClose } sx={ { cursor: 'pointer' } }>
          <X size={ 40 } />
        </IconButton>
      </Flex>

      <Flex
        sx={ {
          flexDirection: 'column',
          padding: [ '8px', '16px' ],
          textAlign: 'center',
        } }
      >
        <Text
          sx={ {
            color: 'primaryOrange',
            fontFamily: 'mrEaves',
            textTransform: 'uppercase',
            fontSize: [ '24px', '32px' ],
            fontWeight: 'bold',
            whiteSpace: 'pre-line',
            lineHeight: '0.9',
            mb: [ 2, 4 ],
          } }
        >
          {t('mealDeal.success.instagramModal.shareOn')}
        </Text>

        <Text sx={ { mb: [ 4, 8 ], fontSize: [ '16px', '18px' ] } }>
          {t('mealDeal.success.instagramModal.subTitle')}
        </Text>

        <Flex
          sx={ {
            flexDirection: 'column',
            textAlign: 'left',
          } }
        >
          <Text
            sx={ {
              textTransform: 'uppercase',
              fontSize: [ '16px', '18px' ],
              fontWeight: 'bold',
              whiteSpace: 'pre-line',
              lineHeight: '0.9',
              mb: 2,
            } }
          >
            {t('mealDeal.success.instagramModal.step1.header')}
          </Text>

          <Text
            sx={ {
              fontSize: [ '14px', '16px' ],
              whiteSpace: 'pre-line',
              mb: 2,
            } }
          >
            {t('mealDeal.success.instagramModal.step1.body')}
          </Text>

          <CopyShareDescription
            copyText={ `${ t('mealDeal.success.shareText', { fundraiserName }) } ${ link }` }
          />

          <Text
            sx={ {
              textTransform: 'uppercase',
              fontSize: [ '16px', '18px' ],
              fontWeight: 'bold',
              whiteSpace: 'pre-line',
              lineHeight: '0.9',
              mt: 4,
              mb: 2,
            } }
          >
            {t('mealDeal.success.instagramModal.step2.header')}
          </Text>

          <Text
            sx={ {
              fontSize: [ '14px', '16px' ],
              whiteSpace: 'pre-line',
              mb: 4,
            } }
          >
            {t('mealDeal.success.instagramModal.step2.body')}
          </Text>
        </Flex>

        <Link
          href="https://www.instagram.com/"
          sx={ {
            mb: '16px',
            height: 'auto !important',
            py: 3,
            color: 'white',
            textDecoration: 'none',
          } }
          target="_blank"
          variant="buttons.primary"
        >
          <Text sx={ { fontSize: [ '18px', '20px' ], fontWeight: 'bold' } }>
            {t('mealDeal.success.instagramModal.button')}
          </Text>
        </Link>

        <Button onClick={ onClose } sx={ { textDecoration: 'none' } } variant="buttons.text">
          <Text sx={ { fontSize: [ '18px', '20px' ], fontWeight: 'bold' } }>
            {t('mealDeal.success.instagramModal.cancel')}
          </Text>
        </Button>
      </Flex>
    </SliceModal>
  );
};

export { InstagramModal };
