import { useMemo } from 'react';
import { useTranslation } from '@lce/i18n';
import { useGrowthBook } from '@growthbook/growthbook-react';

export type TLink = {
  title: string;
  path: string;
  growthbookCheck?: [string, string];
}

export const useFeatureFlaggedNavigationLinks = ({
  fundraiserId,
  customerId,
  fundraiserType,
}) => {
  const [ t ] = useTranslation();
  const growthbook = useGrowthBook();

  return useMemo(() => {
    const allLinks = t<TLink[]>(`dashboard.navigation.${ customerId
      ? 'seller'
      : 'chairperson' }`,
    {
      fundraiserId, customerId, returnObjects: true, context: fundraiserType,
    });

    return allLinks.filter(({ growthbookCheck }) => {
      if (growthbookCheck) {
        const [ featureName, truthyProp ] = growthbookCheck;

        return growthbook.evalFeature(featureName)[truthyProp];
      }

      return true;
    })
      .map(({ growthbookCheck, ...link }) => link);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ customerId, fundraiserId, fundraiserType, growthbook ]);
};
