/* eslint-disable max-lines-per-function */
import React from 'react';
import { Box, Flex, Form } from '@lce/slice_v2';
import { IFieldProps } from '@lce/slice_v2/Elements/Form/Field';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from '@lce/i18n';

import { IOption } from '../IOption';

import TypeaheadSelect from './TypeaheadSelect';

export interface IOptionalTypeaheadValues {
  states: string[];
}

interface IOptionalTypeaheadSelector extends Omit<IFieldProps, 'name'> {
  options: IOption[];
  value1: string;
  value2: string;
  label: string;
  type: string;
  multi?: boolean;
  nameSelector: string;
  nameSelection: string;
}
const boxStyle = {
  display: 'flex',
  whiteSpace: 'nowrap',
  div: { m: 0 },
};

const OptionalTypeaheadSelect: React.FC<IOptionalTypeaheadSelector> = ({
  nameSelector, nameSelection, type, id, value1, value2, options, label, multi = true,
}) => {
  const [ t ] = useTranslation();
  const { watch } = useFormContext();

  return (
    <>
      <Flex
        sx={ {
          maxWidth: '100%',
          flexDirection: [ 'column', 'row', 'row', 'row' ],
        } }
      >
        {label && (
          <Form.Label
            sx={ {
              display: 'flex',
              m: [ '0 0 10px 0', '0 15px 0 0' ],
              width: [ '100%', '50%' ],
              whiteSpace: [ 'normal', 'normal', 'nowrap' ],
            } }
          >
            {`${ label }`}
          </Form.Label>
        )}
        <Box
          mb={ 4 }
          sx={ {
            ...boxStyle,
            m: [ '0 0 25px 0', '0 25px 0 0', '0 35px 0 0' ],
            minWidth: 'auto',
          } }
        >
          <Form.Field
            component={ Form.Input.Radio }
            id={ id }
            inline={ true }
            label={ t('fundraiserReportFilters.OptionalTypeaheadSelect.SelectAll') }
            name={ nameSelector }
            value={ value1 }
          />
        </Box>
        <Box
          mb={ 4 }
          sx={ {
            ...boxStyle,
            minWidth: 156,
          } }
        >
          <Form.Field
            component={ Form.Input.Radio }
            id={ value2 }
            inline={ true }
            label={ `${ t('fundraiserReportFilters.OptionalTypeaheadSelect.Specific') } ${ type }` }
            name={ nameSelector }
            value={ value2 }
          />
        </Box>
      </Flex>
      {watch(nameSelector) === value2 && (
        <TypeaheadSelect
          id={ `${ id }Selection` }
          isMulti={ multi ?? false }
          label=""
          name={ nameSelection }
          options={ options ?? [] }
          sx={ {
            span: {
              backgroundColor: 'none',
            },
          } }
        />
      )}
    </>
  );
};

export default OptionalTypeaheadSelect;
