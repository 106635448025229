import { useQuery } from 'react-query';

import { GroupType } from '../types';
import { fetchGroupTypes } from '../api';

import * as QueryKeys from 'constants/QueryKeys';
import { ApiError } from 'features/common';

export const useFetchGroupTypes = () => useQuery<GroupType[], ApiError>(`${ QueryKeys.FundraisingGroupTypes }`,
  () => fetchGroupTypes(),
  {
    retry: false,
    refetchOnWindowFocus: false,
  });
