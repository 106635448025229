import React from 'react';
import { useTranslation } from '@lce/i18n';
import { RouteComponentProps } from 'react-router-dom';
import {
  Box, Container, Flex, Image, Link, Text,
} from '@lce/slice_v2';
import { Helmet } from 'react-helmet-async';

import { Banner } from 'ui/common';
import CaesarPhone from 'assets/images/caesar-cellphone.svg';
import ArticleBanner from 'assets/images/banners/article-1-banner.png';
import ComputerSimple from 'assets/icons/ComputerSimple.svg';
import EmailLetter from 'assets/icons/EmailLetter.svg';
import GetWordOut from 'assets/icons/GetWordOut.svg';
import Computer from 'assets/icons/Computer.svg';
import ClappingHands from 'assets/icons/ClappingHands.svg';

interface MatchParams {
  articleName: string;
}

// TODO: Eventually we'll pull the article name from the URL params and use that to fetch content
type ArticleDetailPageProps = RouteComponentProps<MatchParams>;

/**
 * TODO:
 * This page component should eventually be a template which takes an article name/id and
 * fetches the content from a CMS - instead I'm hard coding the first article for now
 * Better yet, these pages should be pre-rendered
 */
// eslint-disable-next-line max-lines-per-function
const ArticleDetail: React.FC<ArticleDetailPageProps> = () => {
  const [ t ] = useTranslation();

  return (
    <>
      <Helmet>
        <title>
          {t('articles.fundraiserEmails.banner.Text')}
          {' '}
          | Little Caesars Fundraising
        </title>
      </Helmet>
      <Container>

        <Banner
          gradient="to-bottom"
          heightBreakpoints={ [ '200px', '250px', '400px' ] }
          image={ ArticleBanner }
          showOnMobile={ true }
          subText={ t('articles.fundraiserEmails.banner.SubText') }
          text={ t('articles.fundraiserEmails.banner.Text') }
        />

        <Flex sx={ { mt: '35px' } }>
          <Box sx={ { maxWidth: '855px' } }>
            <Text sx={ { fontSize: '14px', lineHeight: '16px' } }>
              {t('articles.fundraiserEmails.LongDescription')}
            </Text>

            <TipSection />

            <Text sx={ { fontSize: '14px', lineHeight: '16px', mt: '35px' } }>
              Do you have ideas or tips for new fundraising chairpersons?
              We’re in this together, so please share your ideas on our
              {' '}
              <Link
                href="https://www.facebook.com/LittleCaesarsFundraising"
                sx={ { color: 'primaryOrange' } }
              >
                Facebook Page
              </Link>
              {' '}
              If you’re ready to start your campaign, we’d love to help!
              {' '}
              <Link href="/contact-us" sx={ { color: 'primaryOrange' } }>Contact Us</Link>
              {' '}
              at Little Caesars Fundraising.

            </Text>
          </Box>
          <Image
            src={ CaesarPhone }
            sx={ {
              width: '296px',
              height: '317px',
              ml: '71px',
              minWidth: 'initial',
              display: [ 'none', 'none', 'block' ],
            } }
          />
        </Flex>
      </Container>

    </>
  );
};

const TipSection = () => {
  const [ t ] = useTranslation();

  const renderTipSection = (icon, title, content) => (
    <Box sx={ { mt: '16px' } }>
      <Flex
        sx={ {
          flexDirection: [ 'column', 'row', 'row' ],
          alignItems: 'center',
        } }
      >
        <Image
          alt={ title }
          src={ icon }
          sx={ {
            minWidth: '80px',
            height: '80px',
            marginRight: [ 0, '37px', '37px' ],
            mb: [ '16px', 0, 0 ],
          } }
          title={ title }
        />
        <Flex sx={ { flexDirection: 'column', maxWidth: '567px' } }>
          <Text variant="text.header.sub">
            {title}
          </Text>
          <Text sx={ { fontSize: '14px', lineHeight: '16px' } }>
            {content}
          </Text>
        </Flex>
      </Flex>
    </Box>
  );

  return (
    <Box>
      {renderTipSection(ComputerSimple,
        t('articles.fundraiserEmails.Tip1Title'),
        t('articles.fundraiserEmails.Tip1Content'))}
      {renderTipSection(EmailLetter,
        t('articles.fundraiserEmails.Tip2Title'),
        t('articles.fundraiserEmails.Tip2Content'))}
      {renderTipSection(GetWordOut,
        t('articles.fundraiserEmails.Tip3Title'),
        t('articles.fundraiserEmails.Tip3Content'))}
      {renderTipSection(Computer,
        t('articles.fundraiserEmails.Tip4Title'),
        t('articles.fundraiserEmails.Tip4Content'))}
      {renderTipSection(ClappingHands,
        t('articles.fundraiserEmails.Tip5Title'),
        t('articles.fundraiserEmails.Tip5Content'))}
    </Box>
  );
};

export default ArticleDetail;
