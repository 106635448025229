/* eslint-disable max-lines-per-function */
import React, { useState } from 'react';
import { useTranslation } from '@lce/i18n';
import * as Yup from 'yup';
import {
  Button, Box, Form, Flex, Text, Option,
} from '@lce/slice_v2';
import _map from 'lodash/map';

import ChairpersonDetailsSummary from './ChairpersonDetailsSummary';

import { Mode, Step } from 'ui/common';
import { PhoneInput, phonePattern } from 'ui/components/forms';
import { FundraiserType, PhoneNumberTypes } from 'features/fundraiser';
import { UserInfo } from 'features/common/types/userInfo';
import { FundraiserBrochure, FundraiserFormStepState } from 'features/fundraiser/types/fundraiser';

export interface IChairpersonDetailsProps {
  onEdit: () => void;
  onNext: (info: Partial<FundraiserBrochure>) => void;
  defaultChairperson: UserInfo | undefined;
  info?: Partial<FundraiserBrochure> | undefined;
  mode: Mode;
  fundraiserType?: FundraiserType;
}

const ChairpersonDetails: React.FC<IChairpersonDetailsProps> = ({
  onEdit, onNext, mode, defaultChairperson, info,
}) => {
  const [ t ] = useTranslation();
  const [ showCoChairpersonForm, setShowCoChairpersonForm ] =
    useState<boolean>(Boolean(info?.coChairpersonEmailAddress) || false);

  const handleOnSubmit = (e) => {
    const info: Partial<FundraiserBrochure> = {
      ...e,
    };
    onNext(info);
  };

  const validationSchema = Yup.object({
    chairpersonFirstName: Yup.string()
      .required(t('fundraiser.FirstNameRequired'))
      .max(50, t('fundraiser.MaxLength50')),
    chairpersonLastName: Yup.string()
      .required(t('fundraiser.LastNameRequired'))
      .max(50, t('fundraiser.MaxLength50')),
    chairpersonPrimaryPhoneNumber: Yup.string()
      .matches(phonePattern, t('fundraiser.PhoneNumberValid'))
      .min(10, t('fundraiser.PhoneNumberLength'))
      .max(17, t('fundraiser.PhoneNumberLength'))
      .required(t('fundraiser.PhoneRequired')),
    chairpersonSecondaryPhoneNumber: Yup.string()
      .matches(phonePattern, t('fundraiser.PhoneNumberValid'))
      .min(10, t('fundraiser.PhoneNumberLength'))
      .max(17, t('fundraiser.PhoneNumberLength'))
      .required(t('fundraiser.PhoneRequired')),
    coChairpersonPrimaryPhoneNumber: Yup.string()
      .max(17, t('fundraiser.PhoneNumberLength'))
      .matches(phonePattern, { excludeEmptyString: true, message: t('fundraiser.PhoneNumberValid') })
      .notRequired()
      .nullable(),
    chairpersonEmailAddress: Yup.string()
      .email(t('fundraiser.EmailValid'))
      .required(t('fundraiser.EmailRequired')),
    chairpersonConfirmEmailAddress: Yup.string()
      .email(t('fundraiser.EmailMatch'))
      .required(t('fundraiser.EmailRequired'))
      .oneOf([ Yup.ref('chairpersonEmailAddress') ], t('fundraiser.EmailMatch')),
    coChairpersonEmailAddress: Yup.string()
      .max(255, t('fundraiser.MaxLength255'))
      .email(t('fundraiser.EmailValid'))
      .notOneOf([ Yup.ref('chairpersonEmailAddress') ], t('fundraiser.CoChairpersonEmailMatch')),
    coConfirmChairpersonEmailAddress: Yup.string()
      .email(t('fundraiser.EmailMatch'))
      .oneOf([ Yup.ref('coChairpersonEmailAddress') ], t('fundraiser.EmailMatch')),
    chairpersonPrimaryPhoneNumberType: Yup.string().required(t('fundraiser.PhoneTypeRequired')),
    chairpersonSecondaryPhoneNumberType: Yup.string().required(t('fundraiser.PhoneTypeRequired')),
  });

  const stepTitle = t('fundraiser.ChairpersonDetails');

  return (
    <>
      { mode === FundraiserFormStepState.Active && (
        <Step onEdit={ onEdit } title={ stepTitle }>
          <Box data-testid="chairperson-details-form" variant="cards.step.body">
            <Form
              initialValues={ info }
              onSubmit={ handleOnSubmit }
              summary={ false }
              validationSchema={ validationSchema }
            >
              <Flex sx={ { flexDirection: 'column' } }>
                <Text sx={ { mb: '6px' } } variant="text.header.form">
                  {t('fundraiser.AboutChairperson')}
                </Text>
                <Flex sx={ { flexDirection: 'column', gap: '16px' } }>
                  <Flex sx={ { flexDirection: [ 'column', 'row' ], gap: '16px', alignItems: [ '', 'end' ] } }>
                    <Form.Field
                      autoComplete="given-name"
                      data-testid="first-name"
                      defaultValue={ info?.chairpersonFirstName || defaultChairperson?.FirstName || '' }
                      id="chairpersonFirstName"
                      label={ t('fundraiser.labels.FirstName') }
                      name="chairpersonFirstName"
                      sx={ { my: '0px' } }
                      variant="forms.fundraiser.field"
                    />
                    <Form.Field
                      autoComplete="family-name"
                      data-testid="last-name"
                      defaultValue={ info?.chairpersonLastName || defaultChairperson?.LastName || '' }
                      id="chairpersonLastName"
                      label={ t('fundraiser.labels.LastName') }
                      name="chairpersonLastName"
                      sx={ { my: '0px' } }
                      variant="forms.fundraiser.field"
                    />
                  </Flex>
                  <Flex sx={ { flexDirection: 'column', gap: '16px' } }>
                    <Box
                      sx={ {
                        display: 'grid',
                        gridTemplateColumns: [ '3fr 2fr', '3fr 1fr' ],
                        gap: '16px',
                        alignItems: 'end',
                      } }
                    >
                      <PhoneInput
                        defaultValue={ info?.chairpersonPrimaryPhoneNumber }
                        label={ t('fundraiser.labels.PhoneNumber') }
                        name="chairpersonPrimaryPhoneNumber"
                        sx={ { width: '100%', my: '0px' } }
                        testId="phone-number"
                      />
                      <Form.Field
                        component={ Form.Select }
                        defaultValue={ info?.chairpersonPrimaryPhoneNumberType || 'Mobile' }
                        id="chairpersonPrimaryPhoneNumberType"
                        label={ t('fundraiser.labels.PhoneNumberType') }
                        name="chairpersonPrimaryPhoneNumberType"
                        sx={ { my: '0px' } }
                        variant="forms.fundraiser.field"
                      >
                        { _map(PhoneNumberTypes, type => (
                          <Option
                            key={ type }
                            label={ type }
                            value={ type }
                          />
                        ))}
                      </Form.Field>
                    </Box>
                    <Box
                      sx={ {
                        display: 'grid',
                        gridTemplateColumns: [ '3fr 2fr', '3fr 1fr' ],
                        gap: '16px',
                        alignItems: 'end',
                      } }
                    >
                      <PhoneInput
                        defaultValue={ info?.chairpersonSecondaryPhoneNumber }
                        label={ t('fundraiser.labels.SecondaryPhoneNumber') }
                        name="chairpersonSecondaryPhoneNumber"
                        sx={ { width: '100%', my: '0px' } }
                        testId="secondary-phone-number"
                      />
                      <Form.Field
                        component={ Form.Select }
                        defaultValue={ info?.chairpersonSecondaryPhoneNumberType || 'Mobile' }
                        id="chairpersonSecondaryPhoneNumberType"
                        label={ t('fundraiser.labels.PhoneNumberType') }
                        name="chairpersonSecondaryPhoneNumberType"
                        sx={ { my: '0px' } }
                        variant="forms.fundraiser.field"
                      >
                        { _map(PhoneNumberTypes, type => (
                          <Option
                            key={ type }
                            label={ type }
                            value={ type }
                          />
                        ))}
                      </Form.Field>
                    </Box>
                  </Flex>
                  <Flex sx={ { flexDirection: [ 'column', 'row' ], gap: '16px', alignItems: [ '', 'end' ] } }>
                    <Form.Field
                      data-testid="email-address"
                      defaultValue={ info?.chairpersonEmailAddress || '' }
                      id="chairpersonEmailAddress"
                      label={ t('fundraiser.labels.EmailAddress') }
                      name="chairpersonEmailAddress"
                      sx={ { my: '0px' } }
                      variant="forms.fundraiser.field"
                    />
                    <Form.Field
                      data-testid="confirm-email-address"
                      defaultValue={ info?.chairpersonEmailAddress || '' }
                      id="chairpersonConfirmEmailAddress"
                      label={ t('fundraiser.labels.ConfirmEmailAddress') }
                      name="chairpersonConfirmEmailAddress"
                      sx={ { my: '0px' } }
                      variant="forms.fundraiser.field"
                    />
                  </Flex>
                </Flex>
                { showCoChairpersonForm ? (
                  <>
                    <Text sx={ { mt: '24px', mb: '6px' } } variant="text.header.form">
                      {t('fundraiser.AboutCoChairperson')}
                    </Text>
                    <Flex sx={ { flexDirection: 'column', gap: '16px' } }>
                      <Flex sx={ { flexDirection: [ 'column', 'row' ], gap: '16px', alignItems: [ '', 'end' ] } }>
                        <Form.Field
                          data-testid="co-first-name"
                          defaultValue={ info?.coChairpersonFirstName || '' }
                          id="coChairpersonFirstName"
                          label={ t('fundraiser.labels.CoFirstName') }
                          name="coChairpersonFirstName"
                          sx={ { my: '0px' } }
                          variant="forms.fundraiser.field"
                        />
                        <Form.Field
                          data-testid="co-last-name"
                          defaultValue={ info?.coChairpersonLastName || '' }
                          id="coChairpersonLastName"
                          label={ t('fundraiser.labels.CoLastName') }
                          name="coChairpersonLastName"
                          sx={ { my: '0px' } }
                          variant="forms.fundraiser.field"
                        />
                      </Flex>
                      <Flex sx={ { flexDirection: [ 'column', 'row' ], gap: '16px', alignItems: [ '', 'end' ] } }>
                        <PhoneInput
                          defaultValue={ info?.coChairpersonPrimaryPhoneNumber || '' }
                          label={ t('fundraiser.labels.CoPhoneNumber') }
                          name="coChairpersonPrimaryPhoneNumber"
                          sx={ { width: '100%', my: '0px' } }
                          testId="co-phone-number"
                        />
                        <PhoneInput
                          defaultValue={ info?.coChairpersonSecondaryPhoneNumber || '' }
                          label={ t('fundraiser.labels.CoSecondaryPhoneNumber') }
                          name="coChairpersonSecondaryPhoneNumber"
                          sx={ { width: '100%', my: '0px' } }
                          testId="co-secondary-phone-number"
                        />
                      </Flex>
                      <Flex sx={ { flexDirection: [ 'column', 'row' ], gap: '16px', alignItems: [ '', 'end' ] } }>
                        <Form.Field
                          data-testid="co-email-address"
                          defaultValue={ info?.coChairpersonEmailAddress || '' }
                          id="coChairpersonEmailAddress"
                          label={ t('fundraiser.labels.CoEmailAddress') }
                          name="coChairpersonEmailAddress"
                          sx={ { my: '0px' } }
                          variant="forms.fundraiser.field"
                        />
                        <Form.Field
                          data-testid="co-confirm-email-address"
                          defaultValue={ info?.coChairpersonEmailAddress || '' }
                          id="coConfirmChairpersonEmailAddress"
                          label={ t('fundraiser.labels.CoConfirmEmailAddress') }
                          name="coConfirmChairpersonEmailAddress"
                          sx={ { my: '0px' } }
                          variant="forms.fundraiser.field"
                        />
                      </Flex>
                    </Flex>
                  </>
                ) : (
                  <Button
                    data-testid="show-co-form"
                    onClick={ () => setShowCoChairpersonForm(true) }
                    sx={ { mt: '24px' } }
                    variant="secondary"
                  >
                    { t('fundraiser.AddCoChairperson') }
                  </Button>
                )}
                <Button
                  data-testid="submit-button"
                  id="chairpersonNext"
                  sx={ { mt: '24px', border: '1px solid', borderColor: 'primary' } }
                  type="submit"
                >
                  {t('fundraiser.AdditionalInformationNext')}
                </Button>
              </Flex>
            </Form>
          </Box>
        </Step>
      )}
      { mode === FundraiserFormStepState.Preview && (
        <Step customText="chairperson-edit" onEdit={ onEdit } showEdit={ true } title={ stepTitle }>
          <Box variant="cards.step.body">
            <ChairpersonDetailsSummary details={ info } />
          </Box>
        </Step>
      )}
      { mode === FundraiserFormStepState.Hidden && (
        <Step title={ stepTitle } />
      )}
    </>
  );
};

export default ChairpersonDetails;
