import React, { useEffect, useState } from 'react';
import {
  Flex, Form, Box, Button, Text, Heading, Option,
} from '@lce/slice_v2';
import _isEmpty from 'lodash/isEmpty';
import { useTranslation } from '@lce/i18n';

import { useFetchStates, useLocalStorage } from 'features/common';
import HowDidYouHearAboutUsOptions from 'ui/common/HowDidYouHearAboutUsOptions';
import { useFundraiserState } from 'features/fundraiser/context/fundraiserContext';
import { FundraiserType } from 'features/fundraiser';
import { FirstNameInput, PhoneInput } from 'ui/components/forms';

// eslint-disable-next-line max-lines-per-function
const RequestInfoData: React.FC<{}> = () => {
  const [ t ] = useTranslation();
  const { fundraiserType } = useFundraiserState();
  const redirectUrl =
    `${ window.location.origin }${ t('routes.requestInfoConfirmationPage', { context: fundraiserType }) }`;
  const SALESFORCE_URL = process.env.REACT_APP_SALESFORCE_WEB_TO_LEAD_URL;
  const SALESFORCE_OID = process.env.REACT_APP_SALESFORCE_WEB_TO_LEAD_OID;
  const [ franchiseeId ] = useLocalStorage<string>('franchiseeId');
  const [ referralSource ] = useLocalStorage<string>('referralSource');
  const [ country, setCountry ] = useState<number>(1);
  const [ showReferralSource, setShowReferralSource ] = useState(true);
  const referralSourceDefaultValueInitial = franchiseeId ? t('HowDidYouHearAboutUsOptions.LCEStore') : '';
  const [ referralSourceDefaultValue, setReferralSourceDefaultValue ] = useState(referralSourceDefaultValueInitial);

  const { data: states } = useFetchStates(country);

  useEffect(() => {
    if (referralSource || franchiseeId) {
      setReferralSourceDefaultValue(referralSource);
      setShowReferralSource(false);
    } else {
      setShowReferralSource(true);
    }
  }, [ referralSource, franchiseeId ]);

  const hasReferral = !_isEmpty(referralSource);
  const hasFranchiseeId = !_isEmpty(franchiseeId);
  const referralText = fundraiserType === FundraiserType.MealDeal ? 'Meal Deal RFI' : 'Digital RFI';
  const leadSource = hasReferral || hasFranchiseeId ? referralSource || 'Little Caesars Store' : referralText;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const validateZip = (event: any) => {
    const usZipRegEx = (/(^\d{5}$)|(^\d{5}-\d{4}$)/);
    const caPostalCodeRegEx = (/^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i);
    const isUSZip = usZipRegEx.test(event.target.value);
    const isCAPostal = caPostalCodeRegEx.test(event.target.value);
    if (isUSZip || isCAPostal) {
      event.target.setCustomValidity('');
    } else {
      event.target.setCustomValidity('Zip code entered must be valid.');
    }
  };

  const countryMapping = {
    'United States': 1,
    Canada: 2,
  };

  return (
    <Box data-testid="request-info-form" sx={ { margin: 'auto', maxWidth: '700px' } }>
      <Heading
        sx={ {
          fontWeight: 700,
          fontSize: '36px',
          margin: 'auto',
          color: 'primaryOrange',
          mb: '30px',
          textTransform: 'uppercase',
        } }
      >
        {t('requestInformation.Heading')}
      </Heading>
      <Box
        sx={ {
          maxWidth: '700px',
          border: '1px solid lightGray',
          boxShadow: '0 4px 10px 0 rgba(0, 0, 0, 0.25)',
          p: '28px 30px',
        } }
      >
        <Text>
          {t('requestInformation.FormDescription')}
        </Text>
        <Text sx={ { mt: '20px' } }>
          {t('requestInformation.RequiredFields')}
        </Text>
        <form
          action={ SALESFORCE_URL }
          method="POST"
        >
          <Flex sx={ { flexDirection: [ 'column', 'row' ] } }>
            <Box
              sx={ {
                mr: [ 0, 8 ],
                width: '100%',
              } }
            >
              <input
                name="oid"
                type="hidden"
                value={ SALESFORCE_OID }
              />
            </Box>
            <Box
              sx={ {
                mr: [ 0, 8 ],
                width: '100%',
              } }
            >
              <input
                name="retURL"
                type="hidden"
                value={ redirectUrl }
              />
            </Box>
            <Box sx={ { mr: [ 0, 8 ], width: '100%' } }>
              <input
                data-testid="hidden-lead-source" id="lead_source" name="lead_source" type="hidden"
                value={ leadSource }
              />
            </Box>
          </Flex>
          <Flex sx={ { flexDirection: [ 'column', 'row' ] } }>
            <FirstNameInput
              keysToOmit={ [ ] }
              label="First name*"
              name="first_name"
              required={ true }
              sx={ { flex: '1', mr: [ '0px', '16px' ] } }
            />
            <Form.Field
              autoComplete="family-name"
              data-testid="last-name"
              id="last_name"
              label="Last name*"
              name="last_name"
              required={ true }
              sx={ { flex: '1', ml: [ '0px', '16px' ] } }
              type="text"
            />
          </Flex>
          <Flex sx={ { flexDirection: [ 'column', 'row' ] } }>
            <Form.Field
              autoComplete="organization"
              data-testid="company"
              id="company"
              label="Organization*"
              maxLength={ 40 }
              name="company"
              required={ true }
              size={ 20 }
              sx={ { flex: '1', mr: [ '0px', '16px' ] } }
              type="text"
            />
            <Form.Field
              component={ Form.Select }
              data-testid="industry"
              id="industry"
              label="Group type*"
              name="industry"
              required={ true }
              sx={ { flex: '1', ml: [ '0px', '16px' ] } }
            >
              <option value="">--None--</option>
              <option value="Adult Groups (e.g. M.A.D.D., Lions Club,">Adult Groups (e.g. M.A.D.D., Lions Club,</option>
              <option value="Adult Sports Team">Adult Sports Team</option>
              <option value="Boy Scouts">Boy Scouts</option>
              <option value="Church/Religious">Church/Religious</option>
              <option value="College/University">College/University</option>
              <option value="Dance Group">Dance Group</option>
              <option value="Daycares/Preschools">Daycares/Preschools</option>
              <option value="Elementary School">Elementary School</option>
              <option value="Family Reunion">Family Reunion</option>
              <option value="Girl Scouts">Girl Scouts</option>
              <option value="Gymnastics">Gymnastics</option>
              <option value="High School - Band">High School - Band</option>
              <option value="High School - Cheerleader/Pom-Pon">High School - Cheerleader/Pom-Pon</option>
              <option value="High School - Choir">High School - Choir</option>
              <option value="High School - Other Clubs">High School - Other Clubs</option>
              <option value="High School - Student Council">High School - Student Council</option>
              <option value="Hospitals/Health Related Groups">Hospitals/Health Related Groups</option>
              <option value="Martial Arts">Martial Arts</option>
              <option value="Middle School/Jr. High">Middle School/Jr. High</option>
              <option value="Other">Other</option>
              <option value="Skate Clubs">Skate Clubs</option>
              <option value="Youth Sports Groups - Baseball">Youth Sports Groups - Baseball</option>
              <option value="Youth Sports Groups - Basketball">Youth Sports Groups - Basketball</option>
              <option value="Youth Sports Groups - Football">Youth Sports Groups - Football</option>
              <option value="Youth Sports Groups - Hockey">Youth Sports Groups - Hockey</option>
              <option value="Youth Sports Groups - Lacrosse">Youth Sports Groups - Lacrosse</option>
              <option value="Youth Sports Groups - Rugby">Youth Sports Groups - Rugby</option>
              <option value="Youth Sports Groups - Soccer">Youth Sports Groups - Soccer</option>
              <option value="Youth Sports Groups - Swim">Youth Sports Groups - Swim</option>
              <option value="Youth Sports Groups - Track">Youth Sports Groups - Track</option>
              <option value="Youth Sports Groups - Volleyball">Youth Sports Groups - Volleyball</option>
              <option value="Grade-Trip">Grade-Trip</option>
              <option value="4-H Club">4-H Club</option>
            </Form.Field>
          </Flex>
          {showReferralSource && (
            <Flex>
              <Form.Field
                component={ Form.Select }
                data-testid="lead-source"
                defaultValue={ referralSourceDefaultValue }
                disabled={ !!franchiseeId }
                id="Lead_Source_Name__c"
                label="How did you hear about us?*"
                name="Lead_Source_Name__c"
                required={ true }
                sx={ { flex: '1' } }
              >
                <HowDidYouHearAboutUsOptions />
              </Form.Field>
            </Flex>
          )}
          {hasFranchiseeId && (
            <input
              id="LCE_Store_Number__c"
              name="LCE_Store_Number__c"
              type="hidden"
              value={ franchiseeId }
            />
          )}
          <Flex sx={ { flexDirection: [ 'column', 'row' ] } }>
            <Form.Field
              autoComplete="street-address"
              data-testid="street"
              id="street"
              label="Address*"
              name="street"
              required={ true }
              sx={ { flex: '1' } }
            />
            {/* TODO: Implement secondary address on SF side */}
            {/* <Form.Field
              id="secondary_address"
              label="Apt / Suite / Other"
              name="secondary_address"
              sx={ { flex: '1', ml: [ '0px', '16px' ] } }
              type="text"
            /> */}
          </Flex>
          <Flex sx={ { flexDirection: [ 'column', 'row' ] } }>
            <Form.Field
              autoComplete="address-level2"
              data-testid="city"
              id="city"
              label="City / Province*"
              name="city"
              required={ true }
              sx={ { flex: '1', mr: [ '0px', '16px' ] } }
            />
            <Form.Field
              autoComplete="address-level1"
              component={ Form.Select }
              data-testid="state"
              id="state"
              label="State*"
              name="state"
              required={ true }
              sx={ { flex: '1', ml: [ 0, '16px' ], mr: [ '0px', '16px' ] } }
              variant="forms.fundraiser.field"
            >
              <Option value="">---</Option>
              {states && states.map(state => (
                <option
                  key={ state.Id }
                  value={ state.Abbreviation }
                >
                  {state.Abbreviation}
                </option>
              ))}
            </Form.Field>

            <Form.Field
              autoComplete="postal-code"
              data-testid="zip"
              id="zip"
              label="Zip / Postal Code*"
              name="zip"
              onChange={ e => validateZip(e) }
              required={ true }
              sx={ { flex: '1', ml: [ '0px', '16px' ] } }
            />
          </Flex>
          <Flex>
            <Form.Field
              autoComplete="country"
              component={ Form.Select }
              data-testid="country"
              defaultValue={ country }
              id="country"
              label="Country*"
              name="country"
              onChange={ e => setCountry(countryMapping[e.target.value]) }
              required={ true }
              sx={ { flex: '1' } }
            >
              <Option value="United States">USA</Option>
              <Option value="Canada">Canada</Option>
            </Form.Field>
          </Flex>
          <Flex sx={ { flexDirection: [ 'column', 'row' ] } }>
            <PhoneInput
              label="Phone number*"
              name="phone" required={ true } sx={ { flex: '1', mr: [ '0px', '16px' ] } }
            />
            <PhoneInput
              label="Alternate phone" name="mobile"
              sx={ { flex: '1', ml: [ '0px', '16px' ] } } testId="alternate-phone"
            />
          </Flex>
          <Flex>
            <Form.Field
              autoComplete="email"
              data-testid="email"
              id="email"
              label="Email address*"
              name="email"
              required={ true }
              sx={ { flex: '1' } }
            />
          </Flex>
          <Flex sx={ { mt: '30px' } }>
            <Button name="submit" sx={ { flex: '1' } } type="submit">Submit</Button>
          </Flex>
        </form>
      </Box>
    </Box>
  );
};
export default RequestInfoData;
