import {
  Flex, IconButton, Image, IModalProps as ISliceModalProps, Modal as SliceModal,
} from '@lce/slice_v2';
import React from 'react';
import { X } from 'react-feather';

export interface IZoomInModalProps {
  imageURL: string;
  imageAltText: string;
  modalText: string;
  onClose: () => void;
}

const ZoomInModal: React.FC<IZoomInModalProps & ISliceModalProps> = ({
  imageURL, imageAltText, modalText, onClose, ...props
}) => (
  <SliceModal
    { ...props }
    aria-labelledby="qr notification modal"
    aria-modal="true"
    closeIcon={ false }
    closeOnClickOutside={ false }
    data-testid="zoom-in-modal"
    onClose={ onClose }
    sx={ {
      maxWidth: '500px',
      position: [ 'absolute', 'relative', 'relative' ],
      bottom: [ 0, 'none', 'none' ],
      borderRadius: '4px',
      marginTop: [ '100px', '120px', '120px' ],
      width: [ '100%' ],
      height: 'auto',
    } }
  >
    <Flex
      sx={ {
        justifyContent: 'flex-end',
        width: '100%',
      } }
    >
      <IconButton
        aria-label="close"
        data-testid="CloseButton"
        onClick={ onClose }
        sx={ { cursor: 'pointer', ml: 'auto' } }
      >
        <X size={ 80 } />
      </IconButton>
    </Flex>
    <Flex data-testid="zoom-in-modal-content" sx={ { flexDirection: 'column', alignItems: 'center' } }>
      <Image
        alt={ imageAltText }
        data-testid="fundraiserqrcode" src={ imageURL }
        sx={ { height: '400px', maxWidth: '400px' } }
      />
      <a
        download={ true } href={ imageURL } rel="noopener noreferrer"
        style={ { color: 'rgb(255 96 0)', fontWeight: 700, textDecoration: 'none' } }
        target="_blank"
      >
        { modalText }
      </a>
    </Flex>
  </SliceModal>
);

export default ZoomInModal;
