/* eslint-disable max-len */
/* eslint-disable max-lines-per-function */
import React from 'react';
import { useTranslation } from '@lce/i18n';
import {
  Flex, Link, Text,
} from '@lce/slice_v2';

import CardImage1 from './how-it-works-1.png';
import CardImage2 from './how-it-works-2.png';
import CardImage3 from './how-it-works-3.png';
import { InfoCard } from './InfoCards';

const HowItWorksInfoCards: React.FC = () => {
  const [ t ] = useTranslation();

  return (
    <Flex sx={ { flexDirection: [ 'column', 'column', 'row' ] } }>
      <InfoCard
        image={ CardImage1 }
        imageAlt={ t('howItWorks.CardTitle1') }
        sx={ {
          padding: '16px',
          margin: [ '8px 0', '8px 0', '0 9.5px' ],
          flexBasis: [ 1, '33.3333%', '33.3333%' ],
          position: 'relative',
          minHeight: [ 'none', '540px', '540px' ],
          boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.25)',
        } }
        title={ t('howItWorks.CardTitle1') }
      >
        <Text sx={ { lineHeight: '19px', color: 'primaryGrayDark', fontFamily: 'Roboto' } }>
          Maybe you’re a pro, maybe it’s your first time, either way, we're here to help. You can find all of these in your
          {' '}
          <Link href="/start-a-fundraiser" sx={ { color: 'primaryOrange' } }>Chairperson Account</Link>

          <ul>
            <li>A successful Fundraising Checklist</li>
            <li>Fundraiser Kick-off Guide</li>
            <li>Ready-to-go Press Release</li>
            <li>Group Fundraiser Goal Poster</li>
          </ul>

        </Text>
      </InfoCard>
      <InfoCard
        image={ CardImage2 }
        imageAlt={ t('howItWorks.CardTitle2') }
        sx={ {
          padding: '16px',
          margin: [ '8px 0', '8px 0', '0 9.5px' ],
          flexBasis: [ 1, '33.3333%', '33.3333%' ],
          position: 'relative',
          minHeight: [ 'none', '540px', '540px' ],
          boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.25)',
        } }
        title={ t('howItWorks.CardTitle2') }
      >
        <Text sx={ { lineHeight: '19px', color: 'primaryGrayDark', fontFamily: 'Roboto' } }>
          Stay organized during your fundraiser and spread the word about your fundraising goals!

          <ul>
            <li>Fun Graphics and Memes</li>
            <li>Little Caesars Fundraising Logos</li>
            <li>Mouth-Watering Pictures of Our Pizza</li>
          </ul>

        </Text>
      </InfoCard>
      <InfoCard
        image={ CardImage3 }
        imageAlt={ t('howItWorks.CardTitle3') }
        sx={ {
          padding: '16px',
          margin: [ '8px 0', '8px 0', '0 9.5px' ],
          flexBasis: [ 1, '33.3333%', '33.3333%' ],
          position: 'relative',
          minHeight: [ 'none', '540px', '540px' ],
          boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.25)',
        } }
        title={ t('howItWorks.CardTitle3') }
      >
        <Text sx={ { lineHeight: '19px', color: 'primaryGrayDark', fontFamily: 'Roboto' } }>
          If you hit any roadblocks, you can always use our Tools & Tips videos to see how others have done it. Also, our sales reps are a phone call away when you need help.
          { ' '}
          <Link href="/contact-us" sx={ { color: 'primaryOrange' } }>Contact Us</Link>
        </Text>
      </InfoCard>
    </Flex>
  );
};

export default HowItWorksInfoCards;
