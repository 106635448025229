// Fundraiser User Roles
export const AdminRoleName = 'Admin';

export const SuperAdminRoleName = 'SuperAdmin';

export const BuyerRoleName = 'Buyer';

export const ChairpersonRoleName = 'Chairperson';

export const SellerRoleName = 'Seller';

export const GuestsRoleName = 'Guest';

export const SalesAgentRoleName = 'SalesAgent';

export const ISCRoleName = 'Independent Sales Consultant';

// Fundraiser Product Type IDs
export const ComboProductItem = 10;

export const ProfitPerKit = 6;

export const CreateFundraiserSessionId = 'createFundraiserForm';
