import { useMutation } from 'react-query';

import { ApiError } from '../types';

import api from 'api';

const userInRolesRequest = (roles: string[]) => api.post('api/user/in-roles', { roles: roles }).then(res => res);

const useUserInRoles = () => useMutation<boolean, ApiError, string[]>(userInRolesRequest);

export { useUserInRoles };
