import React from 'react';
import { useHistory } from 'react-router-dom';
import {
  Box, Button, Image, Link, Text, Flex,
} from '@lce/slice_v2';
import { useTranslation } from '@lce/i18n';
import _map from 'lodash/map';

import lcLogo from './assets/lc-fundraising-logo.png';
import { GetGroupImage } from './utils/GetGroupImage';

import { useLocalStorage } from 'features/common';

interface ILandingGroupContent {
  parentId: string;
}

// eslint-disable-next-line max-lines-per-function
const LandingGroupContent: React.FC<ILandingGroupContent> = ({ parentId }) => {
  const [ t ] = useTranslation();
  const history = useHistory();
  const [ , setParentId ] = useLocalStorage<string>('parentId');
  const instructionSteps: string[] = t('landingGroup.instructionSteps', { returnObjects: true });
  const disclaimers: string[] = t('landingGroup.disclaimers', { returnObjects: true });

  const groupLogo = GetGroupImage(parentId);

  return (
    <Box data-testid="lg-content" sx={ { textAlign: 'center', mb: [ '50px', '120px' ], fontFamily: 'Lato' } }>
      <Flex
        sx={ {
          minHeight: [ '100px', '150px' ],
          alignItems: 'center',
          mb: [ '8px', '16px' ],
        } }
      >
        <Image
          alt={ groupLogo.alt }
          src={ groupLogo.logo }
          sx={ {
            m: 'auto',
            width: 200,
          } }
        />
      </Flex>
      <Text
        sx={ {
          fontSize: [ 7, 11 ], textTransform: 'uppercase', fontWeight: 'bold', fontFamily: 'mrEaves',
        } }
      >
        {`${ t('landingGroup.instructions') }:`}
      </Text>
      <Box
        as="ol"
        sx={ {
          textAlign: 'left',
          maxWidth: 890,
          m: '0 auto',
          mb: [ 6, '60px' ],
          marginInlineStart: 'auto',
          marginInlineEnd: 'auto',
          paddingInlineStart: '40px',
        } }
      >
        {_map(instructionSteps, step => (
          <Text
            as="li"
            key={ step } sx={ {
              fontSize: [ 4, 7 ],
              fontWeight: 'bold',
              color: 'black',
              lineHeight: 1.1,
              mb: [ 4, '48px' ],
              whiteSpace: 'pre-line',
              '::marker': {
                color: 'primaryOrange',
              },
            } }
          >
            {step}
          </Text>
        ))}
      </Box>
      <Image src={ lcLogo } sx={ { width: [ 148, 270 ], mb: [ 6, '120px' ] } } />
      {_map(disclaimers, disclaimer => (
        <Text key={ disclaimer } sx={ { fontSize: [ 4, 7 ], lineHeight: 1.1, mb: 4 } }>
          {disclaimer}
        </Text>
      ))}
      <Button
        data-testid="lg-button"
        onClick={ () => {
          setParentId(parentId);
          history.push('/start-a-fundraiser/create');
        } }
        sx={ {
          borderRadius: '8px', fontSize: [ 6, 8 ], mb: [ '36px', '60px' ], mt: [ 2, '50px' ],
          width: '100%', maxWidth: '555px', height: [ '72px', '78px' ],
        } }
      >
        { t('landingGroup.buttonText') }
      </Button>
      <Link
        href="/dashboard/fundraisers" sx={ {
          color: 'primaryOrange', display: 'block', fontSize: [ 6, 8 ], fontWeight: 'bold', textDecoration: 'none',
        } }
      >
        { t('landingGroup.linkText') }
      </Link>
    </Box>
  );
};

export default LandingGroupContent;
