import React from 'react';
import { Box, Grid, Skeleton } from '@lce/slice_v2';
import _times from 'lodash/times';

import { FieldLoader } from './ShippingDetailsLoader';

// eslint-disable-next-line max-lines-per-function
export const ShippingDetailsMDLoader: React.FC = () => (
  <Box data-testid="shipping-details-loader">
    <Skeleton count={ 1 } sx={ { height: '27px', width: '30%', mb: '24px' } } />
    <Grid
      sx={ {
        mb: '30px',
        gridColumnGap: '24px',
        gridRowGap: '30px',
        gridTemplateColumns: [ '1fr', '1fr 1fr' ],
      } }
    >
      { _times(4, index => (
        <FieldLoader key={ `user-${ index }` } />
      )) }
    </Grid>

    <Skeleton count={ 1 } sx={ { height: '20px', width: '20%' } } />
    <Skeleton count={ 1 } sx={ { height: '27px', width: '40%', mb: '40px' } } />

    <Skeleton count={ 1 } sx={ { height: '36px', width: '100%' } } />
  </Box>
);
