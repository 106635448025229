import { useTranslation } from '@lce/i18n';
import { Box, Text } from '@lce/slice_v2';
import React from 'react';

const PopoverMessage = () => {
  const [ t ] = useTranslation();
  return (
    <Box sx={ { position: 'relative' } }>
      <Box
        sx={ {
          backgroundColor: 'white', borderRadius: '4px',
          boxShadow: '0 0 4px 1px rgba(0, 0, 0, 0.15)', mt: 2, maxWidth: '350px',
        } }
      >
        <Box
          sx={ {
            borderRadius: '4px', border: 'solid 1px #ff6000', p: 4, maxWidth: '350px',
          } }
        >
          <Text sx={ { fontSize: '16px', lineHeight: 1.2, mb: 2 } }>
            {t('brochure.preferredTimePopoverMessage')}
          </Text>
        </Box>
      </Box>
    </Box>

  );
};

export default PopoverMessage;
