import { useTranslation } from '@lce/i18n';
import {
  Box, Card, Divider, Flex, Text, Link,
} from '@lce/slice_v2';
import React from 'react';
import { format } from 'date-fns';
import numeral from 'numeral';

import { OrderHistoryItem } from 'features/orders';

export interface IOrderHistoryListItemProps {
  order: OrderHistoryItem;
}

const renderOrderStat = (title, value) => (
  <Box sx={ { flexBasis: '33%', padding: [ 0, 0, '0 5px' ], margin: [ '8px 0', 0, 0 ] } }>
    <Text variant="text.orderHistory.label">
      {title}
    </Text>
    <Text variant="text.h5">
      {value}
    </Text>
  </Box>
);

const OrderHistoryListItem: React.FC<IOrderHistoryListItemProps> = ({ order }) => {
  const [ t ] = useTranslation();

  return (
    <>
      <Text sx={ { fontSize: '20px', mb: '10px' } } variant="text.header.sub">
        {`${ order.GroupName } - ${ order.SellerName }`}
      </Text>
      <Flex
        as={ Card } sx={ {
          maxWidth: '855px', flexDirection: 'column', padding: '30px', borderColor: 'primaryGrayMid', mb: '24px',
        } }
      >
        <Flex sx={ { flexDirection: [ 'column', 'column', 'row' ] } }>
          {renderOrderStat('Order Id', order.OrderId)}
          {renderOrderStat('Date of Purchase', format(new Date(order.OrderDate), 'MM/dd/yyyy'))}
          {renderOrderStat('Order Total', numeral(order.OrderTotal).format(t('currency.format')))}
        </Flex>
        <Divider sx={ { color: 'primaryGrayMid' } } />
        <Flex sx={ { flexDirection: [ 'column', 'column', 'row' ] } }>
          <Box sx={ { flexBasis: '33%' } }>
            <Text variant="text.orderHistory.label">FedEx Tracking ID</Text>
            { order.TrackingNumber
              ? (
                <Link
                  // eslint-disable-next-line max-len
                  href={ `https://www.fedex.com/apps/fedextrack/index.html?tracknumbers=${ order.TrackingNumber }&cntry_code=us` }
                  sx={ { color: 'primaryOrange' } }
                  variant="text.h5"
                >
                  {order.TrackingNumber || 'Not yet available'}
                </Link>
              )
              : <Text>Not Yet Available</Text> }
          </Box>
          {renderOrderStat('Order Status', order.OrderStatus)}
          {renderOrderStat('Payment Status', order.PaymentStatus)}
        </Flex>
      </Flex>
    </>
  );
};

export default OrderHistoryListItem;
