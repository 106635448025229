import * as React from 'react';
import {
  Box,
  Button, Flex, Headline, Modal as SliceModal,
  Text,
} from '@lce/slice_v2';
import { useTranslation } from '@lce/i18n';

export interface IDeliveryRestrictionsModal {
  isOpen: boolean;
  onClose: () => void;
}

const DeliveryRestrictionsModal: React.FC<IDeliveryRestrictionsModal> = ({ isOpen, onClose }) => {
  const [ t ] = useTranslation();
  return (
    <SliceModal
      closeIcon={ false }
      closeOnClickOutside={ false }
      data-testid="delivery-restrictions-modal"
      isOpen={ isOpen }
      key="delivery-restrictions-modal"
      lockBodyScroll={ true }
      onClose={ onClose }
      sx={ {
        width: [ '90%', '100%' ],
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        m: '0 !important',
        borderRadius: '4px',
      } }
      variant="modals.profitCheckAddressModal.modalContainer"
    >
      <Flex sx={ { flexDirection: 'column', gap: '16px' } }>
        <Box>
          <Headline>
            {t('fundraiser.DeliveryRestrictionModal.title')}
          </Headline>
        </Box>

        <Text>
          {t('fundraiser.DeliveryRestrictionModal.content')}
        </Text>

        <Button
          data-testid="delivery-restrictions-modal-button"
          onClick={ onClose }
          sx={ { ml: 'auto' } }
          variant="primary"
        >
          {t('fundraiser.DeliveryRestrictionModal.buttonText')}
        </Button>
      </Flex>

    </SliceModal>
  );
};

export default DeliveryRestrictionsModal;
