/* eslint-disable react/no-danger */
import React from 'react';
import { Container, Spinner } from '@lce/slice_v2';
import DOMPurify from 'dompurify';

import { useFetchCampaignPolicy } from 'features/legal';

/**
 * Currently relies on a "topic" in nopCommerce with system name "OnlineCampaignPolicy"
 */
const CampaignPolicy: React.FC = () => {
  const { data: policy, isSuccess, isLoading } = useFetchCampaignPolicy();

  return (
    <>
      { isSuccess && (
        <Container>
          <div
            className="legal-section"
            dangerouslySetInnerHTML={ {
              __html: DOMPurify.sanitize(policy),
            } }
          />
        </Container>
      )}

      { isLoading && (
        <Container sx={ { textAlign: 'center' } }>
          <Spinner variant="lce" />
        </Container>
      )}

    </>
  );
};

export default CampaignPolicy;
