import * as React from 'react';
import {
  Box, Flex, Heading, Text,
} from '@lce/slice_v2';
import { useTranslation } from '@lce/i18n';

import {
  useCartState, useCartDispatch, modifyCart, CartItem,
} from 'features/cart';
import { useQueryParam } from 'features/common';
import { CartListItem, EmptyCartCaesar } from 'ui/cart';
import { FundraiserTracker } from 'ui/fundraiser/FundraiserTracker';
import { OrderSummary, CheckoutDisabledModal, CheckoutNoFundraiserModal } from 'ui/checkout';
import { useFundraiserState } from 'features/fundraiser/context/fundraiserContext';
import { useCartContainsIneligibleProducts } from 'features/cart/hooks';

// eslint-disable-next-line max-lines-per-function
export const MealDealCartPage: React.FC = () => {
  const [ t ] = useTranslation();
  const cartState = useCartState();
  const cartDispatch = useCartDispatch();
  const { fundraiserType } = useFundraiserState();
  const [ checkoutError ] = useQueryParam('error');

  const isNotCouponCodeItem = cartState?.cart?.items?.some(item => !item.IsFreeShipping);
  const isIneligibleProduct = useCartContainsIneligibleProducts();
  const showIneligibleCartItemWarning = isNotCouponCodeItem || isIneligibleProduct;
  const onProductQuantityChange = (item: CartItem, quantity: number) => {
    modifyCart(cartDispatch, item.ProductId, quantity);
  };

  return (
    <div data-testid="meal-deal-cart-page">
      {checkoutError === 'nocheckout' && <CheckoutDisabledModal /> }
      {checkoutError === 'nofundraiser' && <CheckoutNoFundraiserModal />}
      <FundraiserTracker />
      <Heading mb="40px">
        {cartState.totalQuantity === 1
          ? `${ t('cart.YourCartTitle') } (${ cartState.totalQuantity } ${ t('cart.Item') })`
          : `${ t('cart.YourCartTitle') } (${ cartState.totalQuantity } ${ t('cart.Items') })`}
      </Heading>
      {showIneligibleCartItemWarning && (
        <Text data-testid="ineligible-items-message" sx={ { mb: 4, fontSize: '16px' } } variant="text.warning">
          {t('cart.CartHasIneligibleItems')}
        </Text>
      )}
      <Flex sx={ { flexDirection: [ 'column', 'column', 'row' ] } }>
        <Box sx={ { width: '100%' } }>
          {cartState.cart.items.map((item) => {
            if (!item.IsFreeShipping && !item.Warnings.includes('WARN-PK-P-08')) {
              item.Warnings.push('WARN-PK-P-08');
            }
            return (
              <CartListItem
                cartItem={ item }
                key={ `${ item.ProductId }-${ item.Quantity }` }
                onQuantityChange={ onProductQuantityChange }
              />
            );
          })}
          { cartState.cart.items.length === 0 && (
            <EmptyCartCaesar />
          )}
        </Box>

        { cartState.cart && cartState.cart.items.length > 0 && (
          <OrderSummary
            cartItems={ cartState.cart.items }
            fundraiserType={ fundraiserType }
            ineligibleItems={ showIneligibleCartItemWarning }
            shouldDisplayShipping={ false }
            shouldDisplaySubtotal={ false }
            subtotal={ cartState.subtotal }
            supportGuid={ cartState.sellerId }
            type="cart"
          />
        )}
      </Flex>
    </div>
  );
};
