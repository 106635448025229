import React from 'react';
import {
  Box, Button, Flex, Heading, Text,
} from '@lce/slice_v2';
import { RouteComponentProps } from 'react-router-dom';
import { useTranslation } from '@lce/i18n';

import { useFetchFundraiserSellerSummary, useFundraiserSellerSummaryExcel } from 'features/fundraiser';
import { SellerSummaryListItem } from 'ui/fundraiser';

interface MatchParams {
  fundraiserId: string;
}

type FundraiserSellerReportType = RouteComponentProps<MatchParams>;

// eslint-disable-next-line max-lines-per-function
const FundraiserSellerReport: React.FC<FundraiserSellerReportType> = ({ match }) => {
  const [ t ] = useTranslation();
  const { data: report, isError } = useFetchFundraiserSellerSummary(parseInt(match.params.fundraiserId));

  const { refetch } = useFundraiserSellerSummaryExcel(parseInt(match.params.fundraiserId));

  const onClickHandler = () => {
    refetch();
  };

  if (isError) {
    return (
      <Text>Something went wrong when retrieving your summary. Please try again later or call customer support.</Text>
    );
  }

  return (
    <Box>
      { report && (
        <>
          <Flex
            sx={ {
              justifyContent: 'space-between',
              flexDirection: [ 'column', 'column', 'row' ],
              mb: '16px',
              maxWidth: '855px',
            } }
          >
            <Flex sx={ { flexDirection: 'column' } }>
              <Heading sx={ { fontWeight: 900, fontSize: '36px' } }>
                <Text sx={ { textTransform: 'uppercase' } } variant="text.header.title">
                  {t('fundraiserReport.SellerReport')}
                </Text>
                {report.FundraiserName}
              </Heading>
              <Text sx={ { fontSize: '20px' } } variant="text.header.form">
                {`Fundraiser ID: ${ report.FundraiserId }`}
              </Text>
            </Flex>
            <Flex>
              <Button
                onClick={ onClickHandler }
                sx={ { height: '46px', paddingLeft: [ 0, '12px', '12px' ] } }
                variant="text"
              >
                {t('fundraiserReport.Download')}
              </Button>
            </Flex>
          </Flex>
          { report.FundraiserSellerSummary
            .map(data => <SellerSummaryListItem data={ data } key={ data.SellerId } />)}
        </>
      )}
    </Box>
  );
};

export default FundraiserSellerReport;

