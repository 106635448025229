import * as React from 'react';
import { useTranslation } from '@lce/i18n';
import { Text, Flex } from '@lce/slice_v2';
import _countBy from 'lodash/countBy';
import _filter from 'lodash/filter';
import _sumBy from 'lodash/sumBy';

import { ComboProductItem } from '../../../../constants';

import { CartItem } from 'features/cart';
import { displayShippingInfoText } from 'ui/checkout/utils/displayShippingInfoText';
import { areAllFreeShippingItemsDonationItems } from 'features/fundraiser/utils';

export interface IUpsellProps {
  cart: CartItem[];
}

const OrderUpsellText: React.FC<IUpsellProps> = ({ cart }) => {
  const [ t ] = useTranslation();

  const shippableKits = _filter(cart, item => !item.IsFreeShipping && item.ProductTypeId !== ComboProductItem);
  const shippableKitCount = _sumBy(shippableKits, 'Quantity');

  const freeShippingItems = _countBy(cart, ({ IsFreeShipping }) => IsFreeShipping).true;
  const cartHasFreeShippingItems = freeShippingItems > 0;
  const hasOnlyDonationItems = areAllFreeShippingItemsDonationItems(cart);

  const { couponCodeText, shippingText } = displayShippingInfoText(cartHasFreeShippingItems, shippableKitCount);

  return (
    <Flex data-testid="shipping-info-text" sx={ { flexDirection: 'column', gap: '16px' } }>
      {shippingText && (
        <Text variant="text.helper">
          {t(shippingText)}
        </Text>
      )}
      {!hasOnlyDonationItems && couponCodeText && (
        <Text variant="text.helper">
          {t(couponCodeText)}
        </Text>
      )}
    </Flex>
  );
};

export default OrderUpsellText;
