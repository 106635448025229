import React from 'react';
import {
  Flex, Skeleton,
} from '@lce/slice_v2';

const FundraiserListItemSkeleton = () => (
  <Flex variant="cards.fundraiserList.item">
    <Flex sx={ { width: [ '100%', '200px', '300px' ], height: [ '200px', '100%', '100%' ], alignItems: 'center' } }>
      <Skeleton count={ 1 } sx={ { height: '100%', width: '100%' } } />
    </Flex>
    <Flex
      sx={ {
        marginLeft: [ '0px', '20px' ], marginTop: [ '20px', '0px' ], flex: 1, flexDirection: 'column',
      } }
    >
      <Skeleton count={ 1 } sx={ { height: '24px', width: '40%' } } />
      <Skeleton count={ 1 } sx={ { height: '16px', width: '20%' } } />
      <Skeleton count={ 1 } sx={ { height: '16px', width: '30%', mt: '20px' } } />
      <Skeleton count={ 1 } sx={ { height: '16px', width: '25%' } } />
      <Skeleton count={ 1 } sx={ { height: '16px', width: '20%' } } />
    </Flex>
  </Flex>
);

export default FundraiserListItemSkeleton;
