import React, { useState } from 'react';
import {
  Layer, Box, Text, Spinner,
} from '@lce/slice_v2';
import { noop } from 'lodash';
import { useTranslation } from '@lce/i18n';

import { Modal } from 'ui/common';

export interface IPaymentFailedModalProps {
  openModal: boolean;
  onClose: () => void;
}

export interface IOrderFailedModalProps {
  openModal: boolean;
  onClose: () => void;
}

export interface IProcessingOrderOverlayProps {
  openOverlay: boolean;
}

const CheckoutDisabledModal: React.FC = () => {
  const [ isOpen, setIsOpen ] = useState<boolean>(true);
  return (
    <Modal
      ctaText="Dismiss"
      heading="Uh oh!"
      isOpen={ isOpen }
      message="Checkout is currently unavailable. Please try again later!"
      onClose={ () => setIsOpen(false) }
    />
  );
};

const CheckoutNoFundraiserModal: React.FC = () => {
  const [ isOpen, setIsOpen ] = useState<boolean>(true);
  return (
    <Modal
      ctaText="Dismiss"
      heading="Uh oh!"
      isOpen={ isOpen }
      // eslint-disable-next-line max-len
      message="You must be supporting a fundraiser to checkout. Please obtain a support link from a seller and try again!"
      onClose={ () => setIsOpen(false) }
    />
  );
};

const OrderFailMessage = () => {
  const [ t ] = useTranslation();
  return (
    <>
      <div>
        { t('checkout.OrderProcessError.message1') }
      </div>
      <div>
        { t('checkout.OrderProcessError.message2') }
      </div>
    </>
  );
};

const PlaceOrderFailedPayment: React.FC<IOrderFailedModalProps> = ({ openModal, onClose }) => {
  const [ isOpen, setIsOpen ] = useState<boolean>(openModal);
  const [ t ] = useTranslation();

  return (
    <Modal
      ctaText={ t('checkout.CTAText') }
      heading={ t('checkout.OrderProcessError.heading') }
      isOpen={ isOpen }
      message={ <OrderFailMessage /> }
      onClose={ () => {
        setIsOpen(false);
        onClose();
      } }
    />
  );
};

const OrderProcessingOverlay: React.FC<IProcessingOrderOverlayProps> = ({ openOverlay }) => (
  <Layer
    closeOnClickOutside={ false }
    isOpen={ openOverlay }
    lockBodyScroll={ true }
    onClose={ () => noop }
    overlay={ true }
  >
    <Box
      sx={ {
        textAlign: 'center',
        position: 'relative',
        background: 'white',
        margin: 'auto',
        width: [ '95%', '350px' ],
        top: '20%',
        padding: '55px',
        zIndex: 1002,
      } }
    >
      <Text
        as="h3"
        sx={ { fontSize: '26px', mb: '16px' } }
        variant="text.header.subUpper"
      >
        Processing your order...
      </Text>
      <Spinner variant="lce" />
    </Box>
  </Layer>
);

export {
  CheckoutDisabledModal,
  PlaceOrderFailedPayment,
  CheckoutNoFundraiserModal,
  OrderProcessingOverlay,
};
