import { useMutation } from 'react-query';

import { PlaceOrderRequest } from '../types/request';
import { placeOrder } from '../api';
import { PlaceOrderResponse } from '../types/response/checkout';

import { ApiError } from 'features/common';

const usePlaceOrder = () => useMutation<PlaceOrderResponse, ApiError, PlaceOrderRequest>(placeOrder,
  {
    onError: err => console.log(err),
  });

export { usePlaceOrder };
