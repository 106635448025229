/* eslint-disable complexity */
/* eslint-disable max-lines-per-function */
import React, { useRef, useState } from 'react';
import {
  Button, Box, Form, Flex, Text, Option,
  Image,
} from '@lce/slice_v2';
import _ from 'lodash';
import * as Yup from 'yup';
import { useTranslation } from '@lce/i18n';

import infoIcon from '../../../../assets/icons/info-icon.svg';

import { WeekSelector } from './components/WeekSelector';
import DeliveryDetailsSummary from './DeliveryDetailsSummary';
import { DeliveryRestrictionsModal } from './components/DeliveryRestrictionsModal';
import { PopoverMessage } from './components/PopoverMessage';

import { Mode, Step } from 'ui/common';
import { FundraiserType } from 'features/fundraiser';
import { useFetchStates } from 'features/common/hooks';
import { PhoneInput, phonePattern } from 'ui/components/forms';
import {
  DeliveryTime, FundraiserBrochure, FundraiserFormStepState, TimeZone,
} from 'features/fundraiser/types/fundraiser';
import { TooltipPopover } from 'ui/fundraiser';

export interface IDeliveryDetailsProps {
  onEdit: () => void;
  onNext: (info: Partial<FundraiserBrochure>) => void;
  info?: FundraiserBrochure | undefined;
  mode: Mode;
  fundraiserType?: FundraiserType;
}

const DeliveryDetails: React.FC<IDeliveryDetailsProps> = ({
  onEdit, onNext, mode, info,
}) => {
  const [ t ] = useTranslation();
  const [ visible, setVisible ] = useState(false);
  const popoverRef = useRef<HTMLImageElement>(null);

  const timeZones: TimeZone[] = t('timezones', { returnObjects: true });
  const deliveryTimes: DeliveryTime[] = t('brochure.fundraiserDeliveryTimes', { returnObjects: true });

  const [ sameAsMailing, setSameAsMailing ] = useState<boolean>(info?.sameAsMailing || false);
  const [ showDeliveryRestrictionsModal, setShowDeliveryRestrictionsModal ] = useState<boolean>(false);
  const [ deliveryWeekError, setDeliveryWeekError ] = useState<boolean>(false);
  const handleCopyMailingAddress = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setSameAsMailing(e.currentTarget.checked);
  };

  const handleOnSubmit = (data: Partial<FundraiserBrochure>) => {
    let info = {
      ...data,
      deliveryMainCrossStreets: `${ data.crossStreetOne } and ${ data.crossStreetTwo }`,
    };

    if (sameAsMailing) {
      info = {
        ...info,
        deliveryAddress: info?.organizationStreetAddress,
        deliveryCity: info?.organizationCity,
        deliveryState: info?.organizationState,
        deliveryZipCode: info?.organizationZipCode,
      };
    }

    onNext(info);
  };

  const validationSchema = Yup.object({
    deliveryName: Yup.string()
      .required(t('fundraiser.validation.DeliveryNameRequired'))
      .max(255, t('fundraiser.MaxLength255'))
      .trim(),
    deliveryPhoneNumber: Yup.string()
      .matches(phonePattern, t('fundraiser.PhoneNumberValid'))
      .min(10, t('fundraiser.PhoneNumberLength'))
      .max(17, t('fundraiser.PhoneNumberLength'))
      .required(t('fundraiser.PhoneRequired'))
      .trim(),
    deliveryAddress: Yup.string().trim().when('sameAsMailing', {
      is: false,
      then: Yup.string().required(t('fundraiser.validation.DeliveryAddressRequired')),
    }),
    deliveryCity: Yup.string().trim().when('sameAsMailing', {
      is: false,
      then: Yup.string().required(t('fundraiser.validation.DeliveryCityRequired')),
    }),
    deliveryState: Yup.string().when('sameAsMailing', {
      is: false,
      then: Yup.string().required(t('fundraiser.validation.DeliveryStateRequired')),
    }),
    deliveryZipCode: Yup.string().trim().when('sameAsMailing', {
      is: false,
      then: Yup.string().required(t('fundraiser.validation.DeliveryZipCodeRequired')),
    }),
    deliveryRestrictions: Yup.boolean(),
    timeZone: Yup.number().required(t('fundraiser.validation.TimeZoneRequired')),
    preferredDeliveryWeek: Yup.string().required().test('deliveryWeekSelected', '', (value) => {
      if (!value) {
        setDeliveryWeekError(true);
        return false;
      }
      setDeliveryWeekError(false);
      return true;
    }),
    deliveryComments: Yup.string().trim(),
    preferredDeliveryTime: Yup.number().required(t('fundraiser.validation.DeliveryTimeRequired')),
    crossStreetOne: Yup.string().required(t('fundraiser.validation.CrossStreetRequired')).trim(),
    crossStreetTwo: Yup.string().required(t('fundraiser.validation.CrossStreetRequired')).trim(),
  });

  const stepTitle = t('fundraiser.DeliveryDetails');

  const handleDeliveryRestrictionsModal = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (e.currentTarget.checked) {
      setShowDeliveryRestrictionsModal(true);
    }
  };

  const { data: states, isLoading } = useFetchStates(1);
  const filteredStates = states?.filter(state => state.Abbreviation !== 'AK' && state.Abbreviation !== 'HI');

  return (
    <>
      { mode === FundraiserFormStepState.Active && !isLoading && (
        <Step onEdit={ onEdit } title={ stepTitle }>
          <Box data-testid="chairperson-details-form" variant="cards.step.body">
            <Form
              initialValues={ info }
              onSubmit={ handleOnSubmit }
              summary={ false }
              validationSchema={ validationSchema }
            >
              <Flex sx={ { flexDirection: 'column' } }>
                <Text sx={ { mb: '6px' } } variant="text.header.form">
                  {t('fundraiser.labels.DeliveryAddress')}
                </Text>
                <Flex sx={ { flexDirection: 'column', gap: '16px' } }>
                  <Flex sx={ { flexDirection: [ 'column', 'row' ], gap: '16px' } }>
                    <Form.Field
                      component={ Form.Input.Checkbox }
                      data-testid="same-as-mailing"
                      defaultChecked={ info?.sameAsMailing }
                      id="sameAsMailing"
                      inline={ true }
                      label={ t('fundraiser.labels.CopyMailingAddress') }
                      name="sameAsMailing"
                      onChange={ handleCopyMailingAddress }
                      sx={ { mt: '16px', svg: { color: 'black' } } }
                    />
                  </Flex>
                  <Flex sx={ { flexDirection: 'column', gap: '16px' } }>
                    <Form.Field
                      data-testid="delivery-location"
                      id="deliveryName"
                      label={ t('fundraiser.labels.DeliveryLocation') }
                      name="deliveryName"
                      sx={ { my: '0px' } }
                      variant="forms.fundraiser.field"
                    />
                    {sameAsMailing ? (
                      <Form.Field
                        autoComplete="none"
                        data-testid="street-address"
                        disabled={ true }
                        id="deliveryAddress"
                        label={ t('fundraiser.labels.GroupAddress1') }
                        name="deliveryAddress"
                        sx={ { my: '0px' } }
                        value={ info?.organizationStreetAddress }
                        variant="forms.fundraiser.field.disabled"
                      />
                    ) : (
                      <Form.Field
                        autoComplete="none"
                        data-testid="street-address"
                        id="deliveryAddress"
                        label={ t('fundraiser.labels.GroupAddress1') }
                        name="deliveryAddress"
                        sx={ { my: '0px' } }
                        variant="forms.fundraiser.field"
                      />
                    )}
                  </Flex>
                  <Flex sx={ { flexDirection: [ 'column', 'row' ], gap: '16px' } }>
                    {sameAsMailing ? (
                      <Form.Field
                        autoComplete="none"
                        disabled={ true }
                        id="deliveryCity"
                        label={ t('fundraiser.labels.GroupCity') }
                        name="deliveryCity"
                        sx={ { my: '0px' } }
                        value={ info?.organizationCity }
                        variant="forms.fundraiser.field.disabled"
                      />
                    ) : (
                      <Form.Field
                        autoComplete="none"
                        id="deliveryCity"
                        label={ t('fundraiser.labels.GroupCity') }
                        name="deliveryCity"
                        sx={ { my: '0px' } }
                        variant="forms.fundraiser.field"
                      />
                    )}
                    {sameAsMailing ? (
                      <Form.Field
                        autoComplete="none"
                        component={ Form.Select }
                        defaultValue={ info?.organizationState }
                        disabled={ true }
                        id="deliveryState"
                        label={ t('fundraiser.labels.GroupState') }
                        name="deliveryState"
                        sx={ { my: '0px' } }
                        value={ info?.organizationState }
                        variant="forms.fundraiser.field.disabled"
                      >
                        {filteredStates && _.map(filteredStates, state => (
                          <Option
                            key={ state.Id }
                            label={ state.Abbreviation }
                            value={ state.Abbreviation }
                          />
                        ))}
                      </Form.Field>
                    ) : (
                      <Form.Field
                        autoComplete="none"
                        component={ Form.Select }
                        defaultValue={ info?.deliveryState }
                        id="deliveryState"
                        label={ t('fundraiser.labels.GroupState') }
                        name="deliveryState"
                        sx={ { my: '0px' } }
                        variant="forms.fundraiser.field"
                      >
                        <Option value="">---</Option>
                        {filteredStates && _.map(filteredStates, state => (
                          <Option
                            key={ state.Id }
                            label={ state.Abbreviation }
                            value={ state.Abbreviation }
                          />
                        ))}
                      </Form.Field>
                    )}
                    {sameAsMailing ? (
                      <Form.Field
                        autoComplete="none"
                        disabled={ true }
                        id="organizationZipCode"
                        label={ t('fundraiser.labels.GroupZip') }
                        name="organizationZipCode"
                        sx={ { my: '0px' } }
                        value={ info?.organizationZipCode }
                        variant="forms.fundraiser.field.disabled"
                      />
                    ) : (
                      <Form.Field
                        autoComplete="none"
                        id="deliveryZipCode"
                        label={ t('fundraiser.labels.GroupZip') }
                        name="deliveryZipCode"
                        sx={ { my: '0px' } }
                        variant="forms.fundraiser.field"
                      />
                    )}
                  </Flex>
                  <Flex sx={ { flexDirection: [ 'column' ] } }>
                    <Form.Label htmlFor="crossStreetOne">
                      {t('fundraiser.labels.TwoMainCrossStreets')}
                    </Form.Label>
                    <Flex sx={ { gap: '12px', alignItems: 'center' } }>
                      <Form.Field
                        data-testid="cross-street-one"
                        defaultValue={ info?.crossStreetOne || '' }
                        id="crossStreetOne"
                        label=""
                        name="crossStreetOne"
                        sx={ { my: '0px' } }
                        variant="forms.fundraiser.field"
                      />
                      <Text sx={ { display: 'flex', alignSelf: 'flex-end', pb: 2 } }>and</Text>
                      <Form.Field
                        data-testid="cross-street-two"
                        defaultValue={ info?.crossStreetTwo || '' }
                        id="crossStreetTwo"
                        label=""
                        name="crossStreetTwo"
                        sx={ { my: '0px' } }
                        variant="forms.fundraiser.field"
                      />
                    </Flex>
                  </Flex>
                  <Flex sx={ { flexDirection: 'column', gap: '16px' } }>
                    <PhoneInput
                      defaultValue={ info?.deliveryPhoneNumber }
                      hint={ t('fundraiser.labels.DeliveryPhoneNumberHint') }
                      label={ t('fundraiser.labels.DeliveryPhoneNumber') }
                      name="deliveryPhoneNumber"
                      sx={ { my: '0px' } }
                      testId="phone-number"
                    />
                  </Flex>
                  <Flex sx={ { flexDirection: 'column', gap: '16px' } }>
                    <Form.Field
                      component={ Form.Input.Checkbox }
                      data-testid="delivery-restrictions"
                      defaultChecked={ info?.deliveryRestrictions }
                      id="deliveryRestrictions"
                      inline={ true }
                      label={ t('fundraiser.labels.DeliveryRestrictions') }
                      name="deliveryRestrictions"
                      onChange={ handleDeliveryRestrictionsModal }
                      sx={ { my: '0px', svg: { color: 'black' } } }
                    />
                  </Flex>
                </Flex>
                <Text sx={ { mb: '6px', mt: '24px' } } variant="text.header.form">
                  {t('fundraiser.labels.DeliveryScheduling')}
                </Text>
                <Flex sx={ { flexDirection: 'column', gap: '16px' } }>
                  <Form.Field
                    component={ Form.Select }
                    defaultValue={ info?.timeZone || 0 }
                    id="timeZone"
                    label={ t('fundraiser.labels.TimeZone') }
                    name="timeZone"
                    sx={ { my: '0px' } }
                    variant="forms.fundraiser.field"
                  >
                    { _.map(timeZones, timezone => (
                      <Option key={ timezone.value } label={ timezone.label } value={ timezone.value } />
                    ))}
                  </Form.Field>
                  <WeekSelector defaultDate={ info?.preferredDeliveryWeek } validationError={ deliveryWeekError } />
                  <Form.Field
                    component={ Form.Select }
                    defaultValue={ info?.preferredDeliveryTime || 0 }
                    id="preferredDeliveryTime"
                    label={ (
                      <Flex sx={ { gap: '4px' } }>
                        {t('fundraiser.labels.PreferredTimeOfDelivery')}
                        {' '}
                        <Image
                          onClick={ () => setVisible(!visible) } ref={ popoverRef } src={ infoIcon } sx={ {
                            cursor: 'pointer', ml: 2, mb: -1, height: '20px', width: '20px',
                          } }
                        />
                        <TooltipPopover referenceElement={ popoverRef } setVisible={ setVisible } visible={ visible }>
                          <PopoverMessage />
                        </TooltipPopover>
                      </Flex>
                    ) }
                    name="preferredDeliveryTime"
                    sx={ { my: '0px' } }
                    variant="forms.fundraiser.field"
                  >
                    { _.map(deliveryTimes, deliveryTime => (
                      <Option key={ deliveryTime.value } label={ deliveryTime.label } value={ deliveryTime.value } />
                    ))}
                  </Form.Field>
                  <Form.Field
                    component={ Form.Input.Textarea }
                    defaultValue={ info?.deliveryComments || '' }
                    id="deliveryComments"
                    label={ t('fundraiser.labels.DeliveryComments') }
                    name="deliveryComments"
                    rows={ 2 }
                    sx={ { my: '0px' } }
                  />
                </Flex>
                <Flex />

                <Button data-testid="submit-button" id="deliveryDetailsNext" sx={ { mt: '24px' } } type="submit">
                  {t('fundraiser.AdditionalInformationNext')}
                </Button>
              </Flex>
            </Form>
          </Box>
          <DeliveryRestrictionsModal
            isOpen={ showDeliveryRestrictionsModal }
            onClose={ () => setShowDeliveryRestrictionsModal(false) }
          />
        </Step>
      )}
      { mode === FundraiserFormStepState.Preview && (
        <Step customText="deliveryDetails-edit" onEdit={ onEdit } showEdit={ true } title={ stepTitle }>
          <Box variant="cards.step.body">
            <DeliveryDetailsSummary deliveryTimes={ deliveryTimes } details={ info } t={ t } />
          </Box>
        </Step>
      )}
      { mode === FundraiserFormStepState.Hidden && (
        <Step title={ stepTitle } />
      )}
    </>
  );
};

export default DeliveryDetails;
