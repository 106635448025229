import React from 'react';
import {
  Modal as SliceModal, IModalProps as ISliceModalProps, IconButton, Flex, Box,
} from '@lce/slice_v2';
import { XCircle } from 'react-feather';

import { VideoPlayer } from '../../common/VideoPlayer';

export interface IModalProps {
  url: string;
  onClose?: () => void;
}

const VideoModal: React.FC<IModalProps & ISliceModalProps> = ({ url, ...props }) => (
  <SliceModal
    { ...props }
    closeIcon={ false }
    closeOnClickOutside={ false }
    onClose={ props.onClose }
    sx={ {
      backgroundColor: 'inherit',
      maxWidth: '900px',
      marginTop: [ '120px', '120px', '120px' ],
      width: [ '90%', '64%', '100%' ],
    } }
  >
    <Flex
      sx={ {
        justifyContent: 'flex-end',
        ml: [ '28px', '56px' ],
        width: '100%',
      } }
    >
      <IconButton
        aria-label="close" onClick={ props.onClose }
        sx={ {
          cursor: 'pointer', color: 'white', width: '56px', height: '56px',
        } }
      >
        <XCircle size={ 56 } />
      </IconButton>
    </Flex>
    <Box>
      <VideoPlayer
        fullSize={ true }
        src={ url }
        title="lce-fundraising-video-player"
      />
    </Box>
  </SliceModal>
);

export default VideoModal;
