/* eslint-disable max-lines-per-function */
import React from 'react';
import * as Yup from 'yup';
import {
  Form, Flex, Text, Link,
} from '@lce/slice_v2';
import { TFunction } from 'i18next';
import { ThemeUIStyleObject } from 'theme-ui';

import { useFormField } from 'ui/hooks';
import { useMergeWithDefaults } from 'ui/components/utils';

export type TermsAndConditionsInputProps = {
  defaultValue?: boolean;
  id?: string;
  name?: string;
  required?: boolean;
  sx?: ThemeUIStyleObject;
  testId?: string;
  variant?: string;
}

export const dataTestIdTermsAndConditionsInput = 'terms-and-conditions';
export const defaultTermsAndConditionsInputName = 'TermsAndConditions';

export const defaultPropsTermsAndConditionsInput: TermsAndConditionsInputProps = {
  defaultValue: false,
  id: defaultTermsAndConditionsInputName,
  name: defaultTermsAndConditionsInputName,
  required: true,
  sx: { mt: '16px', svg: { color: 'black' }, width: 'fit-content' },
  testId: dataTestIdTermsAndConditionsInput,
  variant: 'forms.fundraiser.field',
};

export const TermsAndConditionsInput: React.FC<TermsAndConditionsInputProps> = (props) => {
  const {
    defaultValue, name, sx, testId, variant, ...rest
  } = useMergeWithDefaults<
  TermsAndConditionsInputProps,
  TermsAndConditionsInputProps
  >(props, defaultPropsTermsAndConditionsInput, [ 'label' ]);

  return (
    <Flex data-testid={ testId } sx={ { flexDirection: [ 'column', 'row' ] } }>
      <Form.Field
        component={ Form.Input.Checkbox }
        defaultChecked={ defaultValue }
        id="TermsAndConditions"
        inline={ true }
        label={ (
          <Text>
            I have read and agree to LittleCaesars.com's
            {' '}
            <Link
              href="/legal/terms-of-service"
              sx={ { color: 'primaryOrange' } }
              tabIndex={ -1 }
              target="_blank"
            >
              Terms of Service
            </Link>
            ,
            {' '}
            <Link
              href="/legal/online-campaign-policy"
              sx={ { color: 'primaryOrange' } }
              tabIndex={ -1 }
              target="_blank"
            >
              Online Campaign Policy
            </Link>
            {' '}
            {' '}
            and consent to its
            {' '}
            {' '}
            <Link
              href="https://littlecaesars.com/en-us/legal/privacy-policy"
              rel="noopener noreferrer"
              sx={ { color: 'primaryOrange' } }
              tabIndex={ -1 }
              target="_blank"
            >
              Privacy Policy
            </Link>
            .
          </Text>
        ) }
        name={ name }
        sx={ sx }
        tabIndex={ 0 }
        variant={ variant }
        { ...rest }
      />
    </Flex>
  );
};

export const termsAndConditionsInputSchema = (t: TFunction,
  validationKey: string,
  required): { [x: string]: Yup.Schema<any> } => {
  const baseSchema = Yup.boolean();

  return required
    ? {
      [validationKey]: baseSchema
        .required(t('fundraiser.AcceptTermsAndConditionsRequired'))
        .oneOf([ true ], t('fundraiser.AcceptTermsAndConditionsRequired')),
    }
    : { [validationKey]: baseSchema };
};

export const useTermsAndConditionsInput = (props: TermsAndConditionsInputProps = {}) => {
  const propsMerged = { ...defaultPropsTermsAndConditionsInput, ...props };

  const values = useFormField(TermsAndConditionsInput,
    termsAndConditionsInputSchema,
    propsMerged,
    'TermsAndConditionsInput',
    'termsAndConditionsInputSchema');

  return values as unknown as
    { TermsAndConditionsInput: React.FC<TermsAndConditionsInputProps>; termsAndConditionsInputSchema: object };
};

