/* eslint-disable max-len */
/* eslint-disable max-lines-per-function */
import React from 'react';
import {
  Flex, Form,
} from '@lce/slice_v2';
import _map from 'lodash/map';
import { useTranslation } from '@lce/i18n';
import { useFormContext } from 'react-hook-form';

import { formatCCNum } from 'features/checkout/utils/formatCCNum';

const PaymentForm: React.FC = () => {
  const [ t ] = useTranslation();

  const monthName: string[] = t('checkout.PaymentMonths', { returnObjects: true });
  const year = new Date().getFullYear();
  const years = Array.from(new Array(40), (val, index) => index + year);
  const shortYear = (year: number) => +(year + '').slice(-2);

  const { setValue } = useFormContext();

  return (
    <Flex sx={ { flexDirection: 'column' } }>
      <Form.Field
        component={ Form.Input.Text }
        data-testid="card-value"
        id="ccNum"
        label="Credit Card Number *"
        name="ccNum"
        onChange={ (e) => {
          const ccNum = formatCCNum(e.target.value.replace(/\D/g, ''), false);
          setValue('ccNum', ccNum);
        } }
      />
      <Flex sx={ { flexDirection: [ 'column', 'row' ] } }>

        <Form.Field
          component={ Form.Select }
          data-testid="expiry-month"
          id="expiryMonth"
          label="Exp Month *"
          name="expiryMonth"
          sx={ { mr: [ 0, '12px' ] } }
          variant="forms.checkout.field"
        >
          <option value="" />
          {monthName && _map(monthName, (month, index) => (
            <option key={ 'month-' + month } value={ (index + 1).toString().length < 2 ? '0' + (index + 1) : index + 1 }>
              {`${ (index + 1).toString().length < 2 ? '0' + (index + 1) : index + 1 } - ${ month }`}
            </option>
          ))}
        </Form.Field>
        <Form.Field
          component={ Form.Select }
          data-testid="expiry-year"
          id="expiryYear"
          label="Exp Year *"
          name="expiryYear"
          sx={ { mr: [ 0, '12px' ] } }
          variant="forms.checkout.field"
        >
          <option value="" />
          {years && _map(years, year => (
            <option key={ 'year-' + year } value={ shortYear(year) }>
              { year }
            </option>
          ))}
        </Form.Field>
        <Form.Field
          data-testid="cvc"
          id="cvvNum"
          label="Security Code *"
          maxLength="4"
          name="cvvNum"
          onChange={ (e) => {
            const cvvNum = e.target.value.replace(/\D/g, '');
            setValue('cvvNum', cvvNum);
          } }
          variant="forms.checkout.field"
        />
      </Flex>
    </Flex>
  );
};

export default PaymentForm;
