import { useQuery } from 'react-query';
import { noop as _noop, isNil as _isNil } from 'lodash';

import { FundraiserListItem, FundraiserStatus } from '../types/fundraiser';

import * as QueryKeys from 'constants/QueryKeys';
import { ApiError } from 'features/common';
import api from 'api';

/** Api Calls */
const fetchFundraiserById =
  async(id: number) => api.get<FundraiserListItem>(`api/fundraiser/${ id }`).then(res => res);

const fetchFundraiserByGuid =
  async(guid: string) => api.get<FundraiserListItem>(`api/fundraiser/${ guid }`).then(res => res);

/** Hooks */
export const useFetchFundraiserById =
  (id: number, onSuccess: (FundraiserListItem) => void = _noop) => useQuery<
  FundraiserListItem,
  ApiError
  >(QueryKeys.FundraiserById + id,
    () => fetchFundraiserById(id), {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: id > 0,
      onSuccess,
      select: (data) => {
        if (_isNil(data.Status)) {
          data.Status = FundraiserStatus.Active;
        }
        return data;
      },
    });

export const useFetchFundraiserByGuid =
  (guid: string) => useQuery<FundraiserListItem, ApiError>(QueryKeys.FundraiserById + guid,
    () => fetchFundraiserByGuid(guid), {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: guid !== '',
    });
