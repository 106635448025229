import {
  Flex, Image, Text,
} from '@lce/slice_v2';
import React from 'react';

import Star from './star.png';

import DefaultSellerAvatar from 'assets/images/default-seller-avatar.png';
import { FundraiserSellerSummary } from 'features/fundraiser/types/fundraiser';

export interface ISellerSummaryListItemProps {
  data: FundraiserSellerSummary;
}

const SellerSummaryListItem: React.FC<ISellerSummaryListItemProps> = ({ data }) => (
  <Flex
    sx={ {
      mb: '20px', maxWidth: '855px',
      padding: [ '20px', '20px', '0px 20px' ],
      alignItems: [ 'left', 'center', 'center' ],
    } }
    variant="cards.fundraiserSeller"
  >
    <Flex sx={ { alignItems: 'center' } }>
      <Image
        src={ data.AvatarSource || DefaultSellerAvatar }
        sx={ {
          width: [ '100px', '50px', '50px' ],
          height: [ '100px', '50px', '50px' ],
          backgroundColor: 'primaryGrayLight',
          mr: '10px',
        } }
      />
    </Flex>
    <Flex
      sx={ {
        justifyContent: 'space-between',
        flex: 1,
        alignItems: [ 'left', 'center', 'center' ],
        flexDirection: [ 'column', 'row', 'row' ],
      } }
    >
      <Text sx={ { fontWeight: 600 } }>
        { data.SellerName }
      </Text>
      <Flex sx={ { alignItems: 'center' } }>
        { data.KitActual > data.KitGoal &&
        <Image alt="LC Fundraising Logo" src={ Star } sx={ { flexShrink: 0 } } title="star" />}
        <Text
          sx={ {
            fontWeight: 600, mr: [ '0px', '25px' ], lineHeight: 1,
          } }
        >
          Kit Goal:
          {' '}
          { data.KitActual }
          /
          { data.KitGoal }
        </Text>
      </Flex>
      <Flex sx={ { alignItems: 'center' } }>
        <Text sx={ { fontWeight: 600, mr: [ '0px', '25px' ] } }>
          Profit:
          $
          { data.Profit }
        </Text>
      </Flex>
    </Flex>
  </Flex>
);

export default SellerSummaryListItem;
