import * as React from 'react';
import { Button, Text } from '@lce/slice_v2';
import { useTranslation } from '@lce/i18n';

interface IReturningUserProps {
  onLoginCallback: () => void;
}

const ReturningUser: React.FC<IReturningUserProps> = ({ onLoginCallback }) => {
  const [ t ] = useTranslation();

  return (
    <>
      <Text variant="text.header.sub">
        {t('checkout.ReturningUserTitle')}
      </Text>
      <Text variant="text.login.message">
        {t('checkout.ReturningUserMessage')}
      </Text>
      <Button data-testid="returning-user-option" onClick={ onLoginCallback } sx={ { width: [ '100%' ] } }>
        {t('common.SignIn')}
      </Button>
    </>
  );
};

export default ReturningUser;
