import React from 'react';
import {
  Flex, Box, Image, Card, Text, Button,
} from '@lce/slice_v2';
import { useTranslation } from '@lce/i18n';
import { useHistory } from 'react-router-dom';

import SuperFan from 'assets/icons/super-fan.svg';
import DefaultSellerAvatar from 'assets/images/default-seller-avatar.png';
import { CopyShare, MiniStats } from 'ui/fundraiser';
import { Seller, useSupportFundraiser } from 'features/fundraiser';
import { useFetchProductsPageRoute } from 'features/products';
import { CreateAccountModal } from 'ui/checkout/Login/CreateAccountModal';
import { useFundraiserState } from 'features/fundraiser/context/fundraiserContext';

export interface IOrderConfirmationCardProps {
  orderSeller: Seller;
  isOpen: boolean;
  isGuest: boolean;
  onClose: () => void;
  testId?: string;
}

// eslint-disable-next-line max-lines-per-function
export const OrderConfirmationCard: React.FC<IOrderConfirmationCardProps> = ({
  orderSeller, testId = 'order-confirmation-page', isOpen, onClose, isGuest, children,
}) => {
  const [ t ] = useTranslation();
  const history = useHistory();
  const { startShopping } = useSupportFundraiser();
  const productPageRoute = useFetchProductsPageRoute();
  const { fundraiserType } = useFundraiserState();

  const onContinueShoppingHandler = () => {
    startShopping();
    history.push(productPageRoute);
  };

  return (
    <Flex
      as={ Card }
      data-testid={ testId }
      sx={ { flexDirection: 'column', position: 'relative', gap: 3 } }
      variant="cards.seller"
    >
      <Image height="160px" objectFit="contain" src={ SuperFan } width="160px" />
      <Text
        sx={ {
          textTransform: 'uppercase',
          fontSize: '24px',
          color: 'primaryOrange',
        } }
        variant="text.header.title"
      >
        {t('orderConfirmation.Header', { context: fundraiserType })}
      </Text>
      <Text
        sx={ {
          fontWeight: 700,
          fontFamily: 'Lato',
          lineHeight: 1,
        } }
      >
        {t('orderConfirmation.Subheader', {
          sellerName: `${ orderSeller.FirstName } ${ orderSeller.LastInitial }`,
          fundraiserName: orderSeller.FundraiserName,
        })}
      </Text>
      <Text sx={ { whiteSpace: 'pre-line', lineHeight: 1 } }>
        {t('orderConfirmation.MoreInfo', { context: fundraiserType })}
        <strong>
          {t('orderConfirmation.OrderNumber', { context: fundraiserType, orderId: orderSeller.OrderId })}
        </strong>
      </Text>
      <Flex sx={ { flexDirection: 'column', gap: 3, mt: 3 } }>
        <Image
          src={ orderSeller.AvatarSource || DefaultSellerAvatar }
          sx={ {
            height: '200px', width: '100%', objectFit: 'contain', maxWidth: '250px',
          } }
        />
        <Text
          sx={ { mr: 0 } } variant="text.header.sub"
        >
          { `${ orderSeller.FirstName } ${ orderSeller.LastInitial }`}
        </Text>
      </Flex>
      <Box sx={ { px: [ 0, 0, 8 ], width: '100%', my: 6 } }>
        <MiniStats sellerId={ orderSeller.Id } />
        <CopyShare
          copyText={ `${ window.location.origin }${ productPageRoute }` }
          sx={ { my: '24px' } }
          title={ t('orderConfirmation.CopyShare', { sellerFirstName: orderSeller.FirstName }) }
        />
        <Button
          data-testid="continue-shopping-button"
          onClick={ onContinueShoppingHandler }
          sx={ { width: '100%' } }
          variant="secondary"
        >
          {t('orderConfirmation.ContinueShopping')}
        </Button>
        { isGuest && (
          <Button
            data-testid="create-account-button"
            onClick={ onClose }
            sx={ { width: '100%', mt: '24px' } }
            variant="primary"
          >
            {t('common.CreateAccount')}
          </Button>
        )}
      </Box>
      <CreateAccountModal
        isOpen={ isOpen }
        onClose={ onClose }
      >
        {children}
      </CreateAccountModal>
    </Flex>
  );
};
