import * as React from 'react';
import { Flex } from '@lce/slice_v2';

import { Graphs } from 'ui/Graphs';
import { Stat } from 'features/fundraiser/types/stats';

export interface ISellerGraphsProps {
  sellerStats: Stat[];
}

export const SellerGraphs: React.FC<ISellerGraphsProps> = ({ sellerStats }) => (
  <Flex
    sx={ {
      minWidth: [ 'none', '404px' ],
      justifyContent: 'center',
      flexWrap: [ 'wrap', 'nowrap' ],
      gap: [ '16px', '32px' ],
    } }
  >
    <Graphs graphStats={ sellerStats } isDashboard={ false } />
  </Flex>
);

