import { Box, Text } from '@lce/slice_v2';
import React from 'react';
import { useTranslation } from '@lce/i18n';
import { useFormContext } from 'react-hook-form';

import { Address } from 'features/common';
import { formatCCNum } from 'features/checkout/utils/formatCCNum';

interface IViewPaymentProps {
  showPayInfo: boolean;
  billingAddress: Address | undefined;
}

const ViewPayment: React.FC<IViewPaymentProps> = ({ billingAddress, showPayInfo }) => {
  const [ t ] = useTranslation();
  const { watch } = useFormContext();
  const ccNum = watch('ccNum') && formatCCNum(watch('ccNum'), true).slice(-4);
  return (
    <Box sx={ { display: showPayInfo ? 'block' : 'none' } } variant="cards.step.body">
      <Text sx={ { mb: '16px' } } variant="text.header.form">
        {t('checkout.CCInformation')}
      </Text>
      <Text>
        {t('payment.PaymentLabel')}
        {' '}
        { ccNum }
      </Text>
      <Text sx={ { mb: '16px', mt: '30px' } } variant="text.header.form">
        {t('checkout.BillingAddress')}
      </Text>
      <Text>
        {billingAddress?.FirstName}
        {' '}
        {billingAddress?.LastName}
      </Text>
      <Text>
        {billingAddress?.Address1}
        {' '}
        {billingAddress?.Address2}
      </Text>
      <Text>
        {billingAddress?.City}
        ,
        {' '}
        {billingAddress?.StateProvinceIso}
        {' '}
        {billingAddress?.ZipPostalCode}
      </Text>
    </Box>
  );
};

export default ViewPayment;
