/* eslint-disable linebreak-style */
import { Box } from '@lce/slice_v2';
import { ThemeUIStyleObject } from 'theme-ui';
import React from 'react';

interface IBorder {
  children?: React.ReactNode;
  sx?: ThemeUIStyleObject;
}

const Border: React.FC<IBorder> = ({ children, sx }) => (
  <Box
    sx={ {
      width: '100%',
      p: [ 0, '3% 3%', '4% 4%', '20px 30px' ],
      borderRadius: [ 0, 0, '4px' ],
      boxShadow: [ 0, '0px 0px 4px 1px rgba(0, 0, 0, 0.15)' ],
      ...sx,
    } }
  >
    {children}
  </Box>
);

export default Border;
