/* eslint-disable react/no-danger */
import * as React from 'react';
import { useCallback } from 'react';
import numeral from 'numeral';
import DOMPurify from 'dompurify';
import {
  Box, Text, Flex,
} from '@lce/slice_v2';
import { useTranslation } from '@lce/i18n';

import { ProductDetailInfo } from './ProductDetailInfo';
import { ProductDetailAddToCart } from './ProductDetailAddToCart';

import { ProductImageCarousel } from 'ui/products';
import { Product } from 'features/products';
import { useCartDispatch, addToCart } from 'features/cart/context/cartContext';

export interface IProductDetailProps {
  product: Product;
  canStartShopping: boolean;
}

// eslint-disable-next-line max-lines-per-function
const ProductDetail: React.FC<IProductDetailProps> = ({ canStartShopping, product }) => {
  const [ t ] = useTranslation();
  const cartDispatch = useCartDispatch();

  const addToCartHandler = useCallback((product, quantity) => {
    addToCart(cartDispatch, product, quantity);
  }, [ cartDispatch ]);

  const isFreeShipping = product.IsFreeShipping;

  return (
    <Box>
      <Flex sx={ { flexDirection: [ 'column', 'column', 'row' ] } }>
        <Flex sx={ { width: '100%', paddingTop: '30px' } }>
          <ProductImageCarousel
            images={ product.GalleryImages ? product.GalleryImages : null }
          />
        </Flex>
        <Flex sx={ { flexDirection: 'column', width: '100%', padding: [ 0, '15px', '30px' ] } }>
          <Text variant="text.product.title">
            {product.Name}
          </Text>
          <Text variant="text.product.price">
            {numeral(product.Price).format(t('currency.format'))}
          </Text>
          <Text variant="text.product.description">
            <div
              dangerouslySetInnerHTML={ {
                __html: product.FullDescription ? DOMPurify.sanitize(product.FullDescription) : '',
              } }
            />
          </Text>
          <Box>
            {!isFreeShipping && (
              <Text
                sx={ { marginBottom: '-15px' } }
                variant="text.product.shortDescription"
              >
                {t('product.IncludeInKitTitle')}
                {' '}
              </Text>
            )}
            <Text variant="text.product.description">
              <div
                dangerouslySetInnerHTML={ {
                  __html: product.KitComponents ? DOMPurify.sanitize(product.KitComponents) : '',
                } }
              />
            </Text>
          </Box>
          <ProductDetailAddToCart
            canStartShopping={ canStartShopping }
            onAddToCart={ addToCartHandler }
            product={ product }
          />
        </Flex>
      </Flex>
      {!isFreeShipping && <ProductDetailInfo product={ product } />}
    </Box>
  );
};

export default ProductDetail;
