import React from 'react';
import { Text, Flex, Image } from '@lce/slice_v2';

import DedicatedReps from './assets/dedicated-reps.svg';
import FundrasingPlan from './assets/fundraising-plan.svg';
import HelpVideos from './assets/help-videos.svg';
import ResourceGuide from './assets/resource-guide.svg';

const IconMap = {
  ResourceGuide: ResourceGuide,
  HelpVideos: HelpVideos,
  FundraisingPlan: FundrasingPlan,
  DedicatedReps: DedicatedReps,
};

export interface ISupportIconTile {
  title: string;
  description: string;
  iconSrc: string;
}

const SupportIconTile: React.FC<ISupportIconTile> = ({
  description, iconSrc, title,
}) => (
  <Flex
    data-testid="support-icon-card"
    sx={ { alignItems: 'start', gap: '16px' } }
  >
    <Image
      src={ IconMap[iconSrc] }
      sx={ { height: '40px', width: '100%', maxWidth: '40px' } }
    />
    <Flex
      sx={ {
        flexDirection: 'column',
        minWidth: '150px',
        textAlign: 'left',
      } }
    >
      <Text variant="home.iconTile.title">
        { title }
      </Text>
      <Text variant="home.iconTile.description">
        { description }
      </Text>
    </Flex>
  </Flex>
);

export default SupportIconTile;
