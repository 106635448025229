import { RouteProps } from 'react-router-dom';
import React from 'react';

import RequestInfoThankYouData from 'ui/RequestInfoThankYou/RequestInfoThankYou';

const RequestInfoThankYou: React.FC<RouteProps> = () => (
  <RequestInfoThankYouData />
);

export default RequestInfoThankYou;
