import {
  Box, Form, IconButton,
} from '@lce/slice_v2';
import _debounce from 'lodash/debounce';
import { Search, XCircle } from 'react-feather';
import React, { useEffect, useRef } from 'react';

import { theme } from 'theme';

export interface ISearchBar {
  onSearch: (value) => void;
  setSearchValue: (value: string) => void;
  searchValue: string;
  placeholderText: string;
}

const SearchBar: React.FC<ISearchBar> = ({
  onSearch, setSearchValue, searchValue, placeholderText,
}: ISearchBar) => {
  const debouncedSearch = useRef(_debounce(value => onSearch(String(value)), 500)).current;

  const handleOnSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    debouncedSearch(e.target.value);
    setSearchValue(e.target.value);
  };

  const handleOnClearSearch = () => {
    setSearchValue('');
    onSearch('');
  };

  useEffect(() => () => debouncedSearch.cancel(), [ debouncedSearch ]);

  return (
    <Box
      data-testid="search-bar"
      sx={ {
        position: 'relative', width: '100%', mt: 7, button: { cursor: 'pointer' },
      } }
    >
      <Form.Input.Text
        id="buyer-search"
        name="buyer-search"
        onChange={ handleOnSearch }
        placeholder={ placeholderText }
        value={ searchValue }
        variant="search"
      />
      <Box sx={ { position: 'absolute', left: '14px', top: '8px' } }>
        <Search color={ theme.colors.primaryGrayDark } size="20px" />
      </Box>
      { searchValue.length > 0 && (
        <Box
          data-testid="clear-search-button"
          sx={ {
            position: 'absolute', right: '14px', top: '3px',
          } }
        >
          <IconButton onClick={ handleOnClearSearch }>
            <XCircle color={ theme.colors.primaryGrayDark } size={ 20 } />
          </IconButton>
        </Box>
      )}
    </Box>
  );
};

export default SearchBar;
