import React, { useEffect } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import * as OktaSignIn from '@okta/okta-signin-widget';
import '@okta/okta-signin-widget/dist/css/okta-sign-in.min.css';
import { useHistory, useLocation } from 'react-router-dom';

import './login-form.css';

import { ILocationStateProps } from '../types';

import Caesar from './assets/Jumping_Caesar.png';

import config from 'auth/config';

export interface ILoginFormProps {
  redirect: boolean;
  onBackCallback?: () => void;
  onLoginRedirectUri?: string;
}

// eslint-disable-next-line max-lines-per-function
const LoginForm: React.FC<ILoginFormProps> = ({
  redirect,
  onBackCallback,
  onLoginRedirectUri = '/dashboard/fundraisers',
}) => {
  const history = useHistory();
  const { search, state } = useLocation<ILocationStateProps>();
  const urlParams = new URLSearchParams(search);
  const redirectURL = urlParams.get('redirectUrl');

  const { authService } = useOktaAuth();
  // eslint-disable-next-line no-extra-parens
  const finalOnLoginRedirectUri = state?.redirectUri || onLoginRedirectUri;

  const customButtons = onBackCallback
    ? [
      {
        title: 'Back',
        click: onBackCallback,
      },
    ]
    : [
      {
        title: 'Create Account',
        className: 'create-account-link',
        click: () => {
          history.push({
            pathname: '/create-account',
            state: {
              redirectUri: state?.redirectUri || redirectURL,
            },
          });
        },
      },
    ];

  // eslint-disable-next-line max-lines-per-function
  useEffect(() => {
    const {
      pkce, issuer, clientId, redirectUri, scopes,
    } = config.oidc;

    localStorage.removeItem('okta-cache-storage');

    const widget = new OktaSignIn({
      baseUrl: issuer.split('/oauth2'[0]),
      clientId,
      redirectUri,
      logo: Caesar,
      i18n: {
        en: {
          'primaryauth.title': 'Sign In',
          'primaryauth.username.placeholder': 'Email',
        },
      },
      authParams: {
        pkce,
        issuer,
        display: 'page',
        responseMode: pkce ? 'query' : 'fragment',
        scopes,
      },
      customButtons: customButtons,
      helpLinks: {
        help: '/contact-us',
      },
    });

    if (!redirect) {
      authService.setFromUri();
    } else {
      if (redirectURL) {
        authService.setFromUri(redirectURL);
      } else {
        authService.setFromUri(finalOnLoginRedirectUri);
      }
    }

    if (widget) {
      widget.renderEl({ el: '#sign-in-widget' },
        () => {

        /**
         * in PKCE flow, the success handler will not be called because we redirect
         * to the Okta org for auth workflow
         */
        },
        (err) => {
          throw err;
        });
    }

    return () => {
      widget && widget.remove();
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div data-testid="login-form">
      <div id="sign-in-widget" />
    </div>
  );
};

export default LoginForm;
