/* eslint-disable complexity */
/* eslint-disable no-duplicate-case */
import jumpingCaesar from '../assets/jumping-caesar-rgb.png';
import itsComingCaesar from '../assets/itscoming.png';
import childImage from '../assets/childImage.png';
import phoneImage from '../assets/phoneImage.png';
import thankYouImage from '../assets/thankYouImage.png';
import picassoImage from '../assets/picasso.png';
import pizzaTimeImage from '../assets/pizzaTime.png';
import youBetchaImage from '../assets/youBetcha.png';
import countingOneCaesar from '../assets/countingOneCaesar.png';
import countingTwoCaesar from '../assets/countingTwoCaesar.png';
import countingThreeCaesar from '../assets/countingThreeCaesar.png';
import countingFourCaesar from '../assets/countingFourCaesar.png';
import thumbsUpCaesar from '../assets/thumbsUpCaesar.png';
import loudSpeakerCaesar from '../assets/loudSpeakerCaesar.png';
import feelingWhen from '../assets/feelingWhen.png';
import smellPizza from '../assets/smellPizza.png';
import youBetchaRectangle from '../assets/youBetchaTwo.png';
import mdSupport from '../assets/md-week1.png';
import mdGetWhileHot from '../assets/md-week2.jpg';
import mdHelpRaiseDough from '../assets/md-week3.jpg';
import mdLastChance from '../assets/md-week4.png';
import mdFollowUp from '../assets/md-followup.png';

export const getTemplateImage = (type: string) => {
  switch (type) {
    case 'jumping-caesar':
      return { src: jumpingCaesar, alt: '' };
    case 'its-coming-caesar':
      return { src: itsComingCaesar, alt: 'itsComing' };
    case 'child':
      return { src: childImage, alt: 'child' };
    case 'phone':
      return { src: phoneImage, alt: 'phone' };
    case 'thanks':
      return { src: thankYouImage, alt: 'deserveHand' };
    case 'picasso':
      return { src: picassoImage, alt: 'picasso' };
    case 'pizza-time':
      return { src: pizzaTimeImage, alt: 'pizzaTime' };
    case 'you-betcha':
      return { src: youBetchaImage, alt: 'youBetcha' };
    case 'counting-one':
      return { src: countingOneCaesar, alt: '' };
    case 'counting-two':
      return { src: countingTwoCaesar, alt: '' };
    case 'counting-three':
      return { src: countingThreeCaesar, alt: '' };
    case 'counting-four':
      return { src: countingFourCaesar, alt: '' };
    case 'thumbs-up':
      return { src: thumbsUpCaesar, alt: '' };
    case 'loud-speaker':
      return { src: loudSpeakerCaesar, alt: '' };
    case 'feeling':
      return { src: feelingWhen, alt: 'feeling' };
    case 'smell':
      return { src: smellPizza, alt: 'smell' };
    case 'you-betcha-rectangle':
      return { src: youBetchaRectangle, alt: 'youBetchaRectangle' };
    case 'md-support':
      return { src: mdSupport, alt: 'mdSupport' };
    case 'md-get-while-hot':
      return { src: mdGetWhileHot, alt: 'mdGetWhileHot' };
    case 'md-help-raise-dough':
      return { src: mdHelpRaiseDough, alt: 'mdHelpRaiseDough' };
    case 'md-last-chance':
      return { src: mdLastChance, alt: 'mdLastChance' };
    case 'md-follow-up':
      return { src: mdFollowUp, alt: 'mdFollowUp' };
    default:
      return { src: '', alt: '' };
  }
};
