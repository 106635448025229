import React, { useEffect, useState } from 'react';
import { useTranslation } from '@lce/i18n';
import * as Yup from 'yup';
import {
  Button, Box, Form, Flex, Text,
} from '@lce/slice_v2';

import { ChairpersonDetailsSummary } from './ChairpersonDetailsSummary';

import { Mode, Step } from 'ui/common';
import { ChairpersonInfo } from 'features/fundraiser';
import { UserInfo } from 'features/common/types/userInfo';
import { RemoveChairpersonModal } from 'ui/fundraiser/RemoveChairpersonModal';
import {
  PhoneInput, phoneInputSchema, phonePattern, FirstNameInput, firstNameInputSchema,
} from 'ui/components/forms';

export interface IChairpersonDetailsProps {
  onEdit: () => void;
  isEdit?: boolean;
  onNext: (info: ChairpersonInfo) => void;
  onChairpersonRemove?: () => void;
  defaultChairperson: UserInfo | undefined;
  hasParentGroupId?: boolean;
  info?: ChairpersonInfo;
  fundraiserId?: number;
  mode: Mode;
}

// eslint-disable-next-line max-lines-per-function
const ChairpersonDetails: React.FC<IChairpersonDetailsProps> = ({
  onEdit, onNext, mode, defaultChairperson, hasParentGroupId, info, isEdit, fundraiserId, onChairpersonRemove,
}) => {
  const [ t ] = useTranslation();
  const [ showCoChairpersonForm, setShowCoChairpersonForm ] = useState(false);
  const [ chairpersonFormData, setChairpersonFormData ] = useState<ChairpersonInfo | undefined>();
  const [ isRemoveChairperson, setIsRemoveChairperson ] = useState<boolean>(false);

  const handleOnSubmit = (e) => {
    const info: ChairpersonInfo = {
      ...e,
    };

    setChairpersonFormData(info);
    onNext(info);
  };

  useEffect(() => {
    if (isEdit && info) {
      setChairpersonFormData(info);

      if (info.CoEmailAddress) {
        setShowCoChairpersonForm(true);
      }
    }
  }, [ info, isEdit ]);

  let validationSchema = Yup.object();
  if (isEdit) {
    validationSchema = validationSchema.shape({
      ...firstNameInputSchema(t),
      LastName: Yup.string()
        .required(t('fundraiser.LastNameRequired'))
        .max(50, t('fundraiser.MaxLength50')),
      ...phoneInputSchema(t),
      CoFirstName: Yup.string()
        .trim()
        .max(50, t('fundraiser.MaxLength50'))
        .when([ 'CoPhoneNumber', 'CoEmailAddress' ], {
          is: (CoPhoneNumber, CoEmailAddress) => CoPhoneNumber || CoEmailAddress,
          then: Yup.string().required(t('fundraiser.FirstNameRequired')),
        }),
      CoLastName: Yup.string()
        .trim()
        .max(50, t('fundraiser.MaxLength50'))
        .when([ 'CoPhoneNumber', 'CoEmailAddress', 'CoFirstName' ], {
          is: (CoPhoneNumber, CoEmailAddress, CoFirstName) => CoPhoneNumber || CoEmailAddress || CoFirstName,
          then: Yup.string().required(t('fundraiser.LastNameRequired')),
        }),
      SecondaryPhoneNumber: Yup.lazy((value) => {
        if (value === '') {
          return Yup.string().notRequired();
        } else {
          return Yup.string()
            .matches(phonePattern, t('fundraiser.PhoneNumberValid'))
            .max(17, t('fundraiser.PhoneNumberLength'));
        }
      }),
      CoPhoneNumber: Yup.string()
        .matches(phonePattern,
          { excludeEmptyString: true, message: t('fundraiser.PhoneNumberValid') })
        .max(17, t('fundraiser.PhoneNumberLength'))
        .notRequired()
        .nullable()
        .when([ 'CoFirstName', 'CoLastName', 'CoEmailAddress' ], {
          is: (CoFirstName, CoLastName, CoEmailAddress) => CoFirstName || CoLastName || CoEmailAddress,
          then: Yup.string().required(t('fundraiser.PhoneRequired')),
        }),
      CoSecondaryPhoneNumber: Yup.string()
        .matches(phonePattern,
          { excludeEmptyString: true, message: t('fundraiser.PhoneNumberValid') })
        .max(17, t('fundraiser.PhoneNumberLength'))
        .notRequired()
        .nullable(),
      CoEmailAddress: Yup.string()
        .max(255, t('fundraiser.MaxLength255'))
        .email(t('fundraiser.EmailValid'))
        .notOneOf([ info?.EmailAddress ], t('fundraiser.CoChairpersonEmailMatch'))
        .when('EmailAddress', {
          is: EmailAddress => !!EmailAddress,
          then: Yup.string().notOneOf([ Yup.ref('EmailAddress') ], t('fundraiser.CoChairpersonEmailMatch')),
        })
        .when([ 'CoFirstName', 'CoLastName', 'CoPhoneNumber' ], {
          is: (CoFirstName, CoLastName, CoPhoneNumber) => (CoFirstName || CoLastName || CoPhoneNumber) &&
          !info?.CoEmailAddress,
          then: Yup.string().required(t('fundraiser.EmailRequired')),
        }),
      CoConfirmEmailAddress: Yup.string()
        .email(t('fundraiser.EmailMatch'))
        .oneOf([ Yup.ref('CoEmailAddress') ], t('fundraiser.EmailMatch')),
    },
    [
      [ 'CoPhoneNumber', 'CoEmailAddress' ],
      [ 'CoFirstName', 'CoLastName' ],
      [ 'CoFirstName', 'CoEmailAddress' ],
      [ 'CoFirstName', 'CoPhoneNumber' ],
      [ 'CoLastName', 'CoEmailAddress' ],
      [ 'CoLastName', 'CoPhoneNumber' ],
    ]);
  } else {
    validationSchema = validationSchema.shape({
      ...firstNameInputSchema(t),
      LastName: Yup.string()
        .required(t('fundraiser.LastNameRequired'))
        .max(50, t('fundraiser.MaxLength50')),
      ...phoneInputSchema(t),
      SecondaryPhoneNumber: Yup.lazy((value) => {
        if (value === '') {
          return Yup.string().notRequired();
        } else {
          return Yup.string()
            .matches(phonePattern, t('fundraiser.PhoneNumberValid'))
            .min(10, t('fundraiser.PhoneNumberLength'))
            .max(17, t('fundraiser.PhoneNumberLength'));
        }
      }),
      CoFirstName: Yup.string()
        .trim()
        .max(50, t('fundraiser.MaxLength50'))
        .when([ 'CoPhoneNumber', 'CoEmailAddress' ], {
          is: (CoPhoneNumber, CoEmailAddress) => CoPhoneNumber || CoEmailAddress,
          then: Yup.string().required(t('fundraiser.FirstNameRequired')),
        }),
      CoLastName: Yup.string()
        .trim()
        .max(50, t('fundraiser.MaxLength50'))
        .when([ 'CoPhoneNumber', 'CoEmailAddress', 'CoFirstName' ], {
          is: (CoPhoneNumber, CoEmailAddress, CoFirstName) => CoPhoneNumber || CoEmailAddress || CoFirstName,
          then: Yup.string().required(t('fundraiser.LastNameRequired')),
        }),
      CoPhoneNumber: Yup.string()
        .max(17, t('fundraiser.PhoneNumberLength'))
        .matches(phonePattern,
          { excludeEmptyString: true, message: t('fundraiser.PhoneNumberValid') })
        .notRequired()
        .nullable()
        .when([ 'CoFirstName', 'CoLastName', 'CoEmailAddress' ], {
          is: (CoFirstName, CoLastName, CoEmailAddress) => CoFirstName || CoLastName || CoEmailAddress,
          then: Yup.string().required(t('fundraiser.PhoneRequired')),
        }),
      CoSecondaryPhoneNumber: Yup.string()
        .matches(phonePattern,
          { excludeEmptyString: true, message: t('fundraiser.PhoneNumberValid') })
        .max(17, t('fundraiser.PhoneNumberLength'))
        .notRequired()
        .nullable(),
      EmailAddress: Yup.string()
        .email(t('fundraiser.EmailValid'))
        .required(t('fundraiser.EmailRequired')),
      ConfirmEmailAddress: Yup.string()
        .email(t('fundraiser.EmailMatch'))
        .required(t('fundraiser.EmailRequired'))
        .oneOf([ Yup.ref('EmailAddress') ], t('fundraiser.EmailMatch')),
      CoEmailAddress: Yup.string()
        .max(255, t('fundraiser.MaxLength255'))
        .email(t('fundraiser.EmailValid'))
        .when('EmailAddress', {
          is: EmailAddress => !!EmailAddress,
          then: Yup.string().notOneOf([ Yup.ref('EmailAddress') ], t('fundraiser.CoChairpersonEmailMatch')),
        })
        .when([ 'CoFirstName', 'CoLastName', 'CoPhoneNumber' ], {
          is: (CoFirstName, CoLastName, CoPhoneNumber) => (CoFirstName || CoLastName || CoPhoneNumber) &&
          !info?.CoEmailAddress,
          then: Yup.string().required(t('fundraiser.EmailRequired')),
        }),
      CoConfirmEmailAddress: Yup.string()
        .email(t('fundraiser.EmailMatch'))
        .oneOf([ Yup.ref('CoEmailAddress') ], t('fundraiser.EmailMatch')),
    },
    [
      [ 'CoPhoneNumber', 'CoEmailAddress' ],
      [ 'CoFirstName', 'CoLastName' ],
      [ 'CoFirstName', 'CoEmailAddress' ],
      [ 'CoFirstName', 'CoPhoneNumber' ],
      [ 'CoLastName', 'CoEmailAddress' ],
      [ 'CoLastName', 'CoPhoneNumber' ],
    ]);
  }

  const onRemoveChairperson = () => {
    setIsRemoveChairperson(false);
    if (onChairpersonRemove !== undefined) {
      onChairpersonRemove();
    }
  };

  const stepTitle = hasParentGroupId ? t('fundraiser.SimpleChairpersonDetails') : t('fundraiser.ChairpersonDetails');

  return (
    <>
      { mode === 'active' && (
        <Step
          customText="Remove Chairperson"
          onCustom={ () => setIsRemoveChairperson(true) }
          onEdit={ onEdit }
          showCustom={ isEdit }
          title={ stepTitle }
        >
          {isRemoveChairperson && fundraiserId && (
            <RemoveChairpersonModal
              chairperson={ info }
              fundraiserId={ fundraiserId }
              onClose={ onRemoveChairperson }
            />
          )}
          <Box data-testid="chairperson-details-form" variant="cards.step.body">
            <Form
              initialValues={ info }
              onSubmit={ handleOnSubmit }
              summary={ false }
              validationSchema={ validationSchema }
            >
              <Text variant="text.header.form">
                {hasParentGroupId ? t('fundraiser.SimpleAboutChairperson') : t('fundraiser.AboutChairperson')}
              </Text>
              <Flex sx={ { flexDirection: 'column' } }>
                <Flex sx={ { flexDirection: [ 'column', 'row' ] } }>
                  <FirstNameInput
                    defaultValue={ defaultChairperson?.FirstName }
                    label="First Name *"
                  />
                  <Form.Field
                    autoComplete="family-name"
                    data-testid="last-name"
                    defaultValue={ defaultChairperson?.LastName || '' }
                    id="LastName"
                    label="Last Name *"
                    name="LastName"
                    sx={ { marginLeft: [ 0, '12px' ] } }
                    variant="forms.fundraiser.field"
                  />
                </Flex>
                <Flex sx={ { flexDirection: [ 'column', 'row' ] } }>
                  <PhoneInput
                    defaultValue={ info?.PhoneNumber }
                    label="Primary Phone Number *"
                    sx={ { mr: [ 0, '12px' ] } }
                  />
                  <PhoneInput
                    defaultValue={ info?.SecondaryPhoneNumber }
                    label="Secondary Phone Number"
                    name="SecondaryPhoneNumber"
                    sx={ { marginLeft: [ 0, '12px' ] } }
                    testId="secondary-phone-number"
                  />
                </Flex>
                {isEdit
                  ? (
                    <Flex sx={ { flexDirection: 'column', mb: '16px' } }>
                      <Text>Email*</Text>
                      <Text>
                        { info?.EmailAddress || '' }
                      </Text>
                    </Flex>
                  )
                  : (
                    <Flex sx={ { flexDirection: [ 'column', 'row' ] } }>
                      <Form.Field
                        data-testid="email-address"
                        defaultValue={ defaultChairperson?.Email || '' }
                        id="EmailAddress"
                        label="Email Address *"
                        name="EmailAddress"
                        sx={ { marginRight: [ 0, '12px' ] } }
                        variant="forms.fundraiser.field"
                      />
                      <Form.Field
                        data-testid="confirm-email-address"
                        defaultValue={ defaultChairperson?.Email || '' }
                        id="ConfirmEmailAddress"
                        label="Confirm Email Address *"
                        name="ConfirmEmailAddress"
                        sx={ { marginLeft: [ 0, '12px' ] } }
                        variant="forms.fundraiser.field"
                      />
                    </Flex>
                  ) }

                { !showCoChairpersonForm && !hasParentGroupId && (
                  <Button
                    data-testid="show-co-form"
                    onClick={ () => setShowCoChairpersonForm(true) }
                    sx={ { mt: '15px', mb: '30px' } }
                    variant="secondary"
                  >
                    Add Co-Chairperson
                  </Button>

                )}
                { showCoChairpersonForm && (
                  <>
                    <Text variant="text.header.form">
                      {t('fundraiser.AboutCoChairperson')}
                    </Text>

                    <Flex sx={ { flexDirection: [ 'column', 'row' ] } }>
                      <Form.Field
                        data-testid="co-first-name"
                        id="CoFirstName"
                        label="Co-Chairperson First Name"
                        name="CoFirstName"
                        sx={ { marginRight: [ 0, '12px' ] } }
                        variant="forms.fundraiser.field"
                      />
                      <Form.Field
                        data-testid="co-last-name"
                        id="CoLastName"
                        label="Co-Chairperson Last Name"
                        name="CoLastName"
                        sx={ { marginLeft: [ 0, '12px' ] } }
                        variant="forms.fundraiser.field"
                      />
                    </Flex>
                    <Flex sx={ { flexDirection: [ 'column', 'row' ] } }>
                      <PhoneInput
                        defaultValue={ info?.CoPhoneNumber }
                        label="Co-Chairperson Primary Phone Number"
                        name="CoPhoneNumber"
                        sx={ { marginRight: [ 0, '12px' ] } }
                        testId="co-phone-number"
                      />
                      <PhoneInput
                        defaultValue={ info?.CoSecondaryPhoneNumber }
                        label="Co-Chairperson Secondary Phone Number"
                        name="CoSecondaryPhoneNumber"
                        sx={ { marginLeft: [ 0, '12px' ] } }
                        testId="co-secondary-phone-number"
                      />
                    </Flex>
                    <Flex sx={ { flexDirection: [ 'column', 'row' ] } }>
                      {info?.CoEmailAddress
                        ? (
                          <Flex sx={ { flexDirection: 'column' } }>
                            <Text>
                              Co-Chairperson Email
                            </Text>
                            <Text>
                              {info.CoEmailAddress}
                            </Text>
                          </Flex>
                        )
                        : (
                          <>
                            <Form.Field

                              data-testid="co-email-address"
                              id="CoEmailAddress"
                              label="Co-Chairperson Email Address"
                              name="CoEmailAddress"
                              sx={ { marginRight: [ 0, '12px' ] } }
                              variant="forms.fundraiser.field"
                            />
                            <Form.Field
                              data-testid="co-confirm-email-address"
                              id="CoConfirmEmailAddress"
                              label="Co-Chairperson Confirm Email Address"
                              name="CoConfirmEmailAddress"
                              sx={ { marginLeft: [ 0, '12px' ] } }
                              variant="forms.fundraiser.field"
                            />
                          </>
                        )}
                    </Flex>
                  </>
                )}
                <Button data-testid="submit-button" id="chairpersonNext" sx={ { mt: '33px' } } type="submit">
                  {isEdit ? 'Apply Changes' : t('fundraiser.AdditionalInformationNext')}
                </Button>
              </Flex>
            </Form>
          </Box>
        </Step>
      )}
      { mode === 'preview' && (
        <Step
          customText="chairperson-edit" onEdit={ onEdit } showEdit={ true } title={ stepTitle }
        >
          <Box data-testid="chairperson-details-summary" variant="cards.step.body">
            <ChairpersonDetailsSummary details={ chairpersonFormData } hasParentGroupId={ hasParentGroupId } />
          </Box>
        </Step>
      )}
      { mode === 'hidden' && (
        <Step title={ stepTitle } />
      )}
    </>
  );
};

export default ChairpersonDetails;
