import { useQuery } from 'react-query';

import { Product } from '../types/product';
import * as QueryKeys from '../../../constants/QueryKeys';
import { fetchProductsByFundraiserType } from '../api';

import { ApiError } from 'features/common';
import { useFundraiserState } from 'features/fundraiser/context/fundraiserContext';

export const useFetchProductsByFundraiserType = () => {
  const { fundraiserType } = useFundraiserState();
  return useQuery<Product[], ApiError>(QueryKeys.Products,
    () => fetchProductsByFundraiserType(fundraiserType), {
      refetchOnWindowFocus: false,
      retry: false,
    });
};

