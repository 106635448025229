/* eslint-disable max-len */
import _find from 'lodash/find';

const doesScriptExist = script => _find(document.getElementsByTagName('script'), src => src.innerHTML && script.innerHTML && src.innerHTML === script.innerHTML);

const doesNoScriptExist = noscript => _find(document.getElementsByTagName('noscript'), src => src.innerHTML === noscript.innerHTML);

const doesScriptSrcUrlExist = script => _find(document.getElementsByTagName('script'), src => script.src === src.src);

export {
  doesScriptSrcUrlExist,
  doesScriptExist,
  doesNoScriptExist,
};
