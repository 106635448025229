import * as React from 'react';
import { Button, Image } from '@lce/slice_v2';
import { ThemeUIStyleObject } from 'theme-ui';

import Checkmark from './confirm.png';

export interface IConfirmationButtonProps {
  sx?: ThemeUIStyleObject;
  loading: boolean;
  variant?: string;
  onClick(): void;
  sku?: string;
}

const ConfirmationButton: React.FC<IConfirmationButtonProps> = ({
  sx, loading, children, onClick, variant = 'primary', sku,
}) => (
  <Button
    data-testid={ `${ sku }-addToCart` }
    onClick={ onClick }
    sx={ {
      ...sx,
    } }
    variant={ variant }
  >
    { loading
      ? (
        <Image
          data-testid="primary-loading-checkmark"
          src={ Checkmark }
        />
      )
      : children}
  </Button>
);

export default ConfirmationButton;
