import React from 'react';
import { Flex, Card, Skeleton } from '@lce/slice_v2';

const OrderSummarySkeleton: React.FC = () => (
  <Flex
    as={ Card }
    data-testid="order-summary-skeleton"
    variant="cards.orderSummary"
  >
    <Skeleton count={ 1 } sx={ { height: '24px', width: '40%', mb: '15px' } } />
    <Flex sx={ { flexDirection: 'row', justifyContent: 'space-between', margin: '8px 0' } }>
      <Skeleton count={ 1 } sx={ { height: '19px', width: '60%' } } />
      <Skeleton count={ 1 } sx={ { height: '19px', width: '80px' } } />
    </Flex>
    <Flex sx={ { flexDirection: 'row', justifyContent: 'space-between', margin: '8px 0' } }>
      <Skeleton count={ 1 } sx={ { height: '19px', width: '30%' } } />
      <Skeleton count={ 1 } sx={ { height: '19px', width: '80px' } } />
    </Flex>
    <Flex sx={ { flexDirection: 'row', justifyContent: 'space-between', margin: '8px 0' } }>
      <Skeleton count={ 1 } sx={ { height: '19px', width: '40%' } } />
      <Skeleton count={ 1 } sx={ { height: '19px', width: '80px' } } />
    </Flex>
    <Flex
      sx={ {
        flexDirection: 'row', justifyContent: 'space-between', mb: '30px', mt: '10px',
      } }
    >
      <Skeleton count={ 1 } sx={ { height: '26px', width: '20%' } } />
      <Skeleton count={ 1 } sx={ { height: '26px', width: '80px' } } />
    </Flex>
    <Flex sx={ { flexDirection: 'row', justifyContent: 'space-between', mb: '30px' } }>
      <Skeleton count={ 1 } sx={ { height: '26px', width: '30%' } } />
      <Skeleton count={ 1 } sx={ { height: '26px', width: '80px' } } />
    </Flex>
    <Flex sx={ { flexDirection: 'column' } }>
      <Skeleton count={ 1 } sx={ { height: '36px', width: '100%', mb: '15px' } } />
      <Skeleton count={ 1 } sx={ { height: '36px', width: '100%' } } />
    </Flex>
  </Flex>
);

export default OrderSummarySkeleton;
