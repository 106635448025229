import React from 'react';
import { Flex, Text } from '@lce/slice_v2';
import { useTranslation } from '@lce/i18n';

interface IProvideAddressBannerProps {
  onClick: () => void;
}

const ProvideAddressBanner: React.FC<IProvideAddressBannerProps> = ({ onClick }) => {
  const [ t ] = useTranslation();

  return (
    <Flex
      onClick={ onClick }
      sx={ {
        backgroundColor: '#ff6000', width: '100%', height: '100%', minHeight: '59px', textAlign: 'center',
        justifyContent: 'center', alignItems: 'center', mb: '30px', mt: '4px', cursor: 'pointer', p: [ 2, 0 ],
      } }
    >
      <Text
        sx={ {
          color: 'white', textDecoration: 'underline', fontWeight: 'bold', fontSize: '24px', fontFamily: 'Lato',
        } }
      >
        {t('fundraiser.ProvideMailingAddressBanner')}
      </Text>
    </Flex>
  );
};

export default ProvideAddressBanner;
