import React from 'react';

import { RouterLink } from '../RouterLink';

export const RedirectAwareLink = ({
  pathname, followRedirect = false, children, ...props
}) => (
  <RouterLink to={ { pathname, state: { followRedirect } } } { ...props }>
    {children}
  </RouterLink>
);
