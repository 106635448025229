import React from 'react';
import {
  Box, Heading,
} from '@lce/slice_v2';
import { useTranslation } from '@lce/i18n';

import { FundraiserCreationForm } from './Form';

import CaesarCash from 'assets/images/caesar-cash.svg';
import { FundraiserCreate } from 'ui/fundraiser/FundraiserCreation';

export const MealDealCreatePage: React.FC = () => {
  const [ t ] = useTranslation();

  return (
    <FundraiserCreate image={ CaesarCash } testId="md-create-page">
      <Box sx={ { flex: 1 } }>
        <Heading>
          {t('mealDeal.fundraiserCreationPage.header.title')}
        </Heading>
        <FundraiserCreationForm />
      </Box>
    </FundraiserCreate>
  );
};
