/* eslint-disable max-lines-per-function */
import React, { useEffect, useMemo } from 'react';
import { RouteComponentProps, Redirect } from 'react-router-dom';
import {
  Box, Container, Flex, Heading, Spinner, Text,
} from '@lce/slice_v2';
import { Plus } from 'react-feather';
import { useTranslation } from '@lce/i18n';

import { ProductList } from 'ui/products/ProductList';
import { FundraiserTracker } from 'ui/fundraiser/FundraiserTracker';
import { NotRunningFundraiserModalsDisplay } from 'ui/fundraiser/NotRunningFundraisersModals';
import { useCartState } from 'features/cart';
import { useFetchSellerBySupportGuid, useSupportFundraiser } from 'features/fundraiser';
import { useFetchCategories } from 'features/products';

export interface MatchParams {
  supportGuid: string;
}

export type IMealDealProductsPageProps = RouteComponentProps<MatchParams>

export const MealDealProductsPage: React.FC<IMealDealProductsPageProps> = ({ match }) => {
  const [ t ] = useTranslation();
  const cartState = useCartState();
  const { supportSeller, startShopping } = useSupportFundraiser();
  const { data: seller, isLoading, error } = useFetchSellerBySupportGuid();
  const { data: categoryResult } = useFetchCategories();
  const isValidFundraiser = seller && seller.CanSupport && seller.Active && !seller.IsDeleted;

  const currentCategory = useMemo(() => {
    const mdCategory = categoryResult?.find(c => c.Name === 'Meal Deal Codes for Pick Up In Store');
    if (mdCategory) {
      return mdCategory.Id;
    }
  }, [ categoryResult ]);

  useEffect(() => {
    if (seller && seller.CanSupport) {
      supportSeller(seller.SupportGuid);
      startShopping();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ seller ]);

  // no seller found - 404
  if (seller && seller.CustomerId === 0) {
    return <Redirect to="/404" />;
  }

  // loading state
  if (isLoading && !error) {
    return (
      <Container sx={ { textAlign: 'center' } }>
        <Spinner variant="lce" />
      </Container>
    );
  }

  if (error) {
    return (
      <Container>
        <Heading>
          {t('mealDeal.products.uhoh')}
        </Heading>
        <Text variant="text.header.sub">
          {t('mealDeal.products.error')}
        </Text>
      </Container>
    );
  }

  return (
    <Box data-testid="meal-deal-products-page" sx={ { pt: [ 0, '30px', '30px', 0 ] } }>
      {seller && (isValidFundraiser
        ? <FundraiserTracker />
        : <NotRunningFundraiserModalsDisplay seller={ seller } />)}
      <Flex sx={ { flexDirection: 'column', mb: '12px', ml: '12px' } }>
        <Text sx={ { fontSize: [ '18px', '22px' ], textAlign: [ 'center', 'left' ] } }>
          {t('mealDeal.products.title')}
        </Text>
        <Text sx={ { fontSize: [ '16px', '18px' ], color: '#878686', textAlign: [ 'center', 'left' ] } }>
          {t('mealDeal.products.subtitle')}
        </Text>
      </Flex>
      <ProductList
        canStartShopping={ cartState.canStartShopping }
        currentCategory={ currentCategory }
        supportGuid={ match.params.supportGuid }
      >
        <Plus color="#fff" data-testid="plus-icon" size={ 30 } />
      </ProductList>
    </Box>
  );
};
