import { useQuery } from 'react-query';

import * as QueryKeys from '../../../constants/QueryKeys';
import { Product } from '../types';
import { fetchProduct } from '../api';

import { ApiError } from 'features/common';

export const useFetchProduct = (sku: string) => useQuery<Product, ApiError>(`${ QueryKeys.ProductPrefix }${ sku }`,
  () => fetchProduct(sku), { retry: false, refetchOnWindowFocus: false });
