import * as React from 'react';
import { SecureRoute } from '@okta/okta-react';

import { PrivateComponent } from '../PrivateComponent';

/**
 * HOC for protecting a route based on Role
 * @param component: The component to be rendered
 * @param roles: Roles which are allowed to access this component
 */
const PrivateRoute = ({ component: Component, roles, ...attrs }) => (
  <SecureRoute
    { ...attrs } render={ props => (
      <PrivateComponent roles={ roles }>
        <Component { ...props } />
      </PrivateComponent>
    ) }
  />
);

export default PrivateRoute;
