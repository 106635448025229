import React from 'react';
import {
  IconButton, Popover as SlicePopover, Image, Box, Flex, Text, IIconButtonProps,
} from '@lce/slice_v2';
import { useTranslation } from '@lce/i18n';
import { XSquare } from 'react-feather';

import SettingsImage from 'assets/icons/shape.svg';

export interface IFundraiserPopoverProps {
  setIsCancelFundraiser: () => void;
  canCancel: boolean;
  fundraiserName: string;
}

export const GearIcon: React.FC<IIconButtonProps> = ({ ...props }) => (
  <IconButton { ...props }>
    <Image src={ SettingsImage } />
  </IconButton>
);

// eslint-disable-next-line max-lines-per-function
const FundraiserPopover: React.FC<IFundraiserPopoverProps> = ({
  setIsCancelFundraiser,
  canCancel,
  fundraiserName,
}) => {
  const [ t ] = useTranslation();
  const [ isOpen, setIsOpen ] = React.useState<boolean>(false);

  const handleOnClose = () => setIsOpen(false);
  const handleOnOpen = () => setIsOpen(true);
  return (
    <SlicePopover
      clickContent={ (
        <GearIcon
          data-testid={ fundraiserName }
          sx={ {
            cursor: 'pointer',
            display: [ 'none', 'block', 'block' ],
          } }
        />
      ) }
      closeOnClickOutside={ true }
      closeOnEsc={ true }
      isOpen={ isOpen }
      onClose={ handleOnClose }
      onOpen={ handleOnOpen }
      placement="bottom-end"
    >
      <Box
        data-testid="popover_content"
        sx={ {
          borderRadius: '4px',
          boxShadow: '0 0 4px 1px rgba(0, 0, 0, 0.15)',
          border: 'solid 1px #ddd',
          backgroundColor: ' #fff',
          width: '312px',
          height: 'auto',
        } }
      >
        { canCancel && (
          <Flex
            as="button"
            data-testid={ `cancel-${ fundraiserName }` }
            onClick={ setIsCancelFundraiser }
            variant="buttons.skeleton"
          >
            <XSquare color="#868686" height="20" width="20" />

            <Text variant="fundraiserOptions.label">
              { t('fundraiser.CancelFundraiser')}
            </Text>
          </Flex>
        )}
      </Box>
    </SlicePopover>
  );
};

export default FundraiserPopover;
