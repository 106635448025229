import React from 'react';
import { Flex, Link, Image } from '@lce/slice_v2';
import _map from 'lodash/map';
import { ThemeUIStyleObject } from 'theme-ui';

import Instagram from 'assets/icons/instagram.svg';
import Facebook from 'assets/icons/facebook.svg';
import Pinterest from 'assets/icons/pinterest.svg';
import Youtube from 'assets/icons/youtube.svg';
import Twitter from 'assets/icons/twitterX.svg';

interface ISocialLink {
  icon: string;
  href: string;
  alt: string;
}

const ourLinks: ISocialLink[] = [
  {
    icon: Facebook,
    href: 'https://www.facebook.com/LittleCaesarsFundraising',
    alt: 'facebook',
  },
  {
    icon: Twitter,
    href: 'https://twitter.com/LCFundraise',
    alt: 'twitter',
  },
  {
    icon: Instagram,
    href: 'https://www.instagram.com/littlecaesarsfundraising/',
    alt: 'instagram',
  },
  {
    icon: Pinterest,
    href: 'https://www.pinterest.com/littlecaesarsfundraising/_created/',
    alt: 'pinterest',
  },
  {
    icon: Youtube,
    href: 'https://www.youtube.com/littlecaesarsfundraising',
    alt: 'youtube',
  },
];

const shareLinks: ISocialLink[] = [
  {
    icon: Facebook,
    href: 'https://www.facebook.com/',
    alt: 'facebook',
  },
  {
    icon: Pinterest,
    href: 'https://www.pinterest.com/',
    alt: 'pinterest',
  },
  {
    icon: Twitter,
    href: 'https://twitter.com/',
    alt: 'twitter',
  },
  {
    icon: Instagram,
    href: 'https://www.instagram.com/',
    alt: 'instagram',
  },
];

interface ISocialLinks {
  isShareLink?: boolean;
  sx?: ThemeUIStyleObject;
}

export const SocialLinks: React.FC<ISocialLinks> = ({ isShareLink = true, sx }) => {
  const links = isShareLink ? shareLinks : ourLinks;
  return (
    <Flex sx={ { ...sx, gap: 4 } }>
      {_map(links, (link, index) => (
        <Link
          data-testid={ `social-media-${ link.alt }` }
          href={ link.href }
          key={ index }
          sx={ { height: '40px', width: '40px' } }
          target="_blank"
        >
          <Image alt={ link.alt } src={ link.icon } title={ link.alt } />
        </Link>
      ))}
    </Flex>
  );
};
