import React from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from '@lce/i18n';
import { useHistory } from 'react-router-dom';

import { GenericToast } from 'ui/common/Toast/CustomToasts';
import { CreateMealDealAccountForm } from 'ui/account/MealDeal/CreateAccount';

export const MealDealRegistrationPage: React.FC = () => {
  const [ t ] = useTranslation();
  const history = useHistory();

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const redirectURL = urlParams.get('redirectUrl');

  const onBackUrl = '/start-a-fundraiser/meal-deal/account';
  const onSuccessUrl = redirectURL || '/start-a-fundraiser/meal-deal/signin';
  const onSuccessMessage = t('createAccount.AccountCreatedConfirmation');

  return (
    <div data-testid="md-registration-page">
      <CreateMealDealAccountForm
        isConfirm={ false }
        onBackCallback={ () => history.push(onBackUrl) }
        onSuccess={ () => {
          toast(<GenericToast text={ onSuccessMessage } />);
          history.push(onSuccessUrl);
        } }
      />
    </div>
  );
};
