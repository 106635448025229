import { useQuery } from 'react-query';

import { fetchOrderByGuid } from '../api';

import * as QueryKeys from 'constants/QueryKeys';
import { ApiError } from 'features/common';
import { Seller } from 'features/fundraiser';

export const useFetchOrderSeller =
  (order: string) => useQuery<Seller, ApiError>(QueryKeys.OrderPrefix + order, () => fetchOrderByGuid(order),
    {
      retry: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      enabled: !!order,
    });
