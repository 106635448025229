/* eslint-disable max-lines-per-function */
import React, { useCallback } from 'react';
import { useTranslation } from '@lce/i18n';
import { useHistory } from 'react-router-dom';
import {
  Button, Box, Form, Spinner, Text,
} from '@lce/slice_v2';
import * as Yup from 'yup';
import { format as dateformat, addWeeks } from 'date-fns';
import { useForm } from 'react-hook-form';

import { useCreateFundraiserMealDeal } from 'features/fundraiser/hooks';
import {
  FundraiserMealDeal, FundraiserType,
} from 'features/fundraiser';
import { ApiError, useLocalStorage } from 'features/common';
import { Step } from 'ui/common';
import {
  MoneyInput, moneyInputValidation, termsAndConditionsInputSchema, TermsAndConditionsInput,
} from 'ui/components';
import { calculateKitsFromProfitGoal } from 'features/fundraiser/utils';

interface IFundraiserCreateFormData {
  fundraiserPurpose: string;
  fundraiserTitle: string;
  profitGoal: string;
  termsAndConditions: boolean;
  zipcode: string;
}

interface IFundraiserCreationFormProps {
  startDate?: Date;
}

export const FundraiserCreationForm: React.FC<IFundraiserCreationFormProps> = ({ startDate = new Date() }) => {
  const history = useHistory();
  const [ t ] = useTranslation();
  const { mutateAsync: createFundraiser, isLoading } = useCreateFundraiserMealDeal();
  const requiredTranslation = useCallback((key: string) => `${ t(key) } *`, [ t ]);
  const [ franchiseeId, setFranchiseeId ] = useLocalStorage<string>('franchiseeId');
  const [ referralSource, setReferralSource ] = useLocalStorage<string>('referralSource');
  const [ fundraiserGoal, setFundraiserGoal ] = useLocalStorage<string>('fundraiserGoal');
  const { setError, formState: { errors } } = useForm();
  const validationSchema = Yup.object().shape({
    fundraiserTitle: Yup.string().required(t('mealDeal.fundraiserCreationPage.form.title.validation.required')),
    fundraiserPurpose: Yup.string().required(t('mealDeal.fundraiserCreationPage.form.purpose.validation.required')),
    zipcode: Yup
      .string()
      .matches(/^[0-9]+$/, t('mealDeal.fundraiserCreationPage.form.zipcode.validation.typeError'))
      .min(5, t('mealDeal.fundraiserCreationPage.form.zipcode.validation.min'))
      .max(5, t('mealDeal.fundraiserCreationPage.form.zipcode.validation.max'))
      .required(t('mealDeal.fundraiserCreationPage.form.zipcode.validation.typeError'))
      .typeError(t('mealDeal.fundraiserCreationPage.form.zipcode.validation.typeError')),
    ...moneyInputValidation(t),
    ...termsAndConditionsInputSchema(t, 'TermsAndConditions', true),
  });

  const onFormSubmit = useCallback(async(data: IFundraiserCreateFormData) => {
    if (isLoading) {
      return;
    }

    const {
      fundraiserTitle, profitGoal: profitGoalRaw, fundraiserPurpose, zipcode,
    } = data;
    const profitGoal = parseInt(profitGoalRaw);

    const endDate = addWeeks(startDate, 4);

    const fundraiser: FundraiserMealDeal = {
      kitGoal: calculateKitsFromProfitGoal(profitGoal),
      customMessage: fundraiserPurpose,
      dollarGoal: profitGoal,
      fundraiserName: fundraiserTitle,
      endDate: dateformat(endDate, 'MM/dd/yyyy'),
      startDate: dateformat(startDate, 'MM/dd/yyyy'),
      fundraiserType: FundraiserType.MealDeal,
      zipCode: zipcode,
      franchiseeId,
      referralSource,
    };

    try {
      const supportGuid = await createFundraiser(fundraiser);
      history.push(`/start-a-fundraiser/meal-deal/create/${ supportGuid }/success`);
      setFranchiseeId(undefined);
      setReferralSource(undefined);
      setFundraiserGoal(undefined);
    } catch (err) {
      const error = err as ApiError;

      if (error.ErrorCode === 'ERR-OK-FUNDRAISER-10') {
        setError('root.serverError', {
          type: error.ErrorCode,
          message: t('errorMessage.ERR-OK-FUNDRAISER-10'),
        });
      } else {
        setError('root.serverError', {
          type: error.ErrorCode,
          message: t('Something went wrong. Please try again.'),
        });
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ isLoading ]);

  return (
    <Step
      title={ t('mealDeal.fundraiserCreationPage.header.stepHeader') }
    >
      <Box variant="cards.step.body">
        <Form
          onSubmit={ onFormSubmit }
          validationSchema={ validationSchema }
        >
          <Form.Field
            id="fundraiserTitle"
            label={ requiredTranslation('mealDeal.fundraiserCreationPage.form.title.label') }
            name="fundraiserTitle"
            sx={ { mt: 0 } }
            variant="forms.fundraiser.field"
          />
          <Form.Field
            component={ Form.Input.Textarea }
            id="fundraiserPurpose"
            label={ requiredTranslation('mealDeal.fundraiserCreationPage.form.purpose.label') }
            name="fundraiserPurpose"
            rows="4"
          />
          <MoneyInput
            defaultValue={ fundraiserGoal }
            id="profitGoal"
            label={ requiredTranslation('mealDeal.fundraiserCreationPage.form.profitGoal.label') }
            name="profitGoal"
            variant="forms.fundraiser.field"
          />
          <Form.Field
            autoComplete="postal-code"
            id="zipcode"
            label={ requiredTranslation('mealDeal.fundraiserCreationPage.form.zipcode.label') }
            name="zipcode"
            variant="forms.fundraiser.field"
          />
          <TermsAndConditionsInput />
          {errors && errors.root && errors.root.serverError && (
            <Text data-testid="server-error" variant="text.warning">
              {errors.root.serverError.message}
            </Text>
          )}
          <Button
            disabled={ isLoading }
            id="submitButton"
            sx={ { mt: '33px', width: '100%' } }
            type="submit"
            variant={ isLoading ? 'disabled' : 'primary' }
          >
            { isLoading ? (
              <Spinner sx={ { height: '100%' } } variant="lce" />
            )
              : t('mealDeal.fundraiserCreationPage.form.submit.label')}
          </Button>
        </Form>
      </Box>
    </Step>
  );
};
