import React from 'react';
import * as Yup from 'yup';
import { Form } from '@lce/slice_v2';
import { TFunction } from 'i18next';
import { ThemeUIStyleObject } from 'theme-ui';

import { useFormField } from 'ui/hooks';
import { useMergeWithDefaults } from 'ui/components/utils';

export type FirstNameInputProps = {
  defaultValue?: string | undefined;
  id?: string;
  label?: string;
  name?: string;
  required?: boolean;
  sx?: ThemeUIStyleObject;
  testId?: string;
  variant?: string;
}

export const dataTestIdFirstNameInput = 'first-name';
export const defaultFirstNameInputName = 'FirstName';

export const defaultPropsFirstNameInput: FirstNameInputProps = {
  defaultValue: '',
  id: defaultFirstNameInputName,
  label: 'First name',
  name: defaultFirstNameInputName,
  required: true,
  sx: { marginRight: [ 0, '12px' ] },
  testId: dataTestIdFirstNameInput,
  variant: 'forms.fundraiser.field',
};

export const FirstNameInput = (props) => {
  const {
    defaultValue, id, label, name, sx, testId, variant, ...rest
  } = useMergeWithDefaults<
  FirstNameInputProps,
  FirstNameInputProps
  >(props, defaultPropsFirstNameInput, props.keysToOmit);

  return (
    <Form.Field
      autoComplete="given-name"
      data-testid={ testId }
      defaultValue={ defaultValue }
      id={ id }
      label={ label }
      name={ name }
      sx={ sx }
      variant={ variant }
      { ...rest }
    />
  );
};

export const firstNameInputSchema = (t: TFunction,
  validationKey: string = defaultFirstNameInputName,
  required = true): { [x: string]: Yup.StringSchema } => {
  const baseSchema = Yup.string()
    .trim()
    .max(50, t('fundraiser.MaxLength50'));

  return required
    ? { [validationKey]: baseSchema.required(t('fundraiser.FirstNameRequired')) }
    : { [validationKey]: baseSchema };
};

export const useFirstNameInput = (props: FirstNameInputProps = {}) => {
  const propsMerged = { ...defaultPropsFirstNameInput, ...props };

  const values = useFormField(FirstNameInput,
    firstNameInputSchema,
    propsMerged,
    'FirstNameInput',
    'firstNameInputSchema');

  return values as unknown as { FirstNameInput: React.FC<FirstNameInputProps>; firstNameInputSchema: object };
};
