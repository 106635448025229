import React from 'react';
import { useTranslation } from '@lce/i18n';
import { Box, Button } from '@lce/slice_v2';

import { LandingDisclaimer, LandingText } from '../../components';

import { FundraiserType } from 'features/fundraiser';
import { ComboLink } from 'ui/components/links';
import { useTranslationKeysAvailable, useStoreQueryParamToLocalStorage } from 'ui/hooks';

export type CallToActionProps = {
  fundraiserType: FundraiserType;
  page: string;
  testId?: string;
}

export const dataTestIdCallToAction = 'call-to-action-component';

// eslint-disable-next-line max-lines-per-function
export const CallToAction: React.FC<CallToActionProps> = ({
  fundraiserType,
  page,
  testId = dataTestIdCallToAction,
}) => {
  const [ t ] = useTranslation();
  useStoreQueryParamToLocalStorage('referralsource', 'referralSource');
  const translationPath = `landing.${ fundraiserType }.${ page }`;
  const showComboButton = useTranslationKeysAvailable([
    `${ translationPath }.buttonPath`,
    `${ translationPath }.buttonText`,
    `${ translationPath }.linkPath`,
    `${ translationPath }.linkText`,
  ]);
  const showJustButton = useTranslationKeysAvailable([
    `${ translationPath }.buttonPath`,
    `${ translationPath }.buttonText`,
  ]) && !showComboButton;

  return (
    <Box data-testid={ testId } sx={ { textAlign: 'center' } }>
      <LandingText translationPath={ translationPath } />
      <Box
        sx={ {
          display: 'flex', flexDirection: [ 'column' ], alignItems: 'center', mt: [ '30px', '65px' ],
        } }
      >
        {showComboButton && (
          <ComboLink
            primaryHref={ t(`${ translationPath }.buttonPath`) }
            primaryText={ t(`${ translationPath }.buttonText`) }
            secondaryHref={ t(`${ translationPath }.linkPath`) }
            secondaryText={ t(`${ translationPath }.linkText`) }
          />
        )}
        {showJustButton && (
          <Button
            as="a"
            data-testid="non-combo-button"
            href={ t(`${ translationPath }.buttonPath`) }
            sx={ {
              display: 'flex', m: 'auto', alignItems: 'center', justifyContent: 'center',
            } }
            variant="buttons.primary.large"
          >
            {t(`${ translationPath }.buttonText`)}
          </Button>
        )}
      </Box>
      <LandingDisclaimer translationPath={ translationPath } />
    </Box>
  );
};
