/* eslint-disable no-confusing-arrow */
/* eslint-disable react/jsx-indent */
import * as React from 'react';
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';

import { GoogleProvider } from '../Google';
import { FingerprintProvider } from '../Fingerprint';
import { reactPlugin } from '../ApplicationInsights';
import { TagManagerSettings } from '../Google/TagManager/interface';

export interface IAnalyticsProviderProps {
  children: React.ReactNode;
  settings: {
    CONFIG: {
      GOOGLE_TAG_MANAGER: TagManagerSettings;
    };
  };
}

const AnalyticsProvider: React.FC<IAnalyticsProviderProps> = ({ children, settings }) => settings ? (
  <AppInsightsContext.Provider value={ reactPlugin }>
    <GoogleProvider gtm={ settings.CONFIG.GOOGLE_TAG_MANAGER } settings={ settings }>
      <FingerprintProvider>
        { children }
      </FingerprintProvider>
    </GoogleProvider>
  </AppInsightsContext.Provider>
) : null;
export default AnalyticsProvider;
