/* eslint-disable max-len */
import React from 'react';
import {
  Divider, Flex, Text,
} from '@lce/slice_v2';
import { useTranslation } from '@lce/i18n';

import { RouterLink } from '../../../RouterLink';

import * as FundraiserDefaults from 'constants/FundraiserDefaults';
import { AccountMenu } from 'ui/common';
import { FundraiserType } from 'features/fundraiser/types';
import { useFundraiserState } from 'features/fundraiser/context/fundraiserContext';
import { useFetchProductsPageRoute } from 'features/products';

export interface ILinksProps {
  loggedIn: boolean;
  roles: string[];
}

interface IRenderLinkItem {
  text: string;
  path: string;
  nested?: boolean;
}

const elevatedRoles = [
  FundraiserDefaults.SuperAdminRoleName,
  FundraiserDefaults.ChairpersonRoleName,
  FundraiserDefaults.SalesAgentRoleName,
  FundraiserDefaults.SellerRoleName,
];

const RenderLinkItem: React.FC<IRenderLinkItem> = ({ path, text, nested = false }) => (
  <RouterLink
    asNav={ true }
    data-testid={ path }
    sx={ { marginLeft: nested ? '38px' : '0px', p: [ '15px 32px' ] } }
    to={ path }
    variant="links.navLinkMobile"
  >
    <Flex sx={ { alignItems: 'center', flexDirection: 'row' } }>
      <Text ml="15px" sx={ { fontSize: '16px', fontWeight: 800 } }>
        {text}
      </Text>
    </Flex>
  </RouterLink>
);

const Links: React.FC<ILinksProps> = ({ loggedIn, roles }) => {
  const [ t ] = useTranslation();
  const showFundraiserRoute = roles && elevatedRoles.filter(x => roles.includes(x)).length > 0;
  const { fundraiserType } = useFundraiserState();
  const loginUrl = t('nav.LoginUrl', { context: fundraiserType });
  const showHowItWorks = fundraiserType !== FundraiserType.MealDeal;
  const showAboutUs = fundraiserType !== FundraiserType.MealDeal;
  const productPageRoute = useFetchProductsPageRoute();
  return (
    <Flex sx={ { flexDirection: 'column', flex: '1 1 auto' } }>
      {showHowItWorks && (<RenderLinkItem path="/how-it-works" text={ `${ t('dashboard.HowItWorks') }` } />)}
      <RenderLinkItem path={ t('routes.requestInfoPage', { context: fundraiserType }) } text={ `${ t('dashboard.RequestInformation') }` } />
      {showAboutUs && (<RenderLinkItem path="/about-us" text={ `${ t('nav.AboutUs') }` } />)}
      <RenderLinkItem path={ productPageRoute } text={ `${ t('dashboard.Products') }` } />

      <Divider />

      {loggedIn ? (
        <AccountMenu>
          {showFundraiserRoute &&
            <RenderLinkItem nested={ true } path="/dashboard/fundraisers" text={ `${ t('dashboard.MyFundraisers') }` } />}
          <RenderLinkItem nested={ true } path="/dashboard/my-orders" text={ `${ t('dashboard.MyOrders') }` } />
          {
            roles.includes(FundraiserDefaults.SuperAdminRoleName) && <RenderLinkItem nested={ true } path="/admin/create-sales-agent" text={ `${ t('dashboard.Administration') }` } />
          }
          <RenderLinkItem nested={ true } path="/dashboard/settings" text={ `${ t('dashboard.Settings') }` } />
          {roles.includes(FundraiserDefaults.SuperAdminRoleName) &&
            <RenderLinkItem nested={ true } path="/dashboard/reports" text={ `${ t('dashboard.Reports') }` } />}
          <RenderLinkItem nested={ true } path="/logout" text={ `${ t('dashboard.Logout') }` } />
        </AccountMenu>
      )
        : <RenderLinkItem path={ loginUrl } text={ `${ t('dashboard.Login') }` } />}
    </Flex>
  );
};

export default Links;
