/* eslint-disable max-len */
import React, { useMemo } from 'react';
import { StringSchema } from 'yup';
import { useTranslation } from '@lce/i18n';
import { TFunction } from 'i18next';

type FormFieldProps = {
  label: string;
  name: string;
}

type SpecicalFieldProps = FormFieldProps & {
  required: boolean;
}

interface Schema {
  [key: string]: StringSchema;
}

export function useFormField<T>(FormField: React.FC<T>,
  schema: (t: TFunction, validationKey: string, required: boolean) => object,
  props: T,
  componentKey: string,
  schemaKey: string) {
  const { t } = useTranslation();

  return useMemo(() => {
    const { label, name, required } = props as unknown as SpecicalFieldProps;
    const specialProps: FormFieldProps = {
      label,
      name,
    };

    if (required) {
      specialProps.label = `${ label }*`;
    }
    const effectiveSchema = schema(t, name, required);
    const ComponentWithSchema = standardProps => <FormField { ...standardProps } { ...specialProps } />;
    ComponentWithSchema.schema = effectiveSchema;
    const FormFieldConfigured: React.FC<T> | Schema = ComponentWithSchema;

    return { [componentKey]: FormFieldConfigured, [schemaKey]: effectiveSchema };
  }, [ schema, props, t, componentKey, schemaKey ]);
}

