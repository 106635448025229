import { forEach as _forEach } from 'lodash';

import { groupsInfo } from '../utils/GroupsData';
import { IParentGroup } from '../types/parentGroup';

const emptyGroup: IParentGroup = {
  parentGroupId: null,
  groupTypeId: null,
  groupTypeName: '',
  numberOfSellers: null,
  salesAgentCode: null,
};

const useFetchParentGroupData = (id: string) => {
  let parentGroup = emptyGroup;
  _forEach(groupsInfo, (group: IParentGroup) => {
    if (id === group.parentGroupId?.toString()) {
      parentGroup = group;
    }
  });
  return parentGroup;
};

export { useFetchParentGroupData };
