import { useTranslation } from '@lce/i18n';

import { FundraiserType, useFetchSellerBySupportGuid } from 'features/fundraiser';
import { useFundraiserState } from 'features/fundraiser/context/fundraiserContext';

export const useFetchProductsPageRoute = (sellerSupportGuid?: string, fundraiserType?: FundraiserType) => {
  const [ t ] = useTranslation();
  const { data: seller } = useFetchSellerBySupportGuid();
  const { fundraiserType: fundraiserTypeFromState } = useFundraiserState();
  const supportGuid = sellerSupportGuid || seller?.SupportGuid;
  const route = supportGuid ? '' : 'NoSupportGuid';
  const context = fundraiserType || fundraiserTypeFromState;
  return t(`routes.productsPage${ route }`, { context, supportGuid });
};
