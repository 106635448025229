/* eslint-disable max-len */
import * as React from 'react';
import {
  Flex, Text, Form,
} from '@lce/slice_v2';
import { useState } from 'react';
import { useTranslation } from '@lce/i18n';

import en from '../../../../i18n/en-us.json';

import { useCheckoutDispatch, useCheckoutState } from 'features/checkout';

// eslint-disable-next-line max-lines-per-function
const GiftMessaging: React.FC = () => {
  const state = useCheckoutState();
  const [ t ] = useTranslation();
  const dispatch = useCheckoutDispatch();
  const [ showGiftMessaging, setShowGiftMessaging ] = useState<boolean>(false);

  const hasOnlyCouponItems = state.checkout?.Cart.every(cart => cart.IsFreeShipping === true);

  const handleOnClick = (value) => {
    if (!value) {
      dispatch({ type: 'SET_GIFT_MESSAGING', giftMessageRecipient: '', giftMessage: '' });
    }
    setShowGiftMessaging(value);
  };

  const handleOnChange = (value, name) => {
    let newGiftEmail = state.giftMessageRecipient;
    let newGiftMessage = state.giftMessage;

    if (state.giftMessageRecipient !== '') {
      if (!state.giftMessage) {
        newGiftMessage = en.translation.giftMessaging.textareaPlaceholder;
      }
    }
    if (name === 'email') {
      newGiftEmail = value;
    } else if (value === '' && name === 'message') {
      newGiftMessage = en.translation.giftMessaging.textareaPlaceholder;
    } else {
      newGiftMessage = value;
    }
    dispatch({ type: 'SET_GIFT_MESSAGING', giftMessageRecipient: newGiftEmail, giftMessage: newGiftMessage });
  };

  return (
    <Flex sx={ { flexDirection: 'column' } }>
      <Form.Field
        aria-checked="false"
        component={ Form.Input.Checkbox }
        data-testid="giftMessageLabel"
        defaultChecked={ false }
        id="optGiftMessage"
        inline={ true }
        label={ (
          <Text variant="orderReview.formLabel">
            {t('giftMessaging.label')}
          </Text>
        ) }
        name="optGiftMessage"
        onClick={ (e) => {
          handleOnClick(e.currentTarget.checked);
        } }
        role="checkbox"
        sx={ { display: hasOnlyCouponItems ? 'none' : 'default' } }
      />
      {showGiftMessaging && (
        <Flex id="giftMessaging" name="giftMessaging" sx={ { flexDirection: 'column', ml: [ 0, '28px' ], mb: [ '24px', '28px' ] } }>
          <Form.Input.Text
            data-testid="txtGiftMessageEmail"
            id="txtGiftMessageEmail"
            name="txtGiftMessageEmail"
            onChange={ e => handleOnChange(e.target.value, 'email') }
            placeholder={ t('giftMessaging.emailPlaceholder') }
            sx={ { mt: '4px', p: '20px', mb: '16px' } }
          />
          <Form.Input.Textarea
            data-testid="txtAreaGiftMessaging"
            id="txtAreaGiftMessaging"
            name="txtAreaGiftMessaging"
            onChange={ e => handleOnChange(e.target.value, 'message') }
            placeholder={ t('giftMessaging.textareaPlaceholder') }
            rows={ 4 }
            sx={ { mt: '4px', p: '20px', resize: 'none' } }
          />
          <Text
            sx={ {
              fontSize: '12px', color: 'primaryGrayDark', lineHeight: 1, mt: '10px',
            } }
          >
            { t('giftMessaging.note') }
          </Text>
        </Flex>
      )}
    </Flex>
  );
};

export default GiftMessaging;
