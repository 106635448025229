import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { useIsArchExpanding } from './hooks';

export interface IArchProps {
  headerHeight: string;
}

const ExpandingArch: React.FC<IArchProps> = ({ headerHeight }) => (
  <svg
    data-testid="expanding-arch"
    style={ { position: 'absolute', width: '100%', top: headerHeight } }
    viewBox="0 0 800 26"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 0H800V0C533.333 0 0 0 0 0V0Z"
      fill="white"
    >
      <animate
        attributeName="d"
        begin="0s"
        dur="0.5s"
        fill="freeze"
        keyTimes="0; 0.01; 0.5; 1"
        values="M0 0H800V0C533.333 0 0 0 0 0V0Z;
          M0 0H800V1C533.333 -0.282051 266.667 -0.282051 0 1V0Z;
          M0 0H800V13C533.333 -3.66667 266.667 -3.66667 0 13V0Z;
          M0 0H800V26C533.333 -7.33333 266.667 -7.33333 0 26V0Z;"
      />
    </path>
  </svg>
);

const CollapsingArch: React.FC<IArchProps> = ({ headerHeight }) => (
  <svg
    data-testid="collapsing-arch"
    style={ { position: 'absolute', width: '100%', top: headerHeight } }
    viewBox="0 0 800 26"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 0H800V26C533.333 -7.33333 266.667 -7.33333 0 26V0Z"
      fill="white"
    >
      <animate
        attributeName="d"
        begin="0s"
        dur="0.5s"
        fill="freeze"
        keyTimes="0; 0.5; 0.99; 1"
        values="M0 0H800V26C533.333 -7.33333 266.667 -7.33333 0 26V0Z;
          M0 0H800V13C533.333 -3.66667 266.667 -3.66667 0 13V0Z;
          M0 0H800V1C533.333 -0.282051 266.667 -0.282051 0 1V0Z;
          M0 0H800V0C533.333 0 0 0 0 0V0Z;"
      />
    </path>
  </svg>
);

const Arch: React.FC<IArchProps> = ({ headerHeight }) => {
  const location = useLocation();
  const isArchExpanding = useIsArchExpanding();

  const AnimatedArch = useMemo(() => {
    if (isArchExpanding) {
      return <ExpandingArch headerHeight={ headerHeight } />;
    }
    return <CollapsingArch headerHeight={ headerHeight } />;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ isArchExpanding ]);

  if (location.pathname === '/') {
    return AnimatedArch;
  }
  return null;
};

export default Arch;
