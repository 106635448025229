import {
  Button,
  Flex, Form, Option, Text, Spinner,
} from '@lce/slice_v2';
import React from 'react';
import { useTranslation } from '@lce/i18n';

import { useFetchStates } from 'features/common';

// eslint-disable-next-line max-lines-per-function
const ProfitCheckAddressForm = () => {
  const [ t ] = useTranslation();
  const [ stateIso, setStateIso ] = React.useState<string|undefined>(undefined);
  const { data: states, isFetched: isStatesFetched } = useFetchStates(1);

  const onStateSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStateIso(e.currentTarget.value);
  };

  if (!isStatesFetched) {
    return (
      <Flex data-testid="profit-check-form-loading" variant="modals.profitCheckAddressModal.formContainer">
        <Text variant="modals.profitCheckAddressModal.title">
          {t('fundraiser.ProfitCheckAddressModal.Title')}
        </Text>

        <Text sx={ { mb: 4 } } variant="modals.profitCheckAddressModal.subTitle">
          {t('fundraiser.ProfitCheckAddressModal.Subtitle')}
        </Text>
        <Spinner variant="lce" />
      </Flex>
    );
  }

  return (
    <Flex data-testid="profit-check-address-form" variant="modals.profitCheckAddressModal.formContainer">

      <Text variant="modals.profitCheckAddressModal.title">
        {t('fundraiser.ProfitCheckAddressModal.Title')}
      </Text>

      <Text variant="modals.profitCheckAddressModal.subTitle">
        {t('fundraiser.ProfitCheckAddressModal.Subtitle')}
      </Text>

      <Flex sx={ { flexDirection: 'column', width: [ '100%' ], px: '20px' } }>
        <Text sx={ { fontWeight: 'bold', fontSize: '14px' } }>
          {t('fundraiser.ProfitCheckAddressModal.AllFieldsRequired')}
        </Text>
        <Form.Field
          defaultValue=""
          id="profitCheckPayableTo"
          label={ (
            <Text variant="modals.profitCheckAddressModal.inputLabel">
              {t('fundraiser.ProfitCheckAddressModal.MakeCheckPayableTo')}
            </Text>
          ) }
          name="profitCheckPayableTo"
          variant="forms.fundraiser.field"
        />
        <Form.Field
          autoComplete="street-address"
          defaultValue=""
          id="groupAddress"
          label={ (
            <Text variant="modals.profitCheckAddressModal.inputLabel">
              {t('fundraiser.ProfitCheckAddressModal.Address')}
            </Text>
          ) }
          name="groupAddress"
          variant="forms.fundraiser.field"
        />
        <Form.Field
          autoComplete="address-level2"
          defaultValue=""
          id="groupCity"
          label={ (
            <Text variant="modals.profitCheckAddressModal.inputLabel">
              {t('fundraiser.ProfitCheckAddressModal.City')}
            </Text>
          ) }
          name="groupCity"
          variant="forms.fundraiser.field"
        />
        <Form.Field
          autoComplete="address-level1"
          component={ Form.Select }
          id="groupState"
          label={ (
            <Text variant="modals.profitCheckAddressModal.inputLabel">
              {t('fundraiser.ProfitCheckAddressModal.State')}
            </Text>
          ) }
          name="groupState"
          onChange={ e => onStateSelect(e) }
          value={ stateIso }
          variant="forms.fundraiser.field"
        >
          <Option value="">---</Option>
          {states && states.map(state => (
            <Option
              key={ state.Id }
              label={ state.Name }
              value={ state.Abbreviation }
            />
          ))}
        </Form.Field>
        <Form.Field
          autoComplete="postal-code"
          defaultValue=""
          id="groupZipCode"
          label={ (
            <Text variant="modals.profitCheckAddressModal.inputLabel">
              {t('fundraiser.ProfitCheckAddressModal.ZipCode')}
            </Text>
          ) }
          name="groupZipCode"
          variant="forms.fundraiser.field"
        />

        <Button
          data-testid="submit-address"
          type="submit"
          variant="modals.profitCheckAddressModal.submitButton"
        >
          {t('fundraiser.ProfitCheckAddressModal.SubmitButtonText')}
        </Button>
      </Flex>
    </Flex>
  );
};

export default ProfitCheckAddressForm;
