import { useMutation } from 'react-query';

import { uploadFundraiserPhoto } from '../api';

import { ApiError, ApiResponse } from 'features/common';

const useUploadFundraiserPhoto =
  () => useMutation<ApiResponse, ApiError, { id: number; data: FormData }>(uploadFundraiserPhoto);

export default useUploadFundraiserPhoto;
