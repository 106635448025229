import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { ThemeUIStyleObject } from 'theme-ui';
import { Button as SliceButton } from '@lce/slice_v2';

export interface ButtonProps {
  children: string;
  href: string;
  sx?: ThemeUIStyleObject;
  testId?: string;
  variant?: string;
}

export const buttonTestIdDefault = 'button';

export const Button: React.FC<ButtonProps> = ({
  children,
  href,
  testId = buttonTestIdDefault,
  sx = {},
  variant = 'primary',
  ...props
}) => {
  const history = useHistory();

  const onClick = useCallback(() => {
    history.push(href);
  }, [ history, href ]);

  return (
    <SliceButton
      data-testid={ testId }
      onClick={ onClick }
      sx={ sx }
      variant={ variant }
      { ...props }
    >
      {children}
    </SliceButton>
  );
};
