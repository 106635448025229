import { Box, Flex, Text } from '@lce/slice_v2';
import React, {
  forwardRef, Ref, useEffect, useImperativeHandle, useState,
} from 'react';
import { Star } from 'react-feather';
import { Placement } from '@popperjs/core';

import { IPopoverRef, Popover } from './Popover';

import { FundraiserPopover, useFundraiserPopover } from 'features/popovers';

export interface INewFundraiserPopover {
  referenceElement: HTMLElement | null;
  onHide: () => void;
  text: string;
  isHidden: boolean;
  shouldDelay?: boolean;
  placement?: Placement;
}

export interface INewFundraiserPopoverParent {
  referenceElement: HTMLElement | null;
  fundraiserId: number;
  popovers: FundraiserPopover[] | undefined;
  setPopovers: React.Dispatch<React.SetStateAction<FundraiserPopover[]>>;
}

export interface IHidePopoverRef {
  hide: () => void;
}

const NewFundraiserPopover = forwardRef(({
  referenceElement, onHide, text, placement = 'top', isHidden = false, shouldDelay = false,
}: INewFundraiserPopover, ref: Ref<IPopoverRef>) => {
  const [ wait, setWait ] = useState<boolean>(shouldDelay);

  useEffect(() => {
    if (shouldDelay) {
      const handler = setTimeout(() => {
        setWait(false);
      }, 500);

      return () => {
        clearTimeout(handler);
      };
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Popover
      isOpen={ !isHidden && !wait }
      onClose={ onHide }
      placement={ placement }
      ref={ ref }
      referenceElement={ referenceElement }
    >
      <Flex sx={ { alignItems: 'center' } }>
        <Box
          sx={ {
            marginRight: '24px',
            minWidth: '50px',
            height: '50px',
            borderRadius: '50%',
            textAlign: 'center',
            verticalAlign: 'middle',
            paddingTop: '14px',
            border: '2px solid',
            borderColor: 'primaryOrange',
          } }
        >
          <Star color="#FF671B" size={ 20 } />
        </Box>

        <Box sx={ { maxWidth: '232px' } }>
          <Text sx={ { fontSize: '16px' } } variant="text.header.sub">
            { text }
          </Text>
        </Box>
      </Flex>
    </Popover>
  );
});

const NewFundraiserCPPopover: React.FC<INewFundraiserPopoverParent> = (props) => {
  const [ popoverHidden, hidePopover, popover ] =
    useFundraiserPopover({
      fundraiserId: props.fundraiserId,
      key: 'cp-dashboard-tip',
      popovers: props?.popovers || [],
      setPopovers: props.setPopovers,
    });

  return (
    <NewFundraiserPopover
      isHidden={ popoverHidden }
      onHide={ () => hidePopover({
        key: 'cp-dashboard-tip',
        fundraiserId: props.fundraiserId,
        id: popover?.Id || 0,
      }) }
      referenceElement={ props.referenceElement }
      text="Your New Fundraiser. Click to Get Started!"
    />
  );
};

const NewFundraiserSellerPopover: React.FC<INewFundraiserPopoverParent> = (props) => {
  const [ popoverHidden, hidePopover, popover ] =
    useFundraiserPopover({
      fundraiserId: props.fundraiserId,
      key: 'seller-dashboard-tip',
      popovers: props?.popovers || [],
      setPopovers: props.setPopovers,
    });

  return (
    <NewFundraiserPopover
      isHidden={ popoverHidden }
      onHide={ () => hidePopover({
        key: 'seller-dashboard-tip',
        fundraiserId: props.fundraiserId,
        id: popover?.Id || 0,
      }) }
      referenceElement={ props.referenceElement }
      text="Personalize your profile!"
    />
  );
};

const SellerInvitePopover = forwardRef((props: INewFundraiserPopoverParent, ref: Ref<IPopoverRef>) => {
  const [ popoverHidden, hidePopover, popover ] =
    useFundraiserPopover({
      fundraiserId: props.fundraiserId,
      key: 'invite-tip',
      popovers: props?.popovers || [],
      setPopovers: props.setPopovers,
    });

  return (
    <NewFundraiserPopover
      isHidden={ popoverHidden }
      onHide={ () => hidePopover({
        key: 'invite-tip',
        fundraiserId: props.fundraiserId,
        id: popover?.Id || 0,
      }) }
      placement="top-start"
      ref={ ref }
      referenceElement={ props.referenceElement }
      shouldDelay={ true }
      text="Invite sellers by sharing the link, or QR code, below!"
    />
  );
});

const BuyerInvitePopover = forwardRef((props: INewFundraiserPopoverParent, ref: Ref<IHidePopoverRef>) => {
  const [ popoverHidden, hidePopover, popover ] =
    useFundraiserPopover({
      fundraiserId: props.fundraiserId,
      key: 'seller-preview-tip',
      popovers: props?.popovers || [],
      setPopovers: props.setPopovers,
    });

  const hide = () => {
    hidePopover({
      key: 'seller-preview-tip',
      fundraiserId: props.fundraiserId,
      id: popover?.Id || 0,
    });
  };

  useImperativeHandle(ref, () => ({ hide }));

  return (
    <NewFundraiserPopover
      isHidden={ popoverHidden }
      onHide={ () => hidePopover({
        key: 'seller-preview-tip',
        fundraiserId: props.fundraiserId,
        id: popover?.Id || 0,
      }) }
      placement="top-end"
      referenceElement={ props.referenceElement }
      shouldDelay={ true }
      text="Share the link, or QR code, below to ask friends and family for support!"
    />
  );
});

SellerInvitePopover.displayName = 'SellerInvitePopover';
NewFundraiserPopover.displayName = 'NewFundraiserPopover';
BuyerInvitePopover.displayName = 'BuyerInvitePopover';

export {
  NewFundraiserCPPopover,
  NewFundraiserSellerPopover,
  SellerInvitePopover,
  BuyerInvitePopover,
};
