import React from 'react';
import {
  Box, Card, Divider, Flex, Skeleton,
} from '@lce/slice_v2';

const OrderHistoryListItemSkeleton = () => (
  <>
    <Skeleton count={ 1 } sx={ { height: '24px', width: '40%', mb: '10px' } } />
    <Flex
      as={ Card } sx={ {
        flexDirection: 'column', padding: '30px', borderColor: 'primaryGrayMid', mb: '24px',
      } }
    >
      <Flex sx={ { flexDirection: [ 'column', 'column', 'row' ] } }>
        <Box sx={ { flexBasis: '33%' } }>
          <Skeleton count={ 1 } sx={ { height: '16px', width: '40%', mb: '10px' } } />
          <Skeleton count={ 1 } sx={ { height: '16px', width: '30%', mb: '10px' } } />
        </Box>
        <Box sx={ { flexBasis: '33%' } }>
          <Skeleton count={ 1 } sx={ { height: '16px', width: '40%', mb: '10px' } } />
          <Skeleton count={ 1 } sx={ { height: '16px', width: '30%', mb: '10px' } } />
        </Box>
        <Box sx={ { flexBasis: '33%' } }>
          <Skeleton count={ 1 } sx={ { height: '16px', width: '40%', mb: '10px' } } />
          <Skeleton count={ 1 } sx={ { height: '16px', width: '30%', mb: '10px' } } />
        </Box>
      </Flex>
      <Divider sx={ { color: 'primaryGrayMid' } } />
      <Flex sx={ { flexDirection: [ 'column', 'column', 'row' ] } }>
        <Box sx={ { flexBasis: '33%' } }>
          <Skeleton count={ 1 } sx={ { height: '16px', width: '40%', mb: '10px' } } />
          <Skeleton count={ 1 } sx={ { height: '16px', width: '30%', mb: '10px' } } />
        </Box>
        <Box sx={ { flexBasis: '33%' } }>
          <Skeleton count={ 1 } sx={ { height: '16px', width: '40%', mb: '10px' } } />
          <Skeleton count={ 1 } sx={ { height: '16px', width: '30%', mb: '10px' } } />
        </Box>
        <Box sx={ { flexBasis: '33%' } }>
          <Skeleton count={ 1 } sx={ { height: '16px', width: '40%', mb: '10px' } } />
          <Skeleton count={ 1 } sx={ { height: '16px', width: '30%', mb: '10px' } } />
        </Box>
      </Flex>
    </Flex>

  </>
);

export default OrderHistoryListItemSkeleton;
