export enum PictureTypes {

  /// <summary>
  /// Entities (products, categories, manufacturers)
  /// </summary>
  Entity = 1,

  /// <summary>
  /// Avatar
  /// </summary>
  Avatar = 10,

  /// <summary>
  /// Product Carousel Image
  /// </summary?
  Carousel = 20,

  /// <summary>
  /// Thumbnail picture
  /// </summary>
  Thumbnail = 30,

  /// <summary>
  /// Product's Nutritional Information picture
  /// </summary>
  NutritionalInfo = 40
}
