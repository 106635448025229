// eslint-disable-next-line complexity
export const displayShippingInfoText = (cartHasFreeShippingItems, shippableKits) => {
  const shippingInfoText = 'cart.ShippingInfoText';
  const shippingInfoText2 = 'cart.ShippingInfoText2';
  const couponCodeInfoText = 'cart.CouponCodeShippingInfoText';
  let shippingText = '';
  let couponCodeText = '';
  const needs1MoreW3Kit = (shippableKits + 1) % 3 === 0;
  const needs2MoreW3Kit = (shippableKits + 2) % 3 === 0;

  if (needs1MoreW3Kit) {
    shippingText = shippingInfoText;
  }
  if (needs2MoreW3Kit) {
    shippingText = shippingInfoText2;
  }
  if (cartHasFreeShippingItems) {
    couponCodeText = couponCodeInfoText;
  }

  return { shippingText, couponCodeText };
};
