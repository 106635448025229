/* eslint-disable max-lines-per-function */
import { Image } from '@lce/slice_v2';
import React from 'react';
import { ThemeUIStyleObject } from 'theme-ui';

import OpenArmsCaesarMan from './assets/Open-Arms-Caesar.png';
import WavingCaesar from './assets/Waving-Caesar.png';
import ThumbsUpCaesar from './assets/thumbsup-LCEMan.png';
import PizzaKits from './assets/pizzakits.png';

import * as FundraiserDefaults from 'constants/FundraiserDefaults';

export interface IUserIconProps {
  isLeft: boolean;
  role: string;
  sx?: ThemeUIStyleObject;
}

const UserIcon: React.FC<IUserIconProps> = ({ role, isLeft }) => {
  let iconImage = '';
  let iconHeight = '';
  let iconWidth = '';

  switch (role) {
    case FundraiserDefaults.ChairpersonRoleName.toLowerCase():
      if (isLeft) {
        iconImage = WavingCaesar;
        iconHeight = '109px';
        iconWidth = '94px';
      } else {
        iconImage = OpenArmsCaesarMan;
        iconHeight = '99px';
        iconWidth = '102px';
      }
      break;
    case FundraiserDefaults.SellerRoleName.toLowerCase():
      if (isLeft) {
        iconImage = WavingCaesar;
        iconHeight = '109px';
        iconWidth = '94px';
      } else {
        iconImage = ThumbsUpCaesar;
        iconHeight = '111px';
        iconWidth = '105px';
      }
      break;
    case FundraiserDefaults.BuyerRoleName.toLowerCase():
      if (isLeft) {
        iconImage = OpenArmsCaesarMan;
        iconHeight = '99px';
        iconWidth = '102px';
      } else {
        iconImage = PizzaKits;
        iconHeight = '96px';
        iconWidth = '116px';
      }
      break;
    default:
      break;
  }

  return (
    <Image
      alt=""
      data-testid={ `${ role }-left-${ isLeft }` } src={ iconImage }
      sx={ {
        height: iconHeight,
        width: iconWidth,
      } }
    />
  );
};

export default UserIcon;
