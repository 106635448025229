import {
  Flex, Form, IconButton, Modal as SliceModal,
} from '@lce/slice_v2';
import * as Yup from 'yup';
import React, { useEffect, useMemo } from 'react';
import { X } from 'react-feather';
import { toast } from 'react-toastify';
import { useTranslation } from '@lce/i18n';

import { ProfitCheckAddressForm } from './ProfitCheckAddressForm.tsx';
import { ProfitCheckAddressConfirm } from './ProfitCheckAddressConfirm.tsx';

import { GenericToast } from 'ui/common';
import { useUpdateFundraiserProfitCheckAddress } from 'features/fundraiser/hooks/useUpdateFundraiserProfitCheckAddress';
import { FundraiserProfitCheckAddress } from 'features/fundraiser/types/fundraiser';

export interface IProfitCheckAddressModal {
  onClose: () => void;
  isOpen: boolean;
  fundraiserId: number;
  profitCheckData: FundraiserProfitCheckAddress | null;
  fundraiserHasExpired: boolean;
  isMissingProfitCheckAddress: boolean;
  showConfirmationDefault?: boolean;
}

// eslint-disable-next-line max-lines-per-function
const ProfitCheckAddressModal: React.FC<IProfitCheckAddressModal> = ({
  onClose, isOpen, profitCheckData, fundraiserHasExpired, isMissingProfitCheckAddress,
  fundraiserId, showConfirmationDefault = false,
}) => {
  const [ showConfirmation, setShowConfirmation ] = React.useState<boolean>(showConfirmationDefault);
  const { mutateAsync: updateProfitCheckAddress } = useUpdateFundraiserProfitCheckAddress();
  const [ t ] = useTranslation();

  const initialData: FundraiserProfitCheckAddress = useMemo(() => ({
    fundraiserId,
    profitCheckPayableTo: profitCheckData?.profitCheckPayableTo || '',
    groupAddress: profitCheckData?.groupAddress || '',
    groupCity: profitCheckData?.groupCity || '',
    groupState: profitCheckData?.groupState || '',
    groupZipCode: profitCheckData?.groupZipCode || '',
  }), [ profitCheckData, fundraiserId ]);

  const [ checkAddress, setCheckAddress ] = React.useState<FundraiserProfitCheckAddress>(initialData);

  useEffect(() => {
    setCheckAddress(initialData);

    if (fundraiserHasExpired && !isMissingProfitCheckAddress) {
      setShowConfirmation(true);
    }
  }, [ profitCheckData, initialData, fundraiserHasExpired, isMissingProfitCheckAddress ]);

  const onSubmit = async(data: FundraiserProfitCheckAddress) => {
    try {
      setCheckAddress(data);
      await updateProfitCheckAddress(data);
      toast(<GenericToast text={ t('fundraiser.ProfitCheckAddressModal.toast.updateAddressSuccess') } />);
      setShowConfirmation(true);
    } catch {
      toast(<GenericToast text={ t('fundraiser.ProfitCheckAddressModal.toast.updateAddressError') } />);
    }
  };

  const handleOnEdit = () => {
    setShowConfirmation(!showConfirmation);
  };

  const validationSchema = Yup.object().shape({
    profitCheckPayableTo: Yup.string()
      .required(t('fundraiser.ProfitCheckAddressModal.formValidations.checkPayableRequired')),
    groupAddress: Yup.string().required(t('fundraiser.ProfitCheckAddressModal.formValidations.addressRequired')),
    groupCity: Yup.string().required(t('fundraiser.ProfitCheckAddressModal.formValidations.cityRequired')),
    groupState: Yup.string().required(t('fundraiser.ProfitCheckAddressModal.formValidations.stateRequired')),
    groupZipCode: Yup.string()
      .required(t('fundraiser.ProfitCheckAddressModal.formValidations.zipCodeRequired'))
      .matches(/^\b\d{5}\b(?:[- ]{1}\d{4})?$/, t('fundraiser.ProfitCheckAddressModal.formValidations.zipCodeInvalid')),
  });

  return (
    <SliceModal
      closeIcon={ false }
      isOpen={ isOpen }
      key={ `address-modal-show-${ showConfirmation }` }
      lockBodyScroll={ true }
      onClose={ onClose }
      sx={ {
        mt: [ 0, '2% !important', '2% !important' ],
        height: [ '100%', 'auto' ],
        overflow: [ 'auto', 'initial' ],
      } }
      variant="modals.profitCheckAddressModal.modalContainer"
    >
      <Flex sx={ { textAlign: 'center' } }>
        <IconButton data-testid="close-button" onClick={ onClose } sx={ { cursor: 'pointer', ml: 'auto' } }>
          <X size={ 80 } />
        </IconButton>
      </Flex>

      <Form
        data-testid="profit-check-address-form"
        initialValues={ checkAddress }
        onSubmit={ data => onSubmit(data) }
        validationSchema={ validationSchema }
      >
        {showConfirmation ? (
          <ProfitCheckAddressConfirm
            address={ checkAddress }
            isExpired={ fundraiserHasExpired }
            isMissingProfitCheckAddress={ isMissingProfitCheckAddress }
            onEdit={ handleOnEdit }
          />
        ) : <ProfitCheckAddressForm />}
      </Form>

    </SliceModal>
  );
};

export default ProfitCheckAddressModal;
