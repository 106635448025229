import React, { useState, useEffect } from 'react';
import {
  Box, Button, Flex, Form, IconButton, Image, Spinner, Text,
} from '@lce/slice_v2';
import { Minus, Plus, X } from 'react-feather';
import { toast } from 'react-toastify';
import { useTranslation } from '@lce/i18n';
import * as Yup from 'yup';
import { useOktaAuth } from '@okta/okta-react';

import infoIcon from '../../../assets/icons/info-icon.svg';
import * as FundraiserDefaults from '../../../constants/FundraiserDefaults';

import { Seller, useUpdateSeller } from 'features/fundraiser';
import { VideoTipPopover } from 'ui/fundraiser';
import { GenericToast } from 'ui/common';
import { UpdateSellerRequest } from 'features/fundraiser/types/seller';
import { useUserInfo } from 'features/common/hooks';

export interface IEditProps {
  seller: Seller;
  onCancelEdit: () => void;
}

// eslint-disable-next-line max-lines-per-function
const Edit: React.FC<IEditProps> = ({ seller, onCancelEdit }) => {
  const [ t ] = useTranslation();
  const [ goal, setGoal ] = useState<number>(seller.Goal);
  const [ showVideoInfo, setShowVideoInfo ] = useState<boolean>(false);
  const { mutateAsync: updateSeller, isLoading } = useUpdateSeller(seller.FundraiserId, seller.CustomerId);

  const onSaveHandler = async(e) => {
    const data: UpdateSellerRequest = {
      Id: seller.Id,
      CustomerId: seller.CustomerId,
      Description: seller.Description,
      VideoSource: e.VideoSource,
      KitGoal: goal,
      FirstName: e.SellerFirstName,
      LastName: e.SellerLastName,
    };

    await updateSeller(data);
    toast(<GenericToast text="Seller profile for this fundraiser has been updated." />);
    onCancelEdit();
  };

  let validationSchema = Yup.object();

  validationSchema = validationSchema.shape({
    SellerFirstName: Yup.string()
      .matches(/^[-a-zA-Z0-9\s']+$/,
        'First Name field can accept ONLY letters, hyphens, and numbers and cannot be empty.')
      .required('First Name is required'),
    SellerLastName: Yup.string()
      .matches(/^[-a-zA-Z0-9\s']+$/,
        'Last Name field can accept ONLY letters, hyphens, and numbers and cannot be empty.')
      .required('Last Name is required'),
  });

  const { authState } = useOktaAuth();
  const userInfo = useUserInfo(authState.isAuthenticated);
  const [ canEditSellerInfo, setCanEditSellerInfo ] = useState<boolean>(true);

  useEffect(() => {
    if (userInfo && userInfo.data && userInfo.data.Roles) {
      const { Roles } = userInfo.data;
      if (Roles.includes(FundraiserDefaults.SellerRoleName) &&
          !Roles.includes(FundraiserDefaults.SuperAdminRoleName) &&
          !Roles.includes(FundraiserDefaults.ChairpersonRoleName) &&
          !Roles.includes(FundraiserDefaults.ISCRoleName) &&
          !Roles.includes(FundraiserDefaults.SalesAgentRoleName)) {
        setCanEditSellerInfo(false);
      }
    }
  }, [ userInfo ]);

  return (
    <>
      <Flex sx={ { justifyContent: 'space-between' } }>
        <Text
          sx={ {
            fontFamily: 'roboto',
            fontSize: '32px',
            fontWeight: 'bold',
          } }
        >
          Edit Profile
        </Text>

        <IconButton data-testid="close-button" onClick={ onCancelEdit } sx={ { cursor: 'pointer', p: 0 } }>
          <X size={ 30 } />
        </IconButton>
      </Flex>

      <Form
        initialValues={ {
          ...seller,
          SellerLastName: seller.LastName || seller.LastInitial,
          SellerFirstName: seller.FirstName,
          Goal: seller.Goal,
        } }
        onSubmit={ e => onSaveHandler(e) }
        summary={ false }
        validationSchema={ canEditSellerInfo ? validationSchema : null }
      >
        {canEditSellerInfo
          ? (
            <div>
              <Form.Field
                autoComplete="given-name"
                defaultValue={ null }
                id="SellerFirstName"
                label={ t('editSeller.FirstName') }
                name="SellerFirstName"
                placeholder={ t('First Name') }
              />

              <Form.Field
                autoComplete="family-name"
                defaultValue={ null }
                id="SellerLastName"
                label={ t('editSeller.LastName') }
                name="SellerLastName"
                placeholder={ t('Last Name') }
              />
            </div>
          )
          : null}

        <Flex sx={ { flexDirection: 'column', width: [ '100%', '33%' ] } }>
          <Flex
            sx={ {
              width: '100%',
              margin: '9px 0px',
              flexDirection: 'column',
            } }
          >
            <Form.Label htmlFor="Goal" sx={ { fontWeight: '600' } }>
              { t('editSeller.IncreaseKitGoal')}
            </Form.Label>
            <Form.Input.Text
              id="Goal"
              min={ seller.GoalMinimum }
              name="Goal"
              onChange={ e => setGoal(e.target.valueAsNumber) }
              type="number"
              value={ goal }
            />
          </Flex>

          <Flex sx={ { width: '100%' } }>
            <IconButton
              data-testid="minus-button"
              disabled={ goal <= seller.GoalMinimum }
              onClick={ () => setGoal(goal - 1) }
              sx={ {
                backgroundColor: goal <= seller.GoalMinimum ? 'primaryGrayLight' : 'primaryOrange',
                width: '100%',
                mr: '15px',
                flex: 1,
                cursor: goal <= seller.GoalMinimum ? 'not-allowed' : 'pointer',
                pointerEvents: goal <= seller.GoalMinimum ? 'none-allowed' : 'auto',
              } }
              type="button"
            >
              <Minus color="#fff" size={ 15 } />
            </IconButton>

            <IconButton
              data-testid="plus-button"
              onClick={ () => setGoal(goal + 1) }
              sx={ {
                backgroundColor: 'primaryOrange',
                width: '100%',
                flex: 1,
                cursor: 'pointer',
              } }
              type="button"
            >
              <Plus color="#fff" size={ 15 } />
            </IconButton>
          </Flex>
        </Flex>

        <Box sx={ { paddingBottom: '8px', position: 'relative' } }>
          <Form.Field
            defaultValue={ seller.VideoSource }
            id="VideoSource"
            label={ (
              <Flex sx={ { alignItems: 'center' } }>
                { t('editSeller.VideoSourceLink')}
                <Image
                  data-testid="video-info-icon"
                  onClick={ () => setShowVideoInfo(!showVideoInfo) }
                  src={ infoIcon }
                  sx={ {
                    cursor: 'pointer', ml: 2, height: '20px', width: '20px',
                  } }
                />
              </Flex>
            ) }
            name="VideoSource"
            placeholder={ t('editSeller.VideoInputPlaceholder') }
          />
          {showVideoInfo && <VideoTipPopover />}
        </Box>

        <Flex sx={ { flex: [ 1 ], justifyContent: 'flex-end', flexDirection: [ 'column', 'row' ] } }>
          <Button
            data-testid="cancel-button"
            onClick={ onCancelEdit }
            sx={ { mr: [ '0', '25px' ], mb: [ '10px', 0 ] } }
            type="button"
            variant="secondary"
          >
            {t('editSeller.Cancel')}
          </Button>

          <Button
            data-testid="save-button"
            disabled={ isLoading }
            type="submit"
            variant={ isLoading ? 'disabled' : 'primary' }
          >
            { isLoading
              ? <Spinner sx={ { height: '100%' } } variant="lce" />
              : t('editSeller.SaveEdits')}
          </Button>
        </Flex>
      </Form>
    </>
  );
};

export default Edit;
