/* eslint-disable max-lines-per-function */
import React, { useState } from 'react';
import {
  parse, startOfWeek, endOfWeek, addDays, format, isValid,
} from 'date-fns';
import { Form, Label } from '@lce/slice_v2';
import 'react-day-picker/lib/style.css';
import './WeekSelector.css';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { Modifiers } from 'react-day-picker';
import { useTranslation } from '@lce/i18n';

export interface IWeekSelectorProps {
  defaultDate?: string;
  validationError: boolean;
}

const getWeekDays = (weekStart) => {
  const days = [ weekStart ];
  for (let i = 1;i < 5;i += 1) {
    days.push(addDays(weekStart, i));
  }
  return days;
};

const getWeekRange = date => ({
  from: startOfWeek(date, { weekStartsOn: 1 }),
  to: endOfWeek(date, { weekStartsOn: 6 }),
});

const WeekSelector: React.FC<IWeekSelectorProps> = ({ defaultDate, validationError }) => {
  const [ t ] = useTranslation();
  const [ hoverRange, setHoverRange ] = useState<{ from: Date; to: Date } | undefined>(undefined);
  const [ selectedDays, setSelectedDays ] = useState<Date[]>([]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleDayChange = (date: Date, modifiers: any = {}) => {
    if (modifiers?.disabled) {
      return;
    }
    const selectedDays = getWeekDays(getWeekRange(date).from);
    setSelectedDays(selectedDays);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleDayEnter = (date: Date, modifiers: any = {}) => {
    if (modifiers?.disabled) {
      return;
    }
    setHoverRange(getWeekRange(date));
  };

  const handleDayLeave = () => {
    setHoverRange(undefined);
  };

  const daysAreSelected = selectedDays.length > 0;

  const modifiers: Partial<Modifiers> = {
    today: new Date(),
    highlighted: daysAreSelected ? { from: selectedDays[0], to: selectedDays[4] } : undefined,
    hoverRange,
    hoverRangeStart: hoverRange && hoverRange.from,
    hoverRangeEnd: hoverRange && hoverRange.to,
  };

  const parseDate = (str: string, format: string) => {
    const parsed = parse(str, format, new Date());
    return isValid(parsed) ? parsed : undefined;
  };

  const formatDate = (date: Date, dateFormat: string) => format(date, dateFormat);

  const disabledDays = [{ before: new Date() }, { daysOfWeek: [ 0, 6 ] }];
  const displayValue = selectedDays.length > 0
    ? `${ formatDate(selectedDays[0], 'MMMM do') } - ${ formatDate(selectedDays[4], 'MMMM do') }`
    : '';

  return (
    <div className="WeekSelector">
      <Label htmlFor="WeekSelectorRange">
        {t('fundraiser.labels.PreferredWeekOfDelivery')}
      </Label>
      <DayPickerInput
        component={ props => (
          <Form.Field
            { ...props }
            id="WeekSelectorRange"
            name="WeekSelectorRange"
            placeholder={ t('fundraiser.labels.WeekSelectorPlaceholder') }
            readOnly={ true }
            sx={ { mt: '0px', mb: 2 } }
            type="text"
            value={ displayValue }
            variant="forms.fundraiser.field"
          />
        ) }
        dayPickerProps={ {
          disabledDays,
          modifiers,
          selectedDays,
          showOutsideDays: true,
          showWeekNumbers: false,
          onDayClick: handleDayChange,
          onDayMouseEnter: handleDayEnter,
          onDayMouseLeave: handleDayLeave,
        } }
        format="yyyy-MM-dd"
        formatDate={ formatDate }
        parseDate={ parseDate }
      />
      <Form.Field
        id="preferredDeliveryWeek" label="" name="preferredDeliveryWeek" sx={ { display: 'none' } }
        type="hidden"
        value={ selectedDays[0] ? format(new Date(selectedDays[0]), 'yyyy-MM-dd') : defaultDate }
      />
      {validationError && (
        <Form.Error htmlFor="preferredDeliveryWeek">
          {t('fundraiser.validation.DeliveryWeekRequired')}
        </Form.Error>
      )}
    </div>
  );
};

export default WeekSelector;
