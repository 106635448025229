/* eslint-disable max-lines-per-function */
import React, { useEffect, useState } from 'react';
import { isNil as _isNil } from 'lodash';
import {
  Container,
} from '@lce/slice_v2';
import { useOktaAuth } from '@okta/okta-react';
import {
  Route, Switch, useHistory, useRouteMatch,
} from 'react-router-dom';

import { groups } from '../../features/fundraiser/utils/GroupsData';

import { MealDealSellerRoutes } from './MealDeal';

import { FundraiserCreate } from 'ui/fundraiser/FundraiserCreation/FundraiserCreate';
import { FundraiserOptionsBanner } from 'ui/startAFundraiser/FundraiserOptionsBanner';
import FundraiserLogin from 'ui/checkout/Login/FundraiserLogin';
import NewFundraiserFormConfirm from 'ui/fundraiser/NewFundraiserForm/NewFundraiserFormConfirm';
import { NewFundraiserForm } from 'ui/fundraiser';
import { MainLayout, LayoutWithoutFooter, NoLayout } from 'ui/common';
import { RouteWrapper } from 'ui/routing';
import { useLocalStorage, useQueryParam } from 'features/common';
import CaesarCash from 'assets/images/caesar-cash.svg';

const FundraiserCreation: React.FC = () => {
  const [ parentId, setParentId ] = useLocalStorage<string>('parentId');
  const isValidGroup = ({ id }) => id === parentId;
  const hasParentGroupId = groups.some(isValidGroup);

  if (!hasParentGroupId && !_isNil(parentId)) {
    setParentId(undefined);
  }

  return (
    <FundraiserCreate image={ CaesarCash } testId="digital-fundraiser-create">
      <NewFundraiserForm hasParentGroupId={ hasParentGroupId } />
    </FundraiserCreate>
  );
};

const StartAFundraiser: React.FC = () => {
  const { path } = useRouteMatch();
  const [ auth, setAuth ] = useState<boolean>(false);
  const { authState } = useOktaAuth();
  const history = useHistory();
  const [ localFranchiseeId, setLocalFranchiseeId ] = useLocalStorage<string>('franchiseeId');
  const [ currentFranId ] = useQueryParam('franchiseeId');

  useEffect(() => {
    if (authState && authState.isAuthenticated) {
      setAuth(true);
    }
    if (currentFranId) {
      setLocalFranchiseeId(currentFranId);
    }
    if (!currentFranId && !localFranchiseeId) {
      setLocalFranchiseeId(undefined);
    }
  }, [ authState, currentFranId, setLocalFranchiseeId, localFranchiseeId ]);

  return (
    <Container data-testid="start a fundraiser">
      <Switch>
        <RouteWrapper
          component={ FundraiserOptionsBanner }
          exact={ true }
          history={ history }
          layout={ NoLayout }
          path={ path }
          seo={ { pageTitle: 'Start a Fundraiser | Little Caesars Fundraising' } }
        />

        <RouteWrapper
          component={ auth ? FundraiserCreation : FundraiserLogin }
          exact={ true }
          history={ history }
          layout={ LayoutWithoutFooter }
          path={ `${ path }/create` }
          routeComponent={ Route }
          seo={ { pageTitle: 'Start a Fundraiser | Little Caesars Fundraising' } }
        />

        <RouteWrapper
          component={ NewFundraiserFormConfirm }
          exact={ true }
          history={ history }
          layout={ MainLayout }
          path={ `${ path }/confirm` }
          routeComponent={ Route }
          seo={ { pageTitle: 'Start a Fundraiser | Little Caesars Fundraising' } }
        />

        <RouteWrapper
          component={ MealDealSellerRoutes }
          history={ history }
          layout={ NoLayout }
          path={ `${ path }/meal-deal/` }
          routeComponent={ Route }
          seo={ { noTitle: true } }
        />
      </Switch>
    </Container>
  );
};

export default StartAFundraiser;
