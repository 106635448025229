import { useQuery } from 'react-query';

import { fetchSellerById } from '../api';
import { Seller } from '../types';

import * as QueryKeys from 'constants/QueryKeys';
import { ApiError } from 'features/common';

// eslint-disable-next-line max-len
const useFetchSeller = (fundraiserId: number, customerId: number) => useQuery<Seller, ApiError>(`${ QueryKeys.SellerDetailPrefix }${ fundraiserId }-${ customerId }`,
  () => fetchSellerById(fundraiserId, customerId),
  {
    retry: false,
    refetchOnWindowFocus: false,
    enabled: fundraiserId > 0 && customerId > 0,
  });

export { useFetchSeller };
