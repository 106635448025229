import { useQuery } from 'react-query';

import { Group } from '../types';
import { fetchGroup } from '../api';
import * as QueryKeys from '../../../constants/QueryKeys';

import { ApiError } from 'features/common';

export const useFetchGroup = (id: string) => useQuery<Group, ApiError>(`${ QueryKeys.Group }${ id }`,
  () => fetchGroup(id), { retry: false, refetchOnWindowFocus: false, enabled: parseInt(id) > 0 });
