/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { CancelToken, AxiosError } from 'axios';
import { toast } from 'react-toastify';

import { ApiError } from './features/common/types/response';
import { getStoredAuthToken } from './auth/authToken';

/**
 * Retrieve the Okta access token from local storage
 * We should _probably_ do this another way, but this works for now
 */
const getAuthToken = (existingAuthToken) => {
  // if not provided, grab it manually from local storage
  if (existingAuthToken) {
    return existingAuthToken;
  }

  const authStorage = getStoredAuthToken();
  if (!authStorage) {
    return undefined;
  }

  const data = JSON.parse(authStorage);
  return data.accessToken?.accessToken;
};

const defaults = {
  baseURL: process.env.REACT_APP_BASE_API_URL || 'https://localhost:44379',
  headers: authToken => ({
    'Content-Type': 'application/json',
    Authorization: getAuthToken(authToken) ? `Bearer ${ getAuthToken(authToken) }` : undefined,
  }),
};

function api<T = any>(method: any, path: string, params?: any, authToken?: string, cancelToken?: CancelToken):
Promise<T> {
  return new Promise((resolve, reject) => {
    axios({
      url: `${ defaults.baseURL }/${ path }`,
      method: method,
      headers: defaults.headers(authToken),
      params: method === 'get' || method === 'GET' ? params : undefined,
      data: method !== 'get' && method !== 'GET' ? params : undefined,
      cancelToken: cancelToken,
    }).then((response) => {
      resolve(response.data);
    }, (error: AxiosError) => {
      console.log(error);
      if (axios.isCancel(error)) {
        reject('CANCEL');
      } else if (error.message === 'Network Error') {
        reject(error);
        // toast.error('A Network Error has occurred. Please check your connection',
        //   { position: toast.POSITION.TOP_CENTER });
      } else if (error.response && error.response.status === 401) {
        // Unauthenticated
        reject(error.response.data as ApiError);
        toast.error('You must be authenticated');
      } else if (error.response && error.response.status === 403) {
        // Unauthorized
        const err: ApiError = {
          Status: error.response.status,
          Message: error.response.data.Message,
          ErrorCode: error.response.data.ErrorCode,
        };
        reject(err);
      } else if (error.response && error.response.status === 404) {
        const err: ApiError = {
          Status: error.response.status,
          Message: error.response.data.Message,
          ErrorCode: error.response.data.ErrorCode,
        };
        reject(err);
      } else if (error.response) {
        const err: ApiError = {
          Status: error.response.status,
          Message: error.response.data.Message,
          ErrorCode: error.response.data.ErrorCode,
        };

        reject(err);
        // toast.error(error.response.data.Message);
      }
    });
  });
}

function downloadApi<T = any>(method: any, path: string, params?: any, authToken?: string): Promise<T> {
  return new Promise((resolve) => {
    axios({
      url: `${ defaults.baseURL }/${ path }`,
      method: 'GET',
      headers: defaults.headers(authToken),
      params: params,
      responseType: 'blob',
    }).then((response) => {
      const fileName = response.request.getResponseHeader('x-suggested-filename');
      const url = window.URL.createObjectURL(new Blob([ response.data ]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName || 'report.xlsx');
      document.body.appendChild(link);
      link.click();
      resolve(response.data);
    });
  });
}

export default {
  get: <T = any>(path, params?, accessToken?, cancelToken?: CancelToken) => api<T>('get', path, params, accessToken, cancelToken),
  post: <T = any>(path, params?, accessToken?) => api<T>('post', path, params, accessToken),
  put: <T = any>(path, params?, accessToken?) => api<T>('put', path, params, accessToken),
  patch: <T = any>(path, params?, accessToken?) => api<T>('patch', path, params, accessToken),
  delete: <T = any>(path, params?, accessToken?) => api<T>('delete', path, params, accessToken),
  download: <T = any>(path, params?, accessToken?) => downloadApi<T>('get', path, params, accessToken),
};
