import * as React from 'react';
import {
  Modal, IModalProps, Box, Flex, IconButton,
} from '@lce/slice_v2';
import { X } from 'react-feather';

const CreateAccountModal: React.FC<IModalProps> = props => (
  <Modal
    { ...props }
    closeIcon={ false }
    lockBodyScroll={ true }
    sx={ {
      maxWidth: '100%',
      mt: [ '100px', '5%' ],
      mb: [ '100px', '5%' ],
      width: [ '90%', '75%' ],
    } }
  >
    <Flex
      sx={ {
        justifyContent: 'flex-end',
        paddingLeft: [ 0, '20px' ],
        paddingRight: [ 0, '20px' ],
        width: '100%',
      } }
    >
      <IconButton onClick={ props.onClose } sx={ { cursor: 'pointer' } }>
        <X size={ 80 } />
      </IconButton>
    </Flex>
    <Flex sx={ { alignItems: 'center', justifyContent: 'center' } }>
      <Box>
        {props.children}
      </Box>
    </Flex>
  </Modal>
);

export default CreateAccountModal;
