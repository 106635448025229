import { useQuery } from 'react-query';

import { fetchFundraiserSellerSummaryExcel } from '../api';

import * as QueryKeys from 'constants/QueryKeys';
import { ApiError } from 'features/common';

const useFundraiserSellerSummaryExcel =
  (id: number) => useQuery<Blob, ApiError>(`${ QueryKeys.SellerReportExcel }${ id.toString() }`,
    () => fetchFundraiserSellerSummaryExcel(id), {
      retry: false,
      refetchOnWindowFocus: false,
      cacheTime: 0,
      enabled: false,
    });

export { useFundraiserSellerSummaryExcel };
