import React from 'react';
import { Text } from '@lce/slice_v2';

const NotFound = () => (
  <div>
    <Text variant="text.header.title">404</Text>
    <Text>The page you are looking for could not be found</Text>
  </div>
);

export default NotFound;
