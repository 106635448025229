import { Box } from '@lce/slice_v2';
import React from 'react';

export type VideoPlayerProps = {
  src: string;
  title: string;
  fullSize?: boolean;
}

export const VideoPlayer: React.FC<VideoPlayerProps> = ({
  src,
  title,
  fullSize = false,
}) => (
  <Box
    data-testid="video-player"
    sx={ {
      position: 'relative',
      paddingTop: fullSize ? '56%' : [ '56%', '56%', '40%' ],
      height: 0,
      mx: 'auto',
      width: '100%',
    } }
  >
    <iframe
      allow="autoplay; fullscreen"
      allowFullScreen={ true }
      data-testid="video-iframe"
      height="100%"
      src={ src }
      style={ {
        position: 'absolute', top: 0, left: 0, border: 'none',
      } }
      title={ title }
      width="100%"
    />
  </Box>
);
