import { createContext, useContext } from 'react';

import { FundraiserType } from 'features/fundraiser/types';

type Action =
  { type: 'SET_FUNDRAISER_ID'; fundraiserId: number | undefined } |
  { type: 'UNSET_FUNDRAISER_ID'; fundraiserId: undefined } |
  { type: 'SET_FUNDRAISER_TYPE'; fundraiserType: FundraiserType | undefined} |
  { type: 'UNSET_FUNDRAISER_TYPE'; fundraiserType: undefined }

export type State = {
  fundraiserId: number | undefined;
  fundraiserType: FundraiserType;
  active: number;
};

type Dispatch = (action: Action) => void;

export const FundraiserStateContext = createContext<State | undefined>(undefined);
export const FundraiserDispatchContext = createContext<Dispatch | undefined>(undefined);

export const useFundraiserState = () => {
  const context = useContext(FundraiserStateContext);
  if (context === undefined) {
    throw new Error('useFundraiserState must be used within FundraiserStateContext');
  }
  return context;
};

export const useFundraiserDispatch = () => {
  const context = useContext(FundraiserDispatchContext);
  if (context === undefined) {
    throw new Error('useFundraiserDispatch must be used within FundraiserDispatchContext');
  }
  return context;
};
