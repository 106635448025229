import {
  Flex, Form, Text, Button, Box,
} from '@lce/slice_v2';
import { map as _map, forEach as _forEach } from 'lodash';
import { useFormContext } from 'react-hook-form';
import React, { useEffect, useState } from 'react';
import { useTranslation } from '@lce/i18n';

import { EmailNotifications } from 'features/fundraiser/types/emailNotifications';
import { ApiError } from 'features/common';

export interface IEmailNotificationForm {
  error: ApiError | null;
  onClose: () => void;
  emailNotifications?: EmailNotifications;
  emailEditMode?: boolean;
}

// eslint-disable-next-line max-lines-per-function
const EmailNotificationForm: React.FC<IEmailNotificationForm> = ({
  emailNotifications,
  error,
  onClose,
  emailEditMode,
}) => {
  const { formState, setValue, watch } = useFormContext();
  const [ toggleCollapse, setToggleCollapse ] = useState(false);
  const [ t ] = useTranslation();

  const Notifications = emailNotifications?.Notifications;

  const handleOnChange = (value) => {
    setToggleCollapse(!value);
    _map(Notifications, (notifications, index) => {
      const watchIsSubscribed = `Notifications.${ index }.IsSubscribed`;
      setValue(watchIsSubscribed, value);
    });
  };

  const setWatches = () => {
    _map(Notifications, (notification, index) => {
      watch(`Notifications.${ index }.IsSubscribed`);
    });
  };

  const findUncheckedInputs = () => {
    let checkedInputs = false;
    _map(Notifications, (notification, index) => {
      watch(`Notifications.${ index }.IsSubscribed`);
      if (watch(`Notifications.${ index }.IsSubscribed`)) {
        checkedInputs = true;
      }
    });
    return checkedInputs;
  };

  setWatches();

  const onFormClose = () => {
    onClose();
    _forEach(Notifications, ({ IsSubscribed }, index) => {
      const watchIsSubscribed = `Notifications.${ index }.IsSubscribed`;
      setValue(watchIsSubscribed, IsSubscribed);
    });
  };
  const foundInputsUnchecked = findUncheckedInputs();
  useEffect(() => {
    setValue('NotificationsDisabled', foundInputsUnchecked ? 'off' : 'on');
    setToggleCollapse(!foundInputsUnchecked);
  }, [ foundInputsUnchecked, setValue ]);
  return (
    <>
      {!error && (
        <Flex
          aria-labelledby="email notification options"
          data-testid="email-notification-form"
          sx={ { flexDirection: 'column', mb: '36px' } }
        >
          <Flex sx={ { flexDirection: 'column', mb: '12px' } }>
            <Form.Field
              aria-checked="true"
              component={ Form.Input.Radio }
              data-testid="notifications-radio-one"
              defaultChecked={ true }
              disabled={ !emailEditMode }
              id="notificationsRadioOne"
              inline={ true }
              label={ (
                <Text
                  sx={ {
                    fontSize: '14px', fontFamily: 'roboto', '&:hover': {
                      cursor: !emailEditMode && 'default',
                    },
                  } }
                >
                  {t('notificationMessages.email.yesEmailNotify')}
                </Text>
              ) }
              name="NotificationsDisabled"
              onChange={ () => {
                handleOnChange(true);
              } }
              role="radio"
              sx={ {
                alignItems: 'center', '& svg': {
                  fill: !emailEditMode && 'primaryGrayLight', // Change fill color of the SVG
                },
              } }
              value="off"
            />
            <Box
              ml="28px"
              sx={ {
                opacity: toggleCollapse ? 0 : 1,
                height: toggleCollapse ? 0 : 'auto',
              } }
            >
              {_map(Notifications && Notifications, (notification, index) => (
                <Form.Field
                  aria-checked="true"
                  component={ Form.Input.Checkbox }
                  data-testid={ `notifications-checkbox-${ index }` }
                  disabled={ !emailEditMode }
                  id={ notification.Name }
                  inline={ true }
                  key={ notification.Name }
                  label={ (
                    <Text
                      sx={ {
                        '&:hover': {
                          cursor: !emailEditMode && 'default',
                        },
                      } } variant="emailNotificationForm.formLabel"
                    >
                      {t(`notificationMessages.email.${ notification.Name }`)}
                    </Text>
                  ) }
                  name={ `Notifications.${ index }.IsSubscribed` }
                  onChange={ () => {
                    findUncheckedInputs();
                  } }
                  role="checkbox"
                  sx={ {
                    '& svg': {
                      fill: !emailEditMode && 'primaryGrayLight',
                    },
                  } }
                />
              ))}
            </Box>
          </Flex>
          <Flex>
            <Form.Field
              aria-checked="false"
              component={ Form.Input.Radio }
              data-testid="notifications-radio-two"
              defaultChecked={ true }
              disabled={ !emailEditMode }
              id="notificationsRadioTwo"
              inline={ true }
              label={ (
                <Text
                  sx={ {
                    '&:hover': {
                      cursor: !emailEditMode && 'default',
                    },
                  } } variant="emailNotificationForm.formLabel"
                >
                  {t('notificationMessages.email.noEmailNotify')}
                </Text>
              ) }
              name="NotificationsDisabled"
              onChange={ () => {
                handleOnChange(false);
              } }
              role="radio"
              sx={ {
                alignItems: 'center', '& svg': {
                  fill: !emailEditMode && 'primaryGrayLight', // Change fill color of the SVG
                },
              } }
              value="on"
            />
          </Flex>
        </Flex>
      )}
      {emailEditMode && (
        <Flex
          sx={ { flexDirection: [ 'column-reverse', 'row' ] } }
          variant="modals.emailNoticationModal.buttonContainer"
        >
          <Flex>
            <Button
              data-testid="emailModal_close"
              onClick={ onFormClose }
              sx={ {
                width: [ '100%', '256px', '296px' ],
              } }
              type="button"
              variant="secondary"
            >
              {t('notificationMessages.email.cancel')}
            </Button>
          </Flex>
          <Flex
            sx={ {
              display: 'flex',
              mb: [ 2, 0 ],
            } }
          >
            <Button
              data-testid="email-submit-button"
              disabled={ !formState.isDirty }
              sx={ {
                width: [ '100%', '256px', '296px' ],
                border: [ 2, 0 ],
              } }
              type="submit"
              variant={ formState.isDirty ? 'primary' : 'disabled.medium' }
            >

              {t('notificationMessages.email.update')}
            </Button>
          </Flex>
        </Flex>
      )}
    </>
  );
};
export default EmailNotificationForm;
