/* eslint-disable max-len */
import React from 'react';
import { Link, Text } from '@lce/slice_v2';
import { useTranslation } from '@lce/i18n';

import { PopupBorder } from './PopupBorder';

export const ManageSellerPopup: React.FC = () => {
  const [ t ] = useTranslation();

  return (
    <PopupBorder>
      <Text
        sx={ {
          fontSize: '13px', fontWeight: 'normal', lineHeight: 1.2, mb: 3,
        } }
      >
        {t('dashboard.steps.popoverContent.manageSeller.part1')}
      </Text>
      <Text
        sx={ {
          fontSize: '13px', fontWeight: 'normal', lineHeight: 1.2, mb: 3,
        } }
      >
        {t('dashboard.steps.popoverContent.manageSeller.part2')}
      </Text>
      <Text sx={ { fontSize: '13px', fontWeight: 'normal', lineHeight: 1.2 } }>
        {t('dashboard.steps.popoverContent.manageSeller.call')}
        {' '}
        <Link href={ `tel:${ t('dashboard.steps.popoverContent.manageSeller.phone') }` } sx={ { color: 'primaryOrange' } }>
          {t('dashboard.steps.popoverContent.manageSeller.phone')}
        </Link>
        {' '}
        {t('dashboard.steps.popoverContent.manageSeller.or')}
        {' '}
        <Link href="mailto:fundraising@littlecaesars.com" sx={ { color: 'primaryOrange' } }>
          {t('dashboard.steps.popoverContent.manageSeller.email')}
        </Link>
        {' '}
        {t('dashboard.steps.popoverContent.manageSeller.help')}
      </Text>
    </PopupBorder>
  );
};

