import * as React from 'react';
import {
  Route, RouteProps, Switch, useRouteMatch,
} from 'react-router-dom';

import { CreateAccountForm } from 'ui/account/CreateAccountForm';

type CreateAccountPageProps = RouteProps

const CreateAccount: React.FC<CreateAccountPageProps> = () => {
  const { path } = useRouteMatch();

  return (
    <div>
      <Switch>
        <Route exact={ true } path={ path }>
          <CreateAccountForm isConfirm={ false } />
        </Route>
        <Route path={ `${ path }/confirm` }>
          <CreateAccountForm isConfirm={ true } />
        </Route>
      </Switch>
    </div>
  );
};

export default CreateAccount;
