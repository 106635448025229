import React from 'react';
import {
  Box, Button, Flex, Form,
} from '@lce/slice_v2';
import { useTranslation } from '@lce/i18n';

import { FirstNameInput, PhoneInput } from 'ui/components/forms';
import { useQueryParam } from 'features/common';

export type LandingFormProps = {
  translationPath: string;
  testId?: string;
}

export const dataTestIdLandingForm = 'landing-form-component';

// eslint-disable-next-line max-lines-per-function
export const LandingForm: React.FC<LandingFormProps> = ({ translationPath, testId = dataTestIdLandingForm }) => {
  const { origin, pathname } = window.location;
  const redirectUrl = `${ origin }${ pathname }/confirmation`;

  const SALESFORCE_URL = process.env.REACT_APP_SALESFORCE_WEB_TO_LEAD_URL;
  const SALESFORCE_OID = process.env.REACT_APP_SALESFORCE_WEB_TO_LEAD_OID;
  const [ t ] = useTranslation();
  const [ referralSource ] = useQueryParam('referralsource');
  const lead = referralSource ?? t(`${ translationPath }.leadSource`);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const validateZip = (event: any) => {
    const usZipRegEx = (/(^\d{5}$)/);
    const isUSZip = usZipRegEx.test(event.target.value);
    if (isUSZip) {
      event.target.setCustomValidity('');
    } else {
      event.target.setCustomValidity('Zip code must be 5 digits.');
    }
  };

  return (
    <Box
      data-testid={ testId }
      sx={ {
        m: 'auto', maxWidth: '994px', fontSize: '20px', itemAlign: 'center', width: '100%',
      } }
    >
      <form action={ SALESFORCE_URL } method="POST">
        <Flex>
          <Box
            sx={ {
              mr: [ 0, 8 ],
              width: '100%',
            } }
          >
            <input
              name="oid"
              type="hidden"
              value={ SALESFORCE_OID }
            />
          </Box>
          <Box
            sx={ {
              mr: [ 0, 8 ],
              width: '100%',
            } }
          >
            <input
              name="retURL"
              type="hidden"
              value={ redirectUrl }
            />
          </Box>
          <Box sx={ { mr: [ 0, 8 ], width: '100%' } }>
            <input
              data-testid="lead_source" id="lead_source" name="lead_source" type="hidden"
              value={ lead }
            />
          </Box>
        </Flex>
        <Flex sx={ { flexDirection: [ 'column', 'row' ], justifyContent: 'center' } }>
          <FirstNameInput
            keysToOmit={ [ ] }
            label="First Name*"
            name="first_name"
            required={ true }
            sx={ {
              flex: '1', mr: [ '0px', '16px' ], my: [ '13px', '20px' ], maxWidth: '371px',
              '& input': {
                height: '44px',
              },
            } }
          />
          <Form.Field
            autoComplete="family-name"
            data-testid="last-name"
            id="last_name"
            label="Last Name*"
            name="last_name"
            required={ true }
            sx={ {
              flex: '1', ml: [ '0px', '16px' ], my: [ '13px', '20px' ], maxWidth: '371px',
              '& input': {
                height: '44px',
              },
            } }
            type="text"
          />
        </Flex>
        <Flex sx={ { flexDirection: [ 'column', 'row' ], justifyContent: 'center' } }>
          <Form.Field
            autoComplete="organization"
            data-testid="company"
            id="company"
            label="Organization*"
            name="company"
            required={ true }
            sx={ {
              flex: '1', mr: [ '0px', '16px' ], my: [ '13px', '20px' ], maxWidth: '371px',
              '& input': {
                height: '44px',
              },
            } }
            type="text"
          />
          <Form.Field
            autoComplete="postal-code"
            data-testid="zip-code"
            id="zipcode"
            label="Zip Code*"
            name="zip"
            onChange={ e => validateZip(e) }
            required={ true }
            sx={ {
              flex: '1', ml: [ '0px', '16px' ], my: [ '13px', '20px' ], maxWidth: '371px',
              '& input': {
                height: '44px',
              },
            } }
            type="text"
          />
        </Flex>
        <Flex sx={ { flexDirection: [ 'column', 'row' ], justifyContent: 'center' } }>
          <PhoneInput
            label="Phone Number*"
            name="phone" required={ true } sx={ {
              flex: '1', mr: [ '0px', '16px' ], my: [ '13px', '20px' ], maxWidth: '371px',
              '& input': {
                height: '44px',
              },
            } }
            testId="phone-number"
          />
          <Form.Field
            autoComplete="email"
            data-testid="email"
            id="email"
            label="Email Address*"
            name="email"
            required={ true }
            sx={ {
              flex: '1', ml: [ '0px', '16px' ],
              my: [ '13px', '20px' ], width: '100%', maxWidth: '371px',
              '& input': {
                height: '44px',
              },
            } }
            type="email"
          />
        </Flex>
        <Button
          data-testid="submit"
          sx={ {
            width: '100%', maxWidth: '226px', minHeight: '40px',
            display: 'flex', m: ' 16px auto', alignItems: 'center', justifyContent: 'center',
          } }
          type="Submit"
          variant="buttons.primary"
        >
          {t('landing.shared.submitButton')}
        </Button>
      </form>
    </Box>
  );
};
