export function parseComponentDate(str) {
  // yyyy/mm/dd
  const ymd = str.split('-');
  return new Date(ymd[0], ymd[1] - 1, ymd[2]);
}
export const getDateString = (date: Date): string => {
  const year = date.getFullYear();

  let month = (1 + date.getMonth()).toString();
  month = month.length > 1 ? month : '0' + month;

  let day = date.getDate().toString();
  day = day.length > 1 ? day : '0' + day;

  return `${ year }-${ month }-${ day }`; // format for dates
};

export const offsetDateDays = (date: Date, offsetDays: number) => {
  date.setDate(date.getDate() + offsetDays);
  return date;
};
