import * as React from 'react';
import {
  Box, Flex, Container, Image, Text,
} from '@lce/slice_v2';
import { useTranslation } from '@lce/i18n';

import { LoginForm } from 'ui/account';
import CaesarPoint from 'assets/images/caesar-point.svg';

const Login: React.FC = () => {
  const [ t ] = useTranslation();
  return (
    <Container sx={ { maxWidth: '800px' } }>
      <Text
        as="h1"
        sx={ {
          fontSize: [ '24px', '24px', '34px' ],
          color: 'primaryOrange',
          mt: '16px',
          mb: '16px',
          textAlign: 'center',
        } }
        variant="text.header.subUpper"
      >
        {t('login.Title')}
      </Text>
      <Text
        sx={ {
          textAlign: 'center', maxWidth: '650px', ml: 'auto', mr: 'auto', color: 'primaryGrayDark',
        } } variant="text.h5"
      >
        {t('login.SubTitle')}
      </Text>
      <Flex sx={ { mt: '30px', justifyContent: 'center', alignItems: 'center' } }>
        <Image
          alt="Little Caesar Pointing"
          src={ CaesarPoint }
          sx={ { flexBasis: '50%', display: [ 'none', 'none', 'initial' ], maxHeight: '300px' } }
          title="Little Caesar Point"
        />
        <Box sx={ { flexBasis: [ '100%', '100%', '50%' ] } }>
          <LoginForm redirect={ true } />
        </Box>
      </Flex>
    </Container>
  );
};

export default Login;
