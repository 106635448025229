import React from 'react';
import { Box } from '@lce/slice_v2';

import { LandingDisclaimer, LandingText } from '../../components';
import { LandingForm } from '../../landing-form';

import { FundraiserType } from 'features/fundraiser';
import { useStoreQueryParamToLocalStorage } from 'ui/hooks';

export type LeadCaptureProps = {
  fundraiserType: FundraiserType;
  page: string;
  testId?: string;
}

export const dataTestIdLeadCapture = 'lead-capture-component';

export const LeadCapture: React.FC<LeadCaptureProps> = ({
  fundraiserType,
  page,
  testId = dataTestIdLeadCapture,
}) => {
  useStoreQueryParamToLocalStorage('referralsource', 'referralSource');
  const translationPath = `landing.${ fundraiserType }.${ page }`;

  return (
    <Box data-testid={ testId } sx={ { textAlign: 'center' } }>
      <LandingText translationPath={ translationPath } />
      <LandingForm translationPath={ translationPath } />
      <LandingDisclaimer translationPath={ translationPath } />
    </Box>
  );
};
