export default {
  primary: {
    select: {
      padding: '6px 8px',
    },
    textarea: {
      height: 'auto',
    },
  },
  input: {
    fontFamily: 'Roboto',
    backgroundColor: 'white',
    borderColor: 'primaryGrayLight',
    borderRadius: '4px',
    height: '36px',
    ':focus': {
      borderColor: 'black',
      outline: 'none',
    },
    '&::placeholder': {
      fontSize: '14px',
    },
    '&::value': {
      fontSize: '22px',
    },
    categorySelector: {
      width: [ '100%', '427px' ],
      border: '1px solid',
      float: 'right',
      borderColor: 'primaryGrayLight',
      color: 'black',
      bg: 'white',
      height: '46px',
      borderRadius: '4px',
      padding: '15px 24px',
      fontWeight: 600,
    },
  },
  label: {
    fontFamily: 'Lato',
  },
  select: {
    height: '46px',
    borderRadius: '4px',
    borderColor: 'lightGrey',
    padding: '0px 16px',
    quantity: {
      padding: '0px 16px',
      height: '36px',
      borderRadius: '4px',
      width: '88px',
      borderColor: 'lightGrey',
      ':focus': {
        borderRadius: 0,
      },
    },
  },
  checkout: {
    field: {
      flex: 1,
    },
  },
  search: {
    input: {
      backgroundColor: 'primaryGrayMid',
      color: 'primaryGrayDark',
      paddingLeft: '46px',
      fontSize: '14px',
      ':focus': {
        backgroundColor: 'white',
        borderColor: '#FF671B',
        color: 'black',
      },
    },
  },
  fundraiser: {
    field: {
      flex: 1,
      disabled: {
        flex: 1,
        color: 'primaryGrayDark',
      },
    },
  },
};
