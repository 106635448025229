/* eslint-disable max-len */
/* eslint-disable complexity */
/* eslint-disable max-lines-per-function */
import React from 'react';
import {
  Box, Flex, Spinner,
} from '@lce/slice_v2';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ChevronLeft } from 'react-feather';

import {
  FundraiserRelationship, useFetchFundraiserById, useFetchSeller, useFetchSellerDashboardStatistics,
} from 'features/fundraiser';
import { useMediaQuery } from 'features/common';
import { Graphs } from 'ui/Graphs';
import { DashboardHeader } from 'ui/fundraiser/DashboardHeader';
import { FundraiserNavigation } from 'ui/fundraiser/FundraiserNavigation';
import { SellerSteps } from 'ui/fundraiser/SellerSteps';
import { RedirectAwareLink } from 'ui/common/RedirectAwareLink';
import { useIsUpcomingFundraiser } from 'features/fundraiser/hooks/useIsUpcomingFundraiser';

export interface MatchParams {
  fundraiserId: string;
  customerId: string;
}

export type SellerDetailMode = 'preview' | 'edit';

export type ISellerDetailProps = RouteComponentProps<MatchParams>;

const SellerDetail: React.FC<ISellerDetailProps> = ({ match }) => {
  const [ t ] = useTranslation();
  const fundraiserId = parseInt(match.params.fundraiserId);
  const customerId = parseInt(match.params.customerId);
  const { data: seller, isError: isSellerError, error: sellerError } = useFetchSeller(fundraiserId, customerId);
  const { data: fundraiser } = useFetchFundraiserById(fundraiserId);
  const { data: sellerStats } = useFetchSellerDashboardStatistics(seller?.Id || 0);
  const isLarge = useMediaQuery('(min-width: 1160px)');
  const isFundraiserUpcoming = useIsUpcomingFundraiser(fundraiser);
  const isFundraiserCancelled = !!fundraiser?.CancellationDate;
  const showChart = !(isFundraiserCancelled || isFundraiserUpcoming);
  const backToSellers = fundraiser && (
    fundraiser.Relationships.includes(FundraiserRelationship.CHAIRPERSON) ||
    fundraiser.Relationships.includes(FundraiserRelationship.SUPERADMIN) ||
    fundraiser.Relationships.includes(FundraiserRelationship.SALESAGENT) ||
    fundraiser.Relationships.includes(FundraiserRelationship.ISC)
  );

  if (!seller) {
    return <Spinner variant="lce" />;
  }
  if (isSellerError && sellerError && sellerError.Status === 404) {
    return <Redirect to="/404" />;
  }
  if (isSellerError && sellerError && sellerError.Status === 403) {
    return <Redirect to="/403" />;
  }

  return (
    <Box data-testid="seller-detail-page">

      <FundraiserNavigation customerId={ `${ customerId }` } fundId={ `${ fundraiserId }` } url={ match.url } />

      <RedirectAwareLink
        pathname={ backToSellers
          ? `/dashboard/fundraisers/manage-sellers/${ fundraiserId }`
          : '/dashboard/fundraisers' }
      >
        <Flex
          sx={ {
            alignItems: 'center', color: 'primaryOrange', fontWeight: 'bold', ml: [ 0, 0, '-30px' ], mb: '12px',
          } }
        >
          <ChevronLeft color="#ff671b" height="32px" width="32px" />
          { backToSellers ? t('dashboard.BackToAllSellers') : t('dashboard.BackToFundraisers')}
        </Flex>
      </RedirectAwareLink>

      { fundraiser && seller && <DashboardHeader description={ seller.Description } fundraiser={ fundraiser } seller={ seller } />}

      {showChart && sellerStats && (
        <Flex
          data-testid="seller-stats"
          sx={ {
            mx: 'auto',
            mb: '48px',
            justifyContent: 'center',
            minWidth: isLarge ? '' : '155px',
            maxWidth: isLarge ? '980px' : '500px',
            flexWrap: isLarge ? 'nowrap' : 'wrap',
            gap: isLarge ? '45px' : '22px',
          } }
        >
          <Graphs graphStats={ sellerStats } isDashboard={ true } />
        </Flex>
      )}

      { fundraiser && !fundraiser.IsExpired && !isFundraiserCancelled &&
        <SellerSteps customerId={ customerId } fundraiser={ fundraiser } sellerId={ seller.Id } supportGuid={ seller.SupportGuid } />}
    </Box>
  );
};

export default SellerDetail;
