import React, { useMemo } from 'react';
import { ThemeUIStyleObject } from 'theme-ui';
import {
  Box, Form, Text,
} from '@lce/slice_v2';
import * as Yup from 'yup';

interface IMoneyInput {
  boxSx?: ThemeUIStyleObject;
  id?: string;
  label?: string;
  fieldSx?: ThemeUIStyleObject;
  name: string;
  testId?: string;
  textSx?: ThemeUIStyleObject;
  variant?: string;
  defaultValue?: string;
}

export const defaults = {
  testId: 'money-input',
};

export const moneyInputValidation = t => ({
  profitGoal: Yup
    .number()
    .required()
    .positive()
    .integer(t('mealDeal.fundraiserCreationPage.form.profitGoal.validation.integer'))
    .typeError(t('mealDeal.fundraiserCreationPage.form.profitGoal.validation.typeError')),
});

export const MoneyInput: React.FC<IMoneyInput> = ({
  boxSx = {},
  id,
  label = '',
  fieldSx = {},
  name,
  testId = defaults.testId,
  textSx = {},
  variant = 'forms.fundraiser.field',
  defaultValue,
}) => {
  const effectiveId = useMemo(() => id || name, [ id, name ]);

  return (
    <div data-testid={ testId }>
      <Box data-testid={ `${ testId }-box` } sx={ { position: 'relative', ...boxSx } }>
        <Text
          sx={ {
            position: 'absolute', left: 2, bottom: '6px', ...textSx,
          } }
        >
          $
        </Text>
        <Form.Field
          data-testid={ `${ testId }-field` }
          defaultValue={ defaultValue }
          id={ effectiveId }
          label={ label }
          name={ name }
          sx={ { '& input': { paddingLeft: '20px', ...fieldSx } } }
          type="number"
          variant={ variant }
        />
      </Box>
    </div>
  );
};
