/* eslint-disable max-len */
/* eslint-disable max-lines-per-function */
import {
  Box, Button, Flex, Headline, Text, Image,
} from '@lce/slice_v2';
import { useHistory } from 'react-router-dom';
import * as React from 'react';
import { useState } from 'react';
import { useTranslation } from '@lce/i18n';
import { useOktaAuth } from '@okta/okta-react';
import { useFeature } from '@growthbook/growthbook-react';

import { FundraiserOptionIcon } from './FundraiserOptionIcon';
import CSpear from './assets/lcespear.svg';
import CaesarManChecklist from './assets/Traditional-Caesar.svg';
import LaptopCaesarMan from './assets/Digital-Caesar.svg';
import { FundraiserComparison } from './FundraiserComparison';

const FundraiserOptionsBanner: React.FC = () => {
  const [ fundraiserComparision, setFundraiserComparision ] = useState<boolean>(false);
  const [ t ] = useTranslation();
  const history = useHistory();
  const { authState } = useOktaAuth();

  const showBrochureLink = useFeature('brochureProgramEnabled').on;

  const brochureUrl = authState.isAuthenticated ? '/brochure/start-a-fundraiser/create' : '/login';

  return (
    <Box as="section">
      <Flex
        data-testid="fundraiser options"
        sx={ {
          alignItems: 'center', justifyContent: 'center',
          backgroundColor: '#f7f2ec',
          paddingTop: [ '196px', '196px', '100px' ],
        } }
      >
        <Flex
          sx={ {
            flexDirection: 'column',
            margin: [ 0, 0, '48px auto' ],
            maxWidth: [ '100%', '75%', '524px' ],
            width: '100%',
            justifyContent: [ 'auto', 'auto', 'center' ],
          } }
        >
          <Headline
            as="h1"
            sx={ {
              fontSize: [ '26px', '26px', '40px' ],
              textAlign: 'center',
              fontFamily: 'mrEaves',
              whiteSpace: 'noWrap',
              mb: [ '32px', '32px', '64px' ],
              textTransform: 'uppercase',
            } }
          >
            {t('startAFundraiser.fundraiserOptionsBanner.Title')}
          </Headline>
          <Box
            sx={ {
              display: 'grid',
              gridTemplateColumns: '1fr auto 1fr',
              maxWidth: [ '500px', '500px', 'initial' ],
              mx: 'auto',
              px: '12px',
            } }
          >
            <FundraiserOptionIcon
              image={ LaptopCaesarMan }
              onClick={ () => history.push('/start-a-fundraiser/create') }
              subtitle={ t('startAFundraiser.fundraiserOptionsBanner.FundraiserType1.SubTitle') }
              title={ t('startAFundraiser.fundraiserOptionsBanner.FundraiserType1.Title') }
            />
            <Flex
              sx={ {
                flexDirection: 'column',
                justifyContent: 'space-evenly',
                mx: '12px',
                alignItems: 'center',
                transform: 'translateY(-20px)',
              } }
            >
              <Box>
                <Image src={ CSpear } />
              </Box>

              <Text sx={ { fontFamily: 'lcScript', fontSize: [ '24px' ], textTransform: 'uppercase' } }>
                {t('startAFundraiser.fundraiserOptionsBanner.spearDividerText')}
              </Text>

              <Box sx={ { transform: 'rotate(180deg)' } }>
                <Image src={ CSpear } />
              </Box>
            </Flex>
            {showBrochureLink ? (
              <FundraiserOptionIcon
                image={ CaesarManChecklist }
                link={ brochureUrl }
                redirectUri="/brochure/start-a-fundraiser/create"
                subtitle={ t('startAFundraiser.fundraiserOptionsBanner.FundraiserType2.SubTitle') }
                title={ t('startAFundraiser.fundraiserOptionsBanner.FundraiserType2.Title') }
              />
            ) : (
              <FundraiserOptionIcon
                image={ CaesarManChecklist }
                onClick={ () => window.open('https://www.pizzakit.com/start-a-fundraiser-signup') }
                subtitle={ t('startAFundraiser.fundraiserOptionsBanner.FundraiserType3.SubTitle') }
                title={ t('startAFundraiser.fundraiserOptionsBanner.FundraiserType3.Title') }
              />
            )}
          </Box>

          <Box sx={ { m: [ '24px 0 12px', 'initial 0 12px', '60px 0 0' ] } }>
            <Text
              sx={ {
                fontWeight: 'bold',
                fontFamily: 'lato',
                fontSize: [ '16px', '18px' ],
                textAlign: 'center',
              } }
            >

              {t('startAFundraiser.fundraiserOptionsBanner.needHelpText')}
              <Button
                aria-label="review-the-differences"
                as="button" data-testid="review-difference-link"
                href="#"
                onClick={ () => setFundraiserComparision(!fundraiserComparision) }
                sx={ {
                  fontWeight: 'bold',
                  fontFamily: 'lato',
                  fontSize: [ '16px', '18px' ],
                  color: 'primaryOrange', ml: [ 2 ],
                  background: '#f7f2ec',
                  border: 'none',
                  padding: 0,
                  '&: hover': {
                    cursor: 'pointer',
                    textDecoration: 'underline',
                  },
                } }
                variant="marketingLabel"
              >
                {t('startAFundraiser.fundraiserOptionsBanner.fundraiserDifferencesLink')}
              </Button>
            </Text>
          </Box>
        </Flex>
      </Flex>

      {fundraiserComparision && (
        <Box data-testid="fundraiser-comparision" sx={ { px: [ '12px', '12px', '12px', 0 ], background: '#f7f2ec' } }>
          <FundraiserComparison sx={ { pt: '48px', transition: 'all 500ms ease-in-out' } } />
          <Flex
            sx={ {
              alignItems: 'center', maxWidth: [ '390px', '680px', '1092px' ], m: '0 auto',
              mb: [ '40px', 0 ], pb: '110px',
            } }
          >
            <Text
              sx={ {
                alignSelf: 'flex-start', fontSize: '14px', fontFamily: 'roboto', ml: '5px',
              } }
            >
              {t('common.BundleDisclaimer')}
            </Text>
          </Flex>
        </Box>
      )}
    </Box>
  );
};
export default FundraiserOptionsBanner;
