/* eslint-disable max-len */
import * as React from 'react';
import {
  Box, Button, Flex, Link, Text,
} from '@lce/slice_v2';
import { useTranslation } from '@lce/i18n';

import { Step, Mode } from 'ui/common';
import { useCheckoutState } from 'features/checkout';
import { CartListItem } from 'ui/cart';

export interface IOrderReviewProps {
  mode: Mode;
  onEdit: () => void;
  onPlaceOrder: () => void;
}

// eslint-disable-next-line max-lines-per-function
export const OrderReviewMD: React.FC<IOrderReviewProps> = ({
  mode, onEdit, onPlaceOrder,
}) => {
  const [ t ] = useTranslation();

  const checkout = useCheckoutState();

  return (
    <Step
      editText={ t('checkout.EditCart') }
      onEdit={ onEdit }
      showEdit={ mode === 'active' }
      title={ t('checkout.OrderReview.Label') }
    >
      <Box data-testid="order-review-inner" sx={ { display: mode === 'active' ? 'block' : 'none' } } variant="cards.step.body">
        <Flex sx={ { flexDirection: 'column' } }>
          { checkout && checkout.checkout &&
            checkout.checkout.Cart.map(item => (
              <CartListItem
                cartItem={ item }
                disableChanges={ true }
                key={ item.ProductId }
                variant="cartListItem.checkout"
              />
            ))}
          <Button
            data-testid="place-order-button"
            disabled={ checkout.loading }
            onClick={ onPlaceOrder }
            variant={ checkout.loading ? 'disabled' : 'primary' }
          >
            { t('checkout.PlaceOrder') }
          </Button>
          <Text
            sx={ {
              fontSize: '12px', color: 'primaryGrayDark', lineHeight: 1, mt: '10px',
            } }
          >
            { t('checkout.OrderReview.Legal.Line1') }
            {' '}
            <Link href="/legal/terms-of-service" sx={ { color: 'primaryOrange' } } target="_blank">
              { t('checkout.OrderReview.Legal.TermsOfService') }
            </Link>
            ,
            {' '}
            <Link href="/legal/terms-of-sale" sx={ { color: 'primaryOrange' } } target="_blank">
              { t('checkout.OrderReview.Legal.OnlineTermsOfSale') }
            </Link>
            {' '}
            { t('checkout.OrderReview.Legal.Line2') }
            {' '}
            <Link href="https://littlecaesars.com/en-us/legal/privacy-policy" rel="noopener noreferrer" sx={ { color: 'primaryOrange' } } target="_blank">
              { t('checkout.OrderReview.Legal.PrivacyPolicy') }
            </Link>
            {' '}
            .
          </Text>
        </Flex>
      </Box>
    </Step>
  );
};
