import React from 'react';
import { Box, Image } from '@lce/slice_v2';
import DOMPurify from 'dompurify';

import { Product } from 'features/products';
import { TabbedCard, TabbedCardConfig } from 'ui/common';

export interface IProductDetailInfoProps {
  product: Product;
}
interface ProductDetailImage {
  src: string;
  alt: string;
  title: string;
}

// eslint-disable-next-line max-lines-per-function
export const ProductDetailInfo: React.FC<IProductDetailInfoProps> = ({ product }) => {
  const renderRte = (text: string) => (
    // eslint-disable-next-line react/no-danger
    <Box dangerouslySetInnerHTML={ { __html: DOMPurify.sanitize(text) } } sx={ { maxWidth: '840px' } } />
  );

  const renderImage = (image: ProductDetailImage) => (
    <Box sx={ { textAlign: 'center' } }>
      <Image
        alt={ image.alt }
        src={ image.src }
        sx={ { height: '400px' } }
        title={ image.title }
      />
    </Box>
  );

  const nutritionalInfoImage: ProductDetailImage = {
    src: product.NutritionalImage ? product.NutritionalImage.VirtualPath : '',
    alt: product.NutritionalImage ? product.NutritionalImage.AltAttribute : '',
    title: product.NutritionalImage ? product.NutritionalImage.TitleAttribute : '',
  };

  const config: TabbedCardConfig[] = [
    {
      tab: {
        id: 'baking-instructions',
        label: 'Baking Instructions',
        value: 'Baking Instructions',
      },
      sections: [
        {
          label: 'Details',
          content: renderRte(product.BakingInstructions ? product.BakingInstructions : ''),
        },
      ],
    },
    {
      tab: {
        id: 'handling-insructions',
        label: 'Handling Instructions',
        value: 'Handling Instructions',
      },
      sections: [
        {
          label: 'Details',
          content: renderRte(product.HandlingInstructions ? product.HandlingInstructions : ''),
        },
      ],
    },
    {
      tab: {
        id: 'nutritional-information',
        label: 'Nutritional Information',
        value: 'Nutritional Information',
      },
      sections: [
        {
          label: 'Nutritional Information',
          content: renderImage(nutritionalInfoImage),
        },
      ],
    },
    {
      tab: {
        id: 'ingredients',
        label: 'Ingredients',
        value: 'Ingredients',
      },
      sections: [
        {
          label: 'Details',
          content: renderRte(product.Ingredients ? product.Ingredients : ''),

        },
      ],
    },
  ];

  return (
    <TabbedCard config={ config } />
  );
};
