import * as React from 'react';
import { Helmet } from 'react-helmet';

export type Mode = | 'preview' | 'hidden' | 'active';

export interface ISeoProps {
  linkTitle?: string;
  pageTitle?: string;
  description?: string;
  type?: string;
  image?: string;
  imageAlt?: string;
  locale?: string;
  url?: string;
  noTitle?: boolean;
}

export const seoDefaultValues: ISeoProps = {
  linkTitle: 'Little Caesars Fundraising | Click to Raise Money for a Cause',
  pageTitle: 'Little Caesars Fundraising | Click to Raise Money for a Cause',
  description: 'Easy, delicious, and profitable – our products are a hit with groups of all ages!',
  type: 'website',
  image: process.env.REACT_APP_OG_IMAGE,
  imageAlt: 'Little Caesars Fundraising | Click to Raise Money for a Cause',
  locale: 'en_US',
  url: '',
  noTitle: false,
};

const Seo: React.FC<ISeoProps> = (props) => {
  const {
    pageTitle = seoDefaultValues.pageTitle,
    linkTitle = seoDefaultValues.linkTitle,
    description = seoDefaultValues.description,
    type = seoDefaultValues.type,
    image = seoDefaultValues.image,
    imageAlt = linkTitle,
    locale = seoDefaultValues.locale,
    url = seoDefaultValues.url,
    noTitle = seoDefaultValues.noTitle,
  } = props;

  return (
    <Helmet>
      {!noTitle && (
        <title>
          {pageTitle}
        </title>
      )}
      <meta content={ linkTitle } name="title" />
      <meta content={ description } name="description" />
      <meta content={ description } property="og:description" />
      <meta content={ type } property="og:type" />
      <meta content={ linkTitle } property="og:title" />
      <meta content={ image } property="og:image" />
      <meta content={ imageAlt } property="og:image:alt" />
      <meta content={ locale } property="og:locale" />
      {url && <meta content={ url } property="og:url" />}
      {url && <link href={ url } rel="canonical" />}

      <meta content={ linkTitle } name="twitter:title" />
      <meta content={ description } name="twitter:description" />
      <meta content="summary_large_image" name="twitter:card" />
      <meta content={ image } name="twitter:image" />
      <meta content={ imageAlt } name="twitter:image:alt" />
    </Helmet>
  );
};

export default Seo;
