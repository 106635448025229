import * as React from 'react';
import { Box } from '@lce/slice_v2';

import {
  SellerGraphs,
  SellerInformation,
  SellerInformationSkeleton,
  SellerGraphsSkeleton,
} from './components';

import { useFetchSellerBySupportGuid, useFetchSellerDashboardStatistics } from 'features/fundraiser';
import { useFundraiserState } from 'features/fundraiser/context/fundraiserContext';

export const FundraiserTracker: React.FC = () => {
  const { data: seller, isLoading: isSellerLoading } = useFetchSellerBySupportGuid();
  const { fundraiserType } = useFundraiserState();
  const { data: sellerStats, isLoading: isSellerStatsLoading } = useFetchSellerDashboardStatistics(seller?.Id || 0);

  return (
    <Box
      data-testid="fundraiser-tracker"
      sx={ {
        alignItems: 'center',
        display: 'grid',
        gap: [ '16px', '16px', '32px' ],
        gridTemplateColumns: [ 'auto', 'auto', 'auto 2px auto' ],
        gridTemplateRows: [ 'auto auto', 'auto auto', '1fr' ],
        justifyContent: 'center',
        mb: '16px',
        mt: '8px',
        p: [ 0, 0, '40px' ],
        width: '100%',
      } }
    >
      { isSellerLoading && !seller && <SellerInformationSkeleton /> }
      { !isSellerLoading && seller && (
        <SellerInformation fundraiserType={ fundraiserType } seller={ seller } />
      )}
      <Box sx={ { display: [ 'none', 'none', 'block' ], bg: 'primaryGrayMid', height: '100%' } } />
      { isSellerStatsLoading
        ? <SellerGraphsSkeleton />
        : <SellerGraphs sellerStats={ sellerStats || [] } />}
    </Box>
  );
};

