import React, { useEffect } from 'react';
import { Flex, SliceProvider, Spinner } from '@lce/slice_v2';
import { QueryClientProvider } from 'react-query';
import { fontDefinitions } from '@lce/slice_v2/theme/lce/eComm';
import { IntlProvider } from '@lce/i18n';
import { ReactQueryDevtools } from 'react-query/devtools';
import { HelmetProvider } from 'react-helmet-async';
import ReactPixel from 'react-facebook-pixel';

import en from './i18n/en-us.json';
import AppRouter from './AppRouter';
import { theme, fontFamilies } from './theme';
import { queryClient } from './query-client';

import { CartStateProvider } from 'features/cart';
import { AnalyticsProvider } from 'features/analytics';
import Maintenence from 'pages/maintenence/Maintenence';
import CookieConsent from 'ui/common/CookieConsent';
import { useGrowthBook } from 'ui/hooks';

const fbpOptions = {
  autoConfig: true,
  debug: false,
};

ReactPixel.init(`${ process.env.REACT_APP_FACEBOOK_PIXEL_ID }`, undefined, fbpOptions);
ReactPixel.pageView();

const analyticsSettings = {
  CONFIG: {
    GOOGLE_TAG_MANAGER: {
      ID: process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID || '',
      PREVIEW: process.env.REACT_APP_GOOGLE_TAG_MANAGER_PREVIEW || '',
      AUTH: process.env.REACT_APP_GOOGLE_TAG_MANAGER_AUTH || '',
    },
  },
};

const App: React.FC = () => {
  const { GrowthBookProvider, growthbook, growthbookReady } = useGrowthBook();

  useEffect(() => {
    console.log(`%c🍕Build: ${ process.env.REACT_APP_AZURE_BUILD }`, 'color: #FF671B');
    console.log(`%cVersion: ${ process.env.REACT_APP_VERSION }`, 'color: #FF671B');
  }, []);

  return growthbookReady ? (
    <GrowthBookProvider growthbook={ growthbook }>
      <IntlProvider resources={ { 'en-us': en } }>
        <AnalyticsProvider settings={ analyticsSettings }>
          <QueryClientProvider client={ queryClient }>
            <SliceProvider global={ [ fontDefinitions, fontFamilies ] } theme={ theme }>
              <HelmetProvider>
                { process.env.REACT_APP_MAINTENENCE_MODE === 'true'
                  ? <Maintenence />
                  : (
                    <CartStateProvider>
                      <AppRouter />
                    </CartStateProvider>
                  )}
                <CookieConsent data-testid="onetrust-cookie-consent" />
              </HelmetProvider>
            </SliceProvider>
            { process.env.NODE_ENV === 'development' &&
            <ReactQueryDevtools initialIsOpen={ false } />}
          </QueryClientProvider>
        </AnalyticsProvider>
      </IntlProvider>
    </GrowthBookProvider>
  ) : (
    <SliceProvider global={ [ fontDefinitions, fontFamilies ] } theme={ theme }>
      <Flex
        sx={ {
          alignItems: 'center', height: '100vh', justifyContent: 'center', width: '100%',
        } }
      >
        <Spinner
          variant="lce"
        />
      </Flex>
    </SliceProvider>
  );
};

export default App;
