import React from 'react';
import { useTranslation } from '@lce/i18n';
import { Text, Box } from '@lce/slice_v2';

import { FundraiserBrochure } from 'features/fundraiser/types/fundraiser';

export interface IChairpersonDetailsSummaryProps {
  details: Partial<FundraiserBrochure> | undefined;
}

const ChairpersonDetailsSummary: React.FC<IChairpersonDetailsSummaryProps> = ({ details }) => {
  const [ t ] = useTranslation();

  return (
    <Box data-testid="chairperson-details-summary">
      <Text variant="text.header.form">
        {t('fundraiser.PrimaryChairperson')}
      </Text>
      { details && (
        <>
          <Text data-testid="chairperson-name">
            {`${ details.chairpersonFirstName } ${ details.chairpersonLastName }`}
          </Text>
          <Text>
            {t('fundraiser.summary.PrimaryPhoneNumber',
              { number: details.chairpersonPrimaryPhoneNumber, type: details.chairpersonPrimaryPhoneNumberType })}
          </Text>
          <Text>
            {t('fundraiser.summary.SecondaryPhoneNumber',
              { number: details.chairpersonSecondaryPhoneNumber, type: details.chairpersonSecondaryPhoneNumberType })}
          </Text>
          <Text>
            {details.chairpersonEmailAddress}
          </Text>
          { details.coChairpersonEmailAddress && (
            <>
              <Text sx={ { mt: '16px' } } variant="text.header.form">
                {t('fundraiser.CoChairperson')}
              </Text>
              <Text>
                {`${ details.coChairpersonFirstName } ${ details.coChairpersonLastName }`}
              </Text>
              <Text>
                { details.coChairpersonEmailAddress}
              </Text>
            </>
          )}
        </>
      ) }
    </Box>
  );
};

export default ChairpersonDetailsSummary;
