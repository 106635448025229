/* eslint-disable max-lines-per-function */
import * as React from 'react';
import {
  Box, Text, Flex, Image, Link,
} from '@lce/slice_v2';
import { ThemeUIStyleObject } from 'theme-ui';
import { useTranslation } from '@lce/i18n';
import _map from 'lodash/map';

import PizzaSlice from './assets/pizzaslice.png';
import LceSpear from './assets/lcespear.svg';

import { useMediaQuery } from 'features/common';
import { FundraiserType } from 'features/fundraiser';

export interface IFundraiserComparisonProps {
  sx?: ThemeUIStyleObject;
}

interface IPizzaSlicesProps {
  digital: boolean;
}

const PizzaSlices: React.FC<IPizzaSlicesProps> = ({ digital }) => (
  <Box sx={ { ml: digital ? '20px' : [ '28px', '52px' ] } }>
    {
      [ 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10 ].map(i => (
        <Box key={ i.toString() } sx={ { height: [ '20px', '40px' ], mb: [ '38px', 4 ] } }>
          {(i === 2 || i === 6) && digital
            ? null
            : (i === 4 || i === 5 || i === 7 || i === 8) && !digital
              ? null
              : (
                <Image
                  src={ PizzaSlice }
                  sx={ {
                    width: [ '20px', '32px' ],
                    height: [ '20px', '30px' ],
                    transform: 'translateY(6px)',
                  } }
                />
              )}
        </Box>
      ))
    }
  </Box>
);

const FundraiserComparison: React.FC<IFundraiserComparisonProps> = ({ sx }) => {
  const [ t ] = useTranslation();
  const isLarge = useMediaQuery('(min-width: 1024px)');
  const points: string[] = t('startAFundraiser.fundraiserComparison.programPoints', { returnObjects: true });
  const fundraiserTypes: FundraiserType[] = [ FundraiserType.Digital, FundraiserType.Brochure ];

  return (
    <Flex
      sx={ {
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#f7f2ec',
        ...sx,
      } }
    >
      <Box
        data-testid="levels"
        sx={ {
          backgroundColor: 'white',
          p: [ '12px', '12px 16px', '64px 108px 64px 72px' ],
          width: '100%',
          maxWidth: [ '396px', '680px', '1092px' ],
          borderRadius: '8px',
          boxShadow: '0px 0px 4px 1px rgba(0,0,0,0.15)',
        } }
      >
        <Flex sx={ { flexDirection: 'column' } }>
          <Flex sx={ { justifyContent: 'space-between' } }>
            <Flex
              sx={ {
                flexDirection: 'column',
                justifyContent: 'space-evenly',
                alignItems: 'flex-start',
                maxWidth: [ '164px', '252px', 'initial' ],
              } }
            >
              <Box
                sx={ {
                  width: [ '152px', '420px' ],
                  height: '12px',
                  float: 'left',
                  mb: [ 5, 2 ],
                } }
              />
              {_map(points, point => (
                <Text
                  sx={ {
                    fontWeight: 'bold',
                    fontFamily: 'lato',
                    fontSize: [ '14px', '18px' ],
                    mb: [ '26px', 2 ],
                  } }
                >
                  {point}
                </Text>
              ))}
            </Flex>
            {_map(fundraiserTypes, type => (
              <Flex>
                {isLarge ? (
                  <Box>
                    <Image
                      src={ LceSpear }
                      sx={ { transform: 'translateX(-63px)translateY(24px)' } }
                    />
                  </Box>
                ) : null}
                <Box>
                  <Text
                    sx={ {
                      fontFamily: 'lcScript',
                      fontSize: [ '14px', '24px' ],
                      mb: [ 5, 4 ],
                    } }
                  >
                    {t('startAFundraiser.fundraiserComparison.fundraiserType', { context: type })}
                  </Text>
                  <PizzaSlices digital={ type === FundraiserType.Digital } />
                </Box>
              </Flex>
            ))}
          </Flex>
        </Flex>
        <Box sx={ { mt: [ '24px', '40px' ] } }>
          <Text
            sx={ {
              fontWeight: 'bold',
              fontFamily: 'lato',
              fontSize: [ '20px', '26px' ],
              textAlign: 'center',
            } }
          >
            {t('startAFundraiser.fundraiserComparison.contactText')}
            <Link
              aria-label="Contact Number"
              as="span"
              data-testid="fundraiser-program-contact-number"
              href={ `tel:${ t('startAFundraiser.fundraiserComparison.contactNumber') }` }
              sx={ {
                fontWeight: 'bold',
                fontFamily: 'lato',
                fontSize: [ '18px', '26px' ],
                color: 'primaryOrange',
                ml: [ 2 ],
              } }
              variant="marketingLabel"
            >
              {t('startAFundraiser.fundraiserComparison.contactNumber')}
            </Link>
          </Text>
        </Box>
      </Box>
    </Flex>
  );
};

export default FundraiserComparison;
