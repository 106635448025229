import React from 'react';
import {
  Flex,
  IconButton,
  Modal as SliceModal,
} from '@lce/slice_v2';
import { X } from 'react-feather';
import { toast } from 'react-toastify';
import { useTranslation } from '@lce/i18n';

import { RemoveChairpersonForm } from './RemoveChairpersonForm/RemoveChairpersonForm';

import { ChairpersonInfo } from 'features/fundraiser';
// eslint-disable-next-line max-len
import { RemoveChairpersonRequest, useRemovePrimaryChairperson } from 'features/fundraiser/hooks/useRemovePrimaryChairperson';
import { GenericToast } from 'ui/common';

export interface IRemoveChairpersonModal {
  chairperson?: ChairpersonInfo;
  onClose: () => void;
  fundraiserId: number;
}

const RemoveChairpersonModal: React.FC<IRemoveChairpersonModal> = ({ onClose, chairperson, fundraiserId }) => {
  const { mutateAsync: removePrimaryChairperson } = useRemovePrimaryChairperson(fundraiserId);
  const [ t ] = useTranslation();

  const onSubmit = async(e: ChairpersonInfo) => {
    const request: RemoveChairpersonRequest = {
      fundraiserId: fundraiserId,
      chairperson: e,
    };
    const isChairpersonUpdated = await removePrimaryChairperson(request);

    if (isChairpersonUpdated) {
      onClose();
      toast(<GenericToast text={ t('fundraiser.FundraiserUpdate') } />, { position: 'top-center' });
    } else {
      toast(<GenericToast text={ t('fundraiser.FundraiserFailUpdate') } />, { position: 'top-center' });
    }
  };

  return (
    <SliceModal
      closeIcon={ false }
      data-testid="remove-chairperson-modal"
      onClose={ onClose }
      sx={ { maxWidth: '790px', mt: [ '70px', '105px', '0px' ] } }
    >
      <Flex>
        <IconButton data-testid="close-modal" onClick={ onClose } sx={ { cursor: 'pointer', ml: 'auto' } }>
          <X size={ 80 } />
        </IconButton>
      </Flex>

      <RemoveChairpersonForm chairperson={ chairperson } onClose={ onClose } onSubmit={ onSubmit } />
    </SliceModal>
  );
};

export { RemoveChairpersonModal };

