/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect } from 'react';
import { Route } from 'react-router-dom';

import { MainLayout } from 'ui/common';
import { ISeoProps, Seo } from 'ui/common/Seo';

type RouteWrapperProps = React.PropsWithChildren<{
  component: React.ComponentType<any>;
  routeComponent?: React.ComponentType<any>;
  layout?: React.ComponentType<any>;
  seo?: ISeoProps;
  history: any;
}> & Omit<React.ComponentProps<typeof Route>, 'component'>;

export function RouteWrapper({
  component: Component,
  routeComponent: RouteComponent = Route,
  layout: Layout = MainLayout,
  seo,
  history,
  ...rest
}: RouteWrapperProps) {
  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
    });
    return () => unlisten();
  }, [ history ]);

  return (
    <RouteComponent
      { ...rest } render={ props => (
        <>
          <Seo { ...seo } />
          <Layout { ...props }>
            <Component { ...props } />
          </Layout>
        </>
      ) }
    />
  );
}
