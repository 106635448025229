/* eslint-disable max-lines-per-function */
import * as React from 'react';
import {
  Box, Button, Flex, Form, Text,
} from '@lce/slice_v2';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from '@lce/i18n';

import { UserInfo } from 'features/common/types/userInfo';
import { GenericToast } from 'ui/common';
import { useUpdateUserInfo } from 'features/common/hooks/useUpdateUser';

type IEditForm = {
  user: UserInfo;
  editMode: () => void;
}

const EditForm: React.FC<IEditForm> = ({ user, editMode }) => {
  const [ t ] = useTranslation();

  const [ formData, setFormData ] = useState(user);
  const [ disabled, setDisabled ] = useState(true);

  const { mutateAsync: updateUser } = useUpdateUserInfo();

  const onHandleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const onCheckboxChangeHandler = (e) => {
    setFormData({
      ...formData,
      OptInMarketing: e.target.checked,
    });
  };

  const onAccountUpdateHandler = async() => {
    const isUserUpdated = await updateUser(formData);
    if (isUserUpdated) {
      toast(<GenericToast text={ t('accountSettings.UpdateSuccess') } />, { position: 'top-center' });
    } else {
      toast(<GenericToast text={ t('accountSettings.UpdateFail') } />, { position: 'top-center' });
    }
  };

  useEffect(() => {
    const isChanged = () => {
      if (
        formData.FirstName !== user.FirstName ||
        formData.LastName !== user.LastName ||
        formData.Phone !== user.Phone ||
        formData.OptInMarketing !== user.OptInMarketing
      ) {
        return true;
      } else {
        return false;
      }
    };

    const isValid = () => {
      if (formData.FirstName === '' || formData.LastName === '' || formData.Phone === '') {
        return false;
      } else {
        return true;
      }
    };

    if (isValid() && isChanged()) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [ formData, user ]);

  return (
    <Box variant="cards.step.body">
      <Form onSubmit={ onAccountUpdateHandler }>
        <Flex sx={ { flexDirection: [ 'column', 'row' ] } }>
          <Form.Field
            autoComplete="given-name"
            id="FirstName"
            label="First Name*" name="FirstName" onChange={ onHandleChange }
            required={ true }
            sx={ { mr: [ 0, '12px' ] } } value={ formData.FirstName }
            variant="forms.fundraiser.field"
          />

          <Form.Field
            autoComplete="family-name"
            id="LastName"
            label="Last Name*"
            name="LastName"
            onChange={ onHandleChange }
            required={ true }
            sx={ { ml: [ 0, '12px' ] } }
            value={ formData.LastName }
            variant="forms.fundraiser.field"
          />
        </Flex>

        <Flex sx={ { flexDirection: [ 'column', 'row' ] } }>
          {/* This should be PhoneInput */}
          <Form.Field
            autoComplete="tel-national"
            id="Phone"
            label="Phone Number*"
            name="Phone"
            onChange={ onHandleChange }
            required={ true }
            sx={ { mr: [ 0, '12px' ] } } type="phone"
            value={ formData.Phone }
            variant="forms.fundraiser.field"
          />

          <Flex
            sx={ { flexDirection: 'column', justifyContent: 'center', margin: [ '16px 0px', '0px' ] } }
            variant="forms.fundraiser.field"
          >
            <Text sx={ { ml: [ 0, '12px' ], mb: '10px' } }>
              {t('accountSettings.Email')}
              *
            </Text>

            <Text sx={ { ml: [ 0, '12px' ] } }>
              {user?.Email}
            </Text>
          </Flex>
        </Flex>

        {/* TODO: Reimplement this when new design comes through */}

        {/* <Flex sx={ { flexDirection: [ 'column', 'row' ] } }>
            <Form.Field
              component={ Form.Input.Password } id="Password" label="Password*" name="Password"
              sx={ { mr: [ 0, '12px' ] } }
              variant="forms.fundraiser.field"
            />
            <Form.Field
              component={ Form.Input.Password }
              id="ConfirmPassword" label="Confirm Password*" name="ConfirmPassword" sx={ { ml: [ 0, '12px' ] } }
              variant="forms.fundraiser.field"
            />
          </Flex> */}

        <Form.Field
          checked={ formData.OptInMarketing }
          component={ Form.Input.Checkbox }
          id="optInMarketing"
          inline={ true }
          label={ t('createAccount.MarketingOptIn') }
          name="OptInMarketing"
          onChange={ onCheckboxChangeHandler }
        />

        <Flex sx={ { flexDirection: [ 'column', 'row' ] } }>
          <Button
            onClick={ editMode }
            sx={ { mr: [ '0px', '12px' ], mb: [ '20px', '0px' ], flex: '1' } }
            type="button"
            variant="buttons.secondary"
          >
            {t('accountSettings.Cancel')}
          </Button>

          <Flex sx={ { flex: '1', flexDirection: 'column' } }>
            <Button
              disabled={ disabled }
              sx={ { flex: '1' } }
              type="submit"
              variant={ disabled ? 'buttons.disabled' : 'buttons.primary' }
            >
              {t('accountSettings.UpdateChanges')}
            </Button>

            <Text sx={ { mt: '5px' } } variant="text.accountSettings.disclaimer">
              {t('accountSettings.TermsAcknowledgement')}
            </Text>
          </Flex>

        </Flex>
      </Form>
    </Box>
  );
};

export default EditForm;
