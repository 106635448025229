/* eslint-disable @typescript-eslint/camelcase */
import _map from 'lodash/map';

import TagManager from '../TagManager';

import { DataLayerCart } from './types';

const viewCart = (item: DataLayerCart) => {
  TagManager.pushToDataLayer({
    event: 'view_cart',
    ecommerce: {
      currency: 'USD',
      value: item.cartTotal,
      items: _map(item.products, product => ({
        item_name: product.name,
        item_id: product.id,
        price: product.price,
        quantity: product.quantity,
      })),
    },
  });
};

export default viewCart;
