import React from 'react';
import { Text } from '@lce/slice_v2';

const Unauthorized = () => (
  <div>
    <Text variant="text.header.title">403</Text>
    <Text>You are not authorized to view this resource</Text>
  </div>
);

export default Unauthorized;
