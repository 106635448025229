const ISSUER = process.env.REACT_APP_OKTA_CLIENT_ORGURL + '/oauth2/default';
const CLIENT_ID = process.env.REACT_APP_OKTA_CLIENT_ID;
const REDIRECT_URI = window.location.origin + '/authorization-code/callback';
const OKTA_TESTING_DISABLEHTTPSCHECK = process.env.OKTA_TESTING_DISABLEHTTPSCHECK || false;

export default {
  oidc: {
    clientId: CLIENT_ID,
    issuer: ISSUER,
    redirectUri: REDIRECT_URI,
    scopes: [ 'openid', 'profile', 'email' ],
    pkce: true,
    disableHttpsCheck: OKTA_TESTING_DISABLEHTTPSCHECK,
  },
};
