import React from 'react';
import { Box, Flex } from '@lce/slice_v2';
import _map from 'lodash/map';

import ChairpersonPhoto from 'assets/images/homepage/chairperson_photo.webp';
import SellerPhoto from 'assets/images/homepage/seller_photo.webp';
import BuyerPhoto from 'assets/images/homepage/buyer_photo.webp';
import ChairpersonIcon from 'assets/images/homepage/leaderboard.svg';
import SellerIcon from 'assets/images/homepage/priceTag.svg';
import BuyerIcon from 'assets/images/homepage/pizza.svg';
import { UserCategoryCard, HomeCardProps } from 'ui/components/homepage/user-category-card';

const roles: HomeCardProps[] = [
  { role: 'chairperson', image: ChairpersonPhoto, icon: ChairpersonIcon },
  { role: 'seller', image: SellerPhoto, icon: SellerIcon },
  { role: 'buyer', image: BuyerPhoto, icon: BuyerIcon },
];

export const UserCategories: React.FC = () => (
  <Flex
    data-testid="user-categories"
    sx={ {
      alignContent: 'end',
      height: [ 'auto', 'auto', '509px' ],
      justifyContent: 'center',
      mt: [ '-64px', '-96px', '-152px' ],
      p: [ '32px 16px', '32px' ],
      position: 'relative',
      width: '100%',
      zIndex: 2,
    } }
  >
    <Box
      sx={ {
        display: 'grid',
        gap: [ '16px', '16px', '32px' ],
        gridTemplateAreas: [
          '"chairperson" "seller" "buyer"',
          '"chairperson chairperson seller seller" ". buyer buyer ."',
          '"chairperson seller buyer"',
        ],
        gridTemplateColumns: [ '1fr', '1fr 1fr 1fr 1fr', '1fr 1fr 1fr' ],
        gridTemplateRows: [ 'auto', '1fr 1fr', '1fr' ],
        height: [ 'auto', 'auto', '445px' ],
        maxWidth: '1216px',
      } }
    >
      { _map(roles, role => <UserCategoryCard { ...role } key={ role.role } />)}
    </Box>
  </Flex>
);

export default UserCategories;
