import React from 'react';

export const RightArchClipPathSvg = () => (
  <svg height="0" width="0">
    <defs>
      <clipPath clipPathUnits="objectBoundingBox" id="right-arch-clip">
        <path d="M 0,0 L 0.98,0 Q 1.02,0.5 0.98,1.01 L 0,1 Z" />
      </clipPath>
    </defs>
  </svg>
);
