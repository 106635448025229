/* eslint-disable no-extra-parens */
import { useMutation } from 'react-query';

import { CheckoutResponse } from '../types/response';
import { CheckoutRequest } from '../types/request';
import { startCheckout } from '../api';

import { ApiError } from 'features/common';

const useCheckoutRequest = () => (
  useMutation<CheckoutResponse, ApiError, CheckoutRequest>(request => startCheckout(request))
);

export { useCheckoutRequest };
