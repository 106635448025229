/* eslint-disable max-lines-per-function */
import {
  Box, Text, Flex, Image,
} from '@lce/slice_v2';
import { Link } from 'react-router-dom';
import React from 'react';
import { ThemeUIStyleObject } from 'theme-ui';

interface IFundraiserOptionIconProps {
  image: string;
  title: string;
  subtitle: string;
  link?: string;
  redirectUri?: string;
  sx?: ThemeUIStyleObject;
  onClick?: () => void;
}

export const FundraiserOptionIcon: React.FC<IFundraiserOptionIconProps> = ({
  image, title, subtitle, link, onClick, redirectUri,
}) => (
  <Flex sx={ { flexDirection: 'column', alignItems: 'center' } }>
    <Box
      as={ Link }
      data-testid={ title }
      onClick={ onClick }
      sx={ {
        display: 'inline-block',
        textAlign: 'center',
        cursor: 'pointer',
      } }
      to={ { pathname: link, state: { redirectUri } } }
    >
      <Image
        alt=""
        src={ image }
        sx={ {
          maxHeight: [ '114px', '114px', '164px' ],
          maxWidth: [ '114px', '114px', '164px' ],
          height: '100%',
          width: '100%',
        } }
        title={ title }
      />
    </Box>
    <Box>
      <Box
        as={ Link }
        onClick={ onClick }
        sx={ {
          color: 'primaryOrange',
          textDecoration: 'none',
          '&:hover': {
            cursor: 'pointer',
            textDecoration: 'underline',
          },
        } }
        to={ { pathname: link, state: { redirectUri } } }
      >

        <Text
          sx={ {
            my: 4,
            fontFamily: 'lato',
            fontWeight: 'bold',
            color: 'primaryOrange',
            textAlign: 'center',
            fontSize: [ '20px', '20px', '22px' ],
          } }
        >
          { title }
        </Text>
      </Box>

      <Text
        sx={ {
          my: 3,
          fontFamily: 'roboto',
          color: 'black',
          fontWeight: 'bold',
          textAlign: 'center',
          fontSize: [ '14px' ],
          maxWidth: [ '164px', 'initial' ],
        } }
      >
        { subtitle }
      </Text>
    </Box>
  </Flex>
);
