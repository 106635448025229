/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/forbid-component-props */
/* eslint-disable max-lines-per-function */
import * as React from 'react';
import { useState, useLayoutEffect, useRef } from 'react';
import numeral from 'numeral';
import {
  Box, Flex, Text, Image, IconButton,
} from '@lce/slice_v2';
import { useTranslation } from '@lce/i18n';
import { ChevronDown, ChevronUp } from 'react-feather';

import { productCard } from './ProductListItem.styles';

import { ConfirmationButton, RouterLink } from 'ui/common';
import { Product } from 'features/products';
import { useFundraiserState } from 'features/fundraiser/context/fundraiserContext';
import { GoogleEvents } from 'features/analytics';

export interface IProductListItemProps {
  key: string;
  product: Product;
  canStartShopping: boolean;
  onAddToCart: (product, quantity) => void;
  supportGuid?: string;
  isTextExpandedDefault?: boolean;
  showExpandedButtonDefault?: boolean;
}

const ProductListItem: React.FC<IProductListItemProps> = ({
  product,
  canStartShopping,
  onAddToCart,
  supportGuid,
  isTextExpandedDefault = false,
  showExpandedButtonDefault = false,
  children,
}) => {
  const [ t ] = useTranslation();
  const [ loading, setLoading ] = useState<boolean>(false);
  const { fundraiserType } = useFundraiserState();
  const productDetailLink = supportGuid
    ? t(`routes.productDetailPage.${ fundraiserType }`, { sku: product.Sku, supportGuid })
    : t(`routes.productDetailPageNoSupportGuid.${ fundraiserType }`, { sku: product.Sku });

  if (!product.ThumbnailImage) {
    product.ThumbnailImage = {
      Id: 1,
      MimeType: '',
      VirtualPath:
          'https://lcepizzakitstordeve2.blob.core.windows.net/pictures/3xDeep.png',
      AltAttribute: 'picture',
      TitleAttribute: 'picture',
    };
  }

  const handleAddToCart = (product, quantity) => {
    if (canStartShopping) {
      setLoading(true);
      onAddToCart(product, quantity);
      setTimeout(() => setLoading(false), 1000);
    } else {
      window.location.href = `${ window.location.origin }/buyer`;
    }
  };

  const gaViewItemOnClick = () => {
    GoogleEvents.viewItem({
      name: product.Name,
      price: product.Price,
      id: product.Id.toString(),
    });
  };

  const [ isTextExpanded, setIsTextExpanded ] = useState<boolean>(isTextExpandedDefault);

  const onToggle = (expanded: boolean) => {
    setIsTextExpanded(expanded);
  };

  const [ showExpandTextButton, setShowExpandTextButton ] = useState<boolean>(showExpandedButtonDefault);
  const titleRef = useRef<any>(null);
  const descriptionRef = useRef<any>(null);

  useLayoutEffect(() => {
    const handleResize = () => {
      const isDescriptionTruncated = descriptionRef.current.scrollHeight > 39;
      const isTitleTruncated = titleRef.current.scrollHeight > 48;
      setShowExpandTextButton(isDescriptionTruncated || isTitleTruncated);
    };
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Box
      sx={ productCard(fundraiserType, isTextExpanded) }
      variant={ `cards.productCard${ fundraiserType }` }
    >
      <RouterLink
        onClick={ gaViewItemOnClick }
        to={ productDetailLink }
        variant={ `links.productCardImageWrapperLink${ fundraiserType }` }
      >
        <Image
          alt={ product.ThumbnailImage.AltAttribute }
          className="product-image"
          src={ product.ThumbnailImage.VirtualPath }
          title={ product.ThumbnailImage.TitleAttribute }
          variant={ `images.productCardImage${ fundraiserType }` }
        />
      </RouterLink>
      <Flex variant={ `cards.productCardDetailsWrapper${ fundraiserType }` }>
        <Flex sx={ { flexDirection: 'column', alignItems: 'flex-start' } }>
          <RouterLink
            onClick={ gaViewItemOnClick }
            sx={ {
              width: '100%', minHeight: [ 'none', '48px' ], display: 'flex', alignItems: 'center',
            } }
            to={ productDetailLink }
          >
            <Text
              className="product-name"
              ref={ titleRef }
              sx={ {
                WebkitLineClamp: isTextExpanded ? 'none' : '2',
              } }
              variant="text.productListItem.name"
            >
              {product.Name}
            </Text>
          </RouterLink>
          <Flex sx={ { py: '4px', flexDirection: 'column', alignItems: 'flex-start' } }>
            <Text
              ref={ descriptionRef }
              sx={ {
                WebkitLineClamp: isTextExpanded ? 'none' : '2',
                mb: showExpandTextButton ? 0 : [ 0, '24px' ],
              } }
              variant="text.productListItem.description"
            >
              { product.ShortDescription}
            </Text>
            {showExpandTextButton && (
              <IconButton
                data-testid="toggle-show-text-button"
                onClick={ () => onToggle(!isTextExpanded) }
                sx={ {
                  cursor: 'pointer',
                  p: 0,
                  m: 0,
                  width: 'auto',
                  height: 'auto',
                  color: 'primaryGrayDark',
                  ':hover': {
                    color: 'primaryGrayDark',
                  },
                } }
              >
                { isTextExpanded
                  ? <ChevronUp data-testid="chevron-up-icon" />
                  : <ChevronDown data-testid="chevron-down-icon" /> }
              </IconButton>
            )}
          </Flex>
        </Flex>
        <Flex
          sx={ {
            alignItems: 'center',
            justifyContent: 'space-between',
            flexDirection: 'row',
            width: '100%',
          } }
        >
          <Text variant="text.productListItem.price">
            {numeral(product.Price).format(t('currency.format'))}
          </Text>
          <ConfirmationButton
            loading={ loading }
            onClick={ () => handleAddToCart(product, 1) }
            sku={ product.Sku }
            variant={ `buttons.confirmationButton${ fundraiserType }` }
          >
            { children }
          </ConfirmationButton>
        </Flex>
      </Flex>
    </Box>
  );
};

export default ProductListItem;
