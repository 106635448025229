import React from 'react';
import { Text } from '@lce/slice_v2';
import { ThemeUIStyleObject } from 'theme-ui';

interface IBracketTextWrapperProps {
  text: string;
  sx: ThemeUIStyleObject;
}

const BracketTextWrapper: React.FC<IBracketTextWrapperProps> = ({ text, sx }) => {
  const regex = /\[[^\]]+\]/g;
  const parts = text.split(regex);
  const matches = text.match(regex);

  return (
    <>
      {parts.map((part, i) => {
        const match = matches && i < matches.length ? matches[i] : null;

        if (!part && !match) {
          return null;
        }

        return (
          // eslint-disable-next-line react/no-array-index-key
          <React.Fragment key={ `${ part }${ i }` }>
            {part && (
              <Text as="span">
                {part}
              </Text>
            )}
            {match && (
              <Text as="span" sx={ { ...sx } }>
                { match.slice(1, -1) }
              </Text>
            )}
          </React.Fragment>
        );
      })}
    </>
  );
};

export default BracketTextWrapper;
