import {
  Box, Button, Flex, Image,
} from '@lce/slice_v2';
import React, { useState } from 'react';
import { useTranslation } from '@lce/i18n';
import { toast } from 'react-toastify';
import { ThemeUIStyleObject } from 'theme-ui';

import PizzaPizza from 'assets/images/pizza-pizza-kit.svg';
import {
  CreateAccountForm, LoginForm, LoginBox, RegisterUserBox,
} from 'ui/account';
import { GenericToast } from 'ui/common/Toast/CustomToasts';
export interface IFundraiserLoginProps {
  showImage?: boolean;
  sx?: ThemeUIStyleObject;
}

const FundraiserLogin: React.FC<IFundraiserLoginProps> = ({ showImage = true, sx }) => {
  const [ showCreateAccount, setShowCreateAccount ] = useState<boolean>(false);
  const [ showLogin, setShowLogin ] = useState<boolean>(false);
  const [ t ] = useTranslation();

  if (showCreateAccount) {
    return (
      <CreateAccountForm
        isConfirm={ false }
        onBackCallback={ () => setShowCreateAccount(false) }
        onSuccess={ () => {
          setShowCreateAccount(false);
          setShowLogin(true);
          toast(<GenericToast text="Your account has been created! Please sign in to continue" />);
        } }
      />
    );
  }

  return (
    <Flex
      id="login"
      sx={ {
        mt: [ 0, '68px' ], flexDirection: [ 'column', 'row' ], justifyContent: 'center', scrollMarginTop: '92px', ...sx,
      } }
    >
      { showImage && (
        <Box sx={ { mr: '100px', display: [ 'none', 'none', 'block' ] } }>
          <Image alt="LC Fundraising Logo" src={ PizzaPizza } title="LC Fundraising" />
        </Box>
      )}
      { showLogin ? (
        <Flex sx={ { flexDirection: 'column', maxWidth: [ 'none' ] } }>
          <LoginForm onBackCallback={ () => setShowLogin(false) } redirect={ false } />
        </Flex>
      ) : (
        <Flex sx={ { flexDirection: 'column', maxWidth: [ 'none' ] } }>
          <RegisterUserBox>
            <Button onClick={ () => setShowCreateAccount(true) } sx={ { width: '100%' } }>
              {t('common.CreateAccount')}
            </Button>
          </RegisterUserBox>
          <LoginBox>
            <Button data-testid="signin_button" onClick={ () => setShowLogin(true) } sx={ { width: '100%' } }>
              {t('common.SignIn')}
            </Button>
          </LoginBox>
        </Flex>
      ) }
    </Flex>
  );
};

export default FundraiserLogin;
