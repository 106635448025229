import { Text, Box } from '@lce/slice_v2';
import React from 'react';

import { OrderHistoryListItem, OrderHistoryListItemSkeleton } from '../OrderHistoryListItem';

import { useOrderHistory } from 'features/orders';

const OrderHistoryList: React.FC = () => {
  const { data: orders, isError, isLoading } = useOrderHistory();

  if (isError) {
    return (
      <Text>Something went wrong when retrieving your orders. Please try again later or call customer support.</Text>
    );
  }

  return (
    <Box>
      { !isLoading
        ? orders?.map(order => <OrderHistoryListItem key={ order.OrderId } order={ order } />)
        : <OrderHistoryListItemSkeleton /> }

      { orders && orders.length === 0 && (
        <Text>You have placed no orders.</Text>
      )}
    </Box>
  );
};

export default OrderHistoryList;
