/* eslint-disable max-lines-per-function */
import React from 'react';
import {
  Box, Flex, Image, Text,
} from '@lce/slice_v2';
import { useTranslation } from '@lce/i18n';
import _map from 'lodash/map';

import LCBackground from './assets/background/lc-slice-background.svg';
import CallOfDutyImg from './assets/Call-of-Duty_Endowment_Logo-Black.jpg';
import LemonadeStandImg from './assets/LemonadeStandLogo.png';
import ToysForTotsImg from './assets/T4Tlogo.jpg';

import { useMediaQuery } from 'features/common';

const featuredOrganizations = [
  { src: CallOfDutyImg, id: 'CallOfDuty' },
  { src: ToysForTotsImg, id: 'ToysForTots' },
  { src: LemonadeStandImg, id: 'LemonadeStand' },
];

const FeaturedOrganizations: React.FC = () => {
  const [ t ] = useTranslation();
  const isLarge = useMediaQuery('(min-width: 391px)');
  return (
    <Flex
      data-testid="featured-organizations"
      sx={ {
        backgroundColor: 'black',
        backgroundImage: `url(${ LCBackground })`,
        backgroundSize: '66px',
        color: 'white',
        justifyContent: 'center',
        p: [ '32px 16px', '32px', '80px 32px' ],
      } }
    >
      <Box
        sx={ {
          display: 'grid',
          gap: '16px',
          gridTemplateAreas: [
            '"title" "image0" "image1" "image2"',
            '"title title title" "image0 image1 image2"',
            '"title image0 image1 image2"',
          ],
          gridTemplateColumns: [ 'auto', '1fr 1fr 1fr', 'auto 1fr 1fr 1fr' ],
          maxWidth: '1216px',
          width: '100%',
        } }
      >
        <Flex
          sx={ {
            flexDirection: 'column',
            gap: [ '4px', '4px', '8px' ],
            gridArea: 'title',
            maxWidth: [ 'none', 'none', '300px' ],
            minWidth: '250px',
            textAlign: [ 'center', 'left' ],
          } }
        >
          <Text variant="home.sublabel">
            {t('home.featuredOrganizations.title')}
          </Text>
          <Text sx={ { maxWidth: isLarge ? 'none' : '300px', mx: isLarge ? '0' : 'auto' } } variant="home.subheading">
            {t('home.featuredOrganizations.subTitle')}
          </Text>
        </Flex>
        {_map(featuredOrganizations, (featuredOrganization, index) => (
          <Flex
            key={ index }
            sx={ {
              background: 'white',
              borderRadius: '8px',
              width: '100%',
              justifyContent: 'center',
              maxWidth: '300px',
              mx: 'auto',
              p: '16px',
              gridArea: `image${ index }`,
            } }
          >
            <Image
              data-testid={ `${ featuredOrganization.id }-image` }
              src={ featuredOrganization.src }
              sx={ { height: '88px', objectFit: 'contain' } }
            />
          </Flex>
        ))}
      </Box>
    </Flex>
  );
};

export default FeaturedOrganizations;
