import React from 'react';
import { Box, Flex } from '@lce/slice_v2';
import { ThemeUIStyleObject } from 'theme-ui';

export type DescriptionList = {
  term: string;
  description: string;
}

export type KeyValuePairsProps = {
  list: DescriptionList[];
  sx?: ThemeUIStyleObject;
  testId?: string;
}

export const dataTestIdKeyValuePairs = 'key-value-pairs-component';

export const KeyValuePairs: React.FC<KeyValuePairsProps> = ({
  list,
  sx = {},
  testId = dataTestIdKeyValuePairs,
}) => (
  <Box
    data-testid={ testId } sx={ {
      fontFamily: 'Roboto',
      color: 'primaryGrayDark',
      '> dl': { margin: 0 },
      ...sx,
    } }
  >
    <dl>
      {list.map(({ term, description }) => (
        <Flex key={ term } sx={ { '> dd': { marginInlineStart: '4px' } } }>
          <dt>
            {term}
            :
          </dt>
          <dd>
            {description}
          </dd>
        </Flex>
      ))}
    </dl>
  </Box>
);
