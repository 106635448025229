/* eslint-disable max-lines-per-function */
import { Box, Heading } from '@lce/slice_v2';
import * as React from 'react';
import { useState } from 'react';
import { useTranslation } from '@lce/i18n';

import { UserInfo } from 'features/common/types/userInfo';
import { Step } from 'ui/common';
import { EditForm, ReadOnlyForm } from 'ui/account';
import { useFetchEmailNotifications } from 'features/fundraiser';
import { EmailNotificationEdit } from 'ui/fundraiser/EmailNotificationEdit';

export type ISettings = {
  user: UserInfo;
};

const Settings: React.FC<ISettings> = ({ user }) => {
  const [ t ] = useTranslation();

  const [ editMode, setEditMode ] = useState(false);
  const [ emailEditMode, setEmailEditMode ] = useState(false);
  const { data, isFetching, error } = useFetchEmailNotifications();

  const cancelEdit = () => {
    setEditMode(false);
  };

  const onEdit = () => {
    setEditMode(true);
  };

  const onCloseEmail = () => {
    setEmailEditMode(false);
  };

  const onEditNotifications = () => {
    setEmailEditMode(true);
  };

  return (
    <Box sx={ { maxWidth: '700px', marginTop: [ '0px', '30px', '30px', '0px' ] } }>
      <Heading as="h1" sx={ { marginBottom: '-4px' } }>
        {t('accountSettings.Title')}
      </Heading>
      <Step onEdit={ onEdit } showEdit={ !editMode } title="Account Details">
        {editMode
          ? (<EditForm editMode={ cancelEdit } user={ user } />)
          : (<ReadOnlyForm user={ user } />
          )}
      </Step>
      <Step
        onEdit={ onEditNotifications } removeEditButton={ true } showEdit={ (!emailEditMode) }
        title="Email Notifications"
      >
        <EmailNotificationEdit
          emailEditMode={ emailEditMode } emailNotifications={ data } error={ error } isFetching={ isFetching }
          onCloseEmail={ onCloseEmail }
        />
      </Step>
    </Box>
  );
};

export default Settings;
