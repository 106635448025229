/* eslint-disable max-len */
import React from 'react';
import { useTranslation } from '@lce/i18n';
import { Box, Text } from '@lce/slice_v2';

import { theme } from 'theme';

export const RouteOne: React.FC = () => {
  const [ t ] = useTranslation();
  return (
    <Box
      data-testid="route-one"
      sx={ { background: '#f7f2ec', p: '16px 16px 24px', mb: 6 } }
    >
      <Text
        sx={ {
          fontSize: [ '20px', '32px' ], fontWeight: 'bold', fontFamily: theme.fonts.mrEaves, mb: 5,
        } }
      >
        {t('mealDeal.landing.howMuchToRaise')}
      </Text>
      <Text
        sx={ {
          fontSize: [ '18px', '32px' ], fontFamily: theme.fonts.body, lineHeight: 1.35, m: '0 auto', mb: 8, maxWidth: [ '250px', '400px' ],
        } }
      >
        {`${ t('mealDeal.landing.raisingGoalIsEasy', { goal: 300, codes: 50 }) }`}
      </Text>
    </Box>
  );
};
