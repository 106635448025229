import * as React from 'react';
import { useState } from 'react';
import _range from 'lodash/range';
import {
  Box, Flex, Form,
} from '@lce/slice_v2';

import { Product } from 'features/products';
import { ConfirmationButton } from 'ui/common';

const MAX_QUANTITY = 99;

export interface IProductDetailAddToCartProps {
  product: Product;
  canStartShopping: boolean;
  onAddToCart: (product, quantity) => void;
}

// eslint-disable-next-line max-lines-per-function
export const ProductDetailAddToCart: React.FC<IProductDetailAddToCartProps> = ({
  canStartShopping, product, onAddToCart,
}) => {
  const [ loading, setLoading ] = useState<boolean>(false);
  const [ quantity, setQuantity ] = useState<number>(1);

  const handleQuantityChange = (e) => {
    const value = parseInt(e.target.value, 10);
    setQuantity(value);
  };

  const handleAddToCart = (product, quantity) => {
    if (canStartShopping) {
      setLoading(true);
      onAddToCart(product, quantity);
      setTimeout(() => setLoading(false), 1000);
    } else {
      window.location.href = `${ window.location.origin }/buyer`;
    }
  };

  const isFreeShipping = product.IsFreeShipping;

  return (
    <Flex
      sx={ {
        alignItems: 'center',
        width: [ '100%', '300px' ],
        marginLeft: [ 'none', 'auto' ],
        marginTop: 'auto',
        marginBottom: isFreeShipping ? [ '25px', '0', '0' ] : '0',
      } }
    >
      <Box>
        <Form.Select
          data-testid={ `${ product.Id }-quantity` }
          id={ `${ product.Id }-quantity` }
          name={ `${ product.Id }-quantity` }
          onChange={ handleQuantityChange }
          variant="select.quantity"
        >
          { _range(1, MAX_QUANTITY + 1).map(value => (
            <option key={ value } value={ value }>
              {value}
            </option>
          )) }
        </Form.Select>
      </Box>
      <ConfirmationButton
        loading={ loading }
        onClick={ () => handleAddToCart(product, quantity) }
        sku={ product.Sku }
        sx={ { flex: 1, marginLeft: '15px' } }
      >
        Add To Cart
      </ConfirmationButton>
    </Flex>
  );
};
