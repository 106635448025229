/* eslint-disable max-len */
import React, { useMemo } from 'react';
import { Flex } from '@lce/slice_v2';

import Avatar from './Avatar/Avatar';

import * as FundraiserDefaults from 'constants/FundraiserDefaults';
import { UserInfo } from 'features/common/types/userInfo';
import { FundraiserNavigation } from 'ui/fundraiser/FundraiserNavigation';
import AdminNavigation from 'ui/admin/AdminNavigation';

export interface ISidebarProps {
  pathname: string | undefined;
  userInfo: UserInfo | undefined;
}

const Sidebar: React.FC<ISidebarProps> = ({ pathname, userInfo }) => {
  const fundraiserPathname = pathname?.split('dashboard/fundraisers')[1];
  const fundraiserPage = !(fundraiserPathname === '' || fundraiserPathname === '/' || fundraiserPathname === undefined);
  const adminPage = pathname?.includes('/admin');
  const matches = pathname?.match(/\d+/g);
  const customerId = matches ? matches[1] : '';
  const displayRole = useMemo(() => {
    if (userInfo) {
      if (userInfo.Roles.includes(FundraiserDefaults.SuperAdminRoleName)) {
        return 'Super Admin';
      } else if (userInfo.Roles.includes(FundraiserDefaults.SalesAgentRoleName)) {
        return 'Sales Agent';
      } else if (userInfo.Roles.includes(FundraiserDefaults.ISCRoleName)) {
        return 'ISC';
      } else if (userInfo.Roles.includes(FundraiserDefaults.ChairpersonRoleName)) {
        return 'Chairperson';
      } else if (userInfo.Roles.includes(FundraiserDefaults.SellerRoleName)) {
        return 'Seller';
      } else {
        return '';
      }
    } else {
      return '';
    }
  }, [ userInfo ]);

  return (
    <Flex
      data-testid="sidebar"
      sx={ {
        top: [ 0, 0, '135px', '86px' ],
        bottom: 0,
        left: 0,
        position: 'fixed',
        padding: '30px',
        width: '343px',
        backgroundColor: 'primaryGrayMid',
        flexDirection: 'column',
        overflowY: 'auto',
      } }
    >
      {userInfo && <Avatar displayRole={ displayRole } name={ `${ userInfo.FirstName } ${ userInfo.LastName }` } />}
      {fundraiserPage && <FundraiserNavigation customerId={ customerId } sidebar={ true } />}
      {adminPage && <AdminNavigation />}
    </Flex>
  );
};

export default Sidebar;
