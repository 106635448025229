import {
  Button,
  Flex, Text,
} from '@lce/slice_v2';
import React from 'react';
import { useTranslation } from '@lce/i18n';
import { CheckCircle } from 'react-feather';

import { ICheckAddressConfirmModalProps } from 'features/fundraiser/types/dashboard';

// eslint-disable-next-line max-lines-per-function
const ProfitCheckAddressConfirm: React.FC<ICheckAddressConfirmModalProps> = ({
  address, onEdit, isExpired, isMissingProfitCheckAddress,
}: ICheckAddressConfirmModalProps) => {
  const [ t ] = useTranslation();
  const {
    profitCheckPayableTo, groupAddress, groupCity, groupState, groupZipCode,
  } = address;
  return (
    <Flex data-testid="profit-check-address-confirm" variant="modals.profitCheckAddressModal.formContainer">

      <Text variant="modals.profitCheckAddressModal.title">
        {t('fundraiser.ProfitCheckAddressModal.Title')}
      </Text>

      <Text variant="modals.profitCheckAddressModal.subTitle">
        <CheckCircle color="#28a713" size="16px" strokeWidth="3px" />
        {' '}
        {t('fundraiser.ProfitCheckAddressModal.AddressReceived')}
      </Text>

      <Flex sx={ { flexDirection: 'column', width: [ '100%' ], px: '20px' } }>
        <Text sx={ { fontSize: '19px', textAlign: 'center', fontWeight: 500 } }>
          {t('fundraiser.ProfitCheckAddressModal.AddressReceivedSubtitle')}
        </Text>

        <Flex
          sx={ {
            flexDirection: 'column', mt: '20px', textAlign: 'center', fontSize: '19px', fontWeight: 500,
            mb: [ '8px', '20px' ],
          } }
        >
          <Text>
            {t('fundraiser.ProfitCheckAddressModal.CheckMadePayableTo')}
            {': '}
            <span style={ { fontWeight: 'normal' } }>
              {profitCheckPayableTo}
            </span>
          </Text>
          <Text>
            {t('fundraiser.ProfitCheckAddressModal.Address')}
            {': '}
            <span style={ { fontWeight: 'normal' } }>
              {groupAddress}
            </span>
          </Text>
          <Text>
            {t('fundraiser.ProfitCheckAddressModal.City')}
            {': '}
            <span style={ { fontWeight: 'normal' } }>
              {groupCity}
            </span>
          </Text>
          <Text>
            {t('fundraiser.ProfitCheckAddressModal.State')}
            {': '}
            <span style={ { fontWeight: 'normal' } }>
              {groupState}
            </span>
          </Text>
          <Text>
            {t('fundraiser.ProfitCheckAddressModal.ZipCode')}
            {': '}
            <span style={ { fontWeight: 'normal' } }>
              {groupZipCode}
            </span>
          </Text>
        </Flex>

        {!isExpired && isMissingProfitCheckAddress && (
          <Button
            data-testid="edit-address"
            onClick={ onEdit }
            variant="modals.profitCheckAddressModal.submitButton"
          >
            {t('fundraiser.ProfitCheckAddressModal.EditButtonText')}
          </Button>
        )}
      </Flex>
    </Flex>
  );
};

export default ProfitCheckAddressConfirm;
