import React, { useRef, useState } from 'react';
import { Box, Text } from '@lce/slice_v2';

import { TooltipPopover } from 'ui/fundraiser/Popovers';
import { PopupBorder } from 'ui/fundraiser/DashboardStep/StepPopupContent';
import { useMediaQuery } from 'features/common';

export interface IRelationshipIndicator {
  label: string;
  color: string;
}

export enum FundraiserRelationship {
  CP = 'Chairperson',
  MD = 'Meal Deal',
  S = 'Seller',
  SS = 'Simple Sign Up',
}

const RelationshipIndicator: React.FC<IRelationshipIndicator> = ({ label, color }) => {
  const [ visible, setVisible ] = useState(false);
  const popoverRef = useRef<any>(null);
  const isMobile = useMediaQuery('(max-width: 600px)');

  return (
    <Box
      aria-controls="tooltip_popover"
      data-testid="relationship_indicator"
      onClick={ (e) => {
        e.preventDefault();
        if (isMobile) {
          setVisible(!visible);
        }
      } }
      onMouseEnter={ () => !isMobile && setVisible(true) }
      onMouseLeave={ () => !isMobile && setVisible(false) }
      ref={ popoverRef }
      sx={ {
        backgroundColor: color,
        width: '40px',
        height: '24px',
        borderRadius: '15px',
        mt: '16px',
        textAlign: 'center',
        cursor: 'pointer',
        mr: '5px',
      } }
    >
      <Text data-testid="relationship_indicator-label" sx={ { color: 'white' } }>
        {label}
      </Text>
      <TooltipPopover
        id="tooltip_popover"
        referenceElement={ popoverRef }
        setVisible={ setVisible }
        visible={ visible }
      >
        <PopupBorder>
          <Text sx={ { color: 'black', fontWeight: 400 } }>
            { FundraiserRelationship[label] }
          </Text>
        </PopupBorder>
      </TooltipPopover>
    </Box>
  );
};

export default RelationshipIndicator;
