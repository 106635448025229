import React from 'react';
import {
  Modal as SliceModal, Text, Flex, Link,
} from '@lce/slice_v2';
import _noop from 'lodash/noop';
import { X } from 'react-feather';
import { useTranslation } from '@lce/i18n';

export interface IEndedFundraiserModalProps {
  endDate: string;
}
export interface IUpcomingFundraiserModalProps {
  startDate: string;
}

const CancelledFundraiserModal: React.FC = () => {
  const [ t ] = useTranslation();
  return (
    <SliceModal
      closeIcon={ false }
      onClose={ _noop }
      sx={ {
        maxWidth: '700px',
        marginTop: '120px',
        width: [ '90%', '100%', '100%' ],
      } }
    >
      <Flex sx={ { justifyContent: 'flex-end' } }>
        <Link href="/buyer" sx={ { cursor: 'pointer' } }>
          <X size={ 20 } />
        </Link>
      </Flex>
      <Flex
        sx={ {
          flexDirection: 'column',
          padding: [ '15px', '50px 120px' ],
          textAlign: 'center',
        } }
      >
        <Text sx={ { mb: '16px', fontSize: '24px' } } variant="text.header.subUpper">
          {t('fundraiser.NotRunningModal.Header.Cancelled')}
        </Text>
        <Text sx={ { mb: '32px', fontSize: '18px', fontWeight: 'bold' } }>
          {t('fundraiser.NotRunningModal.Subheader')}
        </Text>
        <Link
          href={ process.env.REACT_APP_ONGOING_FUNDRAISER_URL }
          sx={ {
            width: '100%', textDecoration: 'none', mb: '16px', color: 'white',
            display: 'flex', alignItems: 'center', justifyContent: 'center',
          } }
          variant="buttons.primary"
        >
          <Text sx={ { fontSize: '18px', fontWeight: 'bold' } }>
            {t('fundraiser.NotRunningModal.StartShoppingButton')}
          </Text>
        </Link>
        <Link href="/buyer" sx={ { textDecoration: 'none' } } variant="buttons.text">
          <Text sx={ { fontSize: '18px', fontWeight: 'bold' } }>
            {t('fundraiser.NotRunningModal.NoThanksButton')}
          </Text>
        </Link>
      </Flex>
    </SliceModal>
  );
};

const EndedFundraiserModal: React.FC<IEndedFundraiserModalProps> = ({ endDate }) => {
  const [ t ] = useTranslation();
  return (
    <SliceModal
      closeIcon={ false }
      onClose={ _noop }
      sx={ {
        maxWidth: '700px',
        marginTop: '120px',
        width: [ '90%', '100%', '100%' ],
      } }
    >
      <Flex sx={ { justifyContent: 'flex-end' } }>
        <Link href="/buyer" sx={ { cursor: 'pointer' } }>
          <X size={ 20 } />
        </Link>
      </Flex>
      <Flex
        sx={ {
          flexDirection: 'column',
          padding: [ '15px', '50px 120px' ],
          textAlign: 'center',
        } }
      >
        <Text sx={ { mb: '16px', fontSize: '24px' } } variant="text.header.subUpper">
          {t('fundraiser.NotRunningModal.Header.Ended')}
        </Text>
        <Text sx={ { mb: '16px', fontSize: '24px', fontWeight: 'bold' } }>
          {endDate}
        </Text>
        <Text sx={ { mb: '32px', fontSize: '18px', fontWeight: 'bold' } }>
          {t('fundraiser.NotRunningModal.Subheader')}
        </Text>
        <Link
          href={ process.env.REACT_APP_ONGOING_FUNDRAISER_URL }
          sx={ {
            width: '100%', textDecoration: 'none', mb: '16px', color: 'white',
            display: 'flex', alignItems: 'center', justifyContent: 'center',
          } }
          variant="buttons.primary"
        >
          <Text sx={ { fontSize: '18px', fontWeight: 'bold' } }>
            {t('fundraiser.NotRunningModal.StartShoppingButton')}
          </Text>
        </Link>
        <Link href="/buyer" sx={ { textDecoration: 'none' } } variant="buttons.text">
          <Text sx={ { fontSize: '18px', fontWeight: 'bold' } }>
            {t('fundraiser.NotRunningModal.NoThanksButton')}
          </Text>
        </Link>
      </Flex>
    </SliceModal>
  );
};

const UpcomingFundraiserModal: React.FC<IUpcomingFundraiserModalProps> = ({ startDate }) => {
  const [ t ] = useTranslation();
  return (
    <SliceModal
      closeIcon={ false }
      onClose={ _noop }
      sx={ {
        maxWidth: '700px',
        marginTop: '120px',
        width: [ '90%', '100%', '100%' ],
      } }
    >
      <Flex sx={ { justifyContent: 'flex-end' } }>
        <Link href="/buyer" sx={ { cursor: 'pointer' } }>
          <X size={ 20 } />
        </Link>
      </Flex>
      <Flex
        sx={ {
          flexDirection: 'column',
          padding: [ '15px', '50px' ],
          textAlign: 'center',
        } }
      >
        <Text sx={ { mb: '16px', fontSize: '24px' } } variant="text.header.subUpper">
          {t('fundraiser.NotStartedModal.Header')}
        </Text>
        <Text sx={ { mb: '16px', fontSize: '18px', fontWeight: 'bold' } }>
          {t('fundraiser.NotStartedModal.Subheader')}
        </Text>
        <Text sx={ { mb: '32px', fontSize: '24px', fontWeight: 'bold' } }>
          {startDate}
        </Text>
        <Text sx={ { mb: '16px', fontSize: '20px', fontWeight: 'bold' } }>
          {t('fundraiser.NotStartedModal.BottomText')}
        </Text>
      </Flex>
    </SliceModal>
  );
};

export {
  CancelledFundraiserModal,
  EndedFundraiserModal,
  UpcomingFundraiserModal,
};
