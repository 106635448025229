import React from 'react';
import { Box } from '@lce/slice_v2';

import { LandingDisclaimer, LandingText } from '../../components';

import { FundraiserType } from 'features/fundraiser';
import { useStoreQueryParamToLocalStorage } from 'ui/hooks';

export type InformationProps = {
  fundraiserType: FundraiserType;
  page: string;
  testId?: string;
}

export const dataTestIdInformation = 'information-component';

export const Information: React.FC<InformationProps> = ({
  fundraiserType,
  page,
  testId = dataTestIdInformation,
}) => {
  useStoreQueryParamToLocalStorage('referralsource', 'referralSource');
  const translationPath = `landing.${ fundraiserType }.${ page }`;

  return (
    <Box data-testid={ testId } sx={ { textAlign: 'center' } }>
      <LandingText translationPath={ translationPath } />
      <LandingDisclaimer translationPath={ translationPath } />
    </Box>
  );
};
