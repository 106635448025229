import { useMutation, useQueryClient } from 'react-query';

import { ChairpersonInfo } from '../types';

import { ApiError } from 'features/common';
import * as QueryKeys from 'constants/QueryKeys';
import api from 'api';

export type RemoveChairpersonRequest = {
  fundraiserId: number;
  chairperson: ChairpersonInfo;
}

export const removePrimaryChairperson =
  // eslint-disable-next-line max-len
  async(request: RemoveChairpersonRequest) => api.put<boolean>(`api/fundraiser/${ request.fundraiserId }/removechairperson`,
    request.chairperson).then(res => res);

export const useRemovePrimaryChairperson =
  (fundraiserId: number) => {
    const queryClient = useQueryClient();

    return useMutation<boolean, ApiError, RemoveChairpersonRequest>(removePrimaryChairperson,
      {
        onSuccess: () => {
          queryClient.invalidateQueries(`${ QueryKeys.FundraiserFormData }-${ fundraiserId }`);
        },
      });
  };

