import React from 'react';
import {
  Box, Text, Flex, Link,
} from '@lce/slice_v2';
import { useTranslation } from '@lce/i18n';

const MoreInfo: React.FC = () => {
  const [ t ] = useTranslation();
  return (
    <Flex
      data-testid="more-info"
      sx={ {
        width: '100%',
        p: [ '32px 16px', '32px' ],
        justifyContent: 'center',
      } }
    >
      <Box
        sx={ {
          display: 'grid',
          gap: '16px',
          gridTemplateAreas: [ '"title" "description" "button" "phone"', '"title button" "description phone"' ],
          maxWidth: '1216px',
          textAlign: [ 'center', 'left' ],
          width: '100%',
        } }
      >
        <Text sx={ { gridArea: 'title', minWidth: [ 'none', '360px' ] } } variant="home.subheading">
          {t('home.moreInfo.title')}
        </Text>
        <Text sx={ { gridArea: 'description', minWidth: [ 'none', '360px' ] } } variant="home.subdescription">
          {t('home.moreInfo.description')}
        </Text>
        <Link
          href={ t('home.moreInfo.link.url') }
          sx={ {
            alignSelf: 'end',
            gridArea: 'button',
            justifySelf: 'center',
            minWidth: '173px',
          } }
          variant="primaryButtonLink"
        >
          { t('home.moreInfo.link.text') }
        </Link>
        <Link
          href={ t('home.moreInfo.phoneNumberLink.url') }
          sx={ {
            color: 'black',
            gridArea: 'phone',
            justifySelf: 'center',
          } }
          variant="primaryTextUppercase"
        >
          { t('home.moreInfo.phoneNumberLink.number') }
        </Link>
      </Box>
    </Flex>
  );
};

export default MoreInfo;
