import {
  Flex, Link, Image,
} from '@lce/slice_v2';
import React, { useState } from 'react';
import { useTranslation } from '@lce/i18n';

import { useMediaQuery } from 'features/common';
import ZoomInIcon from 'assets/icons/LCF-Zoom-In-Icon.svg';
import { ZoomInModal } from 'ui/common/ZoomInModal';

export interface IFundraiserQRCode {
  imageDataURL: string;
  imageAltText: string;
  sellerQRCode?: boolean;
  isDisplayModalOpenDefault?: boolean;
}

const FundraiserQRCode: React.FC<IFundraiserQRCode> = ({
  imageDataURL, imageAltText, sellerQRCode, isDisplayModalOpenDefault = false,
}) => {
  const [ isDisplayModalOpen, setIsDisplayModalOpen ] = useState<boolean>(isDisplayModalOpenDefault);
  const isDesktop = useMediaQuery('(min-width: 961px)');
  const [ t ] = useTranslation();

  return (
    <>
      <Flex
        data-testid={ sellerQRCode ? 'sellerqrcode' : 'fundraiserqrcode' }
        sx={ { justifyContent: [ 'center', 'center', 'initial' ], height: '104px' } }
      >
        <Image
          alt={ imageAltText }
          data-testid={ `${ sellerQRCode ? 'sellerqrcode' : 'fundraiserqrcode' }-image` }
          src={ imageDataURL }
          sx={ { height: '104px', maxWidth: '104px', objectFit: 'contain' } }
        />
        {isDesktop && (
          <Link
            data-testid="zoom-in-icon"
            onClick={ () => setIsDisplayModalOpen(true) }
            sx={ { alignSelf: 'flex-end', '&:hover': { cursor: 'pointer' } } }
          >
            <Image
              alt="zoom-in-icon" src={ ZoomInIcon } sx={ {
                height: '20px', width: '24px', ml: -1, mb: 1,
              } }
            />
          </Link>
        )}
      </Flex>

      { isDesktop && (
        <ZoomInModal
          data-testid="fundraiserQRCode__zoominmodal"
          imageAltText={ imageAltText }
          imageURL={ imageDataURL }
          isOpen={ isDisplayModalOpen }
          modalText={ t('fundraiser.DownloadImage') }
          onClose={ () => setIsDisplayModalOpen(false) }
        />
      )}
    </>
  );
};

export default FundraiserQRCode;
