// eslint-disable-next-line max-lines-per-function
export const paymentReducer = (state, action) => {
  switch (action.type) {
    case 'SET_TOKEN':
      return {
        ...state,
        code: action.code,
        token: action.token,
        popup: false,
      };

    case 'SET_ERROR_CODE':
      return {
        ...state,
        code: action.code,
        token: action.token,
        popup: action.popup,
      };
    case 'UNSET_ERROR_CODE':
      return {
        ...state,
        code: action.code,
        token: action.token,
        popup: action.popup,
      };
    case 'SET_PAYMENT_STEP':
      return {
        ...state,
        showPaymentStep: true,
      };
    case 'UNSET_PAYMENT_STEP':
      return {
        ...state,
        showPaymentStep: false,
      };
    case 'SET_REVIEW_STEP':
      return {
        ...state,
        showReviewStep: true,
      };
    case 'UNSET_REVIEW_STEP':
      return {
        ...state,
        showReviewStep: false,
      };
    case 'SET_POPUP':
      return {
        ...state,
        popup: true,
      };
    case 'UNSET_POPUP':
      return {
        ...state,
        popup: false,
      };
    case 'SET_INLINE_ERROR':
      return {
        ...state,
        inlineError: true,
      };
    case 'UNSET_INLINE_ERROR':
      return {
        ...state,
        inlineError: false,
      };
    case 'SET_CARD':
      return {
        ...state,
        card: {
          cvvNum: action.card.cvvNum,
          expiryMonth: action.card.expiryMonth,
          expiryYear: action.card.expiryYear,
        },
      };
    case 'UNSET_CARD':
      return {
        ...state,
        card: {
          cvvNum: '',
          expiryMonth: '',
          expiryYear: '',
        },
      };
    default:
      return state;
  }
};
