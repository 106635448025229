import React from 'react';
import { Flex, Link, Text } from '@lce/slice_v2';
import _map from 'lodash/map';
import { ThemeUIStyleObject } from 'theme-ui';
import { useTranslation } from '@lce/i18n';

interface IEmailLinks {
  title: string;
  subject: string;
  body: string;
  largeFont?: boolean;
  sx?: ThemeUIStyleObject;
  idSuffix?: string;
}

interface IEmailProviderProps {
  emailClient: string;
  srcURL: string;
  subjectParam: string;
  bodyParam: string;
}

export const EmailLinks: React.FC<IEmailLinks> = ({
  title, subject, body, largeFont, sx, idSuffix = '',
}) => {
  const [ t ] = useTranslation();
  const emailProviders: IEmailProviderProps[] = t('emailProviders', { returnObjects: true });
  return (
    <Flex
      sx={ {
        ...sx,
        columnGap: '24px',
        rowGap: '4px',
        flexWrap: 'wrap',
      } }
    >
      <Text sx={ { fontSize: largeFont ? [ '12px', '16px' ] : '12px', whiteSpace: 'nowrap' } }>
        {title}
      </Text>
      <Flex sx={ { columnGap: '24px' } }>
        {_map(emailProviders, (emailProvider, index) => (
          <Link
            href={ `${ emailProvider.srcURL }${ emailProvider.subjectParam }${ subject }
          ${ emailProvider.bodyParam }${ body }` }
            id={ `click-email-${ emailProvider.emailClient }${ idSuffix }` }
            key={ index }
            rel="noreferrer"
            sx={ { fontSize: largeFont ? [ '14px', '18px' ] : '14px', textDecoration: 'none' } }
            target="_blank"
            variant="links.emailLink"
          >
            { emailProvider.emailClient }
          </Link>
        ))}
      </Flex>
    </Flex>

  );
};
