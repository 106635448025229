import * as React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';

/**
 * HOC to allow only non-authenticated users access
 * @param component The component to be rendered
 */
const GuestRoute = ({ component: Component, ...attrs }) => {
  const { authState } = useOktaAuth();

  if (!authState || authState.isPending) {
    return (<div />);
  }

  return (
    <Route
      // eslint-disable-next-line no-extra-parens
      { ...attrs } render={ props => (!authState.isAuthenticated
        ? <Component { ...props } />
        : <Redirect to={ { pathname: '/', state: { from: props.location } } } />) }
    />
  );
};

export default GuestRoute;
