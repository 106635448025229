import { useQuery } from 'react-query';

import { FundraiserListItem, FundraiserSearchOptions } from '../types/fundraiser';
import { fetchFundraisers } from '../api';

import { ApiError, PagedList } from 'features/common';

// eslint-disable-next-line max-len
const useFetchPaginatedFundraisers = (options: FundraiserSearchOptions, keyword: string) => useQuery<PagedList<FundraiserListItem>, ApiError>([ 'fundraisers', options, keyword ],
  () => fetchFundraisers({ ...options, keyword }),
  {
    retry: false,
    refetchOnWindowFocus: false,
  });

export { useFetchPaginatedFundraisers };
