/* eslint-disable max-len */
import { TagManagerSettings } from './interface';

export interface ISnippets {
  tags: ({ gtmSettings }: { gtmSettings: TagManagerSettings }) => {
    script: string;
    noScript: string;
    dataLayer: string;
  };
}

const Snippets: ISnippets = {
  tags({ gtmSettings }) {
    if (!gtmSettings) {
      throw new Error('GTM properties are required');
    }

    const script = `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl+ '&gtm_auth=${ gtmSettings.AUTH }&gtm_preview=${ gtmSettings.PREVIEW }&gtm_cookies_win=x';f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','${ gtmSettings.ID }');
    `;

    const noScript = `
      <iframe src="https://www.googletagmanager.com/ns.html?id=${ gtmSettings.ID }&gtm_auth=${ gtmSettings.AUTH }&gtm_preview=${ gtmSettings.PREVIEW }&gtm_cookies_win=x"
      height="0" width="0" style="display:none;visibility:hidden"></iframe>
    `;

    const dataLayer = 'window.dataLayer = window.dataLayer || []';

    return {
      script,
      noScript,
      dataLayer,
    };
  },
};

export default Snippets;
