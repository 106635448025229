import { RouteProps } from 'react-router-dom';
import React from 'react';

import RequestInfoData from 'ui/RequestInfo/RequestInfo';

const RequestInfo: React.FC<RouteProps> = () => (
  <RequestInfoData />
);

export default RequestInfo;
