import React from 'react';
import { ThemeUIStyleObject } from 'theme-ui';
import { Link as SliceLink } from '@lce/slice_v2';

export interface LinkProps {
  children: string;
  href: string;
  sx?: ThemeUIStyleObject;
  testId?: string;
  variant?: string;
}

export const testIdDefault = 'link';

export const Link: React.FC<LinkProps> = ({
  children,
  href,
  testId = testIdDefault,
  sx = {},
  variant = 'primary',
  ...props
}) => (
  <SliceLink
    data-testid={ testId }
    href={ href }
    sx={ sx }
    variant={ variant }
    { ...props }
  >
    {children}
  </SliceLink>
);
