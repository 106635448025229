import { scale } from '../utils';
import { base, primary, secondary } from '../components/buttons';

const { medium, large } = scale;

const linkBase = {
  ...base,
  padding: '8px',
  textDecoration: 'none',
};

const linkPrimary = {
  ...primary,
  color: 'white',
};

const primaryText = {
  color: 'primary',
  fontFamily: 'Lato',
  fontSize: [ '14px', '16px' ],
  fontWeight: 'bold',
  textDecoration: 'none',
};

const primaryButton = {
  alignItems: 'center',
  bg: 'primaryOrange',
  borderRadius: '4px',
  color: 'white',
  cursor: 'pointer',
  display: 'flex',
  fontFamily: 'Lato',
  fontSize: '14px',
  fontWeight: 900,
  height: '36px',
  justifyContent: 'center',
  lineHeight: 1,
  px: 5,
  textDecoration: 'none',
  width: 'auto',
};

const navLink = {
  fontSize: [ '22px', '22px', 'initial' ],
  lineHeight: [ 1.3, 1.3, 1.5 ],
  padding: [ '12px', '12px', '15px 24px' ],
  width: '100%',
  position: 'relative',
  transition: '100ms all ease-in',
  alignItems: 'center',
  ':hover': {
    color: 'primaryOrange',
  },
  '&.active': {
    bg: [ 'white', 'white', 'primaryOrange' ],
    boxShadow: [ 'none', 'none', '0px 0px 5px rgba(0, 0, 0, 0.25)' ],
    borderRadius: '4px',
    color: [ 'primaryOrange', 'primaryOrange', 'white' ],
  },
  '&.active::after': {
    content: '""',
    position: 'absolute',
    height: '50px',
    bg: 'primaryOrange',
    width: [ 0, 0, '5px' ],
    right: '-30px',
    top: 0,
    borderTopLeftRadius: '4px',
    borderBottomLeftRadius: '4px',
  },
};

export default {
  accountOptionLinks: {
    padding: '15px 24px',
    height: '50px',
    width: '100%',
    position: 'relative',
    margin: '6px 0',
    transition: '100ms all ease-in',
    alignItems: 'center',
  },
  categorySelectorLink: {
    ...navLink,
  },
  dashboardNavLink: {
    ...navLink,
    height: '50px',
    margin: '6px 0',
  },
  dashboardMobileNav: {
    backgroundColor: 'white',
    border: '1px solid #808080',
    borderRadius: '8px',
    justifyContent: 'space-between',
    my: 4,
    mt: '19px',
  },
  emailLink: {
    color: 'primaryOrange',
    '&:hover': {
      cursor: 'pointer',
      textDecoration: 'underline',
      color: 'primaryOrange',
    },
  },
  marketingLabel: {
    color: 'primaryOrange',
    textDecoration: 'none',
    '&:hover': {
      cursor: 'pointer',
      textDecoration: 'underline',
    },
  },
  navLinkMobile: {
    padding: '15px 24px',
    height: '50px',
    width: '100%',
    position: 'relative',
    margin: '6px 0',
    transition: '100ms all ease-in',
    alignItems: 'center',
    ':hover': {
      color: 'primaryOrange',
    },
    '&.active': {
      color: 'primaryOrange',
    },
  },
  navRouterLink: {
    variant: 'links.primary',
    padding: [ '0 12px', '0 24px' ],
    fontSize: '14px',
    textTransform: 'uppercase',
    whiteSpace: 'nowrap',
  },
  primary: {
    fontFamily: 'Lato',
    color: 'black',
    ':hover': {
      color: 'primaryOrange',
    },
    ':focus': {
      color: 'primaryOrange',
    },
    cursor: 'pointer',
  },
  primaryText: primaryText,
  primaryTextUppercase: {
    ...primaryText,
    textTransform: 'uppercase',
  },
  primaryTextUnderline: {
    ...primaryText,
    textDecoration: 'underline',
  },
  toast: {
    variant: 'links.primary',
    fontWeight: 600,
    color: 'primaryOrange',
    ':hover': {
      color: 'black',
    },
  },
  productCardImageWrapperLinkDG: {
    display: 'flex',
    width: '100%',
    height: 'auto',
    minWidth: '132px',
    p: '16px',
    alignItems: 'center',
  },
  productCardImageWrapperLinkMD: {
    display: 'flex',
    width: [ '132px', '100%' ],
    height: [ '132px', 'auto' ],
    minWidth: '132px',
    p: '16px',
    alignItems: 'center',
  },
  primaryButtonLink: {
    ...primaryButton,
    ':hover, :focus': {
      color: 'white',
    },
  },
  primaryOutlineButtonLink: {
    ...primaryButton,
    bg: 'transparent',
    border: '2px solid',
    borderColor: 'primaryOrange',
    color: 'primaryOrange',
  },
  button: {
    primary: {
      ...linkBase,
      ...linkPrimary,
      medium: {
        ...linkBase,
        ...linkPrimary,
        ...medium,
      },
      large: {
        ...linkBase,
        ...linkPrimary,
        ...large,
      },
    },
    secondary: {
      ...linkBase,
      ...secondary,
      medium: {
        ...linkBase,
        ...secondary,
        ...medium,
      },
      large: {
        ...linkBase,
        ...secondary,
        ...large,
      },
    },
  },
};
