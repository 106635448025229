import { FundraiserType } from 'features/fundraiser/types';
import {
  DesktopLogo, DesktopMDLogo, MobileLogo, MobileMDLogo,
} from 'ui/common/Header/Logo/Logo';

export const useChooseLogo = (fundraiserType?: FundraiserType, isMobile?: boolean) => {
  if (fundraiserType === FundraiserType.MealDeal) {
    if (isMobile) {
      return { Logo: MobileMDLogo };
    } else {
      return { Logo: DesktopMDLogo };
    }
  } else {
    if (isMobile) {
      return { Logo: MobileLogo };
    } else {
      return { Logo: DesktopLogo };
    }
  }
};
