import React from 'react';
import { Box, Text } from '@lce/slice_v2';
import { useTranslation } from '@lce/i18n';

import { LandingImage } from '../components';

import { theme } from 'theme';
import { FundraiserType } from 'features/fundraiser';
import { useTranslationKeysAvailable } from 'ui/hooks';

export type LandingHeaderProps = {
  children?: React.ReactNode;
  fundraiserType: FundraiserType;
  page: string;
  testId?: string;
}

export const dataTestIdLandingHeader = 'landing-header-component';

// eslint-disable-next-line max-lines-per-function
export const LandingHeader: React.FC<LandingHeaderProps> = ({
  fundraiserType,
  page,
  testId = dataTestIdLandingHeader,
}) => {
  const [ t ] = useTranslation();
  const translationPath = `landing.${ fundraiserType }.${ page }`;
  const useScriptHeading = t(`${ translationPath }.useScriptHeading`);
  const heading = t(`${ translationPath }.heading`);
  const hasSubHeading = useTranslationKeysAvailable([ `${ translationPath }.subheading` ]);
  const subheading = t(`${ translationPath }.subheading`);
  return (
    <Box data-testid={ testId }>
      <LandingImage page={ page } />
      {useScriptHeading ? (
        <Box as="h1" sx={ { m: '0 auto', mb: [ '8px', '28px' ], maxWidth: 776 } }>
          <Text
            sx={ {
              textAlign: 'center',
              fontSize: [ '26px', '60px' ],
              transform: 'rotate(-3deg)',
              fontFamily: 'lcScript',
              height: [ '30px', '70px' ],
            } }
          >
            {heading}
          </Text>
          <Text
            sx={ {
              maxWidth: 776,
              color: theme.colors.primaryOrange,
              textTransform: 'uppercase',
              textAlign: 'center',
              fontSize: 7,
            } }
            variant="text.header.title.large"
          >
            {subheading}
          </Text>
        </Box>
      ) : (
        <Box sx={ { m: '0 auto', mb: [ '8px', '28px' ], maxWidth: 776 } }>
          <Text
            as="h1"
            sx={ {
              maxWidth: 776,
              color: 'black',
              textTransform: 'uppercase',
              textAlign: 'center',
            } }
            variant="text.header.title.large"
          >
            {heading}
          </Text>
          {hasSubHeading && (
            <Text
              as="h2"
              sx={ {
                color: 'black',
                fontSize: '36px !important',
              } }
              variant="text.header.title.large"
            >
              {subheading}
            </Text>
          )}
        </Box>
      )}
    </Box>
  );
};
