import React from 'react';
import { Box, Collapse } from '@lce/slice_v2';
import { Icon } from 'react-feather';

import Title from './Title';

export interface IAccordianBoxProps {
  title: string | React.ReactNode;
  icon?: Icon;
  titleSx?: {};
  openTitle?: string | React.ReactNode;
  defaultOpen?: boolean;
  openFromTop?: boolean;
}

const AccordianBox: React.FC<IAccordianBoxProps> = ({
  title, openTitle, children, openFromTop = false, defaultOpen = false,
}) => {
  const [ isOpen, setIsOpen ] = React.useState<boolean>(defaultOpen);

  const onToggle = (open: boolean) => {
    setIsOpen(open);
  };

  return (
    <>
      { openFromTop && (
        <Title
          isOpen={ isOpen }
          onOpenCallback={ onToggle }
          openTitle={ openTitle }
          title={ title }
        />
      )}
      <Collapse isOpen={ isOpen }>
        <Box
          sx={ {
            border: '1px solid',
            borderTop: 'none',
            borderRadius: '4px',
            borderColor: 'primaryGrayMid',
          } }
        >
          {children}
        </Box>
      </Collapse>
      { !openFromTop && (
        <Title
          isOpen={ isOpen }
          onOpenCallback={ onToggle }
          openTitle={ openTitle }
          title={ title }
        />
      )}
    </>
  );
};

export default AccordianBox;
