import React from 'react';
import { useTranslation } from '@lce/i18n';
import {
  Box, Image, Text,
} from '@lce/slice_v2';

import MealDeal from './assets/meal-deal.png';

import { useStoreQueryParamToLocalStorage } from 'ui/hooks';
import { theme } from 'theme';
import { ComboLink } from 'ui/components';

interface MealDealLandingPageProps {
  children: React.ReactNode;
}
// eslint-disable-next-line max-lines-per-function
export const MealDealLandingPage: React.FC<MealDealLandingPageProps> = ({ children }) => {
  const [ t ] = useTranslation();
  useStoreQueryParamToLocalStorage('referralsource', 'referralSource');

  return (
    <Box data-testid="md-landing-page" sx={ { textAlign: 'center' } }>
      <Box sx={ { mb: [ 1, '36px' ], mx: '10px', pt: '100px' } }>
        <Image alt="" src={ MealDeal } sx={ { maxHeight: '400px' } } />
      </Box>
      <Box as="h1" sx={ { m: '0 auto', mb: [ '8px', '28px' ], maxWidth: 776 } }>
        <Text
          sx={ {
            fontSize: [ '26px', '60px' ],
            transform: 'rotate(-3deg)',
            fontFamily: 'lcScript',
            height: [ '30px', '70px' ],
          } }
        >
          {t('mealDeal.landing.script')}
        </Text>
        <Text
          sx={ {
            maxWidth: 776,
            color: theme.colors.primaryOrange,
            textTransform: 'uppercase',
            textAlign: 'center',
            fontSize: 7,
          } }
          variant="text.header.title.large"
        >
          {t('mealDeal.landing.title')}
        </Text>
        <Text
          sx={ {
            fontSize: [ '20px', '32px' ], fontWeight: 'bold', fontFamily: theme.fonts.mrEaves,
          } }
        >
          {t('mealDeal.landing.subtitle')}
        </Text>
      </Box>
      <Box sx={ { m: [ '0 0 24px', '20px 0' ] } }>
        <Text
          sx={ {
            color: 'black',
            fontSize: [ '18px', '32px' ],
            maxWidth: 640,
            mx: 'auto',
            mb: '18px',
            px: 4,
          } }
          variant="text.h2"
        >
          {t('mealDeal.landing.description')}
        </Text>
        {children}
        <ComboLink
          primaryHref={ t('mealDeal.landing.buttonPath') }
          primaryText={ t('mealDeal.landing.buttonText') }
          secondaryHref={ t('mealDeal.landing.linkUrl') }
          secondaryText={ t('mealDeal.landing.linkText') }
        />
      </Box>
    </Box>
  );
};
