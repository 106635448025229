/* eslint-disable max-len */
import { useQuery } from 'react-query';

import { fetchFundraiserQRCode } from '../api';

import * as QueryKeys from 'constants/QueryKeys';
import { ApiError } from 'features/common';

const useFetchFundraiserQRCode =
  (fundraiserId: number) => useQuery<string, ApiError>([ `${ QueryKeys.FundraiserQRCode } }` ],
    () => fetchFundraiserQRCode(fundraiserId),
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: fundraiserId > 0,
    });

export { useFetchFundraiserQRCode };
