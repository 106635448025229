import React from 'react';
import { Flex, Skeleton } from '@lce/slice_v2';

const BuyerListSkeleton: React.FC = () => (
  <Flex sx={ { flexDirection: 'column' } }>
    <Skeleton count={ 1 } sx={ { height: '30px', margin: '24px 0px 10px 0px', width: '80px' } } />
    <Flex sx={ { justifyContent: 'space-between' } }>
      <Skeleton count={ 1 } sx={ { height: '36px', margin: '24px 0px', width: '60%' } } />
      <Skeleton count={ 1 } sx={ { height: '36px', margin: '24px 0px', width: '35%' } } />
    </Flex>
    <Skeleton count={ 1 } sx={ { height: '80px', margin: '10px 0px' } } />
    <Skeleton count={ 1 } sx={ { height: '80px', margin: '10px 0px' } } />
    <Skeleton count={ 1 } sx={ { height: '80px', margin: '10px 0px' } } />
  </Flex>
);

export default BuyerListSkeleton;
