import { useQuery } from 'react-query';

import api from 'api';
import * as QueryKeys from 'constants/QueryKeys';

/** API Call */
const fetchTerms = () => api.get('api/content/campaign-policy').then(res => res);

/** Hook */
const useFetchCampaignPolicy = () => useQuery(QueryKeys.CampaignPolicy, fetchTerms,
  {
    retry: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });

export { useFetchCampaignPolicy };
