import React, { useState } from 'react';
import { useTranslation } from '@lce/i18n';
import {
  Box, Button, Form, Text,
} from '@lce/slice_v2';

import { ProfitPerKit } from '../../../../../../constants';

import { theme } from 'theme';
import { MoneyInput } from 'ui/fundraiser/Forms/MoneyInput';
import { useLocalStorage } from 'features/common';

export const RouteTwo: React.FC = () => {
  const [ t ] = useTranslation();
  const [ goal, setGoal ] = useState<number>(300);
  const [ codes, setCodes ] = useState<string>('50');
  const [ , setFundraiserGoal ] = useLocalStorage<string>('fundraiserGoal');

  const handleClick = (data) => {
    const formGoal = data.Calculate ? parseInt(data.Calculate) : goal;
    setGoal(formGoal);
    const calculatedGoal = Math.ceil(formGoal / ProfitPerKit).toLocaleString();
    setCodes(calculatedGoal);
    setFundraiserGoal(formGoal);
  };

  return (
    <Box
      data-testid="route-two"
      sx={ { background: '#f7f2ec', p: '16px 16px 24px', mb: '40px' } }
    >
      <Text
        sx={ {
          fontSize: [ '20px', '32px' ], fontWeight: 'bold', fontFamily: theme.fonts.mrEaves, mb: 3,
        } }
      >
        {t('mealDeal.landing.howMuchToRaise')}
      </Text>
      <Form onSubmit={ handleClick }>
        <MoneyInput
          boxSx={ { position: 'relative', m: '0 auto 12px', width: [ '200px', '364px' ] } }
          name="Calculate"
        />
        <Button
          data-testid="calculate-click"
          onClick={ handleClick } sx={ {
            background: 'none', border: 'none', color: theme.colors.primaryOrange,
            fontWeight: 900, cursor: 'pointer', fontSize: [ '14px', '28px' ],
          } }
        >
          {t('mealDeal.landing.calculate')}
        </Button>
      </Form>
      <Text
        sx={ {
          fontSize: [ '18px', '32px' ], fontFamily: theme.fonts.body,
          lineHeight: 1.35, mt: 4, mx: 'auto', maxWidth: [ '250px', '460px' ],
        } }
      >
        {`${ t('mealDeal.landing.raisingGoalIsEasy', { goal: goal.toLocaleString(), codes }) }`}
      </Text>
    </Box>
  );
};
