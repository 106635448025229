import React from 'react';
import {
  Box, Text,
} from '@lce/slice_v2';
import { useTranslation } from '@lce/i18n';

export interface ILoginBoxProps {
  children: React.ReactNode;
}

export const LoginBox: React.FC<ILoginBoxProps> = ({ children }) => {
  const [ t ] = useTranslation();

  return (
    <Box>
      <Text variant="text.header.sub">
        {t('login.ExistingUser')}
      </Text>
      <Text variant="text.login.message">
        {t('login.ExistingUserMessage')}
      </Text>
      {children}
    </Box>
  );
};
