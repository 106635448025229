import * as Yup from 'yup';
import { useTranslation } from '@lce/i18n';
import { useHistory } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import {
  Form,
  Flex,
  Box,
  Button,
  Heading,
  Spinner,
  Text,
} from '@lce/slice_v2';
import { toast } from 'react-toastify';

import { RouterLink, GenericToast } from 'ui/common';
import { Address, useQueryParam } from 'features/common';
import {
  phonePattern, PhoneInput,
  useFirstNameInput,
} from 'ui/components';
import { CreateAccountRequest, useCreateAccount, useFetchFundraiserInvite } from 'features/account';

export interface ICreateMealDealAccountFormProps {
  guestId?: string;
  address?: Address;
  isConfirm: boolean;
  onSuccess?: () => void;
  onBackCallback?: () => void;
}

// eslint-disable-next-line max-lines-per-function
const CreateMealDealAccountForm: React.FC<ICreateMealDealAccountFormProps> = ({
  guestId, address, isConfirm, onSuccess, onBackCallback,
}) => {
  const [ t ] = useTranslation();
  const history = useHistory();
  const [ fundraiserGuid ] = useQueryParam('inviteId');
  const [ , setInviteUserRegistered ] = useState<boolean>(false);

  const {
    mutateAsync: submitAccount, error, isLoading, isError,
  } = useCreateAccount();
  const { data: fetchFundraiserInvite } = useFetchFundraiserInvite(fundraiserGuid);

  const mealDealSignInUrl = '/start-a-fundraiser/meal-deal/signin';

  useEffect(() => {
    if (fetchFundraiserInvite?.IsAlreadyRegistered) {
      setInviteUserRegistered(true);
      history.push(mealDealSignInUrl);
    }
  }, [ fetchFundraiserInvite, history ]);

  const { FirstNameInput, firstNameInputSchema } = useFirstNameInput({
    name: 'firstName',
    label: 'First Name ', //Leave space after Name
  });

  const validationSchema = Yup.object().shape({
    ...firstNameInputSchema,
    lastName: Yup.string().required(t('createAccount.LastNameRequired')),
    email: Yup.string()
      .email(t('createAccount.EmailValid'))
      .required(t('createAccount.EmailRequired')),
    password: Yup.string()
      .required(t('createAccount.PasswordRequired'))
      .matches(/^(?=\D*\d)[^ ]{6,}$/, t('createAccount.PasswordMin'))
      .min(6, t('createAccount.PasswordMin')),
    phone: Yup.string()
      .required(`${ t('createAccount.PhoneRequired') }`)
      .matches(phonePattern,
        t('createAccount.PhoneNumberValid'))
      .min(10, t('fundraiser.PhoneNumberMinLength'))
      .max(17, t('fundraiser.PhoneNumberLength')),
  });

  const handleOnSubmit = async(e) => {
    const formData: CreateAccountRequest = {
      firstName: e.firstName,
      lastName: e.lastName,
      phone: e.phone,
      email: e.email,
      password: e.password,
      confirmTerms: e.confirmTerms,
      securityQuestion: e.securityQuestion,
      securityQuestionAnswer: e.securityQuestionAnswer,
      optInMarketing: e.optInMarketing,
      guestId: guestId,
    };

    try {
      const data = await submitAccount(formData);

      if (data) {
        if (onSuccess) {
          onSuccess();
        } else {
          history.push(mealDealSignInUrl);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (isError && error) {
      toast(<GenericToast text={ `Uh oh! ${ error.Message }` } />);
      if (error.ErrorCode === 'ERR-PK-UR-02') {
        history.push(mealDealSignInUrl);
      }
    }
  }, [ isError, error, history ]);

  return (
    <Box data-testid="create-mealdeal-account-form" margin="0 auto" p={ [ 5, 40 ] } sx={ { maxWidth: '980px' } }>
      <Heading as="h2">
        {t('createAccount.MealDealTitle')}
      </Heading>

      { !isConfirm
        ? (
          <Form
            initialValues={ {
              firstName: address?.FirstName || '',
              lastName: address?.LastName || '',
              email: address?.Email || '',
              phone: '',
              password: '',
              confirmTerms: true,
            } }
            onSubmit={ (data) => {
              handleOnSubmit(data);
            } }
            reValidateMode="onSubmit"
            summary={ false }
            validationSchema={ validationSchema }
          >
            <Flex sx={ { flexDirection: [ 'column', 'column', 'row' ] } }>
              <Box
                sx={ {
                  mr: [ 0, 8 ],
                  width: '100%',
                } }
              >
                <FirstNameInput />
              </Box>

              <Box
                sx={ {
                  width: '100%',
                } }
              >
                <Form.Field
                  id="lastName"
                  label="Last Name *"
                  name="lastName"
                />
              </Box>
            </Flex>

            <Flex sx={ { flexDirection: [ 'column', 'column', 'row' ] } }>
              <Box
                sx={ {
                  mr: [ 0, 8 ],
                  width: '100%',
                } }
              >
                <Form.Field
                  id="email"
                  label="Email *"
                  name="email"
                  type="email"
                />
              </Box>

              <Box
                sx={ {
                  width: '100%',
                } }
              >
                <PhoneInput label="Phone *" name="phone" />

              </Box>
            </Flex>

            <Box
              sx={ {
                width: [ '100%', '100%', 'calc(50% - 16px)' ],
              } }
            >
              <Form.Field
                component={ Form.Input.Password }
                id="password"
                label="Password *"
                name="password"
              />
            </Box>

            <Box mb={ 4 }>
              <Form.Field
                component={ Form.Input.Checkbox }
                id="optInMarketing"
                inline={ true }
                label={ t('createAccount.MarketingOptIn') }
                name="optInMarketing"
              />
            </Box>

            { isError && error ? (
              <Box color="red">
                { error.Message }
              </Box>
            ) : null }

            <Flex
              sx={ {
                flexDirection: 'row',
                justifyContent: 'flex-end',
                mt: '30px',
                width: [ '100%', '100%', '100%' ],
                ml: 'auto',
              } }
            >
              { onBackCallback ? (
                <Button
                  onClick={ onBackCallback }
                  sx={ { width: [ '100%', '50%' ] } }
                  type="button" variant="secondary"
                >
                  {t('createAccount.CancelButton')}
                </Button>
              ) : null}

              <Flex
                sx={ {
                  flexDirection: 'column', width: [ '100%', '50%' ], justifyContent: 'flex-end', ml: [ 0, 8 ],
                } }
              >
                <Button
                  data-testid="create-account-button"
                  disabled={ isLoading }
                  id="click-create-account"
                  sx={ { width: '100%' } }
                  type="submit"
                  variant={ isLoading ? 'disabled' : 'primary' }
                >
                  { isLoading ? (
                    <Spinner sx={ { height: '100%' } } variant="lce" />
                  ) : t('common.CreateAccount') }
                </Button>

                <Text
                  sx={ {
                    textAlign: 'right', mt: '8px', fontSize: '12px', color: 'primaryGrayDark',
                  } }
                >
                  {t('createAccount.createAccountPolicies.lineOne')}
                  {' '}

                  <RouterLink
                    sx={ { color: 'primaryOrange' } }
                    target="_blank"
                    to="/legal/terms-of-service"
                  >
                    {t('createAccount.createAccountPolicies.termsOfUse')}
                  </RouterLink>

                  {' '}
                  {t('createAccount.createAccountPolicies.lineTwo')}

                  {' '}

                  <RouterLink
                    as="a"
                    href="https://littlecaesars.com/en-us/legal/privacy-policy"
                    rel="noopener noreferrer"
                    sx={ { color: 'primaryOrange' } }
                    target="_blank"
                  >
                    {t('createAccount.createAccountPolicies.privacyPolicy')}
                  </RouterLink>
                </Text>
              </Flex>
            </Flex>
          </Form>
        ) : <div /> }

      { isConfirm
        ? (
          <div>
            <h2>
              {t('createAccount.AccountCreatedConfirmation')}
            </h2>

            <RouterLink to={ mealDealSignInUrl }>Login</RouterLink>
          </div>
        )
        : <div /> }
    </Box>
  );
};

export default CreateMealDealAccountForm;
