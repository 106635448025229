import React, { useEffect, useReducer } from 'react';

import { cartReducer, calculateSubtotal, getTotalQuantity } from './cartReducer';
import {
  CartDispatchContext, CartState, CartStateContext, CustomerData,
} from './cartContext';

import { Cart } from 'features/cart/types';
import { useLocalStorage } from 'features/common';
import { appInsights } from 'features/analytics/ApplicationInsights';

type CartProviderProps = { children: React.ReactNode };

export const CartStateProvider = ({ children }: CartProviderProps) => {
  const [ cart, setCart ] = useLocalStorage<Cart>('cart');
  const [ customerData, setCustomerData ] = useLocalStorage<CustomerData>('fundraiser/buyer');

  const initialState: CartState = {
    cart: cart || { items: [] },
    subtotal: calculateSubtotal(cart),
    totalQuantity: getTotalQuantity(cart),
    sellerId: customerData?.sellerId || '',
    guestId: customerData?.guestId || undefined,
    fundraiserId: customerData?.fundraiserId || 0,
    canStartShopping: customerData?.canStartShopping || false,
  };

  const [ state, dispatch ] = useReducer(cartReducer, initialState);

  useEffect(() => {
    setCart(state.cart);

    const data: CustomerData = {
      sellerId: state.sellerId,
      guestId: state.guestId || '',
      fundraiserId: state.fundraiserId,
      canStartShopping: state.canStartShopping,
    };

    setCustomerData(data);
    if (data.guestId) {
      appInsights.clearAuthenticatedUserContext();
      appInsights.setAuthenticatedUserContext(data.guestId);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ state ]);

  return (
    <CartStateContext.Provider value={ state }>
      <CartDispatchContext.Provider value={ dispatch }>
        {children}
      </CartDispatchContext.Provider>
    </CartStateContext.Provider>
  );
};
