/* eslint-disable max-len */
import { useQuery } from 'react-query';

import { BuyerFilter, BuyerList } from '../types/buyer';

import * as QueryKeys from 'constants/QueryKeys';
import { ApiError } from 'features/common';
import api from 'api';

const fetchBuyers = async(fundraiserId: number, customerId: number, options: BuyerFilter) => api.get<BuyerList>(`api/order/buyer-list/${ fundraiserId }/${ customerId }`, options);

export const useFetchPaginatedBuyers =
  (fundraiserId: number, customerId: number, options: BuyerFilter) => useQuery<BuyerList, ApiError>([ QueryKeys.Buyers, customerId, options ], () => fetchBuyers(fundraiserId, customerId, options), {
    retry: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });
