import { useTranslation } from '@lce/i18n';
import {
  Box, Flex, Image, Text,
} from '@lce/slice_v2';
import { format } from 'date-fns';
import React from 'react';

import { FundraiserListItem } from 'features/fundraiser';
import { useMediaQuery } from 'features/common';
import { theme } from 'theme';

export interface IJoinFundraiserInfoCard {
  fundraiser: FundraiserListItem;
}

// eslint-disable-next-line max-lines-per-function
const JoinFundraiserInfoCard: React.FC<IJoinFundraiserInfoCard> = ({ fundraiser }) => {
  const [ t ] = useTranslation();
  const allowClick = !useMediaQuery(`(min-width: ${ theme.breakpoints[1] } )`);

  return (
    <Flex
      sx={ {
        flexDirection: 'column',
        mr: [ 0, 0, '70px' ],
        border: [ 'none', 'none', '1px solid' ],
        borderColor: [ 'none', 'none', 'primaryGrayLight' ],
        borderRadius: '4px',
        py: [ 0, 0, '40px' ],
      } }
      variant="cards.fundraiserJoin"
    >
      <Box>
        <Text
          sx={ { display: [ 'block', 'block', 'inline' ], fontWeight: 900 } }
          variant="text.h5"
        >
          {t('login.FundraiserJoin.line1')}
        </Text>
        {' '}
        <Text
          sx={ { display: 'inline', fontWeight: 900 } }
          variant="text.h5"
        >
          {t('login.FundraiserJoin.line2')}
        </Text>
        <Text
          data-testid="sign-in"
          onClick={ () => {
            if (allowClick) {
              const login = document.getElementById('login');
              login && login.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
              });
            }
          } }
          sx={ {
            color: [ 'primaryOrange', 'primaryOrange', 'black' ],
            display: 'inline',
            fontWeight: 900,
            textDecoration: 'none',
          } }
          variant="text.h5"
        >
          {t('login.FundraiserJoin.link')}
        </Text>
        .
      </Box>

      { fundraiser.FundraiserImageSource && (
        <Image
          src={ fundraiser.FundraiserImageSource } sx={ {
            width: '100%', objectFit: 'contain', maxHeight: '183px', maxWidth: '311px', mt: [ '20px', '20px', '60px' ],
          } }
        />
      )}

      <Text sx={ { mt: [ '8px', '8px', '20px' ] } } variant="text.header.sub">
        {fundraiser.Name}
      </Text>

      { fundraiser.CustomMessage && (
        <Text
          sx={ { mt: [ '12px', '12px', '40px' ], lineHeight: 1.1 } }
        >
          {fundraiser.CustomMessage}
        </Text>
      ) }

      <Box sx={ { mb: [ 0, 0, '36px' ], mt: [ '12px', '12px', '30px' ] } }>
        <Box>
          <Text variant="fundraiserTracker.miniDetails">
            {`${ t('fundraiserTracker.miniDetails', {
              endDate: format(new Date(fundraiser.EndDate), 'MMMM d yyyy'),
              startDate: format(new Date(fundraiser.StartDate), 'MMMM d yyyy'),
              fundraiserId: fundraiser.Id,
              fundraiserName: fundraiser.Name,
            }) }`}
          </Text>
        </Box>
      </Box>

    </Flex>
  );
};

export default JoinFundraiserInfoCard;
