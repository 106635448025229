import { useQuery } from 'react-query';

import * as QueryKeys from 'constants/QueryKeys';
import { ApiError } from 'features/common';
import { fetchSellerBuyerReport } from 'features/orders/api';

const useFetchBuyerReport =
  // eslint-disable-next-line max-len
  (fundraiserId: number, customerId: number, enabled: boolean) => useQuery<Blob, ApiError>(`${ QueryKeys.SellerBuyerReport }${ customerId }`,
    () => fetchSellerBuyerReport(fundraiserId, customerId), {
      retry: false, refetchOnWindowFocus: false, cacheTime: 0, enabled: enabled,
    });

export { useFetchBuyerReport };
