export default {
  emailNoticationModal: {
    modalContainer: {
      backgroundColor: '#eee',
      top: 0,
      left: 0,
      position: 'absolute',
      width: '100%',
      padding: '8px',
      borderRadius: '4px',
    },
    buttonContainer: {
      cursor: 'pointer',
      mr: [ 'initial', 'initial', '20px' ],
      mt: '16px',
      mb: '16px',
      justifyContent: [ 'space-evenly' ],
    },
    headerLabel: {
      fontFamily: 'mrEaves',
      fontWeight: 'bold',
      fontSize: '20px',
      marginLeft: '28px',
      textTransform: 'uppercase',
    },
  },
  profitCheckAddressModal: {
    modalContainer: {
      maxWidth: '508px',
      borderRadius: '4px',
      width: [ '100%', '100%', '100%' ],
    },
    title: {
      fontSize: '28px',
      fontWeight: 'bold',
      fontFamily: 'mrEaves',
      color: 'primaryOrange',
      mb: '10px',
      textTransform: 'uppercase',
    },
    subTitle: {
      mb: [ '12px', '34px' ],
      px: [ '15px', '50px', '50px' ],
      fontSize: '19px',
      fontWeight: '500',
      lineHeight: '1.3',
      textAlign: 'center',
    },
    formContainer: {
      flexDirection: 'column',
      padding: [ 0, '15px', '10px 40px' ],
      alignItems: 'center',
    },
    inputLabel: {
      fontSize: [ '16px', '20px', '20px' ],
      fontWeight: 'bold',
    },
    submitButton: {
      minWidth: 120,
      fontWeight: 900,
      height: [ '36px', '39px' ],
      fontFamily: 'Lato',
      fontSize: '18px',
      lineHeight: '1',
      borderRadius: '4px',
      cursor: 'pointer',
      border: '1px solid',
      bg: 'primaryOrange',
      my: [ '8px', '20px' ],
    },
  },
};

