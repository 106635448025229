import React from 'react';
import { Container, Flex } from '@lce/slice_v2';
import {
  Route, RouteProps, Switch, useRouteMatch,
} from 'react-router-dom';

import { ContactInfoList, ContactUsForm } from 'ui/contactUs';

const ContactUs: React.FC<RouteProps> = () => {
  const { path } = useRouteMatch();

  return (
    <Container>
      <Flex sx={ { justifyContent: 'space-between', flexDirection: [ 'column', 'column', 'row' ] } }>
        <ContactInfoList />
        <Switch>
          <Route exact={ true } path={ path }>
            <ContactUsForm isConfirm={ false } />
          </Route>
          <Route path={ `${ path }/confirm` }>
            <ContactUsForm isConfirm={ true } />
          </Route>
        </Switch>
      </Flex>
    </Container>
  );
};

export default ContactUs;
