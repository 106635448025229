import { useLocalStorage } from 'features/common';

export interface IUseJoinFundraiser {
  (): {
    joinFundraiserGuid: string;
    setJoinFundraiserGuid: (fundraiserGuid: string) => void;
    removeFundraiserGuid: () => void;
  };
}

const useJoinFundraiser: IUseJoinFundraiser = () => {
  const [ joinFundraiserGuid, setJoinFundraiserGuid ] = useLocalStorage<string>('join-fundraiser-guid');

  const removeFundraiserGuid = () => {
    localStorage.removeItem('@lce/local/join-fundraiser-guid');
  };

  return {
    joinFundraiserGuid,
    setJoinFundraiserGuid,
    removeFundraiserGuid,
  };
};

export { useJoinFundraiser };
