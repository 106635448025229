/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-len */
import React, { useMemo } from 'react';
import {
  Router,
  Route,
  Switch,
  Redirect,
  useHistory,
  useLocation,
} from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { Security, LoginCallback } from '@okta/okta-react';
import 'react-toastify/dist/ReactToastify.css';

import config from './auth/config';
import {
  Header, GuestRoute, MainLayout, DashboardLayout, HomePageLayout, StartAFundraiserLayout, LandingPageLayout, NoLayout,
} from './ui/common';
import { Home } from './pages/home';
import Products from './pages/products';
import CreateAccount from './pages/createAccount';
import { Cart } from './pages/cart';
import { Logout } from './pages/logout';
import { JoinFundraiser, FundraiserLoginManager } from './pages/login';
import * as FundraiserDefaults from './constants/FundraiserDefaults';
import { Checkout } from './pages/checkout';
import ProductDetail from './pages/productDetail';
import { MealDealBuyerRoutes } from './ui/fundraiser/MealDeal';

import { RouteWrapper, PrivateRouteWrapper } from 'ui/routing';
import { ContactUs } from 'pages/contactUs';
import NotFound from 'pages/notFound';
import Dashboard from 'pages/dashboard';
import { FundraiserSupport } from 'pages/fundraiserSupport';
import StartAFundraiser from 'pages/startAFundraiser/startAFundraiser';
import { OrderConfirmation } from 'pages/orderConfirmation';
import Unauthorized from 'pages/Unauthorized';
import { Landing } from 'pages/landing';
import { HowItWorks } from 'pages/howItWorks';
import { AboutUs } from 'pages/aboutUs';
import RequestInfo from 'pages/requestInfo';
import RequestInfoThankYou from 'pages/requestInfoThankYou';
import { Testimonials } from 'pages/testimonials';
import { Articles } from 'pages/articles';
import { TermsOfService } from 'pages/legal/termsOfService';
import { CouponCodeFaqs } from 'pages/coupon-code-faqs';
import { FrequentlyAskedQuestions } from 'pages/faq';
import { CampaignPolicy } from 'pages/legal/campaignPolicy';
import { TermsOfSale } from 'pages/legal/termsOfSale';
import { history } from 'features/common';
import { Chairperson } from 'pages/chairperson';
import { Seller } from 'pages/seller';
import { Buyer } from 'pages/buyer';
import { LandingGroup } from 'pages/landingGroup';
import { FundraiserState } from 'FundraiserState';
import { FundraiserProvider } from 'features/fundraiser/context/fundraiserContext';
import { Admin } from 'pages/admin';
import { Seo, ISeoProps } from 'ui/common/Seo';
import { LandingConfirmation } from 'ui/components';
import { BrochureRoutes } from 'pages/brochure';

const AppRouter = () => (
  <Router history={ history }>
    <FundraiserProvider>
      <Routes />
    </FundraiserProvider>
  </Router>
);

// eslint-disable-next-line max-lines-per-function
const Routes = () => {
  const history = useHistory();
  const { search } = useLocation();
  const onAuthRequiredHandler = () => {
    history.push(`/login?redirectUrl=${ window.location.pathname }`);
    window.location.reload();
  };

  const ongoingFundraiserURL = useMemo(() => {
    const ongoingFundraiser = new URL(process.env.REACT_APP_ONGOING_FUNDRAISER_URL || '');
    return ongoingFundraiser.pathname + search;
  }, [ search ]);

  return (
    <Security
      { ...config.oidc }
      onAuthRequired={ onAuthRequiredHandler }
    >
      <FundraiserState>
        <Header />
        <Switch>
          <RouteWrapper
            component={ Home }
            exact={ true }
            history={ history }
            layout={ HomePageLayout }
            path="/"
          />
          <RouteWrapper
            component={ Products }
            exact={ true }
            history={ history }
            path="/products"
            seo={ {
              pageTitle: 'Products | Little Caesars Fundraising',
              linkTitle: 'Little Caesars Fundraising | Click to support my fundraiser!',
            } }
          />
          <RouteWrapper
            component={ MealDealBuyerRoutes }
            history={ history }
            layout={ NoLayout }
            path="/fundraiser/meal-deal/" //TODO: make meal-deal a param
            seo={ { linkTitle: 'Little Caesars Fundraising | Click to support my fundraiser!' } }
          />
          <RouteWrapper
            component={ BrochureRoutes }
            history={ history }
            layout={ NoLayout }
            path="/brochure/"
            seo={ { linkTitle: 'Little Caesars Fundraising | Click to support my fundraiser!' } }
          />
          <RouteWrapper
            component={ StartAFundraiser }
            history={ history }
            layout={ StartAFundraiserLayout }
            path="/start-a-fundraiser"
            seo={ { noTitle: true } }
          />
          <RouteWrapper
            component={ Buyer }
            history={ history }
            layout={ LandingPageLayout }
            path="/buyer"
            seo={ { pageTitle: 'Buyer | Little Caesars Fundraising' } }
          />
          <RouteWrapper
            component={ Chairperson }
            history={ history }
            layout={ LandingPageLayout }
            path="/chairperson"
            seo={ { pageTitle: 'Chairperson | Little Caesars Fundraising' } }
          />
          <RouteWrapper
            component={ Seller }
            history={ history }
            layout={ LandingPageLayout }
            path="/seller"
            seo={ { pageTitle: 'Seller | Little Caesars Fundraising' } }
          />
          <RouteWrapper
            component={ HowItWorks }
            exact={ true }
            history={ history }
            path="/how-it-works"
            seo={ { pageTitle: 'How It Works | Little Caesars Fundraising' } }
          />
          <RouteWrapper
            component={ RequestInfo }
            history={ history }
            path="/request-info"
            seo={ {
              pageTitle: 'Request Info | Little Caesars Fundraising',
              url: 'https://fundraising.littlecaesars.com/request-info',
            } }
          />
          <RouteWrapper
            component={ AboutUs }
            exact={ true }
            history={ history }
            path="/about-us"
            seo={ { pageTitle: 'About Us | Little Caesars Fundraising' } }
          />
          <RouteWrapper
            component={ ContactUs }
            history={ history }
            path="/contact-us"
            seo={ { pageTitle: 'Contact Us | Little Caesars Fundraising' } }
          />
          <RouteWrapper
            component={ Testimonials }
            exact={ true }
            history={ history }
            path="/testimonials"
            seo={ { pageTitle: 'Testimonials | Little Caesars Fundraising' } }
          />
          <RouteWrapper
            component={ FrequentlyAskedQuestions }
            exact={ true }
            history={ history }
            path="/frequently-asked-questions"
            seo={ { pageTitle: 'FAQs | Little Caesars Fundraising' } }
          />
          <RouteWrapper
            component={ CouponCodeFaqs }
            exact={ true }
            history={ history }
            path="/coupon-code-faqs"
            seo={ { pageTitle: 'Family Meal Deal - Frequently Asked Questions' } }
          />
          <RouteWrapper
            component={ Landing }
            exact={ true }
            history={ history }
            path="/landing/:page"
            seo={ { noTitle: true } }
          />
          <RouteWrapper
            component={ LandingConfirmation }
            exact={ true }
            history={ history }
            path="/landing/:page/confirmation"
            seo={ { pageTitle: 'Thank you! Thank you! | Little Caesars Fundraising' } }
          />
          <RouteWrapper
            component={ LandingGroup }
            exact={ true }
            history={ history }
            path="/group/:parentId/:parentName"
            seo={ { pageTitle: 'Register Now | Little Caesars Fundraising' } }
          />
          <RouteWrapper
            component={ TermsOfService }
            exact={ true }
            history={ history }
            path="/legal/terms-of-service"
            seo={ { pageTitle: 'Terms of Service | Little Caesars Fundraising' } }
          />
          <RouteWrapper
            component={ TermsOfSale }
            exact={ true }
            history={ history }
            path="/legal/terms-of-sale"
            seo={ { pageTitle: 'Terms of Sale | Little Caesars Fundraising' } }
          />
          <RouteWrapper
            component={ CampaignPolicy }
            exact={ true }
            history={ history }
            path="/legal/online-campaign-policy"
            seo={ { pageTitle: 'Online Campaign Policy | Little Caesars Fundraising' } }
          />
          <RouteWrapper
            component={ Articles }
            history={ history }
            path="/articles"
            seo={ { pageTitle: 'Articles | Little Caesars Fundraising' } }
          />
          <Route
            component={ LoginCallback }
            path="/authorization-code/callback"
          />
          <RouteWrapper
            component={ JoinFundraiser }
            exact={ true }
            history={ history }
            path="/login/join-fundraiser/:fundraiserGuid"
            seo={ { linkTitle: 'Little Caesars Fundraising | Click to join as a seller!' } }
          />
          <GuestRouteWrapper
            component={ FundraiserLoginManager }
            exact={ true }
            path="/login"
            seo={ { pageTitle: 'Login | Little Caesars Fundraising' } }
          />
          <GuestRouteWrapper
            component={ CreateAccount }
            path="/create-account"
            seo={ { pageTitle: 'Create Account | Little Caesars Fundraising' } }
          />
          <RouteWrapper
            component={ Cart }
            history={ history }
            path="/cart"
            seo={ { pageTitle: 'Cart | Little Caesars Fundraising' } }
          />
          <RouteWrapper
            component={ RequestInfoThankYou }
            history={ history }
            path="/request-info-confirmation"
            seo={ {
              pageTitle: 'Thank You | Little Caesars Fundraising',
              url: 'https://fundraising.littlecaesars.com/request-info-confirmation',
            } }
          />
          <RouteWrapper
            component={ Checkout }
            history={ history }
            path="/checkout"
            seo={ { pageTitle: 'Checkout | Little Caesars Fundraising' } }
          />
          <RouteWrapper
            component={ OrderConfirmation }
            history={ history }
            layout={ MainLayout }
            path="/order-confirmation"
            seo={ { pageTitle: 'Order Confirmation | Little Caesars Fundraising' } }
          />
          <RouteWrapper
            component={ Logout }
            history={ history }
            path="/logout"
          />
          <RouteWrapper
            component={ ProductDetail }
            history={ history }
            path="/products/:sku"
            seo={ { pageTitle: 'Product Detail | Little Caesars Fundraising' } }
          />
          <RouteWrapper
            component={ FundraiserSupport }
            history={ history }
            path="/fundraisers/support/:supportGuid"
            seo={ { linkTitle: 'Little Caesars Fundraising | Click to support my fundraiser!' } }
          />
          <PrivateRouteWrapper
            component={ Admin }
            layout={ DashboardLayout }
            path="/admin"
            roles={ [ FundraiserDefaults.SuperAdminRoleName ] }
            seo={ { pageTitle: 'Admin | Little Caesars Fundraising' } }
          />
          <PrivateRouteWrapper
            component={ Dashboard }
            layout={ DashboardLayout }
            path="/dashboard"
            roles={ [
              FundraiserDefaults.ChairpersonRoleName,
              FundraiserDefaults.SuperAdminRoleName,
              FundraiserDefaults.SalesAgentRoleName,
              FundraiserDefaults.BuyerRoleName,
            ] }
            seo={ { pageTitle: 'Dashboard | Little Caesars Fundraising' } }
          />
          <RouteWrapper
            component={ NotFound }
            history={ history }
            path="/404"
            seo={ { pageTitle: 'Not Found | Little Caesars Fundraising' } }
          />
          <RouteWrapper
            component={ Unauthorized }
            history={ history }
            path="/403"
            seo={ { pageTitle: 'Unauthorized | Little Caesars Fundraising' } }
          />
          <Route exact={ true } path="/fundraisers/resource-hub/chairperson">
            <Redirect to="/dashboard/fundraisers" />
          </Route>
          <Route exact={ true } path="/fundraisers/resource-hub/seller">
            <Redirect to="/dashboard/fundraisers" />
          </Route>
          <Route path="/BuyNow">
            <Redirect to={ ongoingFundraiserURL } />
          </Route>
          <Redirect to="/404" />
        </Switch>
        <ToastContainer position={ toast.POSITION.TOP_CENTER } />
      </FundraiserState>
    </Security>
  );
};

type GuestWrapperProps = React.PropsWithChildren<{
  component: React.ComponentType<any>;
  layout?: React.ComponentType<any>;
  path: string;
  seo?: ISeoProps;
  exact?: boolean;
}>;

function GuestRouteWrapper({
  component: Component,
  layout: Layout = MainLayout,
  seo,
  ...rest
}: GuestWrapperProps) {
  return (
    <>
      <Seo { ...seo } />
      <Layout>
        <GuestRoute
          component={ Component }
          { ...rest }
        />
      </Layout>
    </>
  );
}

export default AppRouter;
