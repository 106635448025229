/* eslint-disable react/forbid-component-props */
import React, { useState } from 'react';
import { range as _range } from 'lodash';
import numeral from 'numeral';
import {
  Text,
  Image,
  Flex,
  Card,
  Form,
  Button,
} from '@lce/slice_v2';
import { useTranslation } from '@lce/i18n';
import { Trash } from 'react-feather';

import { WarningMessages } from './Warnings';

import { CartItem } from 'features/cart';
import { GoogleEvents } from 'features/analytics';

const MAX_QUANTITY = 99;

export interface ICartItemProps {
  cartItem: CartItem;
  disableChanges?: boolean;
  onQuantityChange?: (item: CartItem, quantity: number) => void;
  variant?: string;
}

// eslint-disable-next-line max-lines-per-function
const CartListItem: React.FC<ICartItemProps> = ({
  cartItem, onQuantityChange, disableChanges, variant = 'cartListItem',
}) => {
  const [ quantity, setQuantity ] = useState(cartItem.Quantity.toString());
  const [ t ] = useTranslation();

  const handleQuantityChange = (quantity: string) => {
    setQuantity(quantity);
    if (onQuantityChange) {
      onQuantityChange(cartItem, parseInt(quantity, 10));
    }
  };

  return (
    <Flex as={ Card } variant={ `cards.${ variant }` }>
      {cartItem.Picture && (
        <Image
          alt={ cartItem.Picture.AltAttribute }
          data-testid="svg_cart"
          src={ cartItem.Picture.VirtualPath }
          sx={ {
            borderRadius: '4px', height: [ '80px', '108px' ], width: [ '80px', '108px' ],
            objectFit: 'scale-down',
            minWidth: [ '80px', '108px' ], minHeight: [ '80px', '108px' ],
          } }
          title={ cartItem.Picture.TitleAttribute }
        />
      )}
      <Flex sx={ { flexDirection: [ 'column', 'row' ], width: '100%' } }>
        <Flex sx={ { flexDirection: 'column', paddingLeft: '28px', flex: 1 } }>
          <Flex sx={ { maxWidth: [ '172px', '200px' ] } }>
            <Text
              sx={ {
                fontSize: '18px', fontWeight: 'bold', fontFamily: 'Roboto', lineHeight: '1.25em',
              } }
              variant="text.cartListItem.name"
            >
              {cartItem.ProductName}
            </Text>
          </Flex>

          <Flex sx={ { flexDirection: 'column', maxWidth: [ '172px', '200px' ] } }>
            <Text
              sx={ {
                display: 'flex', fontFamily: 'Roboto', fontSize: '14px', paddingBottom: '14px', paddingTop: '4px',
              } } variant="text.cartListItem.description"
            >
              {cartItem.ShortDescription}
            </Text>
            <Text
              sx={ { display: 'flex', fontFamily: 'Roboto', fontSize: '14px' } }
              variant="text.cartListItem.description"
            >
              {numeral(cartItem.UnitPrice).format(t('currency.format'))}
              {' '}
              {t('cart.Each')}
            </Text>
          </Flex>

          <WarningMessages warnings={ cartItem.Warnings } />
        </Flex>
        { disableChanges ? (
          <Text
            sx={ {
              display: 'flex', fontFamily: 'Roboto', fontSize: '14px', paddingLeft: '28px',
              paddingBottom: '14px', paddingTop: '4px',
            } } variant="text.cartListItem.description"
          >
            {t('cart.Quantity')}
            {' '}
            {cartItem.Quantity}
          </Text>
        ) : (
          <Flex
            sx={ {
              flexDirection: 'column', justifyContent: 'flex-start', marginTop: [ '12px', '0' ],
              alignItems: 'flex-start', paddingLeft: [ '28px', '0' ],
            } }
          >
            <Text
              sx={ {
                display: [ 'flex', 'contents' ], fontFamily: 'Roboto', fontSize: '18px', paddingBottom: [ '12px', '0' ],
              } } variant="text.cartListItem.price"
            >
              {numeral(cartItem.UnitPrice * cartItem.Quantity).format(t('currency.format'))}
            </Text>
            <Flex sx={ { paddingBottom: [ '10px', '0' ], paddingTop: [ '0', '10px' ] } }>
              <Form.Select
                data-testid={ `${ cartItem.ProductId }-quantity` }
                id={ `${ cartItem.ProductId }-quantity` }
                name={ `${ cartItem.ProductId }-quantity` }
                onChange={ e => handleQuantityChange(e.target.value) }
                sx={ {
                  fontWeight: 'bold', textAlign: 'center', paddingLeft: '8px', paddingBottom: '4px', paddingTop: '4px',
                } }
                value={ quantity }
                variant="select.quantity"
              >
                { _range(1, MAX_QUANTITY + 1).map(value => (
                  <option key={ value } value={ value }>
                    {value}
                  </option>
                )) }
              </Form.Select>

              <Button
                alt="Remove from cart"
                data-testid={ `${ cartItem.ProductId }-remove` }
                onClick={ () => {
                  GoogleEvents.removeFromCart({
                    id: cartItem.ProductId.toString(),
                    name: cartItem.ProductName,
                    price: cartItem.UnitPrice,
                    quantity: parseInt(quantity),
                  });
                  handleQuantityChange('0');
                } }
                sx={ {
                  border: '0', marginLeft: [ '24px', '20px' ], padding: '0px', minWidth: '36px',
                } }
                title="Remove from cart"
                variant="buttons.secondary"
              >
                <Trash size={ 24 } strokeWidth={ 2.5 } />
              </Button>
            </Flex>
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};

export default CartListItem;
