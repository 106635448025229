const SecurityQuestions: string[] = [
  'What is the food you liked least as a child?',
  'What is the name of your first pet?',
  'What is the name of your first stuffed animal?',
  'What did you earn your first medal or award for?',
  'What is the toy/stuffed animal you liked the most as a kid?',
  'What was the first computer game you played?',
  'What is your favorite type of pizza?',
  'What is your favorite movie quote?',
  'What was the mascot of the first sports team you played on?',
  'What is your grandmother\'s favorite dessert?',
  'Who was your favorite teacher?',
  'What is your best friend\'s name?',
];

export default SecurityQuestions;
