/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useRef, useEffect } from 'react';
import _get from 'lodash/get';
import { createPopper } from '@popperjs/core';
import { Box } from '@lce/slice_v2';

interface ITooltipPopoverProps {
  children: React.ReactNode;
  visible: boolean;
  setVisible: (value: boolean) => void;
  referenceElement: any;
  id?: string;
}

export const TooltipPopover: React.FC<ITooltipPopoverProps> = ({
  children, referenceElement, visible, setVisible, id,
}) => {
  const popoverRef = useRef<any>(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        _get(popoverRef, 'current') &&
        _get(referenceElement, 'current') &&
        !popoverRef.current.contains(event.target) &&
        !referenceElement.current.contains(event.target)
      ) {
        setVisible(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ popoverRef, setVisible, referenceElement ]);

  useEffect(() => {
    if (visible && referenceElement.current) {
      createPopper(referenceElement.current, popoverRef.current, {
        placement: 'bottom',
      });
    }
  }, [ visible, referenceElement ]);

  return (
    <Box
      aria-hidden={ !visible }
      data-testid="tooltip_popover"
      id={ id }
      ref={ popoverRef } sx={ {
        zIndex: 500,
        position: 'absolute',
      } }
    >
      { visible ? children : null }
    </Box>
  );
};
