/* eslint-disable react/no-this-in-sfc */
/* eslint-disable no-extra-parens */
/* eslint-disable max-len */
/* eslint-disable max-lines-per-function */
import React, { useState, useMemo, useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import * as Yup from 'yup';
import { useTranslation } from '@lce/i18n';
import {
  Heading, Form, Box, Flex, Button, Spinner,
} from '@lce/slice_v2';

import { defaultState } from './defaultState';
import { IOption } from './IOption';
import DropdownSelect from './DropdownSelect';
import ReportStatus from './ReportStatus';
import DateRange from './DateRange';
import Border from './StylingComponents/Border';
import FilterOptions from './FilterOptions';

import { useFetchFilteredReport } from 'features/fundraiser/hooks/useFetchFilteredReport';
import { IReportFilterOptions } from 'features/fundraiser/types';
import { Translate } from 'features/fundraiser/utils/ReportFiltersUtils';
import { ClearButton } from 'ui/components';

export interface IReportFilterFormData extends IReportFilterOptions {
  statusAll: boolean;
  statusActive: boolean;
  statusCompleted: boolean;
  statusCancelled: boolean;
  statusUpcoming: boolean;
  statesSelector: string;
  salesRepsSelector: string;
  iscsSelector: string;
}

const sectionSpacing = { marginTop: '25px' };

const ReportFilters: React.FC = () => {
  const [ formData, setFormData ] = useState(defaultState);
  const [ showFilters, setShowFilters ] = useState<boolean>(true);
  const { isLoading, mutate: downloadReport, isSuccess } = useFetchFilteredReport();
  const methods = useForm({ context: { formData } });
  const [ t ] = useTranslation();

  const reportTypes: IOption[] = [
    {
      label: t('fundraiserReportFilters.ReportTypes.FundraiserSummaryReport'),
      value: 'fundraiser-summary',
    },
    {
      label: t('fundraiserReportFilters.ReportTypes.OrderDetailReport'),
      value: 'order-detail',
    },
  ]; // TODO : make this a typed key value pair, ANd should ask an API for this

  const validationSchema = useMemo(() => Yup.object({
    rangeStartDate: Yup.date()
      .nullable()
      .transform((curr, orig) => (orig === '' ? null : curr))
      .when('rangeEndDate', {
        is: val => val != null,
        then: schema => schema.max(Yup.ref('rangeEndDate'), t('fundraiserReportFilters.Validation.StartDate.ProperRange')),
      }),
    rangeEndDate: Yup.date().nullable()
      .transform((curr, orig) => (orig === '' ? null : curr)),

    statesSelection: Yup.array(Yup.object({ label: Yup.string(), value: Yup.string() })).nullable()
      .test('states-selection', t('fundraiserReportFilters.Validation.StatesSelection'),
        function(value) {
          if (this.parent.statesSelector === 'specificStates' && (value?.length ?? 0) === 0) {
            return false;
          }
          return true;
        }),
    salesRepsSelection: Yup.array(Yup.object({ label: Yup.string(), value: Yup.string() })).nullable()
      .test('sales-reps-selection', t('fundraiserReportFilters.Validation.SalesRepSelection'),
        function(value) {
          if (this.parent.salesRepsSelector === 'specificSalesReps' && (value?.length ?? 0) === 0) {
            return false;
          }
          return true;
        }),
    iscsSelection: Yup.array(Yup.object({ label: Yup.string(), value: Yup.string() })).nullable()
      .test('iscs-selection', t('fundraiserReportFilters.Validation.ISCSelection'),
        function(value) {
          if (this.parent.iscsSelector === 'specificISCs' && (value?.length ?? 0) === 0) {
            return false;
          }
          return true;
        }),
  }), [ t ]);

  const onFormSubmit = async(_, formValues) => {
    const newFormData = Translate(formValues, defaultState);
    setFormData(newFormData);
    downloadReport(newFormData);
  };

  const handleChange = (type) => {
    if (type === 'order-detail') {
      setShowFilters(false);
    } else {
      setShowFilters(true);
    }
  };

  useEffect(() => {
    if (isSuccess) {
      setShowFilters(true);
    }
  }, [ isSuccess ]);

  if (isLoading) {
    return (
      <Box sx={ { position: 'relative' } }>
        <Spinner
          sx={ {
            mt: '50px', top: '50%', left: '50%', transform: 'translate(-50%)', position: 'absolute',
          } } variant="lce"
        />
      </Box>
    );
  }

  return (
    <Box
      sx={ {
        'input:disabled': {
          color: '#DCDAD1',
          padding: '2px',
          backgroundImage: 'none',
        },
      } }
    >
      <Heading as="h1">
        {t('fundraiserReportFilters.Title')}
      </Heading>
      <FormProvider { ...methods }>
        <Form
          initialValues={ defaultState }
          onSubmit={ methods.handleSubmit(onFormSubmit) }
          reValidateMode="onSubmit"
          summary={ false }
          validationSchema={ validationSchema }
        >
          <fieldset disabled={ methods.formState.isSubmitting } style={ { margin: '0', border: 'none', padding: '0' } }>
            <Border sx={ { mt: [ '2%', '2%', '2%', '20px' ] } }>
              <Box sx={ { p: [ 0 ], width: [ '100%', '100%', '70%', '48.5%' ] } }>
                <DropdownSelect
                  id="reportType"
                  label=""
                  name="reportType"
                  onChange={ handleChange }
                  options={ reportTypes }
                />
              </Box>

              <Box sx={ { mt: [ '32px', '20px' ] } }>
                <Heading as="h4" sx={ { fontFamily: 'roboto', fontWeight: '500' } }>
                  {t('fundraiserReportFilters.Instructions')}
                </Heading>
              </Box>

              { showFilters &&
                <ReportStatus />}

              <Flex
                sx={ {
                  ...sectionSpacing,
                  flexWrap: 'wrap',
                  flexDirection: [ 'column', 'row' ],
                } }
              >
                <DateRange
                  endLabel={ t('fundraiserReportFilters.DateRange.End') }
                  id="range"
                  name="range"
                  startLabel={ t('fundraiserReportFilters.DateRange.Start') }
                />
              </Flex>

              <Box sx={ { width: [ '100%', '100%', '100%', '100%' ], ...sectionSpacing } }>

                {showFilters &&
                  <FilterOptions />}

              </Box>
            </Border>
          </fieldset>
          <Box sx={ { m: [ '10% auto 8% auto', '4% auto 5% auto', '20px auto auto auto' ] } }>
            {' '}
            <ClearButton
              text={ t('fundraiserReportFilters.FormControls.ClearButton') }
            />
            <Button
              sx={ {
                height: '46px',
                width: [ '100%', '100%', '48.5%' ],
                paddingLeft: [ '12px' ],
                m: [ '3.5% auto auto 0%', '3.5%  auto auto 0%', '3.5%  auto auto 0%', ' 0% auto auto 1.5%' ],
              } }
              type="submit"
              variant="primary"
            >
              {t('fundraiserReportFilters.FormControls.DownloadButton')}
            </Button>
          </Box>
        </Form>
      </FormProvider>
    </Box>
  );
};

export default ReportFilters;
