import React from 'react';
import { Redirect, RouteComponentProps } from 'react-router-dom';

import { LandingGroupContent } from 'ui/landingGroup/LandingGroupContent';
import { useFetchValidParentGroup } from 'features/fundraiser';

interface MatchParams {
  parentId: string;
  parentName: string;
}

const LandingGroup: React.FC<RouteComponentProps<MatchParams>> = ({ match: { params: { parentId, parentName } } }) => {
  const isValid = useFetchValidParentGroup(parentId, parentName);

  return isValid ? (
    <LandingGroupContent parentId={ parentId } />
  ) : <Redirect to="/404" />;
};

export default LandingGroup;
