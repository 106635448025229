import _filter from 'lodash/filter';

import { CartItem } from 'features/cart';

export const areAllFreeShippingItemsDonationItems = (cart: CartItem[] | undefined) => {
  const freeShippingItemsCount = _filter(cart, item => item.IsFreeShipping).length;
  const donationItemsCount = _filter(cart, item => item.IsDonation === true).length;
  const hasOnlyDonationItems = donationItemsCount === freeShippingItemsCount;

  return hasOnlyDonationItems;
};
