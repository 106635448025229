import * as FundraiserDefaults from 'constants/FundraiserDefaults';
import { FundraiserState } from 'features/fundraiser/types/fundraiser';

export const setFundraiserState = (roles) => {
  const superAdmin = roles.includes(FundraiserDefaults.SuperAdminRoleName);
  const seller = roles.includes(FundraiserDefaults.SellerRoleName);
  const chairperson = roles.includes(FundraiserDefaults.ChairpersonRoleName);
  const salesAgent = roles.includes(FundraiserDefaults.SalesAgentRoleName);

  if (superAdmin || salesAgent) {
    return FundraiserState.Active;
  } else if (seller || chairperson) {
    return FundraiserState.ActiveUpcoming;
  } else {
    return FundraiserState.Active;
  }
};
