import React from 'react';
import { SecureRoute } from '@okta/okta-react';

export const AuthenticatedRoute = ({
  component: Component,
  layout: Layout,
  ...rest
}) => (
  <SecureRoute
    { ...rest }
    render={ props => (
      <Layout>
        <Component
          { ...props }
        />
      </Layout>
    ) }
  />
);
