import React from 'react';
import { Box, Text } from '@lce/slice_v2';
import { useTranslation } from '@lce/i18n';
import _map from 'lodash/map';

export type LandingTextProps = {
  children?: React.ReactNode;
  translationPath: string;
  testId?: string;
}

export const dataTestIdLandingText = 'landing-text-component';

export const LandingText: React.FC<LandingTextProps> = ({
  translationPath,
  testId = dataTestIdLandingText,
}) => {
  type TText = {
    text: string;
  }
  const [ t ] = useTranslation();
  const textList: TText[] = t(`${ translationPath }.texts`, { returnObjects: true });

  return (
    <Box data-testid={ testId } sx={ { m: [ '0 0 24px', '20px 0' ] } }>
      {_map(textList, text => text && (
        <Text
          as="p"
          key={ text }
          sx={ {
            color: 'black',
            fontSize: [ '18px', '28px' ],
            maxWidth: 640,
            mb: '18px',
            mx: 'auto',
            px: 4,
          } }
          variant="text.h2"
        >
          {text}
        </Text>
      ))}
    </Box>
  );
};
