import { useQuery } from 'react-query';

import { ISalesCustomer } from '../types/customer';
import { fetchSalesCustomers } from '../api';

import * as QueryKeys from 'constants/QueryKeys';

export const useFetchSalesCustomers =
  () => useQuery<ISalesCustomer[]>(QueryKeys.AllSalesCustomers,
    () => fetchSalesCustomers(), {
      retry: false,
      refetchOnWindowFocus: false,
    });
