/* eslint-disable max-lines-per-function */
export const processOrder = (order, checkoutDispatch, paymentDispatch) => {
  checkoutDispatch({ type: 'UNSET_LOADING' });
  if (order) {
    if (!order.PaymentCallSucceeded) {
      checkoutDispatch({
        type: 'SET_ERROR_CODE', errorCode: order.PaymentErrorCode,
      });
      if (order.PaymentErrorCode !== 'ERR-PAYMENT-01') {
        paymentDispatch({ type: 'SET_PAYMENT_STEP' });
        paymentDispatch({ type: 'SET_INLINE_ERROR' });
        checkoutDispatch({
          type: 'SET_PAYMENT_STATUS', status: 'fail',
        });
      }
      if (order.PaymentErrorCode === 'ERR-PAYMENT-01') {
        checkoutDispatch({
          type: 'SET_PAYMENT_STATUS', status: 'fail',
        });
        paymentDispatch({ type: 'UNSET_PAYMENT_STEP' });
        paymentDispatch({ type: 'SET_POPUP' });
      }
      if (order.OrderCallSucceeded) {
        checkoutDispatch({
          type: 'SET_ORDER_STATUS', status: 'success', orderId: order.OrderId, orderGuid: order.OrderGuid,
        });
      }
    }
    if (!order.OrderCallSucceeded) {
      checkoutDispatch({
        type: 'SET_ERROR_CODE', errorCode: order.PaymentErrorCode,
      });
      checkoutDispatch({
        type: 'SET_ORDER_STATUS', status: 'fail', orderId: order.OrderId, orderGuid: order.OrderGuid,
      });
    }
    if (order.OrderCallSucceeded && order.PaymentCallSucceeded) {
      checkoutDispatch({
        type: 'SET_PAYMENT_STATUS', status: 'success',
      });
      checkoutDispatch({
        type: 'SET_ORDER_STATUS', status: 'success', orderId: order.OrderId, orderGuid: order.OrderGuid,
      });
    }
  } else {
    checkoutDispatch({
      type: 'SET_ORDER_STATUS', status: 'fail', orderId: 0, orderGuid: '',
    });
  }
};
