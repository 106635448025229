/* eslint-disable max-len */
import React from 'react';
import { Box } from '@lce/slice_v2';

export const PopupBorder: React.FC = ({ children }) => (
  <Box
    sx={ {
      position: 'relative', backgroundColor: 'white', borderRadius: '4px', boxShadow: '0 0 4px 1px rgba(0, 0, 0, 0.15)', mt: 2, maxWidth: '252px',
    } }
  >
    <Box
      sx={ {
        borderRadius: '4px', border: 'solid 1px #ff6000', p: 4, maxWidth: '252px',
      } }
    >
      {children}
    </Box>
  </Box>
);
