/* eslint-disable @typescript-eslint/camelcase */
import _map from 'lodash/map';

import TagManager from '../TagManager';

import { DataLayerCategory } from './types';

const viewItemList = (item: DataLayerCategory) => {
  TagManager.pushToDataLayer({
    event: 'view_item_list',
    ecommerce: {
      item_list_id: item.categoryId,
      item_list_name: item.categoryName,
      items: _map(item.products, product => ({
        item_name: product.name,
        item_id: product.id,
        price: product.price,
      })),
    },
  });
};

export default viewItemList;
