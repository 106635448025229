import React from 'react';
import { Text } from '@lce/slice_v2';
import { useTranslation } from '@lce/i18n';

import { useTranslationKeysAvailable } from 'ui/hooks';

export type LandingDisclaimerProps = {
  translationPath: string;
  testId?: string;
}

export const dataTestIdLandingDisclaimer = 'landing-disclaimer-component';

export const LandingDisclaimer: React.FC<LandingDisclaimerProps> = ({
  translationPath,
  testId = dataTestIdLandingDisclaimer,
}) => {
  const [ t ] = useTranslation();
  const disclaimerTextIsValid = useTranslationKeysAvailable([ `${ translationPath }.disclaimer` ]);

  if (!disclaimerTextIsValid) {
    return null;
  }

  return (
    <Text
      as="p"
      data-testid={ testId }
      sx={ {
        color: 'black',
        fontSize: [ '18px', '28px' ],
        m: [ '24px', '36px' ],
      } }
      variant="text.h2"
    >
      {t(`${ translationPath }.disclaimer`)}
    </Text>
  );
};
