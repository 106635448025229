/* eslint-disable max-len */
/* eslint-disable max-lines-per-function */
import React from 'react';
import { Box } from '@lce/slice_v2';
import { map as _map } from 'lodash';
import { useTranslation } from '@lce/i18n';

import DashboardStep from '../DashboardStep';

import {
  CompleteYourProfile,
  InviteFriendsAndFamily,
  PromoteYourFundraiser,
} from './Steps';

import { ISellerStepsProps, IStep, PopoverType } from 'features/fundraiser/types/dashboard';

const SellerSteps: React.FC<ISellerStepsProps> = ({
  fundraiser, sellerId, supportGuid, customerId,
}) => {
  const [ t ] = useTranslation();

  const sellerStepsByFundraiserType: Record<string, IStep[]> = {
    DG: [
      {
        stepNumber: 1,
        hasInfo: false,
        title: t('dashboard.steps.seller.step1.title'),
        content: <CompleteYourProfile />,
      },
      {
        stepNumber: 2,
        title: t('dashboard.steps.seller.step2.title'),
        hasInfo: true,
        popupType: PopoverType.FriendFamily,
        content: <InviteFriendsAndFamily fundraiser={ fundraiser } idSuffix={ `-2-${ fundraiser.FundraiserType }` } sellerId={ sellerId } sellerSupportGuid={ supportGuid } />,
      },
      {
        stepNumber: 3,
        title: t('dashboard.steps.seller.promoteYourFundraiser.title'),
        hasInfo: true,
        popupType: PopoverType.Manage,
        content: <PromoteYourFundraiser customerId={ customerId } fundraiser={ fundraiser } />,
      },
    ],
    SS: [
      {
        stepNumber: 1,
        hasInfo: false,
        title: t('dashboard.steps.seller.step1.title'),
        content: <CompleteYourProfile />,
      },
      {
        stepNumber: 2,
        title: t('dashboard.steps.seller.step2.title'),
        hasInfo: true,
        popupType: PopoverType.FriendFamily,
        content: <InviteFriendsAndFamily fundraiser={ fundraiser } idSuffix={ `-2-${ fundraiser.FundraiserType }` } sellerId={ sellerId } sellerSupportGuid={ supportGuid } />,
      },
      {
        stepNumber: 3,
        title: t('dashboard.steps.seller.promoteYourFundraiser.title'),
        hasInfo: true,
        popupType: PopoverType.Manage,
        content: <PromoteYourFundraiser customerId={ customerId } fundraiser={ fundraiser } />,
      },
    ],
    MD: [
      {
        stepNumber: 1,
        hasInfo: false,
        title: t('dashboard.steps.seller.step1.title'),
        content: <CompleteYourProfile />,
      },
      {
        stepNumber: 2,
        title: t('dashboard.steps.seller.step2.title'),
        hasInfo: true,
        popupType: PopoverType.FriendFamily,
        content: <InviteFriendsAndFamily fundraiser={ fundraiser } idSuffix={ `-2-${ fundraiser.FundraiserType }` } sellerId={ sellerId } sellerSupportGuid={ supportGuid } />,
      },
      {
        stepNumber: 3,
        title: t('dashboard.steps.seller.promoteYourFundraiser.title'),
        hasInfo: true,
        popupType: PopoverType.Manage,
        content: <PromoteYourFundraiser customerId={ customerId } fundraiser={ fundraiser } />,
      },
    ],
  };

  return (
    <Box
      data-testid="seller-steps"
      sx={ {
        display: [ 'flex', 'flex', 'flex', 'grid' ],
        flexWrap: 'wrap',
        justifyContent: 'center',
        gridTemplateRows: 'auto auto',
        gridTemplateColumns: 'auto auto',
        columnGap: 4,
      } }
    >
      { _map(sellerStepsByFundraiserType[fundraiser.FundraiserType], (step, index) => (
        <DashboardStep
          hasInfo={ step.hasInfo } key={ index } popupType={ step.popupType } stepNumber={ step.stepNumber }
          title={ step.title }
        >
          { step.content }
        </DashboardStep>
      ))}
    </Box>
  );
};

export default SellerSteps;
