/* eslint-disable max-lines-per-function */
import React from 'react';
import {
  Flex, Text, Link, Container,
} from '@lce/slice_v2';
import { useTranslation } from '@lce/i18n';

import { RouterLink } from '..';

import { SocialLinks } from 'ui/fundraiser';
import { useFundraiserState } from 'features/fundraiser/context/fundraiserContext';

const Footer: React.FC<{}> = () => {
  const [ t ] = useTranslation();

  return (
    <footer data-testid="footer">
      <Container
        sx={ {
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
          padding: [ '16px', '32px' ],
        } }
      >
        <Flex
          sx={ {
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            width: '100%',
            bg: 'white',
            maxWidth: '1280px',
            gap: '16px',
          } }
        >
          <FooterNavLinks />
          <SocialLinks isShareLink={ false } />
          <Text data-testid="copyright" sx={ { maxWidth: '547px', textAlign: 'center' } } variant="text.footer">
            {`©2003-${ new Date().getFullYear() } ${ t('footer.FinePrint') }`}
          </Text>
        </Flex>
      </Container>
    </footer>
  );
};

const FooterNavLinks: React.FC = () => {
  const { fundraiserType } = useFundraiserState();
  const [ t ] = useTranslation();
  const faqsUrl = t('footer.faqLinksUrl', { context: fundraiserType });

  return (
    <Flex
      sx={ {
        width: '100%',
        borderTop: '1px solid',
        borderBottom: '1px solid',
        borderColor: 'primaryOrange',
        padding: [ '16px', '32px' ],
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: [ 'column', 'column', 'row' ],
        gap: [ '8px', '8px', 0 ],
      } }
    >
      <RouterLink
        as="a"
        data-testid="footer-canada"
        href="https://www.pizzakit.ca/"
        rel="noopener noreferrer"
        variant="links.navRouterLink"
      >
        {t('footer.Canada')}
      </RouterLink>
      <RouterLink
        as="a"
        data-testid="footer-privacy-policy"
        href="https://littlecaesars.com/en-us/legal/privacy-policy"
        rel="noopener noreferrer"
        target="_blank"
        variant="links.navRouterLink"
      >
        {t('footer.PrivacyPolicy')}
      </RouterLink>
      <RouterLink
        as="a"
        data-testid="footer-ca-privacy-policy"
        href="https://littlecaesars.com/en-us/legal/privacy-policy/#s12"
        rel="noopener noreferrer"
        target="_blank"
        variant="links.navRouterLink"
      >
        {t('footer.CaliforniaPrivacyPolicy')}
      </RouterLink>
      <RouterLink
        asNav={ true }
        data-testid="footer-contact-us"
        to="/contact-us"
        variant="links.navRouterLink"
      >
        {t('footer.ContactUs')}
      </RouterLink>
      <RouterLink
        asNav={ true }
        data-testid="footer-faqs"
        to={ faqsUrl }
        variant="links.navRouterLink"
      >
        {t('footer.FAQs')}
      </RouterLink>
      <Link
        data-testid="footer-careers"
        href="https://littlecaesars.com/en-us/careers"
        sx={ { fontFamily: 'Lato', textDecoration: 'none', fontWeight: 700 } }
        variant="links.navRouterLink"
      >
        {t('footer.Careers')}
      </Link>
    </Flex>
  );
};

export default Footer;
