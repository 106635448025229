import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

export type LocationProps = {
  followRedirect?: boolean;
}

export const useConditionalRedirect = (checkCondition: boolean, destination: string) => {
  const history = useHistory();
  const { state } = useLocation<LocationProps>();

  const followRedirect = state?.followRedirect ?? true;

  useEffect(() => {
    if (followRedirect && checkCondition) {
      history.replace(destination);
    }
  }, [ checkCondition, destination, history, followRedirect ]);
};
