import * as React from 'react';
import {
  Flex, Button, Text, Form, Box,
} from '@lce/slice_v2';
import { useState, useEffect } from 'react';

export interface Tab {
  label: string;
  id: string;
  value: string;
}

export interface ICardTabsProps {
  tabs: Tab[];
  selected: string;
  onClick: (value) => void;
}
const MIN_DESKTOP_VIEW_PORT = 1200;

const CardTabs: React.FC<ICardTabsProps> = (props) => {
  const clickHandler = (value) => {
    props.onClick && props.onClick(value);
  };

  const [ isDropDown, setIsDropdown ] = useState(window.innerWidth < MIN_DESKTOP_VIEW_PORT);

  const updateMedia = () => {
    setIsDropdown(window.innerWidth < MIN_DESKTOP_VIEW_PORT);
  };
  useEffect(() => {
    window.addEventListener('resize', updateMedia);
    return () => window.removeEventListener('resize', updateMedia);
  });

  return (
    <Box>
      {isDropDown ? (
        <Box sx={ { width: [ '100%', '300px' ] } }>
          <Form.Select
            id="option-selector"
            name="option-selector" onChange={ x => clickHandler(x.target.value) }
          >
            {props.tabs.map(x => (
              <option key={ x.id } value={ x.value }>
                {x.label.toLocaleUpperCase()}
              </option>
            ))}
          </Form.Select>
        </Box>
      ) : (
        <Flex sx={ { justifyContent: 'flex-start', borderBottom: '1px solid', borderColor: 'primaryGrayLight' } }>
          {props.tabs.map(button => (
            <Button
              id={ button.id }
              key={ button.id }
              onClick={ () => clickHandler(button.value) }
              variant={ props.selected === button.value ? 'buttons.tabbedCard.active' : 'buttons.tabbedCard' }
            >
              <Text variant="text.tabbedCard.sectionHeader">
                {button.label.toUpperCase()}
              </Text>
            </Button>
          ))}
        </Flex>
      )}
    </Box>
  );
};

export default CardTabs;
