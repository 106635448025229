import React from 'react';
import * as Yup from 'yup';
import { Form } from '@lce/slice_v2';
import { TFunction } from 'i18next';
import { subDays } from 'date-fns';
import { noop } from 'lodash';
import { ThemeUIStyleObject } from 'theme-ui';

import { useFormField } from 'ui/hooks';
import { useMergeWithDefaults } from 'ui/components/utils';

export type StartDateInputProps = {
  defaultValue?: string | undefined;
  id?: string;
  label?: string;
  name?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  required?: boolean;
  sx?: ThemeUIStyleObject;
  testId?: string;
  variant?: string;
}

export const dataTestIdStartDateInput = 'start-date';
export const defaultStartDateInputName = 'StartDate';

export const defaultPropsStartDateInput: StartDateInputProps = {
  defaultValue: undefined,
  id: defaultStartDateInputName,
  label: 'Start date',
  name: defaultStartDateInputName,
  onChange: noop,
  required: true,
  sx: { marginRight: [ 0, '12px' ] },
  testId: dataTestIdStartDateInput,
  variant: 'forms.fundraiser.field',
};

export const StartDateInput: React.FC<StartDateInputProps> = (props) => {
  const {
    defaultValue, label, name, sx, testId, variant, ...rest
  } = useMergeWithDefaults<
  StartDateInputProps,
  StartDateInputProps
  >(props, defaultPropsStartDateInput);

  return (
    <Form.Field
      data-testid={ testId }
      defaultValue={ defaultValue }
      id="StartDateInput"
      label={ label }
      name={ name }
      sx={ sx }
      type="date"
      variant={ variant }
      { ...rest }
    />
  );
};

export const startDateInputSchema = (t: TFunction,
  validationKey: string,
  required): { [x: string]: Yup.Schema<any> } => {
  const today = new Date();
  const threeYearsFromToday = new Date();
  threeYearsFromToday.setFullYear(today.getFullYear() + 3);

  const baseSchema = Yup
    .date()
    .nullable()
    // eslint-disable-next-line no-confusing-arrow
    .transform((curr, orig) => orig === '' ? null : curr)
    .min(subDays(new Date(), 1), 'Select today or later')
    .max(threeYearsFromToday, 'Start date cannot be more than 3 years from today');

  return required
    ? { [validationKey]: baseSchema.required('Start Date is required') }
    : { [validationKey]: baseSchema.optional() };
};

export const useStartDateInput = (props: StartDateInputProps = {}) => {
  const propsMerged = { ...defaultPropsStartDateInput, ...props };

  const values = useFormField(StartDateInput,
    startDateInputSchema,
    propsMerged,
    'StartDateInput',
    'startDateInputSchema');

  return values as unknown as { StartDateInput: React.FC<StartDateInputProps>; startDateInputSchema: object };
};
