import { useCartDispatch } from 'features/cart';

export interface ISupportFundraiser {
  (): {
    supportSeller: (id: string) => void;
    supportFundraiser: (id: number) => void;
    startShopping: () => void;
  };
}

const useSupportFundraiser: ISupportFundraiser = () => {
  const cartDispatch = useCartDispatch();

  const supportSeller = (id: string) => {
    cartDispatch({ type: 'SET_SELLER', sellerId: id });
  };

  const supportFundraiser = (id: number) => {
    cartDispatch({ type: 'SET_FUNDRAISER', fundraiserId: id });
  };

  const startShopping = () => {
    cartDispatch({ type: 'SET_START_SHOPPING' });
  };

  return {
    supportSeller,
    supportFundraiser,
    startShopping,
  };
};

export { useSupportFundraiser };
