import React from 'react';
import { map as _map } from 'lodash';
import { useTranslation } from '@lce/i18n';
import { Box } from '@lce/slice_v2';

import { LinkItem } from '../../fundraiser/LinkItem';

// eslint-disable-next-line max-lines-per-function
const AdminNavigation: React.FC = () => {
  type TLink = {
    title: string;
    path: string;
    path2: string;
  }

  const [ t ] = useTranslation();
  const links: TLink[] = t('dashboard.navigation.admin', { returnObjects: true });

  return (
    <Box>
      {_map(links, (link: TLink) => (
        <LinkItem
          data-testid={ `admin-${ link.title }` }
          key={ link.title }
          path={ link.path }
          text={ link.title }
        />
      ))}
    </Box>
  );
};

export default AdminNavigation;
