import React, { useEffect, useState } from 'react';
import {
  Box, Heading,
} from '@lce/slice_v2';
import { useHistory } from 'react-router-dom';
import { useTranslation } from '@lce/i18n';
import { useOktaAuth } from '@okta/okta-react';

import FundraiserDetails from './FundraiserDetails/FundraiserDetails';
import ChairpersonDetails from './ChairpersonDetails/ChairpersonDetails';
import AdditionalDetails from './AdditionalDetails/AdditionalDetails';
import { SimpleSignupForm } from './SimpleSignupForm';

import { useCreateFundraiser } from 'features/fundraiser/hooks/useCreateFundraiser';
import { FundraiserInfo, ChairpersonInfo, Fundraiser } from 'features/fundraiser';
import { FundraiserExtraInfo } from 'features/fundraiser/types/fundraiser';
import { useLocalStorage, useUserInfo } from 'features/common';
import * as FundraiserDefaults from 'constants/FundraiserDefaults';

export interface INewFundraiserFormProps {
  onSuccess?: () => void;
  hasParentGroupId?: boolean;
  initialFundraiserDetails?: boolean;
  initialChairpersonDetails?: boolean;
  initialFundraiserDetailFormData?: FundraiserInfo;
  initialChairpersonFormData?: ChairpersonInfo;
  initialMiscFormData?: FundraiserExtraInfo;
}

// eslint-disable-next-line max-lines-per-function
const NewFundraiserForm: React.FC<INewFundraiserFormProps> = ({
  hasParentGroupId, onSuccess,
  initialFundraiserDetails = false,
  initialChairpersonDetails = false,
  initialFundraiserDetailFormData = undefined,
  initialChairpersonFormData = undefined,
  initialMiscFormData = undefined,
}) => {
  const [ t ] = useTranslation();
  const history = useHistory();
  const { authState } = useOktaAuth();
  const [ fundraiserDetails, setFundraiserDetails ] = useState<boolean>(initialFundraiserDetails);
  const [ fundraiserDetailFormData, setFundraiserDetailFormData ] =
    useState<FundraiserInfo | undefined>(initialFundraiserDetailFormData);
  const [ chairpersonFormData, setChairpersonFormData ] =
    useState<ChairpersonInfo | undefined>(initialChairpersonFormData);
  const [ miscFormData, setMiscFormData ] = useState<FundraiserExtraInfo | undefined>(initialMiscFormData);
  const [ chairpersonDetails, setChairpersonDetails ] = useState<boolean>(initialChairpersonDetails);

  const [ , setParentId ] = useLocalStorage<string>('parentId');
  const { mutateAsync: createFundraiser, isLoading } = useCreateFundraiser();
  const { data: userInfo } = useUserInfo(authState.isAuthenticated);
  const [ franchiseeId, setFranchiseeId ] = useLocalStorage<string>('franchiseeId');
  const [ referralSource, setReferralSource ] = useLocalStorage<string>('referralSource');
  const isFranchiseeReferral = Boolean(franchiseeId);
  const hasReferralSource = Boolean(referralSource);
  const showHowDidYouHearAboutUs = !hasParentGroupId && !isFranchiseeReferral && !hasReferralSource;

  const createFundraiserHandler = async() => {
    const isLCEStore = isFranchiseeReferral || miscFormData?.Referral === t('HowDidYouHearAboutUsOptions.LCEStore');

    let referral = '';

    if (hasReferralSource) {
      referral = referralSource;
    } else if (isLCEStore) {
      referral = franchiseeId;
    } else {
      referral = miscFormData?.Referral || '';
    }

    if (fundraiserDetailFormData && chairpersonFormData) {
      const fundraiser: Fundraiser = {
        Id: 0,
        Info: fundraiserDetailFormData,
        Chairperson: chairpersonFormData,
        SalesAgentCode: fundraiserDetailFormData.SalesAgentCode || '',
        FederalTaxId: fundraiserDetailFormData.FederalTaxId || '',
        Referral: referral,
        ProfitCheckPayableTo: '',
      };

      const data = await createFundraiser(fundraiser);
      if (data) {
        onSuccess && onSuccess();
        setParentId(undefined);
        setFranchiseeId(undefined);
        setReferralSource(undefined);
        history.push('/start-a-fundraiser/confirm');
      }
    }
  };

  useEffect(() => {
    createFundraiserHandler();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ miscFormData ]);

  return (
    <Box sx={ { flex: 1 } }>
      <Heading>
        {t('fundraiser.StartAFundraiser')}
      </Heading>

      {hasParentGroupId ? (
        <SimpleSignupForm
          info={ fundraiserDetailFormData }
          mode={ !fundraiserDetails ? 'active' : 'preview' }
          onEdit={ () => setFundraiserDetails(false) }
          onNext={ (info: FundraiserInfo) => {
            setFundraiserDetailFormData(info);
            setFundraiserDetails(true);
          } }
        />
      )
        : (
          <FundraiserDetails
            info={ fundraiserDetailFormData }
            mode={ !fundraiserDetails ? 'active' : 'preview' }
            onEdit={ () => setFundraiserDetails(false) }
            onNext={ (info: FundraiserInfo) => {
              setFundraiserDetailFormData(info);
              setFundraiserDetails(true);
            } }
          />
        )}

      <ChairpersonDetails
        defaultChairperson={ !userInfo?.Roles.includes(FundraiserDefaults.SuperAdminRoleName) ? userInfo : undefined }
        hasParentGroupId={ hasParentGroupId }
        info={ chairpersonFormData }
        mode={ fundraiserDetails ? !chairpersonDetails ? 'active' : 'preview' : 'hidden' }
        onEdit={ () => setChairpersonDetails(false) }
        onNext={ (info: ChairpersonInfo) => {
          setChairpersonFormData(info);
          setChairpersonDetails(true);
        } }
      />

      <AdditionalDetails
        isLoading={ isLoading }
        mode={ fundraiserDetails && chairpersonDetails ? 'active' : 'hidden' }
        onNext={ (info: FundraiserExtraInfo) => {
          setMiscFormData(info);
        } }
        showHowDidYouHearAboutUs={ showHowDidYouHearAboutUs }
      />
    </Box>
  );
};

export default NewFundraiserForm;
