import {
  Box, Flex, Headline, Text,
} from '@lce/slice_v2';
import React from 'react';

export interface IGraph {
  value: string;
  title: string;
  isDashboard: boolean;
}

const Graph: React.FC<IGraph> = ({ value, title, isDashboard }) => {
  const size = isDashboard ? '155px' : [ '112px', '124px' ];
  const headerFontSize = isDashboard ? '38px' : [ '24px', '28px' ];
  const bodyFontSize = isDashboard ? '16px' : '12px';

  return (
    <Box>
      { value && title && (
        <Flex
          data-testid={ `graph-${ title }-${ value }` }
          sx={ {
            width: size,
            height: size,
            border: 'dashed 2px #ff671b',
            borderRadius: '50%',
            textAlign: 'center',
            flexDirection: 'column',
            justifyContent: 'center',
          } }
        >
          <Headline
            as="h2"
            sx={ { fontSize: headerFontSize, fontWeight: 900 } }
          >
            {value}
          </Headline>
          <Text mt="4px" sx={ { fontSize: bodyFontSize, fontWeight: 500 } }>
            {title}
          </Text>
        </Flex>
      )}
    </Box>
  );
};

export default Graph;
