import React from 'react';
import {
  Form, Option,
} from '@lce/slice_v2';
import { IFieldProps } from '@lce/slice_v2/Elements/Form/Field';

import { IOption } from '../IOption';

interface IDropdownSelect extends Omit<IFieldProps, 'label'> {
  label? : string;
  name: string;
  id: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  options: IOption[];
}

const DropdownSelect: React.FC<IDropdownSelect> = ({
  id, name, label, onChange, options,
}) => (
  <Form.Field
    component={ Form.Select }
    id={ id }
    label={ label }
    name={ name }
    onChange={ e => onChange(e.target.value) }
    sx={ {
      m: [ '0' ],
      p: [ '0' ],
      borderRadius: [ '5px' ],
      border: [ 'solid 1px #c4c4c4' ],
      backgroundColor: [ '#eeeeee' ],
      div: { p: [ 0 ] },
    } }
  >
    {options.map(x => (
      <Option key={ x.value } { ...x }>
        {x.label}
      </Option>
    ))}
  </Form.Field>
);

export default DropdownSelect;
