declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    eProtect: any;
  }
}

// eslint-disable-next-line max-lines-per-function
export const eProtectResponse = (response, paymentDispatch, checkoutDispatch, paymentStatus) => {
  // has token
  if (response.paypageRegistrationId) {
    paymentDispatch({
      type: 'SET_TOKEN', code: '', token: response.paypageRegistrationId,
    });
    // has error
  } else if (response.response) {
    let code = response.response;
    let popup = false;
    switch (response.response) {
      case '871':
        // alert('Invalid card number. Check and retry. (Not Mod10)');
        code = response.response;
        break;
      case '872':
        // alert('Invalid card number. Check and retry. (Too short)');
        code = response.response;
        break;
      case '873':
        // alert('Invalid card number. Check and retry. (Too long)');
        code = response.response;
        break;
      case '874':
        // alert('Invalid card number. Check and retry. (Not a number)');
        code = response.response;
        break;
      case '875':
      case '889':
        // alert('We are experiencing technical difficulties. Please try again later or call 555-555-1212');
        popup = true;
        code = response.response;
        break;
      case '876':
        // alert('Invalid card number. Check and retry. (Failure from Server)');
        code = response.response;
        break;
      case '881':
        // alert('Invalid card validation code. Check and retry.  (Not a number)');
        code = response.response;
        break;
      case '882':
        // alert('Invalid card validation code. Check and retry. (Too short)');
        code = response.response;
        break;
      case '883':
        // alert('Invalid card validation code. Check and retry. (Too long)');
        code = response.response;
        break;
      case '901':
        // alert('Request has timed out.');
        popup = true;
        code = response.response;
        break;
      default:
        code = response.response;
        break;
    }
    paymentDispatch({
      type: 'SET_ERROR_CODE', code, token: '', popup,
    });
    if (paymentStatus === 'retry') {
      checkoutDispatch({ type: 'UNSET_LOADING' });
      if (!popup) {
        paymentDispatch({ type: 'UNSET_PAYMENT_STEP' });
        paymentDispatch({ type: 'SET_INLINE_ERROR' });
      }
      checkoutDispatch({ type: 'SET_PAYMENT_STATUS', status: 'fail' });
    }
    if (paymentStatus === 'pending') {
      checkoutDispatch({ type: 'SET_PAYMENT_STATUS', status: 'retry' });
    }
  }
};

export const callEprotect = (eProtectRequest, formFields, paymentDispatch, checkoutDispatch, paymentStatus) => {
  new window.eProtect().sendToEprotect(eProtectRequest, formFields,
    response => eProtectResponse(response, paymentDispatch, checkoutDispatch, paymentStatus),
    response => eProtectResponse(response, paymentDispatch, checkoutDispatch, paymentStatus),
    response => eProtectResponse(response, paymentDispatch, checkoutDispatch, paymentStatus),
    15000);
};
