import React from 'react';
import { Text, Flex } from '@lce/slice_v2';
import { useTranslation } from '@lce/i18n';
import _isNil from 'lodash/isNil';

import { useFetchHomepageData } from '../hooks';

import { FeaturedFundraiserTracker } from './FeaturedFundraiserTracker';

const FeaturedFundraiser: React.FC = () => {
  const [ t ] = useTranslation();
  const { data: homepageData, isLoading } = useFetchHomepageData();
  if (_isNil(homepageData) || isLoading) {
    return null;
  }
  const { seller } = homepageData.featuredFundraiser;
  if (_isNil(seller)) {
    return null;
  }
  return (
    <Flex
      data-testid="featured-fundraiser"
      sx={ {
        alignItems: 'center',
        flexDirection: 'column',
        gap: '16px',
        p: [ '32px 16px', '32px' ],
        textAlign: 'center',
        width: '100%',
      } }
    >
      <Text variant="home.subheading">
        {t('home.featuredFundraiser.title')}
      </Text>
      <FeaturedFundraiserTracker { ...homepageData.featuredFundraiser } />
    </Flex>
  );
};

export default FeaturedFundraiser;
