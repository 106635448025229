import React, { ChangeEvent, useMemo } from 'react';
import { ThemeUIStyleObject } from 'theme-ui';
import {
  Box, Form, Image, Text,
} from '@lce/slice_v2';

import DollarSign from './assets/dollar-sign.png';

interface IMoneyInput {
  boxSx?: ThemeUIStyleObject;
  id?: string;
  label?: string;
  fieldSx?: ThemeUIStyleObject;
  name: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  testId?: string;
  textSx?: ThemeUIStyleObject;
  variant?: string;
}

export const defaults = {
  testId: 'money-input',
};

export const MoneyInput: React.FC<IMoneyInput> = ({
  boxSx = {},
  id,
  label = '',
  fieldSx = {},
  name,
  testId = defaults.testId,
  textSx = {},
  variant = 'forms.fundraiser.field',
}) => {
  const effectiveId = useMemo(() => id || name, [ id, name ]);

  return (
    <div data-testid={ testId }>
      <Box data-testid={ `${ testId }-box` } sx={ { position: 'relative', ...boxSx } }>
        <Text
          sx={ {
            position: 'absolute', left: 4, bottom: '6px', ...textSx,
          } }
        >
          <Image src={ DollarSign } sx={ { width: '10px', height: '18px' } } />
        </Text>
        <Form.Field
          component={ Form.Input.Text }
          data-testid={ `${ testId }-field` }
          id={ effectiveId }
          label={ label }
          name={ name }
          sx={ { '& input': { paddingLeft: '28px', height: '44px', ...fieldSx } } }
          type="number"
          variant={ variant }
        />
      </Box>
    </div>
  );
};
