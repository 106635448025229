/* eslint-disable max-lines-per-function */
import React from 'react';
import { Prompt } from 'react-router-dom';
import { Box } from '@lce/slice_v2';
import { useTranslation } from '@lce/i18n';

import {
  Details,
  CheckoutWrapper,
  PlaceOrderFailedPayment,
  ShippingDetailsMD,
  ShippingDetailsSummaryMD,
  Payment,
  OrderReviewMD,
  OrderProcessingOverlay,
} from 'ui/checkout';
import { GuestLogin } from 'ui/account';
import { useCartContainsValidProducts } from 'features/cart/hooks';

export const MealDealCheckoutPage: React.FC = () => {
  const [ t ] = useTranslation();
  const hasValidProducts = useCartContainsValidProducts();

  return (
    <div data-testid="meal-deal-checkout-page">
      <CheckoutWrapper
        LoginComponent={ GuestLogin }
        shouldDisplayShipping={ false }
        shouldDisplaySubtotal={ false }
      >
        {({
          checkoutState,
          details,
          setDetails,
          paymentState,
          paymentDispatch,
          hasOnlyCouponItems,
          isCouponItemExist,
          handleModalClose,
          handlePaymentInfoComplete,
          redirectToCart,
          placeOrderHandler,
        }) => (
          <Box data-testid="checkout-process" sx={ { flex: 1 } }>
            { paymentState.popup && checkoutState.paymentStatus === 'fail' && (
              <PlaceOrderFailedPayment
                onClose={ handleModalClose }
                openModal={ true }
              />
            )}
            <Details
              ShippingDetailsComponent={ ShippingDetailsMD }
              ShippingDetailsSummaryComponent={ ShippingDetailsSummaryMD }
              hasOnlyCouponItems={ hasOnlyCouponItems }
              isCouponItemExist={ isCouponItemExist }
              mode={ !details ? 'active' : 'preview' }
              onEdit={ () => {
                setDetails(false);
                paymentDispatch({ type: 'UNSET_PAYMENT_STEP' });
                paymentDispatch({ type: 'UNSET_REVIEW_STEP' });
              } }
              onVerified={ () => setDetails(true) }
            />
            <Payment
              isCouponItemExist={ isCouponItemExist }
              mode={ details ? paymentState.showPaymentStep ? 'active' : 'preview' : 'hidden' }
              onEdit={ () => {
                paymentDispatch({ type: 'SET_PAYMENT_STEP' });
                paymentDispatch({ type: 'UNSET_REVIEW_STEP' });
              } }
              onPaymentInfoComplete={ handlePaymentInfoComplete }
            />
            {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
            <a
              aria-label="Scroll to submit button and order review"
              href="submitAnchor"
              id="submitAnchor"
              style={ { scrollMarginTop: '130px' } }
            />
            <OrderReviewMD
              mode={ details && paymentState.showReviewStep ? 'active' : 'hidden' }
              onEdit={ () => redirectToCart() }
              onPlaceOrder={ placeOrderHandler }
            />
            <OrderProcessingOverlay openOverlay={ checkoutState.loading || false } />
            {hasValidProducts && (
              <Prompt
                message={ t('checkout.OrderProcessingWarning') }
                when={ checkoutState.loading && checkoutState.paymentStatus !== 'success' }
              />
            )}
          </Box>
        )}
      </CheckoutWrapper>
    </div>
  );
};
