import * as React from 'react';
import {
  Flex, Link, Text,
} from '@lce/slice_v2';
import { format } from 'date-fns';
import { useTranslation } from '@lce/i18n';

import { FundraiserType, Seller } from 'features/fundraiser';

export interface ISellerTextProps {
  seller: Seller;
  fundraiserType: FundraiserType;
}

export const SellerText: React.FC<ISellerTextProps> = ({ seller, fundraiserType }) => {
  const [ t ] = useTranslation();
  return (
    <Flex
      sx={ {
        flexDirection: 'column',
        minHeight: '130px',
        justifyContent: 'center',
        gap: '4px',
        textAlign: 'left',
      } }
    >
      <Text variant="fundraiserTracker.name">
        {`${ t('fundraiserTracker.header',
          { context: fundraiserType, name: `${ seller.FirstName } ${ seller.LastInitial }` }) }`}
      </Text>
      { seller.VideoSource && (
        <Link
          href={ seller.VideoSource }
          sx={ { lineHeight: 1 } }
          target="_blank"
          variant="primaryTextUnderline"
        >
          {t('fundraiserTracker.videoLinkTitle')}
        </Link>
      )}
      <Text variant="fundraiserTracker.description">
        { seller.Description !== '' ? seller.Description : seller.FundraiserMessage }
      </Text>
      <Text variant="fundraiserTracker.miniDetails">
        {`${ t('fundraiserTracker.miniDetails', {
          context: fundraiserType,
          endDate: format(new Date(seller.EndDate), 'MMMM d yyyy'),
          startDate: format(new Date(seller.StartDate), 'MMMM d yyyy'),
          firstName: seller.FirstName,
          fundraiserId: seller.FundraiserId,
          fundraiserName: seller.FundraiserName,
        }) }`}
      </Text>
    </Flex>
  );
};
