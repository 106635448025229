import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { useFundraiserDispatch } from 'features/fundraiser/context/fundraiserContext';
import { FundraiserType } from 'features/fundraiser/types';

export const FundraiserState = ({ children }) => {
  const { pathname, search } = useLocation();
  const isMealDeal = pathname.includes('/meal-deal') || search.includes('meal-deal');
  const isBrochure = pathname.includes('/brochure') || search.includes('brochure');

  const fundraiserDispatch = useFundraiserDispatch();
  useEffect(() => {
    if (isMealDeal) {
      fundraiserDispatch({ type: 'SET_FUNDRAISER_TYPE', fundraiserType: FundraiserType.MealDeal });
    } else if (isBrochure) {
      fundraiserDispatch({ type: 'SET_FUNDRAISER_TYPE', fundraiserType: FundraiserType.Brochure });
    } else {
      fundraiserDispatch({ type: 'SET_FUNDRAISER_TYPE', fundraiserType: FundraiserType.Digital });
    }
  }, [ fundraiserDispatch, isMealDeal, isBrochure ]);
  return children;
};
