/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import { useTranslation } from '@lce/i18n';
import { Container, Text } from '@lce/slice_v2';
import { map as _map } from 'lodash';

import { FaqFooter } from 'ui/faq/FaqFooter';
import { Category } from 'ui/faq/Category';

const CouponCodeFaqs: React.FC = () => {
  const [ t ] = useTranslation();
  const qAndAs: any = t('couponCodeFaqs', { returnObjects: true });

  return (
    <>
      <Container sx={ { width: '100%', maxWidth: '1068px' } }>
        <Text
          as="h1"
          sx={ {
            fontSize: [ '24px', '24px', '34px' ],
            color: 'primaryOrange', mt: [ '16px', 0, 0, '55px' ],
          } } variant="text.header.subUpper"
        >
          {t('couponCodeFaqPage.pageTitle')}
        </Text>
        {_map(qAndAs, category => _map(category, cat => (
          <Category
            faqCategoryTitle={ cat.title }
            key={ `${ cat.title }` }
            qAndACollection={ cat.qAndAs }
          />
        )))}
      </Container>
      <FaqFooter />
    </>
  );
};

export default CouponCodeFaqs;
