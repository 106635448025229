import React from 'react';
import * as Yup from 'yup';
import { useTranslation } from '@lce/i18n';
import {
  Button, Box, Flex, Form, Text, Spinner,
} from '@lce/slice_v2';

import { Mode, Step } from 'ui/common';
import { useTermsAndConditionsInput } from 'ui/components/forms';
import HowDidYouHearAboutUsOptions from 'ui/common/HowDidYouHearAboutUsOptions';
import { FundraiserBrochure, FundraiserFormStepState, FundraiserType } from 'features/fundraiser/types/fundraiser';

export interface IAdditionalDetailsProps {
  isLoading: boolean;
  mode: Mode;
  info: Partial<FundraiserBrochure>;
  onNext: (info: Partial<FundraiserBrochure>) => void;
  showHowDidYouHearAboutUs: boolean;
  fundraiserType: FundraiserType;
}

// eslint-disable-next-line max-lines-per-function
const AdditionalDetails: React.FC<IAdditionalDetailsProps> = ({
  onNext, isLoading, mode, showHowDidYouHearAboutUs, fundraiserType, info,
}) => {
  const [ t ] = useTranslation();
  const { TermsAndConditionsInput, termsAndConditionsInputSchema } = useTermsAndConditionsInput();

  const handleOnSubmit = (data: Partial<FundraiserBrochure>) => {
    const info = {
      ...data,
    };
    onNext(info);
  };

  const validationSchema = Yup.object().shape({
    Referral: Yup.string(),
    couponCode: Yup.string().trim(),
    ...termsAndConditionsInputSchema,
  });

  return (
    <>
      { mode === FundraiserFormStepState.Active && (
        <Step title={ t('fundraiser.AdditionalDetails', { context: fundraiserType }) }>
          <Box variant="cards.step.body">
            <Form
              initialValues={ info }
              onSubmit={ handleOnSubmit }
              summary={ false }
              validationSchema={ validationSchema }
            >
              <Flex sx={ { flexDirection: 'column' } }>
                <Flex sx={ { flexDirection: [ 'column', 'row' ], gap: '16px', alignItems: [ '', 'end' ] } }>
                  {showHowDidYouHearAboutUs && (
                    <Flex sx={ { flexDirection: 'column', width: [ '100%', '50%' ] } }>
                      <Text sx={ { mb: '6px' } } variant="text.header.form">
                        {t('fundraiser.Referral')}
                      </Text>
                      <Form.Field
                        component={ Form.Select }
                        defaultValue={ info.Referral || '' }
                        id="Referral"
                        label={ t('fundraiser.labels.ReferralSelect') }
                        name="Referral"
                        sx={ { my: 0, width: '100%' } }
                      >
                        <HowDidYouHearAboutUsOptions />
                      </Form.Field>
                    </Flex>
                  )}
                  <Form.Field
                    id="couponCode"
                    label={ t('fundraiser.labels.CouponCode') }
                    name="couponCode"
                    sx={ { my: 0, width: [ '100%', '50%' ] } }
                    type="text"
                  />
                </Flex>
                <Text sx={ { mt: '24px' } } variant="text.header.form">
                  {t('fundraiser.TermsAndConditions')}
                </Text>
                <TermsAndConditionsInput />
                <Button
                  id="create-brochure-fundraiser"
                  sx={ { mt: '24px' } }
                  type="submit"
                  variant={ isLoading ? 'disabled' : 'primary' }
                >
                  { isLoading ? (
                    <Spinner sx={ { height: '100%' } } variant="lce" />
                  ) : t('fundraiser.CreateFundraiser') }
                </Button>
              </Flex>
            </Form>
          </Box>
        </Step>
      )}

      { mode === FundraiserFormStepState.Hidden && (
        <Step title={ t('fundraiser.AdditionalDetails', { context: fundraiserType }) } />
      )}
    </>
  );
};

export default AdditionalDetails;
