/* eslint-disable max-len */
import React, { useEffect, useRef, useState } from 'react';
import {
  Box, Form, Button, Modal,
} from '@lce/slice_v2';
import { toast } from 'react-toastify';
import { useTranslation } from '@lce/i18n';

import { Edit } from 'ui/sellerDetail';
import { GenericToast } from 'ui/common';
import {
  Seller, updateFundraiser, useUpdateSeller,
} from 'features/fundraiser';
import { FundraiserListItem } from 'features/fundraiser/types/fundraiser';
import { UpdateSellerRequest } from 'features/fundraiser/types/seller';

export interface IHeaderDescription {
  description: string;
  fundraiser: FundraiserListItem;
  seller?: Seller;
  isPreviewOpenDefault?: boolean;
  isEditDescriptionDefault?: boolean;
}

const DescriptionButton: React.FC<{ children: React.ReactNode; onClick: () => void; dataTestId: string} > = ({ children, onClick, dataTestId }) => (
  <Button
    data-testid={ dataTestId }
    onClick={ onClick }
    sx={ {
      backgroundColor: 'white',
      color: 'primaryOrange',
      cursor: 'pointer',
      fontSize: '14px',
      height: [ 'auto', 'auto' ],
      fontWeight: 'normal',
      px: 0,
      textAlign: 'left',
      border: 'none',
    } }
  >
    {children}
  </Button>
);

const ProfileButton: React.FC<{ children: React.ReactNode; onClick: () => void; dataTestId: string} > = ({ children, onClick, dataTestId }) => (
  <Button
    data-testid={ dataTestId }
    onClick={ onClick }
    sx={ {
      backgroundColor: 'white',
      color: 'primaryOrange',
      cursor: 'pointer',
      fontSize: '14px',
      height: [ 'auto', 'auto' ],
      fontWeight: 'normal',
      px: 0,
      textAlign: 'center',
      border: '1px solid primaryOrange',
      ml: '5px',
      py: '4px',
    } }
  >
    {children}
  </Button>
);

// eslint-disable-next-line max-lines-per-function
const HeaderDescription: React.FC<IHeaderDescription> = ({
  fundraiser, seller, isPreviewOpenDefault = false, isEditDescriptionDefault = false, description,
}) => {
  const [ t ] = useTranslation();
  const placeholder = seller ? t('dashboard.header.sellerPlaceholder', { context: fundraiser.FundraiserType }) : t('dashboard.header.fundraiserPlaceholder');
  const [ isEditDescription, setIsEditDescription ] = useState<boolean>(isEditDescriptionDefault);
  const [ isPreviewOpen, setIsPreviewOpen ] = useState<boolean>(isPreviewOpenDefault);
  const [ customMessage, setCustomMessage ] = useState(description || placeholder);

  const textInput = useRef<HTMLInputElement>(null);

  const handleClosePreview = () => setIsPreviewOpen(false);

  useEffect(() => {
    if (isEditDescription && textInput.current) {
      textInput.current.focus();
    }
  }, [ isEditDescription ]);

  const { mutateAsync: updateSeller } = useUpdateSeller(fundraiser.Id, seller?.CustomerId);

  const onSaveHandler = async() => {
    setIsEditDescription(false);
    const customMessageTrimmed = customMessage.trim();
    const descriptionChanged = customMessageTrimmed !== description;
    if (descriptionChanged) {
      if (seller) {
        const data: UpdateSellerRequest = {
          Id: seller.Id,
          CustomerId: seller.CustomerId,
          Description: customMessageTrimmed,
          VideoSource: seller.VideoSource,
          KitGoal: seller.Goal,
          FirstName: seller.FirstName,
          LastName: seller.LastName,
        };
        try {
          await updateSeller(data);
          toast(<GenericToast text={ t('dashboard.header.toast.sellerUpdated') } />);
        } catch {
          toast(<GenericToast text={ t('dashboard.header.toast.sellerError') } />);
        }
      } else {
        const data: FundraiserListItem = {
          ...fundraiser,
          CustomMessage: customMessageTrimmed,
        };
        try {
          await updateFundraiser(data);
          toast(<GenericToast text={ t('dashboard.header.toast.fundraiserUpdated') } />);
        } catch {
          toast(<GenericToast text={ t('dashboard.header.toast.fundraiserError') } />);
        }
      }
    }
  };

  return isEditDescription ? (
    <Box data-testid="header-desc" sx={ { position: 'relative', flex: 1, minWidth: '150px' } }>
      <Form.Input.Textarea
        data-testid="custom-message"
        id="custom-message"
        label={ t('dashboard.header.customMessage') }
        maxLength="255"
        name={ t('dashboard.header.customMessage') }
        onBlur={ onSaveHandler }
        onChange={ e => setCustomMessage(e.target.value) }
        ref={ textInput }
        sx={ {
          height: [ '100px', '100px', '180px' ],
          resize: 'none',
          flex: 1,
          mb: 2,
          ml: 0,
          width: '100%',
        } }
        value={ customMessage }
      />
      <DescriptionButton dataTestId="save-description-button" onClick={ onSaveHandler }>
        { t('dashboard.header.saveDescription') }
      </DescriptionButton>
    </Box>
  ) : (
    <Box data-testid="header-desc">
      <Box>
        {customMessage}
      </Box>
      <DescriptionButton dataTestId="edit-description-button" onClick={ () => setIsEditDescription(true) }>
        { t('dashboard.header.editDescription') }
      </DescriptionButton>
      {seller && (
        <ProfileButton dataTestId="edit-profile-button" onClick={ () => setIsPreviewOpen(true) }>
          { t('dashboard.header.editProfile') }
        </ProfileButton>
      )}
      {isPreviewOpen && seller && (
        <Modal
          closeIcon={ false }
          data-testid="edit-profile-modal"
          onClose={ handleClosePreview }
          sx={ {
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            m: '0 !important',
            p: '10px',
            maxWidth: '750px',
            height: 'auto',
            maxHeight: '95%',
            width: [ '90%', '90%', '100%' ],
            overflowY: 'auto',
          } }
        >
          <Edit onCancelEdit={ handleClosePreview } seller={ seller } />
        </Modal>
      )}
    </Box>
  );
};

export default HeaderDescription;
