/* eslint-disable max-len */
import { IReportFilterOptions } from '../types';

export const GenerateReportFiltersQuery = (filterOptions: IReportFilterOptions) => {
  let query = '';
  query += filterOptions.reportType !== null ? `?ReportType=${ filterOptions.reportType }` : '';
  query += filterOptions.reportFormat != null ? `&ReportFormat=${ filterOptions.reportFormat }` : '';
  query += filterOptions.reportStatuses != null ? filterOptions.reportStatuses.map(value => `&ReportStatuses=${ value.toString() }`).toString().replace(/,/g, '') : '';
  query += filterOptions.rangeStartDate != null ? `&RangeStartDate=${ filterOptions.rangeStartDate }` : '';
  query += filterOptions.rangeEndDate != null ? `&RangeEndDate=${ filterOptions.rangeEndDate }` : '';
  query += filterOptions.statesSelection != null ? filterOptions.statesSelection.map(value => `&StatesSelect=${ value.toString() }`).toString().replace(/,/g, '') : '';
  query += filterOptions.salesRepsSelection != null ? filterOptions.salesRepsSelection.map(value => `&SalesRepsSelect=${ value.toString() }`).toString().replace(/,/g, '') : '';
  query += filterOptions.iscsSelection != null ? filterOptions.iscsSelection.map(value => `&IscsSelect=${ value.toString() }`).toString().replace(/,/g, '') : '';
  return encodeURI(query);
};
