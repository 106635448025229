import { Cart } from '../../types/cart';

import { CartState } from './cartContext';

import { GoogleEvents } from 'features/analytics';
import { Product } from 'features/products';

const addItemToCart = (cart: Cart, { product, quantity }: { product: Product; quantity: number }): Cart => {
  let currentItem = cart && cart.items.find(x => x.ProductId === product.Id);

  GoogleEvents.addToCart({
    id: product.Id.toString(),
    name: product.Name,
    price: product.Price,
    quantity: quantity,
  });

  if (currentItem) {
    currentItem.Quantity = currentItem.Quantity + quantity;
    if (currentItem.Quantity > 99) {
      currentItem.Quantity = 99;
    }
  } else {
    currentItem = {
      ProductId: product.Id,
      Sku: product.Sku,
      IsFreeShipping: product.IsFreeShipping,
      IsDonation: product.IsDonation,
      Quantity: quantity,
      Picture: product.ThumbnailImage,
      ProductName: product.Name,
      ProductTypeId: product.ProductTypeId,
      UnitPrice: product.Price,
      ShortDescription: product.ShortDescription,
      Subtotal: product.Price * quantity,
      Warnings: [],
    };
    cart.items.push(currentItem);
  }

  return cart;
};

const modifyItemInCart = (cart: Cart, { productId, quantity }: { productId: number; quantity: number }): Cart => {
  const itemIndex = cart.items.findIndex(x => x.ProductId === productId);

  if (itemIndex === -1) {
    return cart;
  }

  if (quantity === 0) {
    cart.items.splice(itemIndex, 1);
  } else {
    cart.items[itemIndex].Quantity = quantity;
  }

  return cart;
};

const calculateSubtotal = (cart: Cart): number => {
  let total = 0;

  if (cart && cart.items && cart.items.length > 0) {
    for (let i = 0;i < cart.items.length;i++) {
      total += cart.items[i].UnitPrice * cart.items[i].Quantity;
    }
  }

  return total;
};

const getTotalQuantity = (cart: Cart): number => {
  let total = 0;

  if (cart && cart.items && cart.items.length > 0) {
    total = cart.items.map(x => x.Quantity).reduce((a, b) => a + b);
  }

  return total;
};

const cartReducer = (state: CartState, action): CartState => {
  const { type, payload } = action;

  switch (type) {
    case 'ADD_ITEM': {
      const lineItems = addItemToCart(state.cart, payload);

      return {
        ...state,
        cart: lineItems,
        subtotal: calculateSubtotal(lineItems),
        totalQuantity: getTotalQuantity(lineItems),
      };
    }
    case 'MODIFY_ITEM': {
      const lineItems = modifyItemInCart(state.cart, payload);

      return {
        ...state,
        cart: lineItems,
        subtotal: calculateSubtotal(lineItems),
        totalQuantity: getTotalQuantity(lineItems),
      };
    }
    case 'UPDATE_CART': {
      return {
        ...state,
        cart: action.items,
        subtotal: calculateSubtotal(action.items),
      };
    }
    case 'CLEAR':
      return {
        ...state,
        cart: { items: [] },
        subtotal: 0,
        totalQuantity: 0,
      };
    case 'SET_SELLER':
      return {
        ...state,
        sellerId: action.sellerId,
      };
    case 'SET_START_SHOPPING':
      return { ...state, canStartShopping: true };
    case 'SET_GUEST':
      return {
        ...state,
        guestId: action.guestId,
      };
    default:
      return state;
  }
};

export { cartReducer, calculateSubtotal, getTotalQuantity };
