/* eslint-disable max-len */
import React from 'react';
import { Text } from '@lce/slice_v2';
import { useTranslation } from '@lce/i18n';

import { PopupBorder } from './PopupBorder';

export const BuyerInvitePopup: React.FC = () => {
  const [ t ] = useTranslation();

  return (
    <PopupBorder>
      <Text sx={ { fontSize: '13px', lineHeight: 1.2, fontWeight: 'normal' } }>
        {t('dashboard.steps.popoverContent.buyerInvite.note')}
      </Text>
    </PopupBorder>
  );
};
