/* eslint-disable max-lines-per-function */
import React from 'react';
import { useTranslation } from '@lce/i18n';
import {
  Box, Flex, Form, Option, Text,
} from '@lce/slice_v2';
import _map from 'lodash/map';

import { State } from 'features/common';

export type ProductShippingFieldProps = {
  states?: State[];
};

export const ProductShippingFields: React.FC<ProductShippingFieldProps> = ({
  states = [],
}) => {
  const { t } = useTranslation();

  return (
    <Box data-testid="shipping_addressFields">
      <Flex sx={ { flexDirection: [ 'column', 'row' ] } }>
        <Form.Field
          autoComplete="shipping address-line1"
          data-testid="shipping_streetAdress"
          id="Address1"
          label={ (
            <Text
              variant="primary"
            >
              {t('checkout.ShippingDetails.streetAddreessLabel')}
            </Text>
          ) }
          name="Address1"
          sx={ {
            marginRight: [ 0, '12px' ],
          } }
          variant="forms.checkout.field"
        />

        <Form.Field
          autoComplete="shipping address-line2"
          data-testid="shipping_aptNo"
          id="Address2"
          label={ (
            <Text
              variant="primary"
            >
              {t('checkout.ShippingDetails.streetAddressLabel2')}
            </Text>
          ) }
          name="Address2"
          sx={ {
            marginLeft: [ 0, '12px' ],
          } }
          variant="forms.checkout.field"
        />
      </Flex>

      <Form.Field
        component={ Form.Input.Checkbox }
        data-testid="poBox"
        defaultChecked={ false }
        id="poBox"
        inline={ true }
        label={ (
          <Text
            variant="primary"
          >
            {t('checkout.PoBoxLabel')}
          </Text>
        ) }
        name="poBox"
        sx={ { mt: '16px' } }
      />

      <Flex sx={ { flexDirection: [ 'column', 'row' ] } }>
        <Form.Field
          autoComplete="shipping address-level2"
          data-testid="shipping_city"
          id="City"
          label={ (
            <Text
              variant="primary"
            >
              {t('checkout.ShippingDetails.cityLabel')}
            </Text>
          ) }
          name="City"
          sx={ {
            mr: [ 0, '6px' ],
          } }
          variant="forms.checkout.field"
        />
        <Form.Field
          autoComplete="shipping address-level1"
          component={ Form.Select }
          data-testid="shipping_state"
          id="StateProvinceIso"
          label={ (
            <Text
              variant="primary"
            >
              {t('checkout.ShippingDetails.stateLabel')}
            </Text>
          ) }
          name="StateProvinceIso"
          sx={ {
            width: [ '100%', '156px' ],
            mr: [ 0, '12px' ],
            ml: [ 0, '12px' ],
            svg: { color: 'black' },
          } }
        >
          <Option value="">---</Option>
          { _map(states, state => state.Abbreviation !== 'AK' && state.Abbreviation !== 'HI' && (
            <Option
              data-testid={ `shipping_state-${ state.Abbreviation }` }
              key={ state.Id }
              value={ state.Abbreviation }
            >
              { state.Abbreviation }
            </Option>
          )) }
        </Form.Field>
        <Form.Field
          autoComplete="shipping postal-code"
          data-testid="shipping_zipCode"
          id="ZipPostalCode"
          label={ (
            <Text
              variant="primary"
            >
              {t('checkout.ShippingDetails.postalCodeLabel')}
            </Text>
          ) }
          name="ZipPostalCode"
          sx={ {
            ml: [ 0, '6px' ],
          } }
          variant="forms.checkout.field"
        />
      </Flex>
    </Box>
  );
};
