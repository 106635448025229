import {
  Dispatch, SetStateAction, useEffect, useState,
} from 'react';

function useDebounce<T = unknown>(initialValue: T, delay: number): [ T, Dispatch<SetStateAction<T>>, T] {
  const [ value, setValue ] = useState<T>(initialValue);
  const [ debouncedValue, setDebouncedValue ] = useState<T>(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  },
  [ value, delay ]);

  return [ debouncedValue, setValue, value ];
}

export default useDebounce;
