/* eslint-disable @typescript-eslint/camelcase */
import TagManager from '../TagManager';

import { DataLayerItem } from './types';

const viewItem = (item: DataLayerItem) => {
  TagManager.pushToDataLayer({
    event: 'view_item',
    ecommerce: {
      currency: 'USD',
      value: item.price,
      items: [
        {
          item_name: item.name,
          item_id: item.id,
          price: item.price,
        },
      ],
    },
  });
};

export default viewItem;
