import 'react-image-gallery/styles/css/image-gallery.css';
import './carousel.css';
import React from 'react';
import ImageGallery, { ReactImageGalleryItem } from 'react-image-gallery';

import { Picture, useMediaQuery } from 'features/common';

export interface IProductImageCarouselProps {
  images: Picture[] | null;
}

const ProductImageCarousel: React.FC<IProductImageCarouselProps> = ({ images }) => {
  const isMobile = useMediaQuery('(max-width: 600px)');

  if (!images) {
    return null;
  }

  const galleryImages: ReactImageGalleryItem[] = images.map(picture => ({
    original: picture.VirtualPath,
    thumbnail: picture.VirtualPath,
    originalTitle: picture.TitleAttribute,
    originalAlt: picture.AltAttribute,
    thumbnailTitle: picture.TitleAttribute,
    thumbnailAlt: picture.AltAttribute,
  }));

  return (
    <ImageGallery
      items={ galleryImages }
      showFullscreenButton={ false }
      showNav={ false }
      showPlayButton={ false }
      showThumbnails={ galleryImages.length > 1 }
      thumbnailPosition={ isMobile ? 'bottom' : 'left' }
    />
  );
};

export default ProductImageCarousel;
