import React from 'react';
import { useTranslation } from '@lce/i18n';
import { Box, Button } from '@lce/slice_v2';

import { LandingDisclaimer, LandingText } from '../../components';
import { LandingForm } from '../../landing-form';

import { FundraiserType } from 'features/fundraiser';
import { useStoreQueryParamToLocalStorage } from 'ui/hooks';

export type CtaLeadCaptureProps = {
  fundraiserType: FundraiserType;
  page: string;
  testId?: string;
}

export const dataTestIdCtaLeadCapture = 'cta-lead-capture-component';

export const CtaLeadCapture: React.FC<CtaLeadCaptureProps> = ({
  fundraiserType,
  page,
  testId = dataTestIdCtaLeadCapture,
}) => {
  const [ t ] = useTranslation();
  useStoreQueryParamToLocalStorage('referralsource', 'referralSource');
  const translationPath = `landing.${ fundraiserType }.${ page }`;
  const getStartedUrl = t(`${ translationPath }.getStartedButtonLink`);

  return (
    <Box
      data-testid={ testId }
      sx={ {
        m: '0 auto 32px',
        maxWidth: '992px',
        textAlign: 'center',
      } }
    >
      <LandingText translationPath={ translationPath } />
      <Button
        as="a"
        href={ getStartedUrl }
        sx={ {
          width: '100%', maxWidth: '226px', minHeight: '40px',
          display: 'flex', m: '0 auto 12px', alignItems: 'center', justifyContent: 'center',
        } }
        variant="buttons.primary"
      >
        {t(`${ translationPath }.getStartedButtonText`)}
      </Button>
      <LandingForm translationPath={ translationPath } />
      <LandingDisclaimer translationPath={ translationPath } />
    </Box>
  );
};
