import {
  Box, Flex, Heading, Text,
} from '@lce/slice_v2';
import React from 'react';

import lcBackground from './assets/lc-slice-bg.png';

export interface IResourceHubHeaderProps {
  heading: string;
}

const ResourceHubHeader: React.FC<IResourceHubHeaderProps> = ({ heading }) => (
  <Box
    data-testid="resourceHubHeader"
    sx={ { pb: [ '0', '10px' ] } }
  >
    <Flex
      sx={ {
        background: `url(${ lcBackground }) repeat`,
        height: '200px', justifyContent: 'center',
        m: [ '0 auto 32px auto' ], backgroundRepeat: 'round',
        alignItems: 'center', backgroundSize: [ '16%', '9%', '7%' ],
      } }
    >
      <Box sx={ { alignItems: 'center' } }>
        <Heading
          sx={ {
            textAlign: 'center', color: 'primaryOrange', lineHeight: 1, maxWidth: [ 'unset', '343px' ],
          } }
        >
          <Text sx={ { fontSize: '40px' } }>
            { heading}
          </Text>
        </Heading>
      </Box>
    </Flex>
  </Box>
);

export default ResourceHubHeader;
