import addPaymentInfo from './addPaymentInfo';
import addShippingInfo from './addShippingInfo';
import addToCart from './addToCart';
import beginCheckout from './beginCheckout';
import purchase from './purchase';
import removeFromCart from './removeFromCart';
import viewCart from './viewCart';
import viewItem from './viewItem';
import viewItemList from './viewItemList';

export default {
  addPaymentInfo,
  addShippingInfo,
  addToCart,
  beginCheckout,
  purchase,
  removeFromCart,
  viewCart,
  viewItem,
  viewItemList,
};
