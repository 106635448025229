import { FundraiserListItem, FundraiserType, Group } from '../types';

export const hasIncompleteProfitCheckAddress = (fundraiser: FundraiserListItem | undefined,
  group: Group | undefined) => {
  if (fundraiser?.FundraiserType !== FundraiserType.MealDeal) {
    return false;
  }

  const missingFields =
  !fundraiser ||
  !fundraiser.ProfitCheckPayableTo ||
  !group ||
  !group.GroupAddress ||
  !group.GroupAddress.Address1 ||
  !group.GroupAddress.City ||
  !group.GroupAddress.StateProvinceIso ||
  !group.GroupAddress.ZipPostalCode;

  return missingFields;
};
