import React from 'react';
import {
  Text,
  Flex,
} from '@lce/slice_v2';
import { useTranslation } from '@lce/i18n';

interface IWarnings {
  warnings: string[];
}

export const WarningMessages: React.FC<IWarnings> = ({ warnings }) => {
  const [ t ] = useTranslation();

  return (
    <Flex sx={ { fontFamily: 'Roboto', fontSize: '14px', flexDirection: [ 'column' ] } }>
      {warnings.map(warning => (
        <Text key={ warning } variant="text.warning">
          { t(`warningMessage.${ warning }`)}
        </Text>
      ))}
    </Flex>
  );
};

