import {
  Container, Text, Flex, Link, Image,
} from '@lce/slice_v2';
import React, { useState } from 'react';
import { RouteProps } from 'react-router-dom';
import { useTranslation } from '@lce/i18n';

import VideoThumbnail from './assets/VideoThumb.jpg';
import FundraiserLoves from './assets/FundraiserLoves.jpg';

import { Banner } from 'ui/common';
import { HowItWorksInfoCards, InformationCards } from 'ui/howItWorks';
import VideoModal from 'ui/howItWorks/VideoModal';

type HowItWorksPageProps = RouteProps;

// eslint-disable-next-line max-lines-per-function
const HowItWorks: React.FC<HowItWorksPageProps> = () => {
  const [ t ] = useTranslation();
  const [ isOpen, setIsOpen ] = useState(false);

  return (
    <Container>
      <Text
        sx={ {
          fontSize: [ '24px', '24px', '34px' ],
          color: 'primaryOrange',
          mt: '16px',
          mb: '16px',
          textAlign: 'center',
        } }
        variant="text.header.subUpper"
      >
        {t('howItWorks.PageTitle')}
      </Text>
      <Flex
        sx={ {
          justifyContent: 'center',
          width: '100%',
        } }
      >
        <Link onClick={ () => setIsOpen(true) }>
          <Image
            alt="How-it-works-video" src={ VideoThumbnail } sx={ {
              height: 'auto',
              display: 'block',
              mb: 0,
              width: '100%',
              maxWidth: [ '340px', '416px', '580px' ],
              '&:hover': {
                cursor: 'pointer',
              },
            } }
          />
        </Link>
        <VideoModal
          isOpen={ isOpen }
          onClose={ () => setIsOpen(false) }
          url="https://player.vimeo.com/video/567361486"
        />
      </Flex>
      <InformationCards />
      <Banner
        buttonBgColor="black"
        buttonLink="/start-a-fundraiser"
        buttonMarginProps={ [ '19px', '90px', '156px' ] }
        buttonText={ t('howItWorks.BannerButtonText') }
        gradient="none"
        heightBreakpoints={ [ '200px', '316px', '450px' ] }
        image={ FundraiserLoves }
        showOnMobile={ false }
      />
      <Text
        sx={ {
          fontSize: [ '24px', '24px', '34px' ],
          color: 'primaryOrange',
          mt: '75px',
          mb: '16px',
          textAlign: 'center',
        } }
        variant="text.header.subUpper"
      >
        {t('howItWorks.TipsForSuccessTitle')}
      </Text>
      <Text
        sx={ {
          textAlign: 'center',
          lineHeight: '14px',
          fontSize: '16px',
          maxWidth: '700px',
          margin: 'auto',
          marginBottom: '27px',
        } }
      >
        {t('howItWorks.TipsForSuccessSubTitle')}
      </Text>
      <HowItWorksInfoCards />
    </Container>
  );
};

export default HowItWorks;
