import React, { useEffect, useState, ReactElement } from 'react';
import { Redirect } from 'react-router-dom';
import { Spinner, Container } from '@lce/slice_v2';

import { useUserInRoles } from 'features/common';

interface IPrivateComponentProps {
  roles: string[];
}

const PrivateComponent: React.FC<IPrivateComponentProps> = ({ roles, children }) => {
  const {
    mutateAsync: userRoles, isLoading, isError,
  } = useUserInRoles();
  const [ canActivate, setCanActivate ] = useState<boolean | undefined>(undefined);

  useEffect(() => {
    async function getUserRoles() {
      const data = await userRoles(roles);
      setCanActivate(data || false);
    }

    getUserRoles();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isError) {
    return <Redirect push={ true } to="/" />;
  }

  return (
    isLoading || canActivate === undefined
      ? (
        <Container data-testid="private-component" sx={ { textAlign: 'center', padding: '150px' } }>
          <Spinner variant="lce" />
        </Container>
      )
      : !canActivate
        ? <Redirect push={ true } to={ { pathname: '/' } } />
        : children as ReactElement
  );
};

export default PrivateComponent;
