import { useState } from 'react';

function useLocalStorage<T>(key: string, initialValue?: T): [T, (value) => void] {
  const [ state, setState ] = useState<T>(() => {
    try {
      const localStorageValue = localStorage.getItem(`@lce/local/${ key }`);

      if (typeof localStorageValue !== 'string') {
        localStorage.setItem(`@lce/local/${ key }`, JSON.stringify(initialValue));
        return initialValue;
      } else {
        return JSON.parse(localStorageValue || 'null');
      }
    } catch {
      // If user is in private mode or has storage restriction
      // localStorage can throw. JSON.parse and JSON.stringify
      // can throw, too.
      return initialValue;
    }
  });

  const setValue = (value: T) => {
    try {
      const serializedState = JSON.stringify(value);
      localStorage.setItem(`@lce/local/${ key }`, serializedState);
      setState(value);
    } catch {
      // Do nothing?
    }
  };

  return [ state, setValue ];
}

export default useLocalStorage;
