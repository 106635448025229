import { PagedList } from 'features/common';

export interface BuyerList extends PagedList<BuyerOrder> {
  BuyerCount: number;
}

export interface BuyerOrder {
  BuyerInfo: BuyerInfo;
  OrderInfo: BuyerOrderInfo;
}

export interface BuyerInfo {
  FirstName: string;
  LastName: string;
  Email: string;
}

export interface BuyerOrderInfo {
  Id: number;
  DateOfPurchase: Date;
  OrderItems: OrderItem[];
}

export interface OrderItem {
  Id: number;
  Name: string;
  Quantity: number;
}

export interface BuyerFilter {
  Filter?: string;
  SortByColumn: BuyerListSortFields;
  PageIndex: number;
  PageSize: number;
}

export enum BuyerListSortFields {
  OrderDate = 1,
  FullName = 2,
  KitsPurchased = 3,
}
