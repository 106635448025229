import React from 'react';
import { Box, Grid, Skeleton } from '@lce/slice_v2';
import _times from 'lodash/times';

export const FieldLoader: React.FC = () => (
  <Box>
    <Skeleton count={ 1 } sx={ { height: '20px', mb: '12px' } } />
    <Skeleton count={ 1 } sx={ { height: '36px' } } />
  </Box>
);

// eslint-disable-next-line max-lines-per-function
export const ShippingDetailsLoader: React.FC = () => (
  <Box data-testid="shipping-details-loader">
    <Skeleton count={ 1 } sx={ { height: '27px', width: '30%', mb: '24px' } } />
    <Grid
      sx={ {
        mb: '30px',
        gridColumnGap: '24px',
        gridRowGap: '30px',
        gridTemplateColumns: [ '1fr', '1fr 1fr' ],
      } }
    >
      { _times(4, index => (
        <FieldLoader key={ `user-${ index }` } />
      )) }
    </Grid>
    <Skeleton count={ 1 } sx={ { height: '27px', width: '40%', mb: '30px' } } />
    <Grid
      sx={ {
        gridColumnGap: '18px',
        mb: '30px',
        gridTemplateColumns: [ '1fr', '1fr 1fr 1fr' ],
        gridRowGap: '30px',
      } }
    >
      { _times(3, index => (
        <FieldLoader key={ `address-${ index }` } />
      )) }
    </Grid>
    <Skeleton count={ 1 } sx={ { height: '27px', width: '70%', mb: '24px' } } />

    <Grid
      sx={ {
        gridColumnGap: '18px',
        gridRowGap: '30px',
        gridTemplateColumns: [ '1fr', '1fr 1fr' ],
        mb: '30px',
      } }
    >
      { _times(3, index => (
        <FieldLoader key={ `contact-${ index }` } />
      )) }
    </Grid>

    <Skeleton count={ 1 } sx={ { height: '27px', width: '40%', mb: '8px' } } />
    <Skeleton count={ 1 } sx={ { height: '16px', width: '70%', mb: '33px' } } />
    <Skeleton count={ 1 } sx={ { height: '36px', width: '100%' } } />
  </Box>
);
