/* eslint-disable no-extra-parens */
/* eslint-disable implicit-arrow-linebreak */

import * as React from 'react';
import { ThemeUIStyleObject } from 'theme-ui';
import { Text } from '@lce/slice_v2';

export interface IScriptParserProps {
  text: string | undefined;
  scriptText?: string;
  sx?: ThemeUIStyleObject;
}

const ScriptParser: React.FC<IScriptParserProps> = ({ text, scriptText }) => {
  const splitValues = (text && text.split(' ')) || [];

  return (
    <>
      {splitValues.map(word =>
        (word === scriptText ? (
          <Text
            as="span"
            key={ `${ word }${ word.indexOf }` } sx={ {
              display: 'inline-block',
              fontFamily: 'lcScript',
              fontSize: [ '24px', '24px', '26px' ],
              transform: 'rotate(-2deg)translateY(-2px)',
              color: 'black',
              mr: '8px',
            } }
          >
            {`${ word }${ ' ' }`}
          </Text>
        ) : (
          <React.Fragment key={ `${ word }${ word.indexOf }` }>
            {`${ word }${ ' ' }`}
          </React.Fragment>
        )))}
    </>
  );
};

export default ScriptParser;
