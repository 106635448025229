import { Card, Flex, Skeleton } from '@lce/slice_v2';
import React from 'react';

const JoinFundraiserInfoCardSkeleton = () => (
  <Flex
    as={ Card }
    sx={ { flexDirection: 'column', width: [ '100%', '500px' ], alignItems: 'center' } }
    variant="cards.fundraiserJoin"
  >
    <Skeleton count={ 1 } sx={ { height: '20px', width: '100%' } } />
    <Skeleton count={ 1 } sx={ { height: '20px', width: '80%' } } />
    <Skeleton count={ 1 } sx={ { height: '181px', width: '80%', mt: '45px' } } />

    <Skeleton count={ 1 } sx={ { height: '22px', width: '60%', mt: '6px' } } />

    <Skeleton count={ 1 } sx={ { height: '18px', width: '90%', mt: '35px' } } />
    <Skeleton count={ 1 } sx={ { height: '18px', width: '100%' } } />
    <Skeleton count={ 1 } sx={ { height: '18px', width: '60%' } } />

    <Skeleton count={ 1 } sx={ { height: '12px', width: '45%', mt: '35px' } } />
    <Skeleton count={ 1 } sx={ { height: '12px', width: '50%' } } />
    <Skeleton count={ 1 } sx={ { height: '12px', width: '30%' } } />

  </Flex>
);

export default JoinFundraiserInfoCardSkeleton;
