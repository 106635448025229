import React from 'react';
import {
  Box, Button, Flex, Image,
} from '@lce/slice_v2';
import { ThemeUIStyleObject } from 'theme-ui';
import { useTranslation } from '@lce/i18n';

import PizzaPizza from 'assets/images/jumping-caesar.png';
import {
  LoginBox, RegisterUserBox,
} from 'ui/account';

export interface IAccountProps {
  returnUrl?: string;
  sx?: ThemeUIStyleObject;
}

export const Account: React.FC<IAccountProps> = ({ returnUrl, sx }) => {
  const [ t ] = useTranslation();
  const registerUrl = returnUrl
    ? `/start-a-fundraiser/meal-deal/create-account?redirectUrl=${ returnUrl }`
    : '/start-a-fundraiser/meal-deal/create-account';

  const loginUrl = returnUrl
    ? `/start-a-fundraiser/meal-deal/signin?redirectUrl=${ returnUrl }`
    : '/start-a-fundraiser/meal-deal/signin';

  return (
    <Flex
      data-testid="meal-deal-account"
      id="login"
      sx={ {
        height: [ 'unset', '562px' ], mt: [ 0, '68px' ], pb: [ '24px', 0 ],
        pt: [ '8px', 0 ], flexDirection: [ 'column', 'row' ],
        justifyContent: 'center', scrollMarginTop: '92px', ...sx,
      } }
    >
      <Box
        sx={ {
          width: '274px', my: 'auto', mr: '100px', display: [ 'none', 'none', 'block' ],
        } }
      >
        <Image alt="LC Fundraising Logo" src={ PizzaPizza } title="LC Fundraising" />
      </Box>

      <Flex
        sx={ {
          flexDirection: 'column', maxWidth: 'none', width: [ '100%', '400px' ], my: 'auto',
        } }
      >
        <RegisterUserBox>
          <Button
            onClick={ () => window.location.href = registerUrl }
            sx={ { width: '100%' } }
          >
            {t('common.CreateAccount')}
          </Button>
        </RegisterUserBox>
        <LoginBox>
          <Button
            onClick={ () => window.location.href = loginUrl }
            sx={ { width: '100%' } }
          >
            {t('common.SignIn')}
          </Button>
        </LoginBox>
      </Flex>
    </Flex>
  );
};
