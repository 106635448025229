/* eslint-disable max-lines-per-function */
import React, { useState } from 'react';
import { Text } from '@lce/slice_v2';
import { useTranslation } from '@lce/i18n';

import { SellerStatusCard } from '../SellerStatusCard';

import { Seller } from 'features/fundraiser/types/seller';

export interface ISellerStatus {
  isChairperson: boolean;
  sellers: Seller[];
  userId: number | undefined;
}
const SellerStatus: React.FC<ISellerStatus> = ({ userId, isChairperson, sellers }) => {
  const [ activeCount, setActiveCount ] = useState(sellers.filter(x => x.Active).length);
  const [ inactiveCount, setInactiveCount ] = useState(sellers.filter(x => !x.Active).length);
  const [ t ] = useTranslation();

  const ActivateSeller = () => {
    setActiveCount(activeCount + 1);
    setInactiveCount(inactiveCount - 1);
  };

  const DeactivateSeller = () => {
    setActiveCount(activeCount - 1);
    setInactiveCount(inactiveCount + 1);
  };

  const IsSellerTheChairperson = (sellerCustomerId: number) => {
    const checkCurrentSeller = isChairperson && userId !== undefined && userId === sellerCustomerId;
    return checkCurrentSeller;
  };

  return (
    <>
      {sellers.filter(x => x.Active).length === 0 && (
        <Text>
          {t('dashboard.ManageSellersPage.NoActiveSellers')}
        </Text>
      )}
      {sellers.filter(x => x.Active).map(seller => (
        <SellerStatusCard
          activateSeller={ ActivateSeller }
          deactivateSeller={ DeactivateSeller }
          fundraiserSeller={ seller }
          isChairperson={ IsSellerTheChairperson(seller.CustomerId) }
          key={ seller.Id }
        />
      ))}
      {inactiveCount > 0 && (
        <Text data-testid="inactive-sellers" sx={ { mt: '30px', mb: '10px' } } variant="text.h5">
          {t('dashboard.ManageSellersPage.InactiveSellers')}
        </Text>
      )}
      {sellers.filter(x => !x.Active).map(seller => (
        <SellerStatusCard
          activateSeller={ ActivateSeller }
          deactivateSeller={ DeactivateSeller }
          fundraiserSeller={ seller }
          isChairperson={ IsSellerTheChairperson(seller.CustomerId) }
          key={ seller.Id }
        />
      ))}
    </>
  );
};

export default SellerStatus;
