import React from 'react';
import { Form } from '@lce/slice_v2';
import { ThemeUIStyleObject } from 'theme-ui';
import MaskedInput from 'react-input-mask';
import * as Yup from 'yup';

interface IPhoneInput {
  defaultValue?: string;
  label: string;
  name?: string;
  required?: boolean;
  sx?: ThemeUIStyleObject;
  testId?: string;
  hint?: string;
}

export const phonePattern = /^(?:\+1\s*)?(?:\([2-9]{1}[0-9]{2}\)\s*|[2-9]{1}[0-9]{2}-)[0-9]{3}-[0-9]{4}$/;

export const defaultPhoneInputName = 'PhoneNumber';
export const defaultPhoneInputTestId = 'phone-number';

export const phoneInputSchema = (t, validationKey: string = defaultPhoneInputName) => ({
  [validationKey]: Yup.string()
    .matches(phonePattern, t('fundraiser.PhoneNumberValid'))
    .min(10, t('fundraiser.PhoneNumberLength'))
    .max(17, t('fundraiser.PhoneNumberLength'))
    .required(t('fundraiser.PhoneRequired')),
});

export const PhoneInput: React.FC<IPhoneInput> = ({
  defaultValue = '',
  label,
  name = defaultPhoneInputName,
  required = false,
  sx,
  testId = defaultPhoneInputTestId,
  hint,
}) => (
  <MaskedInput
    defaultValue={ defaultValue }
    mask="(999) 999-9999"
    name={ name }
    type="phone"
  >
    {fieldProps => (
      <Form.Field
        { ...fieldProps }
        autoComplete="tel-national"
        data-testid={ testId }
        hint={ hint }
        id={ name }
        label={ label }
        required={ required }
        sx={ sx }
        variant="forms.fundraiser.phone"
      />
    )}
  </MaskedInput>
);
