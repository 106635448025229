/* eslint-disable max-len */
import { useTranslation } from '@lce/i18n';
import React, { useState } from 'react';
import {
  Container, Flex, Link,
} from '@lce/slice_v2';
import { ThemeUIStyleObject } from 'theme-ui';

import VideoModal from '../VideoModal';

import HIWCard from './HIWCard';

import TimeToSell from 'assets/icons/TimeToSell.svg';
import Register from 'assets/icons/Register.svg';
import CloseFundraiser from 'assets/icons/CloseFundraiser.svg';
import DollarSign from 'assets/icons/DollarSign.svg';
import GetWordOut from 'assets/icons/GetWordOut.svg';
import ClappingHands from 'assets/icons/ClappingHands.svg';

// eslint-disable-next-line max-lines-per-function
const InformationCards: React.FC = () => {
  const [ t ] = useTranslation();

  const [ isOpen, setIsOpen ] = useState(0);

  const VideoLink: ThemeUIStyleObject = {
    color: 'primaryOrange',
    background: 'white',
    border: 'none',
    padding: 0,
    fontSize: '16px',
    '&: hover': {
      cursor: 'pointer',
    },
  };

  return (
    <Container sx={ { mt: [ '16px', '20px', '56px' ], mb: '24px' } }>
      <Flex sx={ { flexFlow: 'wrap', justifyContent: 'center', alignItems: 'center' } }>
        <HIWCard icon={ Register } title={ t('howItWorks.accordians.Title1') }>
          It’s easy to get started as soon as today with just a few clicks!
          {' '}
          <Link href="/start-a-fundraiser" sx={ { color: 'primaryOrange' } }>Register</Link>
          {' '}
          online 24x7, pick your start date, and your fundraiser is created. If you’d prefer to chat with a real person, one of our friendly Customer Care Representatives is always available 8:30AM-6:00PM EST
          {' '}
          <Link
            aria-label="how to register your fundraiser video" as="button" href="#" onClick={ () => setIsOpen(1) }
            sx={ VideoLink }
          >
            Show Me How
          </Link>
        </HIWCard>
        <HIWCard icon={ GetWordOut } title={ t('howItWorks.accordians.Title2') }>
          {t('howItWorks.accordians.Content2-1')}
          {' '}
          <Link
            aria-label="how to register your fundraiser video" as="button" href="#" onClick={ () => setIsOpen(1) }
            sx={ VideoLink }
          >
            (Show Me How)
          </Link>
          {' '}
          {t('howItWorks.accordians.Content2-2')}
          {' '}
          <Link
            aria-label="invite your group video" as="button" href="#" onClick={ () => setIsOpen(2) }
            sx={ VideoLink }
          >
            (Show Me How)
          </Link>
          {t('howItWorks.accordians.Content2-3')}
        </HIWCard>
        <HIWCard icon={ TimeToSell } title={ t('howItWorks.accordians.Title3') }>
          {t('howItWorks.accordians.Content3')}
        </HIWCard>
        <HIWCard icon={ ClappingHands } title={ t('howItWorks.accordians.Title4') }>
          {t('howItWorks.accordians.Content4')}
        </HIWCard>
        <HIWCard icon={ CloseFundraiser } title={ t('howItWorks.accordians.Title5') }>
          {t('howItWorks.accordians.Content5')}
        </HIWCard>
        <HIWCard icon={ DollarSign } title={ t('howItWorks.accordians.Title6') }>
          {t('howItWorks.accordians.Content6')}
        </HIWCard>
      </Flex>
      <VideoModal isOpen={ isOpen === 2 } onClose={ () => setIsOpen(0) } url="https://player.vimeo.com/video/567360169" />
      <VideoModal isOpen={ isOpen === 1 } onClose={ () => setIsOpen(0) } url="https://player.vimeo.com/video/567361486" />
    </Container>
  );
};

export default InformationCards;
