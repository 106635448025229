import * as React from 'react';
import { Helmet } from 'react-helmet';

const CookieConsent: React.FC = () => {
  const cookieConsentKey = process.env.REACT_APP_COOKIE_CONSENT_KEY;
  const cookieConsentUrl = process.env.REACT_APP_COOKIE_CONSENT_URL;
  return (
    <Helmet>
      <script
        src={ `https://cdn.cookielaw.org/consent/${ cookieConsentKey }/OtAutoBlock.js` }
        type="text/javascript"
      />
      <script
        charSet="UTF-8"
        data-domain-script={ cookieConsentKey }
        src={ cookieConsentUrl }
        type="text/javascript"
      />
      <script type="text/javascript">
        {'function OptanonWrapper() {}'}
      </script>
    </Helmet>
  );
};

export default CookieConsent;
