import { CheckoutRequest } from './types/request';
import { PlaceOrderRequest, OrderPaymentMethodRequest } from './types/request/checkout';
import { CheckoutInfo } from './types/interface';

import api from 'api';
import { Address } from 'features/common';
import { CartItem } from 'features/cart';

export const fetchUserAddress = async() => api.get<Address>('api/buyer/address')
  .then(res => res);

export const startCheckout = async(request: CheckoutRequest) => api.post<CheckoutInfo>('api/checkout', request)
  .then(res => res);

export const validateAddress = async(request: Address) => api.post('api/reference/address-validation', request)
  .then(res => res);

// eslint-disable-next-line max-len
export const setOrderPaymentMethod = async(request: OrderPaymentMethodRequest) => api.post('api/checkout/paymentstatus', request)
  .then(res => res);

export const placeOrder = async(request: PlaceOrderRequest) => api.post('api/checkout/placeorder', request)
  .then(res => res);

export const fetchOrderByGuid = async(order: string) => api.get(`api/order/${ order }`)
  .then(res => res);

export const fetchShippingCost = async(cartItems: CartItem[]) => api.post<number>('api/cart/shippingcost', cartItems)
  .then(res => res);
