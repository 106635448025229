import React from 'react';
import {
  Flex, Text, Form, Modal as SliceModal, IModalProps as SliceModalProps, Button,
} from '@lce/slice_v2';

import { Address } from 'features/common';

export interface IModalProps {
  onSelect: (selection: string) => void;
  suggestedAddress: Address | undefined;
  originalAddress: Address | undefined;
}

// eslint-disable-next-line max-lines-per-function
const AddressConfirmModal: React.FC<IModalProps & SliceModalProps> = (props) => {
  const handleOnSubmit = (e) => {
    props.onSelect(e.address);
    props.onClose();
  };

  return (
    <SliceModal
      { ... props }
      closeIcon={ false }
      closeOnClickOutside={ false }
      data-testid="address-confirm-modal"
      lockBodyScroll={ true }
      onClose={ props.onClose }
      overlay={ true }
      sx={ {
        maxWidth: '700px', borderRadius: '40x', mt: [ '50%', '5%' ],
      } }
    >
      <Flex
        sx={ {
          flexDirection: 'column', paddingLeft: '20px', paddingRight: '20px',
        } }
      >
        <Form
          initialValues={ { address: 'suggested' } }
          onSubmit={ e => handleOnSubmit(e) }
        >
          <Form.Group hint="Please pick an address" name="address">
            <Flex sx={ { mb: '30px', mt: '15px' } }>
              <Form.Field
                component={ Form.Input.Radio }
                id="original-address"
                label=""
                name="original-address"
                value="original"
              />
              <Flex sx={ { flexDirection: 'column' } }>
                <Text>
                  {props.originalAddress?.Address1}
                  {' '}
                  {props.originalAddress?.Address2}
                </Text>
                <Text>
                  {`${ props.originalAddress?.City }
                  ${ props.originalAddress?.StateProvinceIso },
                  ${ props.originalAddress?.ZipPostalCode }`}
                </Text>
              </Flex>
            </Flex>
            <Flex>
              <Form.Field
                component={ Form.Input.Radio }
                id="suggested-address"
                label=""
                name="suggested-address"
                value="suggested"
              />
              <Flex sx={ { flexDirection: 'column' } }>
                <Text>
                  {props.suggestedAddress?.Address1}
                  {' '}
                  {props.suggestedAddress?.Address2}
                </Text>
                <Text>
                  {`${ props.suggestedAddress?.City }
                  ${ props.suggestedAddress?.StateProvinceIso },
                  ${ props.suggestedAddress?.ZipPostalCode }`}
                </Text>
              </Flex>
            </Flex>
          </Form.Group>
          <Button
            data-testid="address_confirmModal"
            sx={ { width: '100%' } }
            type="submit"
          >
            Confirm
          </Button>
        </Form>
      </Flex>
    </SliceModal>
  );
};

export default AddressConfirmModal;
