import { useMutation } from 'react-query';

import { submitContactRequest } from '../api';
import { ContactUsRequest } from '../types';

import { ApiError, ApiResponse } from 'features/common';

const useContactUs = () => useMutation<ApiResponse, ApiError, ContactUsRequest>(submitContactRequest);

export default useContactUs;
