import { useMutation, useQueryClient } from 'react-query';

import { updateFundraiserEmailPreferences } from '../api';
import { FundraiserEmailPreferences } from '../../../features/fundraiser/types/fundraiser';

import { ApiError } from 'features/common';
import * as QueryKeys from 'constants/QueryKeys';

interface IUpdateEmailPrefRequest {
  fundraiserPreferences: FundraiserEmailPreferences;
}

// eslint-disable-next-line max-len
export const useUpdateEmailPreferences = () => {
  const queryClient = useQueryClient();

  return useMutation<boolean, ApiError, IUpdateEmailPrefRequest>(updateFundraiserEmailPreferences, {
    onSuccess: () => {
      queryClient.invalidateQueries(`${ QueryKeys.EmailNotifications }`);
    },
  });
};
