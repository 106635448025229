import React, { useMemo } from 'react';
import * as Yup from 'yup';
import { map as _map } from 'lodash';
import { useTranslation } from '@lce/i18n';
import {
  Heading, Form, Box, Button, Text,
} from '@lce/slice_v2';
import { toast } from 'react-toastify';
import { FormProvider, useForm } from 'react-hook-form';

import { useAddSalesAgent } from 'features/salesAgent/hooks/useAddSalesAgent';
import { ApiError } from 'features/common';
import { ClearButton, useFormReset } from 'ui/components';
import { GenericToast } from 'ui/common';

export const dataTestId = 'create-sales-agent';

interface ICreateSalesAgentProps {
  testId?: string;
}
type TOption = {
  label: string;
  value: number;
}

// eslint-disable-next-line max-lines-per-function
export const CreateSalesAgent: React.FC<ICreateSalesAgentProps> = ({ testId = dataTestId }) => {
  const [ t ] = useTranslation();
  const { mutateAsync: addSalesAgent } = useAddSalesAgent();
  const methods = useForm();
  const { resetForm, setResetForm } = useFormReset();
  const validationSchema = useMemo(() => Yup.object({
    salesCode: Yup.string().required(t('admin.createAgent.validation.salesCode'))
      .max(15, t('admin.createAgent.validation.MaxLength15'))
      .matches(/^[0-9]+$/, t('admin.createAgent.validation.TypeError')),
    salesAgentType: Yup.string().required(t('admin.createAgent.validation.salesAgentType')),
    company: Yup.string().optional()
      .max(100, t('admin.createAgent.validation.MaxLength100')),
    email: Yup.string().email()
      .required(t('admin.createAgent.validation.email'))
      .max(100, t('admin.createAgent.validation.MaxLength100')),
  }), [ t ]);
  const salesAgentTypeOptions: TOption[] = t('admin.createAgent.form.salesAgentTypes', { returnObjects: true });
  const handleOnSubmit = async(data) => {
    try {
      await addSalesAgent(data);
      resetForm();
      toast(<GenericToast text={ t('admin.createAgent.toast.successMessage') } />);
    } catch (err) {
      const error = err as ApiError;
      if (error.ErrorCode?.includes('ERR-OK-SALESAGENT')) {
        toast.error(t(`admin.createAgent.toast.${ error.ErrorCode }`));
      } else {
        toast.error(t('admin.createAgent.toast.errorMessage'));
      }
    }
  };

  return (
    <Box
      data-testid={ testId }
      sx={ {
        'input:disabled': {
          color: '#DCDAD1',
          padding: '2px',
          backgroundImage: 'none',
        },
      } }
    >
      <Heading as="h1">
        {t('admin.createAgent.pageTitle')}
      </Heading>
      <FormProvider { ...methods }>
        <Form
          initialValues={ {
            salesAgentType: '',
            email: '',
          } }
          onSubmit={ data => handleOnSubmit(data) }
          reValidateMode="onSubmit"
          summary={ false }
          validationSchema={ validationSchema }
        >
          <Box
            sx={ { boxShadow: '0px 0px 4px 1px rgba(0, 0, 0, 0.15)', borderRadius: '4px' } }
          >
            <Box
              sx={ {
                fontSize: '14px', fontWeight: 'bold', textTransform: 'uppercase',
                mt: 5, p: '4px 30px',
                backgroundColor: '#eee',
              } }
            >
              {t('admin.createAgent.form.title')}
            </Box>
            <Box sx={ { width: '100%', p: '28px' } }>
              <Text>
                {t('admin.createAgent.form.requiredFields')}
              </Text>
              <Box
                sx={ {
                  display: 'grid', gridTemplateColumns: [ '1fr', '1fr 1fr' ], columnGap: '28px',
                  width: '100%',
                  mb: '28px',
                  p: 0,
                } }
              >
                <Form.Field
                  data-testid="sales-agent-code"
                  defaultValue=""
                  id="sales-agent-code"
                  label={ t('admin.createAgent.form.salesAgentCode') }
                  name="salesCode"
                  size={ 20 }
                  sx={ { flex: '1' } }
                  type="text"
                />
                <Form.Field
                  component={ Form.Select }
                  data-testid="sales-agent-type"
                  id="sales-agent-type"
                  label={ t('admin.createAgent.form.salesAgentType') }
                  name="salesAgentType"
                  sx={ { flex: '1' } }
                >
                  <option value="" />
                  {_map(salesAgentTypeOptions, option => (
                    <option key={ option.label } value={ option.value }>
                      {option.label}
                    </option>
                  ))}
                </Form.Field>
                <Form.Field
                  data-testid="company"
                  id="company"
                  label={ t('admin.createAgent.form.company') }
                  name="company"
                  size={ 20 }
                  sx={ { flex: '1' } }
                  type="text"
                />
                <Form.Field
                  data-testid="email"
                  id="email"
                  label={ t('admin.createAgent.form.email') }
                  name="email"
                  size={ 20 }
                  sx={ { flex: '1' } }
                  type="text"
                />
              </Box>
              <Button
                data-testid="submit-button"
                sx={ { height: '46px', width: '100%' } }
                type="submit"
                variant="primary"
              >
                {t('admin.createAgent.form.submitButton')}
              </Button>
            </Box>
            <Box sx={ { m: '20px auto auto auto' } }>
              {/* ClearButton is needed to clear the form when the form is successfully submitted */}
              <ClearButton
                setReset={ setResetForm } sx={ { display: 'none' } }
                text={ t('admin.createAgent.form.cancelButton') }
              />
            </Box>
          </Box>
        </Form>
      </FormProvider>
    </Box>
  );
};
