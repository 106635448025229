import React from 'react';
import { Text, Flex, Box } from '@lce/slice_v2';
import { useTranslation } from '@lce/i18n';
import _map from 'lodash/map';

import FundraisingSupportBackground from './assets/fundraising-support-background.webp';
import SupportIconTile, { ISupportIconTile } from './SupportIconTile';

const FundraisingSupport: React.FC = () => {
  const [ t ] = useTranslation();
  const supportCards: ISupportIconTile[] = t('home.fundraisingSupport.cards', { returnObjects: true });

  return (
    <Flex
      data-testid="fundraising-support"
      sx={ {
        alignItems: 'center',
        background: `linear-gradient(rgba(255, 96, 0, 0.85),rgba(255, 96, 0, 0.85)), 
          url(${ FundraisingSupportBackground }) no-repeat center center`,
        backgroundSize: 'cover',
        color: 'white',
        flexDirection: 'column',
        gap: '16px',
        p: [ '32px 16px', '32px', '64px 32px' ],
        width: '100%',
      } }
    >
      <Flex
        sx={ {
          flexDirection: 'column',
          justifyContent: 'center',
          textAlign: 'center',
          gap: '8px',
        } }
      >
        <Text variant="home.subheading">
          {t('home.fundraisingSupport.title')}
        </Text>
        <Text variant="home.subdescription">
          {t('home.fundraisingSupport.description')}
        </Text>
      </Flex>
      <Box
        sx={ {
          columnGap: '32px',
          display: 'grid',
          gridTemplateColumns: [ '1fr', '1fr 1fr', '1fr 1fr 1fr 1fr' ],
          maxWidth: '1216px',
          rowGap: '16px',
        } }
      >
        {_map(supportCards, card => (
          <SupportIconTile { ...card } key={ card.title } />
        ))}
      </Box>
    </Flex>
  );
};

export default FundraisingSupport;
