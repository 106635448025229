import { Product } from 'features/products';

export const filterProductList = (productResult: Product[], categoryId: number) => {
  const products: Product[] = [];
  productResult.map((product) => {
    product.Categories.map((category) => {
      if (category.CategoryId === categoryId) {
        products.push(product);
      }
      return true;
    });
    return true;
  });

  products.sort((a, b) => {
    const firstCategory = a.Categories.map((category) => {
      if (category.CategoryId === categoryId) {
        return category.DisplayOrder;
      } else {
        return undefined;
      }
    });

    const secondCategory = b.Categories.map((category) => {
      if (category.CategoryId === categoryId) {
        return category.DisplayOrder;
      } else {
        return undefined;
      }
    });

    const filteredFirstCategory = firstCategory.filter(x => x !== undefined);

    const first = filteredFirstCategory && filteredFirstCategory[0] !== undefined ? filteredFirstCategory[0] : 100;

    const filteredSecondCategory = secondCategory.filter(x => x !== undefined);

    const second = filteredSecondCategory && filteredSecondCategory[0] !== undefined ? filteredSecondCategory[0] : 100;

    return first - second;
  });
  return products;
};
