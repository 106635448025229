import React from 'react';
import { IFieldProps } from '@lce/slice_v2/Elements/Form/Field';
import { Form } from '@lce/slice_v2';

interface IDateRange extends Omit<IFieldProps, 'label'> {
  name: string;
  startLabel?: string;
  endLabel?: string;
}

const styling = {
  width: [ '100%', '100%', '49.75%', '34.25%' ],
  maxWidth: [ '350px', '100%' ],
  flex: [ '1 1 auto', '1 1 200px' ],
};

const DateRange: React.FC<IDateRange> = ({
  name, startLabel = 'Start Date', endLabel = 'End Date',
}) => (
  <>
    <Form.Field
      id={ `${ name }StartDate` }
      label={ startLabel }
      name={ `${ name }StartDate` }
      sx={ {
        margin: [ '0 0 1.5% 0', '0' ],
        ...styling,
      } }
      type="date"
    />
    <Form.Field
      id={ `${ name }EndDate` }
      label={ endLabel }
      name={ `${ name }EndDate` }
      sx={ {
        margin: [ '0 0 0 0', '0 0 0 1.5%' ],
        ...styling,
      } }
      type="date"
    />
  </>
);

export default DateRange;
