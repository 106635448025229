import { getDateString, offsetDateDays } from '../DateExtensions';

import { IReportFilterFormData } from './ReportFilters';

export const defaultState: IReportFilterFormData = { // initial date
  reportType: 'fundraiser-summary',
  reportFormat: 'xlsx',
  reportStatuses: null,

  statusAll: true,
  statusActive: false,
  statusCompleted: false,
  statusCancelled: false,
  statusUpcoming: false,

  rangeStartDate: getDateString(offsetDateDays(new Date(), -364)),
  rangeEndDate: getDateString(new Date()),

  statesSelector: 'allStates',
  statesSelection: null,

  salesRepsSelector: 'allSalesReps',
  salesRepsSelection: null,

  iscsSelector: 'allISCs',
  iscsSelection: null,
};
