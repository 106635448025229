import { useMutation, useQueryClient } from 'react-query';

import { updateSeller } from '../api';
import { UpdateSellerRequest } from '../types/seller';

import * as QueryKeys from 'constants/QueryKeys';
import { ApiError } from 'features/common';

export const useUpdateSeller =
  (fundraiserId, sellerId) => {
    const queryClient = useQueryClient();
    return useMutation<boolean, ApiError, UpdateSellerRequest>(updateSeller,
      {
        onSuccess: () => {
          queryClient.invalidateQueries(`${ QueryKeys.SellerDetailPrefix }${ fundraiserId }-${ sellerId }`);
        },
      });
  };
