import api from 'api';

const uploadSellerPhoto =
  async(form: { id: number; data: FormData }) => api.post(`api/imageupload/seller/${ form.id }`, form.data)
    .then(res => res);

const uploadFundraiserPhoto =
  async(form: { id: number; data: FormData }) => api.post(`api/imageupload/fundraiser/${ form.id }`, form.data)
    .then(res => res);

export { uploadSellerPhoto, uploadFundraiserPhoto };
